import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "../../classes/Routes";
import Button, { EButtonColor } from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import InputGroup from "../../components/UI/InputGroup/InputGroup";
import ModalContext, { EModalSize, defaultModal } from "../../components/UI/Modal/ModalContext";
import firebase from "../../firebase";
import { useAuthUser } from "../../hooks/useAuthUser";
import { IAppState, ISettingsState } from "../../interfaces";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

interface ISettingsParams {
  pipedriveSuccess: string;
  netsSuccess: string;
  netsError: string;
}

enum ESettingsParams {
  pipedriveSuccess = "pipedriveSuccess",
  netsSuccess = "netsSuccess",
  netsError = "netsError",
}

interface IProps extends RouteComponentProps { }

const Settings: React.FC<IProps> = ({ history, location }) => {
  const params = useMemo((): ISettingsParams => {
    const params = new URLSearchParams(location.search);
    return {
      pipedriveSuccess: params.get(ESettingsParams.pipedriveSuccess) ?? "",
      netsSuccess: params.get(ESettingsParams.netsSuccess) ?? "",
      netsError: params.get(ESettingsParams.netsError) ?? "",
    };
  }, [location.search]);

  const { setModal } = useContext(ModalContext);
  const authUser = useAuthUser();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, netsUrl } = useSelector<IAppState, ISettingsState>(state => state.settings);

  useEffect(() => {
    dispatch(actions.getSettings());
  }, [dispatch]);

  useEffect(() => {
    if (params.pipedriveSuccess) {
      setModal({
        isOpen: true,
        title: t(ETranslation.PIPEDRIVE_SUCCESS_TITLE),
        size: EModalSize.SMALL,
        content: (
          <div>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="green" size="lg" />
              {" "}
              {t(ETranslation.PIPEDRIVE_SUCCESS_TEXT)}
            </p>
          </div>
        ),
      });
    } else if (params.netsSuccess) {
      setModal({
        isOpen: true,
        title: t(ETranslation.NETS_EIDENT_TITLE),
        size: EModalSize.SMALL,
        content: (
          <div>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="green" size="lg" />
              {" "}
              {t(ETranslation.NETS_EIDENT_SUCCESS)}
            </p>
          </div>
        ),
      });
    } else if (params.netsError) {
      setModal({
        isOpen: true,
        title: t(ETranslation.NETS_EIDENT_TITLE),
        size: EModalSize.SMALL,
        content: (
          <div>
            <p>{t(ETranslation.NETS_EIDENT_FAIL)}</p>
          </div>
        ),
      });
    }
  }, [params.pipedriveSuccess, setModal, t, params.netsSuccess, params.netsError]);

  const setPasswordResetDialog = () => setModal({
    isOpen: true,
    title: t(ETranslation.SEND_PASSWORD_RESET_LINK),
    size: EModalSize.SMALL,
    content: (
      <div>
        <p>{t(ETranslation.PASSWORD_RESET_TEXT)}</p>
        <InputGroup>
          <Button onClick={() => sendEmail()}>{t(ETranslation.COMMON_YES)}</Button>
          <Button onClick={() => setModal(defaultModal)}>{t(ETranslation.COMMON_NO)}</Button>
        </InputGroup>
      </div>
    ),
  })

  const sendEmail = () => {
    if (authUser && authUser.email) {
      sendPasswordResetEmail(getAuth(firebase), authUser.email);
    }
    setModal(defaultModal);
  }

  const netsHandler = () => {
    if (netsUrl) window.open(netsUrl, "_self");
  }

  return (
    <Container>
      <h2>{t(ETranslation.TITLE_SETTINGS)}</h2>
      <div>
        <Button color={EButtonColor.SECONDARY} onClick={setPasswordResetDialog}>{t(ETranslation.SEND_PASSWORD_RESET_LINK)}</Button>
        <br /><br />
        <Button color={EButtonColor.SECONDARY} onClick={() => history.push(Routes.FINANCE_COMPANIES)}>{t(ETranslation.TITLE_FINANCE_COMPANIES)}</Button>
        <br /><br />
        <Button color={EButtonColor.SECONDARY} onClick={() => history.push(Routes.DEALERSHIPS)}>{t(ETranslation.TITLE_DEALERSHIPS)}</Button>
        <br /><br />
        <Button color={EButtonColor.SECONDARY} onClick={netsHandler} loading={loading}>{t(ETranslation.NETS_EIDENT_TITLE)}</Button>
      </div>
      {/* <h2>{t(ETranslation.TITLE_INTEGRATIONS)}</h2> */}
      {/* {loading ? <Spinner /> : (
        <div>
          <p>Talenom-integraation tila:{' '}
            {auth?.expired ? (<a className={classes.Danger} href={auth.authUrl}>vaatii kirjautumisen</a>)
              : (<span className={classes.Success}>ok</span>)}
          </p>
          <p>Uusin haettu lasku: {auth?.latestInvoice?.dateString}</p>
        </div>
      )} */}
    </Container>
  );
}

export default Settings;
