import { INoteAction, INoteState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: INoteState = {
  loading: false,
  notes: null,
  error: null,
};

const listNotesStart = (state: INoteState, action: INoteAction): INoteState => {
  return updateObject(state, {
    notes: null,
    loading: true,
    error: null,
  });
};

const listNotesSuccess = (state: INoteState, action: INoteAction): INoteState => {
  return updateObject(state, {
    notes: action.notes,
    loading: false,
    error: null,
  });
};

const listNotesFail = (state: INoteState, action: INoteAction): INoteState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveNoteStart = (state: INoteState, action: INoteAction): INoteState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveNoteSuccess = (state: INoteState, action: INoteAction): INoteState => {
  const notes = state.notes?.slice() || [];
  if (action.note) {
    if (notes.length > 0) notes.unshift(action.note);
    else notes.push(action.note);
  }
  return updateObject(state, {
    notes,
    loading: false,
    error: null,
  });
};

const saveNoteFail = (state: INoteState, action: INoteAction): INoteState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteNoteStart = (state: INoteState, action: INoteAction): INoteState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const deleteNoteSuccess = (state: INoteState, action: INoteAction): INoteState => {
  return updateObject(state, {
    notes: state.notes?.filter(note => note.id !== action.id),
    loading: false,
    error: null,
  });
};

const deleteNoteFail = (state: INoteState, action: INoteAction): INoteState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (state: INoteState = initialState, action: INoteAction): INoteState => {
  switch (action.type) {
    case ActionTypes.NOTES_LIST_START: return listNotesStart(state, action);
    case ActionTypes.NOTES_LIST_SUCCESS: return listNotesSuccess(state, action);
    case ActionTypes.NOTES_LIST_FAIL: return listNotesFail(state, action);
    case ActionTypes.NOTES_SAVE_START: return saveNoteStart(state, action);
    case ActionTypes.NOTES_SAVE_SUCCESS: return saveNoteSuccess(state, action);
    case ActionTypes.NOTES_SAVE_FAIL: return saveNoteFail(state, action);
    case ActionTypes.NOTES_DELETE_START: return deleteNoteStart(state, action);
    case ActionTypes.NOTES_DELETE_SUCCESS: return deleteNoteSuccess(state, action);
    case ActionTypes.NOTES_DELETE_FAIL: return deleteNoteFail(state, action);
    default: return state;
  }
};

export default reducer;
