import React from "react";
import Table from "../UI/Table/Table";
import { ILogItemChange } from "../../interfaces/domain";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

const LogItemChangesTable: React.FC<{ id: string, logItemChanges: ILogItemChange[] }> = ({ id, logItemChanges }) => {
  const {t} = useTranslation();

  return (
    <Table hover={false}>
      <thead>
      <tr>
          <th>{t(ETranslation.LOG_FIELD_NAME)}</th>
          <th>{t(ETranslation.LOG_OLD_VALUE)}</th>
          <th>{t(ETranslation.LOG_NEW_VALUE)}</th>
      </tr>
      </thead>
      <tbody>
      {logItemChanges.map((logItemChange, i) => {
          const fieldNameParts = logItemChange.fieldName ? logItemChange.fieldName.split('.') : [];
          // ":" doesnt work without checking for it
          const translatedFieldName = fieldNameParts.map(part => part.includes(':') ? part : t(part)).join('.');
          return (
            <tr key={id + i}>
              <td>{translatedFieldName}</td>
              <td>{logItemChange.oldValue ? t(logItemChange.oldValue) : <FontAwesomeIcon icon={faBan}/>}</td>
              <td>{logItemChange.newValue ? t(logItemChange.newValue) : <FontAwesomeIcon icon={faBan}/>}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  )
}

export default LogItemChangesTable;
