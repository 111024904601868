import { JSON_REPORTS_HANDOVERS } from "./../../endpoints/index";
import axios from "../../axios";
import { TDispatch } from "./../../index";
import { IDeal } from "./../../interfaces/domain";
import { ActionTypes } from "./actionTypes";
import { IReportAction, IAppState } from "../../interfaces";
import { ThunkAction } from "redux-thunk";
import { JSON_REPORTS_LIST } from "../../endpoints";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";

const listReportsStart = (): IReportAction => {
  return {
    type: ActionTypes.LIST_REPORTS_START,
  };
};

const listReportsSuccess = (reports: Array<IDeal>): IReportAction => {
  return {
    type: ActionTypes.LIST_REPORTS_SUCCESS,
    reports,
  };
};

const listReportsFail = (error: string): IReportAction => {
  return {
    type: ActionTypes.LIST_REPORTS_FAIL,
    error,
  };
};

type TListReportsPromise = Promise<IDeal[] | null>;
type TListReportsAction = ThunkAction<
  TListReportsPromise,
  IAppState,
  any,
  IReportAction
>;

export interface IReportsSearchOptions {
  financeCompanyId?: string;
  status?: string;
  sellerId?: string;
  customerId?: string;
  year?: string;
  archived?: boolean;
}

export const listReports = (
  options: IReportsSearchOptions
): TListReportsAction => (dispatch: TDispatch): TListReportsPromise => {
  dispatch(listReportsStart());
  return axios
    .post(JSON_REPORTS_LIST, options)
    .then((res) => {
      dispatch(listReportsSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch(({ response }) => {
      dispatch(listReportsFail((response as AxiosResponse).data));
      return Promise.resolve((response as AxiosResponse).data);
    });
};

const listHandoversStart = (): IReportAction => {
  return {
    type: ActionTypes.LIST_HANDOVERS_START,
  };
};

const listHandoversSuccess = (handovers: Map<string, number>): IReportAction => {
  return {
    type: ActionTypes.LIST_HANDOVERS_SUCCESS,
    handovers,
  };
};

const listHandoversFail = (error: string): IReportAction => {
  return {
    type: ActionTypes.LIST_HANDOVERS_FAIL,
    error,
  };
};

export const listHandovers = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listHandoversStart());
    try {
      const res = await axios.get(JSON_REPORTS_HANDOVERS);
      dispatch(listHandoversSuccess(res.data));
    } catch ({ response }) {
      dispatch(listHandoversFail((response as AxiosResponse).data));
    }
  };
};
