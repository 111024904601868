import React, { CSSProperties, ReactNode } from "react";
import logo from "../../../assets/images/One-Fleet_logo.svg";
import classes from "./OneFleetLogo.module.scss";

interface IProps {
  style?: CSSProperties;
  children?: ReactNode;
}

const OneFleetLogo: React.FC<IProps> = ({ style, children }) => {
  let containerClassNames = [classes.Logo];

  if (children) {
    containerClassNames.push(classes.LogoContainer);
  }

  return (
    <div className={containerClassNames.join(" ")}>
      <div style={{ flexGrow: 1 }}>
        <img
          src={logo}
          alt="One Fleet Oy"
          style={style}
          className={classes.Background}
        />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default OneFleetLogo;
