import { IAuthAction, IAuthState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

type ReducerState = IAuthState;
type ReducerSignature = (
  state: IAuthState,
  action: IAuthAction
) => ReducerState;

const initialState: IAuthState = {
  authUser: null,
  loading: false,
  error: null,
};

const authStart: ReducerSignature = (state, action): IAuthState => {
  return {
    ...state,
    error: null,
    loading: true,
    authUser: null,
  };
};

const authSuccess: ReducerSignature = (state, action): IAuthState => {
  return {
    ...state,
    authUser: action.authUser || null,
    error: null,
    loading: false,
  };
};

const authFail: ReducerSignature = (state, action): IAuthState => {
  return {
    ...state,
    error: action.error || null,
    loading: false,
  };
};

const logoutStart = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, { error: null, loading: true });
};

const logoutSuccess = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    authUser: null,
    error: null,
    loading: false,
  });
};

const logoutFail = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getCurrentUserStart = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getCurrentUserSuccess = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return updateObject(state, {
    authUser: action.authUser,
    loading: false,
    error: null,
  });
};

const getCurrentUserFail = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return updateObject(state, {
    error: action.error,
    authUser: null,
    loading: false,
  });
};

const getCurrentUserClear = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return {
    ...initialState,
  };
};

const reducer = (
  state: IAuthState = initialState,
  action: IAuthAction
): IAuthState => {
  switch (action.type) {
    case ActionTypes.AUTH_START:
      return authStart(state, action);
    case ActionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case ActionTypes.AUTH_FAIL:
      return authFail(state, action);
    case ActionTypes.LOGOUT_START:
      return logoutStart(state, action);
    case ActionTypes.LOGOUT_SUCCESS:
      return logoutSuccess(state, action);
    case ActionTypes.LOGOUT_FAIL:
      return logoutFail(state, action);
    case ActionTypes.GET_CURRENT_USER_START:
      return getCurrentUserStart(state, action);
    case ActionTypes.GET_CURRENT_USER_SUCCESS:
      return getCurrentUserSuccess(state, action);
    case ActionTypes.GET_CURRENT_USER_FAIL:
      return getCurrentUserFail(state, action);
    case ActionTypes.AUTH_GET_CLEAR:
      return getCurrentUserClear(state, action);
    default:
      return state;
  }
};

export default reducer;
