import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { IInvoiceRow } from "../../../../interfaces";
import { getInputData } from "../../../../shared/utility";
import { ETranslation } from "../../../../translations/translation-keys";
import Button from "../../../UI/Button/Button";
import Fieldset from "../../../UI/Fieldset/Fieldset";
import { EInputType, IInputField } from "../../../UI/Input/Input";
import InputGroup from "../../../UI/InputGroup/InputGroup";

enum EEditInvoiceRow {
  product = "product",
  quantity = "quantity",
  unit = "unit",
  unitPrice = "unitPrice",
  // discountPercent = "discountPercent",
  vatPercent = "vatPercent",
}

interface IProps {
  invoiceRow: IInvoiceRow;
  setRows: Dispatch<SetStateAction<IInvoiceRow[]>>;
  deleteRow: (id: number) => void;
}

const EditInvoiceRow: React.FC<IProps> = ({
  invoiceRow,
  setRows,
  deleteRow,
}) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    [EEditInvoiceRow.product]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INVOICE_ROW_PRODUCT,
      placeholderTranslation: ETranslation.INVOICE_ROW_PRODUCT,
      value: invoiceRow.product ?? "",
    },
    [EEditInvoiceRow.quantity]: {
      type: EInputType.number,
      labelTranslation: ETranslation.INVOICE_ROW_QUANTITY,
      placeholderTranslation: ETranslation.INVOICE_ROW_QUANTITY,
      value: invoiceRow.quantity ?? "",
    },
    [EEditInvoiceRow.unit]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INVOICE_ROW_UNIT,
      placeholderTranslation: ETranslation.INVOICE_ROW_UNIT,
      value: invoiceRow.unit ?? "",
    },
    [EEditInvoiceRow.unitPrice]: {
      type: EInputType.number,
      labelTranslation: ETranslation.INVOICE_ROW_UNIT_PRICE,
      placeholderTranslation: ETranslation.INVOICE_ROW_UNIT_PRICE,
      value: invoiceRow.unitPrice ?? "",
    },
    [EEditInvoiceRow.vatPercent]: {
      type: EInputType.number,
      labelTranslation: ETranslation.INVOICE_ROW_VAT_PERCENT,
      placeholderTranslation: ETranslation.INVOICE_ROW_VAT_PERCENT,
      value: invoiceRow.vatPercent ?? "",
    },
  });

  const getData = useCallback(() => {
    return getInputData<IInvoiceRow>(inputs);
  }, [inputs]);

  useEffect(() => {
    const data = getData();
    const newRow = { ...invoiceRow, ...data };
    setRows((rows) => {
      const idx = rows.findIndex((row) => row.id === invoiceRow.id);
      rows[idx] = newRow;
      return rows;
    });
    // eslint-disable-next-line
  }, [getData, setRows]);

  const createInput = useCreateInput(inputs, setInputs);

  return (
    <Fieldset label="">
      {createInput(EEditInvoiceRow.product)}
      {createInput(EEditInvoiceRow.quantity)}
      <InputGroup>
        {createInput(EEditInvoiceRow.unit)}
        {createInput(EEditInvoiceRow.unitPrice)}
      </InputGroup>
      {createInput(EEditInvoiceRow.vatPercent)}
      <Button onClick={() => deleteRow(invoiceRow.id ?? 0)}>
        {t(ETranslation.COMMON_REMOVE)}
      </Button>
    </Fieldset>
  );
};

export default EditInvoiceRow;
