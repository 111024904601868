import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { INoteAction, IDealNote } from "../../interfaces";
import { ActionTypes } from "./actionTypes";

const apiPath = "/json/deals/notes";

const listNotesStart = (): INoteAction => {
  return {
    type: ActionTypes.NOTES_LIST_START,
  };
};

const listNotesSuccess = (notes: IDealNote[]): INoteAction => {
  return {
    type: ActionTypes.NOTES_LIST_SUCCESS,
    notes,
  };
};

const listNotesFail = (error: string): INoteAction => {
  return {
    type: ActionTypes.NOTES_LIST_FAIL,
    error,
  };
};

export const listNotes = (dealId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(listNotesStart());
    try {
      const res = await axios.get(`${apiPath}/list?id=${dealId}`);
      dispatch(listNotesSuccess(res.data));
    } catch ({ response }) {
      dispatch(listNotesFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const saveNoteStart = (): INoteAction => {
  return {
    type: ActionTypes.NOTES_SAVE_START,
  };
};

const saveNoteSuccess = (note: IDealNote): INoteAction => {
  return {
    type: ActionTypes.NOTES_SAVE_SUCCESS,
    note,
  };
};

const saveNoteFail = (error: string): INoteAction => {
  return {
    type: ActionTypes.NOTES_SAVE_FAIL,
    error,
  };
};

export const saveNote = (note: IDealNote) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveNoteStart());
    try {
      const res = await axios.post(`${apiPath}/add`, note);
      dispatch(saveNoteSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveNoteFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const deleteNoteStart = (): INoteAction => {
  return {
    type: ActionTypes.NOTES_DELETE_START,
  };
};

const deleteNoteSuccess = (id: string): INoteAction => {
  return {
    type: ActionTypes.NOTES_DELETE_SUCCESS,
    id,
  };
};

const deleteNoteFail = (error: string): INoteAction => {
  return {
    type: ActionTypes.NOTES_DELETE_FAIL,
    error,
  };
};

export const deleteNote = (id: string, dealId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteNoteStart());
    try {
      const res = await axios.delete(`${apiPath}/delete?id=${id}&dealId=${dealId}`);
      dispatch(deleteNoteSuccess(res.data));
    } catch ({ response }) {
      dispatch(deleteNoteFail((response as AxiosResponse).data));
      return null;
    }
  };
};
