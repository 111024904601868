import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import {
  JSON_INVOICES_ATTACH,
  JSON_INVOICES_DEAL_LIST, JSON_INVOICES_HIDE,
  JSON_INVOICES_LIST
} from "../../endpoints";
import { IInvoice, IInvoiceAction } from "../../interfaces";
import { ActionTypes } from "./actionTypes";

const listInvoicesStart = (): IInvoiceAction => {
  return {
    type: ActionTypes.LIST_INVOICES_START,
  };
};

const listInvoicesSuccess = (
  invoices: Array<IInvoice>
): IInvoiceAction => {
  return {
    type: ActionTypes.LIST_INVOICES_SUCCESS,
    invoices,
  };
};

const listInvoicesFail = (error: string): IInvoiceAction => {
  return {
    type: ActionTypes.LIST_INVOICES_FAIL,
    error,
  };
};

export const listInvoices = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listInvoicesStart());
    try {
      const res = await axios.get(JSON_INVOICES_LIST);
      dispatch(listInvoicesSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(listInvoicesFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listDealInvoicesStart = (): IInvoiceAction => {
  return {
    type: ActionTypes.LIST_DEAL_INVOICES_START,
  };
};

const listDealInvoicesSuccess = (invoices: Array<IInvoice>): IInvoiceAction => {
  return {
    type: ActionTypes.LIST_DEAL_INVOICES_SUCCESS,
    invoices,
  };
};

const listDealInvoicesFail = (error: string): IInvoiceAction => {
  return {
    type: ActionTypes.LIST_DEAL_INVOICES_FAIL,
    error,
  };
};

export const listDealInvoices = (dealId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(listDealInvoicesStart());
    try {
      const res = await axios.get(JSON_INVOICES_DEAL_LIST(dealId));
      dispatch(listDealInvoicesSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(listDealInvoicesFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const attachInvoiceStart = (): IInvoiceAction => {
  return {
    type: ActionTypes.ATTACH_INVOICE_START,
  };
};

const attachInvoiceSuccess = (invoice: IInvoice): IInvoiceAction => {
  return {
    type: ActionTypes.ATTACH_INVOICE_SUCCESS,
    invoice,
  };
};

const attachInvoiceFail = (error: string): IInvoiceAction => {
  return {
    type: ActionTypes.ATTACH_INVOICE_FAIL,
    error,
  };
};

export const attachInvoice = (invoiceId: string, dealId: string, dealInfo: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(attachInvoiceStart());
    try {
      const res = await axios.put(JSON_INVOICES_ATTACH(invoiceId, dealId, dealInfo));
      dispatch(attachInvoiceSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(attachInvoiceFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const hideInvoiceStart = (): IInvoiceAction => {
  return {
    type: ActionTypes.HIDE_INVOICE_START,
  };
};

const hideInvoiceSuccess = (invoice: IInvoice): IInvoiceAction => {
  return {
    type: ActionTypes.HIDE_INVOICE_SUCCESS,
    invoice,
  };
};

const hideInvoiceFail = (error: string): IInvoiceAction => {
  return {
    type: ActionTypes.HIDE_INVOICE_FAIL,
    error,
  };
};

export const hideInvoice = (invoiceId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(hideInvoiceStart());
    try {
      const res = await axios.put(JSON_INVOICES_HIDE(invoiceId));
      dispatch(hideInvoiceSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(hideInvoiceFail((response as AxiosResponse).data));
      return null;
    }
  };
};
