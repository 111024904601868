import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUserOptions } from '../../../hooks/useUserOptions';
import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';

interface IProps {
  setSellerId: React.Dispatch<React.SetStateAction<string>>;
  sellerId: string;
}

const SellerSearch: React.FC<IProps> = ({ setSellerId, sellerId }) => {

  const { userOptions, usersLoading } = useUserOptions();
  const { t } = useTranslation();

  return (
    <div>
      <label>{t(ETranslation.DEAL_SELLER)}</label>
      <Select
        inputName={"sellerSearch"}
        value={sellerId}
        onChange={setSellerId}
        options={userOptions}
        isSearchable
        isClearable
        isLoading={usersLoading}
      />
    </div>
  )

}

export default SellerSearch;
