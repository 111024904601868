import React from "react";
import { useTranslation } from "react-i18next";
import { IIdentificationInfo } from "../../../../interfaces";
import { ETranslation } from "../../../../translations/translation-keys";
import DealIdentification from "./DealIdentification/DealIdentification";

interface IProps {
  identifications: IIdentificationInfo[];
}

const DealIdentifications: React.FC<IProps> = ({ identifications }) => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t(ETranslation.DEAL_IDENTIFICATION_TITLE)}</h3>
      {identifications.length > 0 &&
        identifications.map((identification) => (
          <DealIdentification
            key={identification.id}
            identification={identification}
          />
        ))}
    </>
  );
};

export default DealIdentifications;
