import { IAttachmentAction, IAttachmentState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IAttachmentState = {
  attachments: null,
  loading: false,
  error: null,
  loadingIds: []
};

const clearAttachmentState = (
  state: IAttachmentState,
  action: IAttachmentAction
): IAttachmentState => {
  return initialState;
};

const uploadAttachmentStart = (
  state: IAttachmentState,
  action: IAttachmentAction
): IAttachmentState => {
  return updateObject(state, { loading: true, error: null });
};

const uploadAttachmentSuccess = (
  state: IAttachmentState,
  action: IAttachmentAction
): IAttachmentState => {
  return updateObject(state, {
    loading: false,
    attachments: action.attachments,
    error: null,
  });
};

const uploadAttachmentFail = (
  state: IAttachmentState,
  action: IAttachmentAction
): IAttachmentState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const openAttachmentStart = (
  state: IAttachmentState,
  action: IAttachmentAction
): IAttachmentState => {
  const attachmentId = action.attachmentId ?? "";
  const updatedLoadingIds = state.loadingIds ? [...state.loadingIds, attachmentId] : [attachmentId];
  return updateObject(state, { 
    loadingIds: updatedLoadingIds
  });
};

const openAttachmentSuccess = (
  state: IAttachmentState,
  action: IAttachmentAction
): IAttachmentState => {
  return updateObject(state, {
    loadingIds: state.loadingIds?.filter(id => id !== action.attachmentId)
  });
};

const openAttachmentFail = (
  state: IAttachmentState,
  action: IAttachmentAction
): IAttachmentState => {
  return updateObject(state, {
    loadingIds: state.loadingIds?.filter(id => id !== action.attachmentId)
  });
};

const reducer = (
  state: IAttachmentState = initialState,
  action: IAttachmentAction
): IAttachmentState => {
  switch (action.type) {
    case ActionTypes.CLEAR_ATTACHMENT_STATE:
      return clearAttachmentState(state, action);
    case ActionTypes.UPLOAD_ATTACHMENT_START:
      return uploadAttachmentStart(state, action);
    case ActionTypes.UPLOAD_ATTACHMENT_SUCCESS:
      return uploadAttachmentSuccess(state, action);
    case ActionTypes.UPLOAD_ATTACHMENT_FAIL:
      return uploadAttachmentFail(state, action);
    case ActionTypes.OPEN_ATTACHMENT_START:
      return openAttachmentStart(state, action);
    case ActionTypes.OPEN_ATTACHMENT_SUCCESS:
      return openAttachmentSuccess(state, action);
    case ActionTypes.OPEN_ATTACHMENT_FAIL:
      return openAttachmentFail(state, action);
    default:
      return state;
  }
};

export default reducer;
