import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { JSON_LOG_ITEMS_GET, JSON_LOG_ITEMS_LIST, JSON_LOG_ITEMS_SAVE_LOGIN } from "../../endpoints";
import { ILogItem, ILogItemAction } from "../../interfaces";
import { ActionTypes } from "./actionTypes";

const listLogItemsStart = (): ILogItemAction => {
  return {
    type: ActionTypes.LIST_LOG_ITEMS_START,
  };
};

const listLogItemsSuccess = (
  logItems: Array<ILogItem>
): ILogItemAction => {
  return {
    type: ActionTypes.LIST_LOG_ITEMS_SUCCESS,
    logItems,
  };
};

const listLogItemsFail = (error: string): ILogItemAction => {
  return {
    type: ActionTypes.LIST_LOG_ITEMS_FAIL,
    error,
  };
};

export interface ILogItemsSearchOptions {
  actionUserId?: string;
  actionOrganizationId?: string;
  startDate?: Date;
  endDate?: Date;
  action?: string;
  targetDealId?: string;
}

export const listLogItems = (options?: ILogItemsSearchOptions) => {
  return async (dispatch: Dispatch) => {
    dispatch(listLogItemsStart());
    try {
      const res = await axios.post(JSON_LOG_ITEMS_LIST(), options);
      dispatch(listLogItemsSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(listLogItemsFail((response as AxiosResponse).data));
      return null;
    }
  };
};

export const saveLogItemLogin = (email: string, success: boolean, error?: string) => {
  return async () => {
    await axios.post(JSON_LOG_ITEMS_SAVE_LOGIN(email, success, error));
  };
};

const getLogItemStart = (): ILogItemAction => {
  return {
    type: ActionTypes.GET_LOG_ITEM_START,
  };
};

const getLogItemSuccess = (logItem: ILogItem): ILogItemAction => {
  return {
    type: ActionTypes.GET_LOG_ITEM_SUCCESS,
    logItem,
  };
};

const getLogItemFail = (error: string): ILogItemAction => {
  return {
    type: ActionTypes.GET_LOG_ITEM_FAIL,
    error,
  };
};

export const getLogItem = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getLogItemStart());
    try {
      const res = await axios.get(JSON_LOG_ITEMS_GET(id));
      dispatch(getLogItemSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getLogItemFail((response as AxiosResponse).data));
      return null;
    }
  };
};

export const clearLogItemsState = () => {
  return {
    type: ActionTypes.CLEAR_LOG_ITEMS,
  };
};