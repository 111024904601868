import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EDealStatus, EDealType } from "../../../../../enums";
import { useCreateInput } from "../../../../../hooks/useCreateInput";
import { IDeal } from "../../../../../interfaces";
import { getInputData, validateInputs } from "../../../../../shared/utility";
import { ETranslation } from "../../../../../translations/translation-keys";
import Button from "../../../../UI/Button/Button";
import { EInputType, IInputField, IOption } from "../../../../UI/Input/Input";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import ModalBody from "../../../../UI/Modal/ModalBody/ModalBody";
import ModalContext from "../../../../UI/Modal/ModalContext";
import ModalFooter from "../../../../UI/Modal/ModalFooter/ModalFooter";
import DealVariationPreview from "../../DealVariation/DealVariationPreview/DealVariationPreview";

interface IProps {
  deal: IDeal;
  onSubmit: (id: string) => void;
  isTerminate?: boolean;
}

interface IDealsignatureDialog {
  selectedVariation: string;
}

export enum EDealSignatureDialog {
  selectedVariation = "selectedVariation",
  isAuthorized = "isAuthorized",
}

export enum EIdentificationType {
  CONTRACT = "CONTRACT", 
  TERMINATE = "TERMINATE",
}

const DealSignatureDialog: React.FC<IProps> = ({
  deal,
  onSubmit,
  isTerminate,
}) => {
  const { t } = useTranslation();
  const [isValid, setValid] = useState(false);
  const { closeModal } = useContext(ModalContext);

  const variationOptions = useMemo(
    () =>
      deal.dealPricingVariations?.map(
        (v, i) =>
          ({
            value: v.id,
            label: `${t(ETranslation.DEAL_VARIATION)} #${i + 1}`,
          } as IOption)
      ) || [],
    [deal.dealPricingVariations, t]
  );

  const [inputs, setInputs] = useState<IInputField>({
    [EDealSignatureDialog.selectedVariation]: {
      type: EInputType.select,
      labelTranslation: ETranslation.DEAL_VARIATION,
      placeholderTranslation: ETranslation.DEAL_VARIATION,
      value: variationOptions.length > 0 && variationOptions[0].value,
      options: variationOptions,
      validation: {
        required: true,
      },
    },
    [EDealSignatureDialog.isAuthorized]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.DEAL_SIGNATURE_IS_AUTHORIZED,
      placeholderTranslation: ETranslation.DEAL_SIGNATURE_IS_AUTHORIZED,
      value: "",
      validation: {
        required: true,
      },
    }
  });

  const createInput = useCreateInput(inputs, setInputs);

  useEffect(() => {
    setValid(validateInputs(inputs));
  }, [inputs]);

  const submitHandler = () => {
    const data = getInputData<IDealsignatureDialog>(inputs);
    onSubmit(data.selectedVariation);
    closeModal();
  };

  return (
    <React.Fragment>
      <ModalBody style={{ display: "flex", flexDirection: "column" }}>
        <p>{t(ETranslation.DEAL_SIGNATURE_DIALOG_TEXT_1)}</p>
        {!isTerminate && deal.status === EDealStatus.OFFER && (
          <>
            <p>{t(ETranslation.DEAL_SIGNATURE_DIALOG_TEXT_2)}</p>
            {deal.dealPricingVariations &&
              deal.dealPricingVariations.map((variation, index) => (
                <DealVariationPreview
                  key={variation.id}
                  variation={variation}
                  index={index + 1}
                  status={deal.status}
                  accept={true}
                  leasingModel={deal.leasingModel}
                  isCar={deal.type === EDealType.CAR}
                  hideVatPrices={deal.hideVatPrices}
                  vatPercent={deal.vatPercent}
                />
              ))}
            {createInput(EDealSignatureDialog.selectedVariation)}
          </>
        )}
        {createInput(EDealSignatureDialog.isAuthorized)}
      </ModalBody>
      <ModalFooter>
        <InputGroup>
          <Button disabled={!isValid} onClick={submitHandler}>
            {t(ETranslation.COMMON_YES)}
          </Button>
          <Button onClick={() => closeModal()}>
            {t(ETranslation.COMMON_NO)}
          </Button>
        </InputGroup>
      </ModalFooter>
    </React.Fragment>
  );
};

export default DealSignatureDialog;
