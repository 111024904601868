import { IDashboardAction, IDashboardState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "./../actions/actionTypes";

const initialState: IDashboardState = {
  loading: false,
  dashboard: null,
  fees: null,
  error: null,
};

const getDashboardStart = (
  state: IDashboardState,
  action: IDashboardAction
): IDashboardState => {
  return updateObject(state, {
    dashboard: null,
    fees: null,
    loading: true,
    error: null,
  });
};

const getDashboardSuccess = (
  state: IDashboardState,
  action: IDashboardAction
): IDashboardState => {
  return updateObject(state, {
    dashboard: action.dashboard,
    loading: false,
    error: null,
  });
};

const getDashboardFail = (
  state: IDashboardState,
  action: IDashboardAction
): IDashboardState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getLeasingLiabilitiesStart = (
  state: IDashboardState,
  action: IDashboardAction
): IDashboardState => {
  return updateObject(state, {
    fees: null,
    loading: true,
    error: null,
  });
};

const getLeasingLiabilitiesSuccess = (
  state: IDashboardState,
  action: IDashboardAction
): IDashboardState => {
  return updateObject(state, {
    fees: action.fees,
    loading: false,
    error: null,
  });
};

const getLeasingLiabilitiesFail = (
  state: IDashboardState,
  action: IDashboardAction
): IDashboardState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IDashboardState = initialState,
  action: IDashboardAction
): IDashboardState => {
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_START:
      return getDashboardStart(state, action);
    case ActionTypes.GET_DASHBOARD_SUCCESS:
      return getDashboardSuccess(state, action);
    case ActionTypes.GET_DASHBOARD_FAIL:
      return getDashboardFail(state, action);
    case ActionTypes.GET_LEASING_LIABILITIES_START:
      return getLeasingLiabilitiesStart(state, action);
    case ActionTypes.GET_LEASING_LIABILITIES_SUCCESS:
      return getLeasingLiabilitiesSuccess(state, action);
    case ActionTypes.GET_LEASING_LIABILITIES_FAIL:
      return getLeasingLiabilitiesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
