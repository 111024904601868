import { faFile, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button, { EButtonColor } from "../../../components/UI/Button/Button";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Table from "../../../components/UI/Table/Table";
import { IAppState, IInvoice } from "../../../interfaces";
import { toTwoDecimalsString } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import classes from "./Invoice.module.scss";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import Spinner from "../../../components/UI/Spinner/Spinner";

interface IProps {
  invoice: IInvoice;
  attachInvoice?: (invoiceId: string) => void;
  hideInvoice?: (invoiceId: string) => void;
  detachInvoice?: (invoiceId: string) => void;
  editInvoice?: (invoice: IInvoice) => void;
}

const Invoice: React.FC<IProps> = ({
  invoice,
  attachInvoice,
  hideInvoice,
  detachInvoice,
  editInvoice,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { loadingAttachmentIds } = useSelector(
    (state: IAppState) => ({
      loadingAttachmentIds: state.attachments.loadingIds
    })
  );
  
  const invoiceDetailsList = useMemo(
    () => [
      // { label: "sopimus", value: invoice.dealInfo },
      { label: "eräpäivä", value: invoice.paymentInfo?.dueDate },
      { label: "tilausnumero", value: invoice.orderNumber },
      { label: "viitteemme", value: invoice.orderReference },
      { label: "sopimusnumero", value: invoice.agreementNumber },
      { label: "muistiinpanot", value: invoice.notes },
    ],
    [
      invoice.agreementNumber,
      invoice.notes,
      invoice.orderNumber,
      invoice.orderReference,
      invoice.paymentInfo,
    ]
  );

  return (
    <div className={classes.Container}>
      <div className={classes.Content}>
        <div className={classes.Table}>
          <h4 className={classes.InvoiceTitle}>
            Lasku nro {invoice.invoiceNumber}{" "}
            {invoice.originalInvoiceNumber &&
              `(alkup. ${invoice.originalInvoiceNumber})`}{" "}
            / {invoice.date}, {invoice.counterParty?.counterPartyAddress?.name},{" "}
            {invoice.counterParty?.identifier}
          </h4>

          <div className={classes.TableInfoTop}>
            {invoiceDetailsList
              .filter((item) => item.value)
              .map((item) => {
                if (!item.value) return "";
                return `${item.label}: ${item.value}`;
              })
              .join(", ")}
          </div>

          <Table
            containerClassName={classes.TableContainer}
            className={classes.CompactTable}
          >
            <thead>
              <tr>
                <th>{t(ETranslation.INVOICE_ROW_PRODUCT)}</th>
                <th>{t(ETranslation.INVOICE_ROW_QUANTITY)}</th>
                <th>{t(ETranslation.INVOICE_ROW_UNIT)}</th>
                <th>{t(ETranslation.INVOICE_ROW_UNIT_PRICE)}</th>
                <th>{t(ETranslation.INVOICE_ROW_TOTAL)}</th>
                <th>{t(ETranslation.INVOICE_ROW_VAT_PERCENT)}</th>
              </tr>
            </thead>
            <tbody>
              {invoice.invoiceRows?.map((row) => (
                <tr key={row.id}>
                  <td>{row.product}</td>
                  <td>{row.quantity}</td>
                  <td>{row.unit}</td>
                  <td>{toTwoDecimalsString(row.unitPrice ? Number(row.unitPrice) : 0)}</td>
                  <td>{toTwoDecimalsString(row.total ? Number(row.total) : 0)}</td>
                  <td>{row.vatPercent}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className={classes.TableInfoBottom}>
            {t(ETranslation.INVOICE_TOTAL)}{" "}
            <b>
              {toTwoDecimalsString(invoice.rowTotalsVat || 0)}{" "}
              {invoice.paymentInfo?.currency}
            </b>
            <br />
            {t(ETranslation.INVOICE_TOTAL_VAT_0)}{" "}
            {toTwoDecimalsString(invoice.rowTotals || 0)}{" "}
            {invoice.paymentInfo?.currency}
          </div>
        </div>
        {!invoice.dealId && (attachInvoice || hideInvoice) && (
          <InputGroup className={classes.Buttons}>
            {attachInvoice && (
              <Button
                color={EButtonColor.SECONDARY}
                onClick={() => attachInvoice(invoice.id)}
              >
                {t(ETranslation.INVOICES_ATTACH_INVOICE_TITLE)}
              </Button>
            )}
            {hideInvoice && (
              <Button
                color={EButtonColor.SECONDARY}
                onClick={() => hideInvoice(invoice.id)}
              >
                <FontAwesomeIcon icon={faTimes} />{" "}
                {t(ETranslation.INVOICES_HIDE_INVOICE_TITLE)}
              </Button>
            )}
          </InputGroup>
        )}
        {invoice.dealId && (editInvoice || detachInvoice) && (
          <InputGroup className={classes.Buttons}>
            {editInvoice && (
              <Button
                color={EButtonColor.SECONDARY}
                onClick={() => editInvoice(invoice)}
              >
                {t(ETranslation.EDIT_INVOICE_TITLE)}
              </Button>
            )}
            {detachInvoice && (
              <Button
                color={EButtonColor.SECONDARY}
                onClick={() => detachInvoice(invoice.id)}
              >
                {t(ETranslation.DETACH_INVOICE_TITLE)}
              </Button>
            )}
          </InputGroup>
        )}
      </div>
      {invoice.dealId &&
        invoice.attachments &&
        invoice.attachments.map((attachment) => (
          loadingAttachmentIds?.includes(attachment.id) ? <Spinner/> :
          <p className={classes.Attachment} key={attachment.id} style={{cursor: "pointer"}} 
            onClick={()=> dispatch(actions.openAttachment(attachment.link, attachment.id))}>
            <FontAwesomeIcon icon={faFile} size="lg" color={"black"} />{" "}
            {attachment.clientName}
          </p>
        ))}
      <hr />
    </div>
  );
};

export default Invoice;
