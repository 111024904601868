import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import {
  JSON_DEALERSHIPS_ADD, JSON_DEALERSHIPS_GET,
  JSON_DEALERSHIPS_LIST,

  JSON_DEALERSHIPS_UPDATE
} from "../../endpoints/index";
import { IDealershipAction } from "../../interfaces";
import { IDealership } from "../../interfaces/domain";
import { ActionTypes } from "./actionTypes";

const getDealershipStart = (): IDealershipAction => {
  return {
    type: ActionTypes.GET_DEALERSHIP_START,
  };
};

const getDealershipSuccess = (
  dealership: IDealership
): IDealershipAction => {
  return {
    type: ActionTypes.GET_DEALERSHIP_SUCCESS,
    dealership,
  };
};

const getDealershipFail = (error: string): IDealershipAction => {
  return {
    type: ActionTypes.GET_DEALERSHIP_START,
    error,
  };
};

export const getDealership = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getDealershipStart());
    try {
      const res = await axios.get(JSON_DEALERSHIPS_GET(id));
      dispatch(getDealershipSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getDealershipFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listDealershipsStart = (): IDealershipAction => {
  return {
    type: ActionTypes.LIST_DEALERSHIPS_START,
  };
};

const listDealershipsSuccess = (dealerships: Array<IDealership>): IDealershipAction => {
  return {
    type: ActionTypes.LIST_DEALERSHIPS_SUCCESS,
    dealerships,
  };
};

const listDealershipsFail = (error: string): IDealershipAction => {
  return {
    type: ActionTypes.LIST_DEALERSHIPS_FAIL,
    error,
  };
};

export const listDealerships = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listDealershipsStart());
    try {
      const res = await axios.get(JSON_DEALERSHIPS_LIST);
      dispatch(listDealershipsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listDealershipsFail((response as AxiosResponse).data));
    }
  };
};

const saveDealershipStart = (): IDealershipAction => {
  return {
    type: ActionTypes.SAVE_DEALERSHIP_START,
  };
};

const saveDealershipSuccess = (dealership: IDealership): IDealershipAction => {
  return {
    type: ActionTypes.SAVE_DEALERSHIP_SUCCESS,
    dealership,
  };
};

const saveDealershipFail = (error: string): IDealershipAction => {
  return {
    type: ActionTypes.SAVE_DEALERSHIP_FAIL,
    error,
  };
};

export const saveDealership = (dealership: IDealership) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveDealershipStart());
    try {
      const res = await axios.post(JSON_DEALERSHIPS_ADD, dealership);
      dispatch(saveDealershipSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveDealershipFail((response as AxiosResponse).data));
    }
  };
};

const updateDealershipStart = (): IDealershipAction => {
  return {
    type: ActionTypes.UPDATE_DEALERSHIP_START,
  };
};

const updateDealershipSuccess = (dealership: IDealership): IDealershipAction => {
  return {
    type: ActionTypes.UPDATE_DEALERSHIP_SUCCESS,
    dealership,
  };
};

const updateDealershipFail = (error: string): IDealershipAction => {
  return {
    type: ActionTypes.UPDATE_DEALERSHIP_FAIL,
    error,
  };
};

export const updateDealership = (dealership: IDealership) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateDealershipStart());
    try {
      const res = await axios.put(JSON_DEALERSHIPS_UPDATE, dealership);
      dispatch(updateDealershipSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateDealershipFail((response as AxiosResponse).data));
    }
  };
};

export const updateDealershipList = (dealership: IDealership) => {
  return {
    type: ActionTypes.UPDATE_DEALERSHIP_LIST,
    dealership,
  };
};
