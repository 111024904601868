import {
  JSON_ORGANIZATIONS_LIST,
} from "../../endpoints/index";
import { IOrganization } from "../../interfaces/domain";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IPrimaryAction } from "../../interfaces";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { EOrganizationLevel } from "../../enums";

const listPrimaryOrganizationsStart = (): IPrimaryAction => {
  return {
    type: ActionTypes.LIST_PRIMARY_ORGANIZATIONS_START,
  };
};

const listPrimaryOrganizationsSuccess = (
  primaryOrganizations: Array<IOrganization>
): IPrimaryAction => {
  return {
    type: ActionTypes.LIST_PRIMARY_ORGANIZATIONS_SUCCESS,
    primaryOrganizations,
  };
};

const listPrimaryOrganizationsFail = (error: string): IPrimaryAction => {
  return {
    type: ActionTypes.LIST_PRIMARY_ORGANIZATIONS_FAIL,
    error,
  };
};

export const listPrimaryOrganizations = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listPrimaryOrganizationsStart());
    try {
      const res = await axios.get(JSON_ORGANIZATIONS_LIST(EOrganizationLevel.PRIMARY));
      dispatch(listPrimaryOrganizationsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listPrimaryOrganizationsFail((response as AxiosResponse).data));
    }
  };
};