import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { EInputUpdateAction } from "../../../context/InputContext";
import { updateInputHandler } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../Button/Button";
import Input, { EInputType, IInputField, IInputOptions, IOption } from "../Input/Input";
import InputGroup from "../InputGroup/InputGroup";
import classes from "./TextInput.module.scss";

export enum ETextInput {
  VALUE = "value",
}

interface IProps {
  id: string;
  value?: string;
  onRemove: (value: IOption) => void;
  onUpdate: (value: IOption) => void;
}

const TextInput: React.FC<IProps> = ({ id, value, onRemove, onUpdate }) => {
  // const { t } = useTranslation();

  const [feeInputs, setFeeInputs] = useState<IInputField>({
    [ETextInput.VALUE]: {
      type: EInputType.text,
      placeholderTranslation: ETranslation.USER_EMAIL,
      value: value
    },
  });

  const createInput = (inputName: ETextInput, options?: IInputOptions) => {
    const item = feeInputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.DEAL_SEND_DIALOG}
      onChange={value => {
        updateInputHandler(inputName, value, setFeeInputs);
      }}
      onBlur={() => onUpdate(getData())}
      inputName={inputName}
    />
  };

  const getData = () => {
    const value = feeInputs[ETextInput.VALUE].value as string;
    const option: IOption = {
      value: id,
      label: value
    }
    return option;
  }

  return (
    <div className={classes.TextInput}>
      <InputGroup>
        {createInput(ETextInput.VALUE)}
        <Button size={EButtonSize.SMALL} color={EButtonColor.SECONDARY} onClick={() => onRemove(getData())} icon={faTrash} />
      </InputGroup>
    </div >
  )
};

export default TextInput;
