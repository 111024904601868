import { IAuditLogAction, IAuditLogState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IAuditLogState = {
  loading: false,
  auditLogs: null,
  error: null,
};

const listAuditLogsStart = (
  state: IAuditLogState,
  action: IAuditLogAction
): IAuditLogState => {
  return updateObject(state, {
    auditLogs: null,
    loading: true,
    error: null,
  });
};

const listAuditLogsSuccess = (
  state: IAuditLogState,
  action: IAuditLogAction
): IAuditLogState => {
  return updateObject(state, {
    auditLogs: action.auditLogs,
    loading: false,
    error: null,
  });
};

const listAuditLogsFail = (
  state: IAuditLogState,
  action: IAuditLogAction
): IAuditLogState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IAuditLogState = initialState,
  action: IAuditLogAction
): IAuditLogState => {
  switch (action.type) {
    case ActionTypes.LIST_AUDIT_LOGS_START:
      return listAuditLogsStart(state, action);
    case ActionTypes.LIST_AUDIT_LOGS_SUCCESS:
      return listAuditLogsSuccess(state, action);
    case ActionTypes.LIST_AUDIT_LOGS_FAIL:
      return listAuditLogsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
