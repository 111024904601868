import { IPrimaryAction, IPrimaryState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IPrimaryState = {
  loading: false,
  primaryOrganizations: null,
  error: null,
};

const listPrimaryOrganizationsStart = (
  state: IPrimaryState,
  action: IPrimaryAction
): IPrimaryState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const listPrimaryOrganizationsSuccess = (
  state: IPrimaryState,
  action: IPrimaryAction
): IPrimaryState => {
  return updateObject(state, {
    primaryOrganizations: action.primaryOrganizations,
    loading: false,
    error: null,
  });
};

const listPrimaryOrganizationsFail = (
  state: IPrimaryState,
  action: IPrimaryAction
): IPrimaryState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IPrimaryState = initialState,
  action: IPrimaryAction
): IPrimaryState => {
  switch (action.type) {
    case ActionTypes.LIST_PRIMARY_ORGANIZATIONS_START:
      return listPrimaryOrganizationsStart(state, action);
    case ActionTypes.LIST_PRIMARY_ORGANIZATIONS_SUCCESS:
      return listPrimaryOrganizationsSuccess(state, action);
    case ActionTypes.LIST_PRIMARY_ORGANIZATIONS_FAIL:
      return listPrimaryOrganizationsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
