import React, { CSSProperties } from "react";
import classes from "./CompactTable.module.scss";

interface IProps {
  containerClassName?: string;
  containerStyle?: CSSProperties;
  className?: string;
  style?: CSSProperties;
}

const CompactTable: React.FC<IProps> = ({ children, containerClassName, containerStyle, className, style }) => {
  return (
    <div className={containerClassName} style={containerStyle}>
      <table className={[classes.Table, className].join(" ")} style={style}>
        {children}
      </table>
    </div>
  )
}

export default CompactTable;
