import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EDealEdit } from "../../../../../../containers/Deals/Deal/Deal";
import DealContext from "../../../../../../context/DealContext";
import DealVariationConditionContext from "../../../../../../context/DealVariationConditionContext";
import DealVariationContext from "../../../../../../context/DealVariationContext";
import { EInputUpdateAction } from "../../../../../../context/InputContext";
import { EDealStatus, ELeasingModel } from "../../../../../../enums";
import { useAuthUser } from "../../../../../../hooks/useAuthUser";
import { IDealPricingVariation } from "../../../../../../interfaces";
import {
  INVALID_DEFAULT,
  VALID_DEFAULT,
  fv,
  getInputData,
  pmt,
  toTwoDecimals,
  updateInputHandler,
} from "../../../../../../shared/utility";
import { ETranslation } from "../../../../../../translations/translation-keys";
import Button from "../../../../../UI/Button/Button";
import Fieldset from "../../../../../UI/Fieldset/Fieldset";
import Input, { IInputOptions } from "../../../../../UI/Input/Input";
import InputGroup from "../../../../../UI/InputGroup/InputGroup";
import { EDealVariation, EPaymentType } from "../DealVariationEdit";

const DealVariationEditMachine: React.FC = () => {
  const { t } = useTranslation();
  const {
    inputs,
    updateVariation,
    removeVariation,
    copyVariation,
    isValid,
    deal,
    isDisabled,
    // updateExtraFee,
    // addExtraFee,
    // removeExtraFee,
    // copyExtraFee,
    additionalExpenses,
  } = useContext(DealContext);
  const {
    variation,
    variationInputs,
    setVariationInputs,
    extraFees,
    // setExtraFees,
    index,
  } = useContext(DealVariationContext);
  const {
    variationContractLength,
    variationResidualValue,
    setVariationContractLength,
    setVariationResidualValue,
  } = useContext(DealVariationConditionContext);

  const authUser = useAuthUser();

  const [editableFields, setEditableFields] = useState<EDealVariation[]>([]);
  useEffect(() => {
    if (authUser?.isAdmin) {
      setEditableFields([
        EDealVariation.MAINTENANCE_BUDGET,
        EDealVariation.GROSS_PROFIT,
        EDealVariation.RESIDUAL_VALUE,
        EDealVariation.CONTRACT_LENGTH,
        EDealVariation.INTEREST_RATE,
        EDealVariation.PAYMENT_TYPE,
        EDealVariation.FIRST_LARGER_RENT,
        EDealVariation.kickbackVAT,
      ]);
    }
  }, [authUser]);

  const leasingModel = inputs[EDealEdit.LEASING_MODEL].value as ELeasingModel;
  const totalPrice = inputs[EDealEdit.TOTAL_PRICE].value as number;
  const vatPercent = inputs[EDealEdit.vatPercent].value as number;

  const interestRate = variationInputs[EDealVariation.INTEREST_RATE]
    .value as number;
  const grossProfit = variationInputs[EDealVariation.GROSS_PROFIT]
    .value as number;
  const maintenanceBudget = variationInputs[EDealVariation.MAINTENANCE_BUDGET]
    .value as number;
  const paymentType = variationInputs[EDealVariation.PAYMENT_TYPE]
    .value as EPaymentType;
  const contractLength = variationInputs[EDealVariation.CONTRACT_LENGTH]
    .value as number;
  const residualValue = variationInputs[EDealVariation.RESIDUAL_VALUE]
    .value as number;
  const firstLargerRent = variationInputs[EDealVariation.FIRST_LARGER_RENT]
    .value as number;
  const downPayment = variationInputs[EDealVariation.DOWN_PAYMENT]
    .value as number;
  const fundableCapital = variationInputs[EDealVariation.FUNDABLE_CAPITAL]
    .value as number;
  const kickbackVAT = variationInputs[EDealVariation.kickbackVAT].value as number;

  useEffect(() => {
    if (leasingModel === ELeasingModel.RAHOITUS) {
      setVariationInputs((stateInputs) => {
        const newInputs = { ...stateInputs };
        newInputs[EDealVariation.MAINTENANCE_BUDGET].value = 0.0;
        newInputs[EDealVariation.MAINTENANCE_BUDGET_VAT].value = 0.0;
        return newInputs;
      });
    }
    // eslint-disable-next-line
  }, [leasingModel]);

  // machine future value
  useEffect(() => {
    // console.log("useEffect() DealVariationEditMachine changed");
    if (deal?.importName || !paymentType) return;

    let totalPriceWithAdditionalExpenses = Number(totalPrice ?? 0);
    if (additionalExpenses && additionalExpenses.length > 0) {
      for (let i = 0; i < additionalExpenses.length; i++) {
        const additionalExpense = additionalExpenses[i];
        if (additionalExpense.value) {
          totalPriceWithAdditionalExpenses += Number(additionalExpense.value);
        }
      }
    }

    const calculateFutureValue =
      grossProfit &&
      (interestRate || totalPriceWithAdditionalExpenses || maintenanceBudget);
    // fundable capital + downpayment only required if payment type is installment
    const calculateMonthlyPayment =
      grossProfit &&
      contractLength &&
      contractLength > 1 &&
      ((paymentType === EPaymentType.INSTALLMENT ||
        paymentType === EPaymentType.CASH_OLF ||
        paymentType === EPaymentType.CASH_SELLER
        )
        ? fundableCapital && downPayment
        : true) &&
      (interestRate ||
        totalPriceWithAdditionalExpenses ||
        maintenanceBudget ||
        residualValue);
    const calculateResidualValue =
      totalPriceWithAdditionalExpenses && residualValue;
    const calculateGrossProfitVat = !!grossProfit;
    const calculateMaintencaBudgetVat = !!maintenanceBudget;
    const calculateFundableCapital =
      totalPriceWithAdditionalExpenses && grossProfit;
    const shouldCalculateKickback = !!kickbackVAT;

    // setValid(calculateGrossProfitVat);

    // only update when changed
    if (contractLength !== variationContractLength) {
      setVariationContractLength(contractLength || "");
    }
    if (residualValue !== variationResidualValue) {
      setVariationResidualValue(residualValue || "");
    }

    setVariationInputs((stateInputs) => {
      const newInputs = { ...stateInputs };

      let futureValue = 0;
      let monthlyPayment = 0;

      switch (paymentType) {
        case EPaymentType.LEASING:
          newInputs[EDealVariation.DOWN_PAYMENT].value = "";
          if (calculateFutureValue) {
            futureValue = fv(
              +interestRate / 100 / 12,
              1,
              +firstLargerRent,
              -(
                +totalPriceWithAdditionalExpenses +
                +maintenanceBudget +
                +grossProfit
              ),
              1
            );
          }
          if (calculateMonthlyPayment) {
            monthlyPayment = pmt(
              +interestRate / 100 / 12,
              +contractLength,
              -(
                +totalPriceWithAdditionalExpenses +
                +maintenanceBudget +
                +grossProfit
              ),
              +residualValue,
              1
            );
          }
          break;
        case EPaymentType.INSTALLMENT:
        case EPaymentType.CASH_OLF:
        case EPaymentType.CASH_SELLER:
          newInputs[EDealVariation.FIRST_LARGER_RENT].value = "";
          newInputs[
            EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT
          ].value = "";
          newInputs[
            EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT
          ].value = "";
          if (calculateFutureValue) {
            futureValue = fv(
              +interestRate / 100 / 12,
              1,
              +downPayment,
              -(
                +totalPriceWithAdditionalExpenses +
                +maintenanceBudget +
                +grossProfit
              ),
              1
            );
          }
          // if (calculateMonthlyPayment) monthlyPayment = pmt(+interestRate / 100 / 12, +contractLength, -(+(fundableCapital - downPayment) + +maintenanceBudget), +residualValue, 1);
          if (calculateMonthlyPayment) {
            monthlyPayment = pmt(
              +interestRate / 100 / 12,
              +contractLength,
              -+(fundableCapital - downPayment),
              +residualValue,
              1
            );
          }
          break;
        default:
          break;
      }

      newInputs[EDealVariation.FUTURE_VALUE].value = calculateFutureValue
        ? toTwoDecimals(futureValue)
        : "";
      newInputs[EDealVariation.MONTHLY_PAYMENT].value = calculateMonthlyPayment
        ? toTwoDecimals(monthlyPayment)
        : "";
      newInputs[EDealVariation.MONTHLY_PAYMENT_VAT].value =
        calculateMonthlyPayment ? toTwoDecimals(monthlyPayment * vatPercent) : "";

      newInputs[EDealVariation.RESIDUAL_VALUE_PERCENTAGE].value =
        calculateResidualValue
          ? toTwoDecimals(
              (residualValue / totalPriceWithAdditionalExpenses) * 100
            )
          : "";
      newInputs[EDealVariation.RESIDUAL_VALUE_VAT].value =
        calculateResidualValue ? toTwoDecimals(residualValue * vatPercent) : "";

      const calculatedGrossProfitVAT = calculateGrossProfitVat
      ? toTwoDecimals(grossProfit * vatPercent)
      : "";
      newInputs[EDealVariation.GROSS_PROFIT_VAT].value = calculatedGrossProfitVAT;
      newInputs[EDealVariation.GROSS_PROFIT_VAT].validationResult = !!calculatedGrossProfitVAT ? VALID_DEFAULT : INVALID_DEFAULT;

      newInputs[EDealVariation.MAINTENANCE_BUDGET_VAT].value =
        calculateMaintencaBudgetVat
          ? toTwoDecimals(maintenanceBudget * vatPercent)
          : "";
      newInputs[EDealVariation.FUNDABLE_CAPITAL].value =
        calculateFundableCapital
          ? toTwoDecimals(
              +totalPriceWithAdditionalExpenses +
                +grossProfit +
                +maintenanceBudget
            )
          : "";

      newInputs[EDealVariation.FUNDABLE_CAPITAL_VAT].value =
        calculateFundableCapital
          ? toTwoDecimals(
              (+totalPriceWithAdditionalExpenses +
                +grossProfit +
                +maintenanceBudget) * vatPercent
            )
          : "";

      const calculateMonthlyInstallmentFirstInstallment =
        paymentType === EPaymentType.LEASING &&
        grossProfit &&
        contractLength &&
        contractLength > 1 &&
        (interestRate || futureValue || residualValue);

      let monthlyInstallmentFirstInstallment: string | number = "";
      let monthlyInstallmentFirstInstallmentVat: string | number = "";
      if (calculateMonthlyInstallmentFirstInstallment) {
        const payment = pmt(
          +interestRate / 100 / 12,
          +contractLength - 1,
          -futureValue,
          +residualValue,
          1
        );
        monthlyInstallmentFirstInstallment = toTwoDecimals(payment);
        monthlyInstallmentFirstInstallmentVat = toTwoDecimals(payment * vatPercent);
      }
      newInputs[EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT].value =
        monthlyInstallmentFirstInstallment;
      newInputs[
        EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT
      ].value = monthlyInstallmentFirstInstallmentVat;
      newInputs[EDealVariation.kickback].value =
        shouldCalculateKickback
          ? toTwoDecimals(kickbackVAT / vatPercent)
          : "";

      return newInputs;
    });
    // eslint-disable-next-line
  }, [
    interestRate,
    contractLength,
    firstLargerRent,
    residualValue,
    totalPrice,
    maintenanceBudget,
    grossProfit,
    downPayment,
    fundableCapital,
    paymentType,
    additionalExpenses,
    kickbackVAT,
    vatPercent,
  ]);

  useEffect(() => {
    if (variation && variation.id) updateVariation(getData());
    // eslint-disable-next-line
  }, [variationInputs, extraFees]);

  const getData = () => {
    const pricingVariation: IDealPricingVariation =
      getInputData(variationInputs);
    pricingVariation.id = variation!.id;
    pricingVariation.extraFees = extraFees;
    return pricingVariation;
  };

  const createInput = (inputName: EDealVariation, options?: IInputOptions) => {
    const item = variationInputs[inputName];
    return (
      <Input
        disabled={!editableFields.includes(inputName) && isDisabled}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.DEAL_VARIATION}
        onChange={(value) =>
          updateInputHandler(inputName, value, setVariationInputs)
        }
        // onBlur={() => { if (variation && variation.id) updateVariation(getData()) }}
        inputName={inputName}
        showValidation={!isValid}
      />
    );
  };

  return (
    <>
      <Fieldset
        label={
          !deal?.status || deal?.status === EDealStatus.OFFER
            ? `${t(ETranslation.DEAL_VARIATION)} #${index}`
            : t(ETranslation.DEAL_VARIATION)
        }
      >
        {leasingModel !== ELeasingModel.RAHOITUS ? (
          <InputGroup>
            {createInput(EDealVariation.MAINTENANCE_BUDGET)}
            {createInput(EDealVariation.MAINTENANCE_BUDGET_VAT, {
              labelTranslation:
                ETranslation.DEAL_MACHINE_MAINTENANCE_CONTRACT_VAT,
              placeholderTranslation:
                ETranslation.DEAL_MACHINE_MAINTENANCE_CONTRACT_VAT,
              disabled: true,
            })}
          </InputGroup>
        ) : null}

        <InputGroup>
          {createInput(EDealVariation.GROSS_PROFIT, {
            disabled: !authUser?.isAdmin && isDisabled,
          })}
          {createInput(EDealVariation.GROSS_PROFIT_VAT, { disabled: true })}
          {createInput(EDealVariation.kickbackVAT)}
          {createInput(EDealVariation.kickback, { disabled: true })}
        </InputGroup>

        <InputGroup>
          {createInput(EDealVariation.RESIDUAL_VALUE_LIABILITY, {
            disabled: !authUser?.isAdmin && isDisabled,
          })}
          {createInput(EDealVariation.RESIDUAL_VALUE)}
          {createInput(EDealVariation.RESIDUAL_VALUE_PERCENTAGE)}
          {createInput(EDealVariation.RESIDUAL_VALUE_VAT, { disabled: true })}
        </InputGroup>

        <InputGroup>
          {createInput(EDealVariation.CONTRACT_LENGTH)}
          {createInput(EDealVariation.INTEREST_RATE)}
        </InputGroup>

        <InputGroup>
          {createInput(EDealVariation.MONTHLY_PAYMENT_VAT)}
          {createInput(EDealVariation.MONTHLY_PAYMENT)}
        </InputGroup>

        <InputGroup>
          {createInput(EDealVariation.PAYMENT_TYPE, {
            disabled: !authUser?.isAdmin && isDisabled,
          })}
          {(variationInputs[EDealVariation.PAYMENT_TYPE].value === EPaymentType.INSTALLMENT ||
            variationInputs[EDealVariation.PAYMENT_TYPE].value === EPaymentType.CASH_OLF ||
            variationInputs[EDealVariation.PAYMENT_TYPE].value === EPaymentType.CASH_SELLER) &&
              createInput(EDealVariation.DOWN_PAYMENT)}
          {variationInputs[EDealVariation.PAYMENT_TYPE].value ===
            EPaymentType.LEASING && (
            <>
              {createInput(EDealVariation.FIRST_LARGER_RENT)}
              {createInput(
                EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT
              )}
              {createInput(
                EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT
              )}
            </>
          )}
          {createInput(EDealVariation.FUTURE_VALUE, {
            labelTranslation: ETranslation.DEAL_FUTURE_VALUE,
            placeholderTranslation: ETranslation.DEAL_FUTURE_VALUE,
          })}
        </InputGroup>

        <InputGroup>{createInput(EDealVariation.FUNDABLE_CAPITAL)}</InputGroup>
        <InputGroup>{createInput(EDealVariation.FUNDABLE_CAPITAL_VAT)}</InputGroup>

        {/* {extraFees && extraFees.length > 0 && (
          <Fieldset label={t(ETranslation.DEAL_EXTRA_FEES)}>
            {extraFees.map((fee) => (
              <ExtraFee
                key={fee.id}
                fee={fee}
                onCopy={(fee) =>
                  copyExtraFee(fee, EDealVariation.EXTRA_FEES, setExtraFees)
                }
                onRemove={(id) => removeExtraFee(id, setExtraFees)}
                onUpdate={(fee) => updateExtraFee(fee, setExtraFees)}
                disabled={isDisabled}
              />
            ))}
          </Fieldset>
        )} */}

        {(!deal?.status || deal?.status === EDealStatus.OFFER) && (
          <InputGroup>
            {/* <Button
              onClick={() =>
                addExtraFee(EDealVariation.EXTRA_FEES, setExtraFees)
              }
            >
              {t(ETranslation.COMMON_ADD)} {t(ETranslation.DEAL_EXTRA_FEE)}
            </Button> */}
            <Button onClick={() => copyVariation(getData())}>
              {t(ETranslation.COMMON_COPY)}
            </Button>
            <Button
              onClick={() => {
                if (variation && variation.id) removeVariation(variation.id);
              }}
            >
              {t(ETranslation.COMMON_REMOVE)}
            </Button>
          </InputGroup>
        )}
      </Fieldset>
    </>
  );
};

export default DealVariationEditMachine;
