import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { TDispatch } from "../../..";
import Alert from "../../../components/UI/Alert/Alert";
import Button from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import Input, { EInputType, IInputField, IInputOptions } from "../../../components/UI/Input/Input";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../../context/InputContext";
import { IAppState, IFinanceCompany } from "../../../interfaces";
import { Routes } from "../../../classes/Routes";
import { initForm, updateInputHandler } from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

// TODO: OL-83 Sopimuksen päättymispäivän laskenta (hankintajakso)
export enum EAcquisitionPeriod {
  FIRST_DAY = "FIRST_DAY",
  FIRST_OR_15TH_DAY = "FIRST_OR_15TH_DAY",
}

export enum EFinanceCompany {
  NAME = "name",
  INTEREST_RATE = "interestRate",
  INVOICING_FEE = "invoicingFee",
  OPENING_FEE = "openingFee",
  REGISTRATION_INFO = "registrationInfo",
  INVOICE_PAYER = "invoicePayer",
  BILLING_INFORMATION = "billingInformation",
  PAYMENT_TERM = "paymentTerm",
  REFERENCE_INFORMATION = "referenceInformation",
  ACQUISITION_PERIOD = "acquisitionPeriod",
}

const FinanceCompany: React.FC<IProps> = ({ match, history }) => {
  const dispatch = useDispatch<TDispatch>();

  const { loading, error, financeCompany } = useSelector((state: IAppState) => ({
    loading: state.financeCompanies.loading,
    error: state.financeCompanies.error,
    financeCompany: state.financeCompanies.financeCompany,
  }));

  const { t } = useTranslation();
  const { id } = match.params;

  const isNew = id === "new";

  const initialInputs: IInputField = {
    [EFinanceCompany.NAME]: {
      type: EInputType.text,
      labelTranslation: ETranslation.FINANCE_COMPANY_NAME,
      placeholderTranslation: ETranslation.FINANCE_COMPANY_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [EFinanceCompany.INTEREST_RATE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.FINANCE_COMPANY_INTEREST_RATE,
      placeholderTranslation: ETranslation.FINANCE_COMPANY_INTEREST_RATE,
      value: "",
      validation: {
        required: true
      }
    },
    [EFinanceCompany.INVOICING_FEE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.FINANCE_COMPANY_INVOICING_FEE,
      placeholderTranslation: ETranslation.FINANCE_COMPANY_INVOICING_FEE,
      value: "",
      validation: {
        required: true
      }
    },
    [EFinanceCompany.OPENING_FEE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.FINANCE_COMPANY_OPENING_FEE,
      placeholderTranslation: ETranslation.FINANCE_COMPANY_OPENING_FEE,
      value: "",
      validation: {
        required: true
      }
    },
    [EFinanceCompany.REGISTRATION_INFO]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.FINANCE_COMPANY_REGISTRATION_INFO,
      placeholderTranslation: ETranslation.FINANCE_COMPANY_REGISTRATION_INFO,
      value: "",
      validation: {
        required: true
      }
    },
    [EFinanceCompany.INVOICE_PAYER]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.FINANCE_COMPANY_INVOICE_PAYER,
      placeholderTranslation: ETranslation.FINANCE_COMPANY_INVOICE_PAYER,
      value: "",
      validation: {
        required: true
      }
    },
    [EFinanceCompany.BILLING_INFORMATION]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.FINANCE_COMPANY_BILLING_INFORMATION,
      placeholderTranslation: ETranslation.FINANCE_COMPANY_BILLING_INFORMATION,
      value: "",
      validation: {
        required: true
      }
    },
    [EFinanceCompany.PAYMENT_TERM]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.FINANCE_COMPANY_PAYMENT_TERM,
      placeholderTranslation: ETranslation.FINANCE_COMPANY_PAYMENT_TERM,
      value: "",
      validation: {
        required: true
      }
    },
    [EFinanceCompany.REFERENCE_INFORMATION]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.FINANCE_COMPANY_REFERENCE_INFORMATION,
      placeholderTranslation: ETranslation.FINANCE_COMPANY_REFERENCE_INFORMATION,
      value: "",
      validation: {
        required: true
      }
    },
    [EFinanceCompany.ACQUISITION_PERIOD]: {
      type: EInputType.select,
      labelTranslation: ETranslation.FINANCE_COMPANY_ACQUISITION_PERIOD,
      value: EAcquisitionPeriod.FIRST_DAY,
      options: [
        { value: EAcquisitionPeriod.FIRST_DAY, labelTranslation: ETranslation.FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_DAY },
        { value: EAcquisitionPeriod.FIRST_OR_15TH_DAY, labelTranslation: ETranslation.FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_OR_15TH_DAY },
      ],
    },
  };

  const [inputs, setInputs] = useState<IInputField>({ ...initialInputs });

  useEffect(() => {
    if (id && !isNew) {
      dispatch(actions.getFinanceCompany(id));
    }
  }, [id, dispatch, isNew]);

  useEffect(() => {
    if (id && !isNew && financeCompany) {
      setInputs(stateInputs => initForm(stateInputs, financeCompany));
    } else {
      setInputs(_ => initialInputs);
    }
    // eslint-disable-next-line
  }, [financeCompany]);


  const createInput = (inputName: EFinanceCompany, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.FINANCE_COMPANY}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    />
  };

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const name = inputs[EFinanceCompany.NAME].value as string;
    const interestRate = inputs[EFinanceCompany.INTEREST_RATE].value as number;
    const invoicingFee = inputs[EFinanceCompany.INVOICING_FEE].value as number;
    const openingFee = inputs[EFinanceCompany.OPENING_FEE].value as number;
    const registrationInfo = inputs[EFinanceCompany.REGISTRATION_INFO].value as string;
    const invoicePayer = inputs[EFinanceCompany.INVOICE_PAYER].value as string;
    const billingInformation = inputs[EFinanceCompany.BILLING_INFORMATION].value as string;
    const paymentTerm = inputs[EFinanceCompany.PAYMENT_TERM].value as string;
    const referenceInformation = inputs[EFinanceCompany.REFERENCE_INFORMATION].value as string;
    const acquisitionPeriod = inputs[EFinanceCompany.ACQUISITION_PERIOD].value as EAcquisitionPeriod;

    const company: IFinanceCompany = {
      id: "", name, interestRate, invoicingFee, openingFee, registrationInfo, invoicePayer, billingInformation,
      paymentTerm, referenceInformation, acquisitionPeriod,
    }

    if (isNew) {
      await dispatch(actions.saveFinanceCompany(company));
    } else {
      company.id = id;
      await dispatch(actions.updateFinanceCompany(company));
    }

    // console.log(financeCompany);
    history.push(Routes.FINANCE_COMPANIES);
  };

  const createInputs = () => {
    return (
      <>
        <InputGroup>
          {createInput(EFinanceCompany.NAME)}
        </InputGroup>
        <InputGroup>
          {createInput(EFinanceCompany.INTEREST_RATE)}
        </InputGroup>
        <InputGroup>
          {createInput(EFinanceCompany.INVOICING_FEE)}
        </InputGroup>
        <InputGroup>
          {createInput(EFinanceCompany.OPENING_FEE)}
        </InputGroup>
        <InputGroup>
          {createInput(EFinanceCompany.REGISTRATION_INFO)}
        </InputGroup>
        <InputGroup>
          {createInput(EFinanceCompany.INVOICE_PAYER)}
        </InputGroup>
        <InputGroup>
          {createInput(EFinanceCompany.BILLING_INFORMATION)}
        </InputGroup>
        <InputGroup>
          {createInput(EFinanceCompany.PAYMENT_TERM)}
        </InputGroup>
        <InputGroup>
          {createInput(EFinanceCompany.REFERENCE_INFORMATION)}
        </InputGroup>
        <InputGroup>
          {createInput(EFinanceCompany.ACQUISITION_PERIOD)}
        </InputGroup>
      </>
    );
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{isNew ? t(ETranslation.TITLE_FINANCE_COMPANY_NEW) : t(ETranslation.TITLE_FINANCE_COMPANY)}</h2>
        {loading
          ? <Spinner />
          : createInputs()
        }
        <InputGroup>
          <Button disabled={loading} onClick={submitHandler}>
            {t(ETranslation.COMMON_SAVE)}
          </Button>
          <Button onClick={() => history.goBack()}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
        </InputGroup>
      </Container>
    </>
  );
};

export default FinanceCompany;
