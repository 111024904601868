import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import DesktopLogo from "../../../components/Logo/DesktopLogo/DesktopLogo";
import Alert from "../../../components/UI/Alert/Alert";
import { TInputValue } from "../../../components/UI/Input/Input";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { InputSuffix } from "../../../enums";
import { IAppState } from "../../../interfaces";
import { dateToString, toTwoDecimals } from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import classes from "./LeasingLiabilitiesReport.module.scss";
import Button, { EButtonColor } from "../../../components/UI/Button/Button";

interface IMatch {
  id: string;
}

const LeasingLiabilitiesReport: React.FC<RouteComponentProps<IMatch>> = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { loading, error, fees } = useSelector((state: IAppState) => ({
    loading: state.dashboard.loading,
    error: state.dashboard.error,
    fees: state.dashboard.fees,
  }));

  useEffect(() => {
    dispatch(actions.getLeasingLiabilities());
    // eslint-disable-next-line
  }, [dispatch]);

  const mapLabelAndValue = (label: ETranslation, value?: TInputValue, bold?: boolean, suffix?: string) => {
    return (
      <div className={classes.ValueContainer}>
        <div className={classes.Label}>
          <p>{t(label)}:</p>
        </div>
        <div className={classes.Value}>
          <p>
            {value
              ? bold
                ? <b>{value}</b>
                : value
              : null}
            {' '}
            {suffix}
          </p>
        </div>
      </div>
    )
  };

  const mapFees = () => {
    if (!fees) return null;
    return (
      <div>
        <div className={classes.Section}>
          {mapLabelAndValue(ETranslation.DASHBOARD_FEES_TOTAL, toTwoDecimals(fees?.totalMonth || 0), false, InputSuffix.EUROS_PER_MONTH(t))}
          {mapLabelAndValue(ETranslation.LEASING_LIABILITIES_SHORT, toTwoDecimals(fees?.totalShort || 0), false, InputSuffix.EUROS)}
          {mapLabelAndValue(ETranslation.LEASING_LIABILITIES_LONG, toTwoDecimals(fees?.totalLong || 0), false, InputSuffix.EUROS)}
        </div>
      </div>
    );
  };

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <div className={classes.Container}>
        {
          loading
            ? <Spinner />
            : fees
              ? (
                <>
                  <DesktopLogo>
                    <p>{dateToString(new Date())}</p>
                  </DesktopLogo>
                  <div className={classes.Content}>
                    <h1 className={classes.Title}>{t(ETranslation.DASHBOARD_LEASING_LIABILITIES_REPORT)}</h1>
                    {/* Kulujen tiedot */}
                    {mapFees()}
                  </div>
                  <Button
                    className={classes.Print}
                    color={EButtonColor.SECONDARY}
                    onClick={() => window.print()}
                  >{t(ETranslation.DEAL_ACCEPT_PRINT)}</Button>
                </>
              )
              : null
        }
      </div>
    </>
  )
};

export default LeasingLiabilitiesReport;
