import * as qs from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Alert from "../../../../components/UI/Alert/Alert";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { IAppState } from "../../../../interfaces";
import * as actions from "../../../../store/actions";
import Deal from "../Deal";

interface IProps extends RouteComponentProps { }

// ?resource=deal&view=details&userId=12345&companyId=12345&selectedIds=9
interface IPipedriveQueryParams {
  resource: string;
  view: string;
  userId: string;
  companyId: string;
  selectedIds: string;
}

const PipedriveDeal: React.FC<IProps> = ({ history, location, match }) => {
  const pipedriveParams = ((): IPipedriveQueryParams => {
    const params = qs.parse(location.search);
    return {
      resource: params.resource as string,
      view: params.view as string,
      userId: params.userId as string,
      companyId: params.companyId as string,
      selectedIds: params.selectedIds as string,
    }
  })();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, error, pipedriveDeal } = useSelector((state: IAppState) => ({
    loading: state.pipedriveDeal.loading,
    error: state.pipedriveDeal.error,
    pipedriveDeal: state.pipedriveDeal.pipedriveDeal
  }));

  useEffect(() => {
    // console.log("Pipedrive params", pipedriveParams);
    dispatch(actions.getNewPipedriveDeal(pipedriveParams.selectedIds));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      {loading
        ? <Spinner />
        : <Deal
          history={history}
          location={location}
          match={{ ...match, params: { id: "new" } }}
          pipedriveDeal={{ ...pipedriveDeal, id: pipedriveParams.selectedIds, companyId: pipedriveParams.companyId }}
          // for testing
          // pipedriveDeal={{ organization: { name: "Ryklin Oy", address: "Saukonojantie 58", companyId: 8, businessId: "21211069" }, status: EPipedriveStatus.won, companyId: 8, id: 1 }}
        />
      }
    </>
  );
}

export default PipedriveDeal;
