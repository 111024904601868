import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import DealContext from "../../../../context/DealContext";
import { EDealType } from "../../../../enums";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { ETranslation } from "../../../../translations/translation-keys";
import Fieldset from "../../../UI/Fieldset/Fieldset";
import { EInputType, IInputField, TInputValue } from "../../../UI/Input/Input";
import InputGroup from "../../../UI/InputGroup/InputGroup";
import { useTranslation } from "react-i18next";
import classes from "../DealPreview/DealPreview.module.scss";
import {
  copyToClipboard,
  getInputData,
  initForm,
  formatDateString,
  toTwoDecimals,
} from "../../../../shared/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { IAppState, IDealTerminationCalculation } from "../../../../interfaces";
import * as actions from "../../../../store/actions";
import Button from "../../../UI/Button/Button";
import DisabledInput from "../../../UI/Input/DisabledInput";
import { VAT_PERCENT_24 } from "../../../../shared/vat";

enum EDealTerminate {
  finalDebt = "finalDebt",
  finalDebtDate = "finalDebtDate",
  purchasePrice = "purchasePrice",
  maintenanceBudget = "maintenanceBudget",
  handlingFee = "handlingFee",
  terminationFee = "terminationFee",
  terminationFeeVat = "terminationFeeVat",
  terminationFeeVatAmount = "terminationFeeVatAmount",
  underKilometerCredit = "underKilometerCredit",
  usedKilometers = "usedKilometers",
  extraKilometerCharge = "extraKilometerCharge",
  machineId = "machineId",
}

enum EKilometerType {
  over = "over",
  under = "under",
}

interface IKilometerCalculation {
  type: EKilometerType;
  km: number;
  value: number;
}

const DealTerminate: React.FC = () => {
  const { t } = useTranslation();
  const { deal } = useContext(DealContext);
  const ref = useRef<HTMLInputElement>(null);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const { terminationLoading } = useSelector((state: IAppState) => state.deals);
  const isCar = deal?.type === EDealType.CAR;
  const [kilometerCalculation, setKilometerCalculation] =
    useState<IKilometerCalculation>({
      type: EKilometerType.over,
      km: 0,
      value: 0,
    });

  const [terminateInputs, setTerminateInputs] = useState<IInputField>({
    [EDealTerminate.finalDebt]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_TERMINATE_FINAL_DEBT,
      placeholderTranslation: ETranslation.DEAL_TERMINATE_FINAL_DEBT,
      value: "",
      post: "€",
    },
    [EDealTerminate.finalDebtDate]: {
      type: EInputType.datepicker,
      labelTranslation: ETranslation.DEAL_TERMINATE_FINAL_DEBT_DATE,
      placeholderTranslation: ETranslation.DEAL_TERMINATE_FINAL_DEBT_DATE,
      value: "",
    },
    [EDealTerminate.purchasePrice]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
    [EDealTerminate.maintenanceBudget]: {
      type: EInputType.number,
      labelTranslation: ETranslation.MAINTENANCE_BUDGET_STATUS_REMAINING,
      placeholderTranslation: ETranslation.MAINTENANCE_BUDGET_STATUS_REMAINING,
      value: "",
      post: "€",
    },
    [EDealTerminate.handlingFee]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_TERMINATE_HANDLING_FEE,
      placeholderTranslation: ETranslation.DEAL_TERMINATE_HANDLING_FEE,
      value: "",
      post: "€",
    },
    [EDealTerminate.terminationFee]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_TERMINATE_TERMINATION_FEE,
      placeholderTranslation: ETranslation.DEAL_TERMINATE_TERMINATION_FEE,
      value: "",
      post: "€",
    },
    [EDealTerminate.terminationFeeVat]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_TERMINATE_TERMINATION_FEE_VAT,
      placeholderTranslation: ETranslation.DEAL_TERMINATE_TERMINATION_FEE_VAT,
      value: "",
      post: "€",
    },
    [EDealTerminate.terminationFeeVatAmount]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_TERMINATE_TERMINATION_FEE_VAT_AMOUNT,
      placeholderTranslation:
        ETranslation.DEAL_TERMINATE_TERMINATION_FEE_VAT_AMOUNT,
      value: "",
      post: "€",
    },
    [EDealTerminate.underKilometerCredit]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_TERMINATE_UNDER_KILOMETER_CREDIT,
      placeholderTranslation:
        ETranslation.DEAL_TERMINATE_UNDER_KILOMETER_CREDIT,
      value: "",
      post: "€/km",
    },
    [EDealTerminate.usedKilometers]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_TERMINATE_USED_KILOMETERS,
      placeholderTranslation: ETranslation.DEAL_TERMINATE_USED_KILOMETERS,
      value: "",
      post: "km",
    },
    [EDealTerminate.extraKilometerCharge]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_TERMINATE_EXTRA_KILOMETER_CHARGE,
      placeholderTranslation:
        ETranslation.DEAL_TERMINATE_EXTRA_KILOMETER_CHARGE,
      value: "",
      post: "€/km",
    },
    [EDealTerminate.machineId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_TERMINATE_MACHINE_ID,
      placeholderTranslation: ETranslation.DEAL_TERMINATE_MACHINE_ID,
      value: "",
    },
  });

  const createInput = useCreateInput(terminateInputs, setTerminateInputs, {
    disabled: terminationLoading,
  });

  useEffect(() => {
    // console.log("testitesti", deal)
    if (deal?.terminationCalculation) {
      setTerminateInputs((inputs) =>
        initForm(inputs, deal.terminationCalculation)
      );
    } else {
      setTerminateInputs((inputs) => {
        const newInputs = { ...inputs };
        newInputs[EDealTerminate.terminationFee].value = deal?.terminationFee;
        newInputs[EDealTerminate.terminationFeeVat].value =
          deal?.terminationFeeVat;
        newInputs[EDealTerminate.terminationFeeVatAmount].value =
          deal?.terminationFeeVatAmount;
        newInputs[EDealTerminate.maintenanceBudget].value =
          deal?.maintenanceBudgetUnused;
        if (isCar) {
          newInputs[EDealTerminate.usedKilometers].value =
            deal?.selectedVariation?.contractKilometers;
          newInputs[EDealTerminate.extraKilometerCharge].value =
            deal?.selectedVariation?.extraKilometerCharge;
        } else {
          newInputs[EDealTerminate.purchasePrice].value =
            deal?.selectedVariation?.fundableCapital;
        }
        return newInputs;
      });
    }
  }, [deal, isCar]);

  const finalDebt = terminateInputs[EDealTerminate.finalDebt].value as number;
  const maintenanceBudget = terminateInputs[EDealTerminate.maintenanceBudget]
    .value as number;
  const handlingFee = terminateInputs[EDealTerminate.handlingFee]
    .value as number;
  const terminationFeeVat = terminateInputs[EDealTerminate.terminationFeeVat]
    .value as number;

  useEffect(() => {
    if (
      !finalDebt &&
      !maintenanceBudget &&
      !handlingFee &&
      !terminationFeeVat &&
      !kilometerCalculation.value
    )
      return;
    setTotal(
      toTwoDecimals(
        Number(finalDebt ?? 0) +
          Number(maintenanceBudget ?? 0) +
          Number(handlingFee ?? 0) +
          Number(terminationFeeVat ?? 0) +
          Number(kilometerCalculation.value ?? 0)
      )
    );
  }, [
    finalDebt,
    maintenanceBudget,
    handlingFee,
    terminationFeeVat,
    kilometerCalculation.value,
  ]);

  useEffect(() => {
    const km = deal?.selectedVariation?.contractKilometers;
    if (!km) return;
    const usedKm = terminateInputs[EDealTerminate.usedKilometers]
      .value as number;
    if (!usedKm) return;

    if (usedKm > km) {
      const extraKm = usedKm - km;
      const extraKilometerCharge = terminateInputs[
        EDealTerminate.extraKilometerCharge
      ].value as number;
      console.log(extraKm, extraKilometerCharge);
      setKilometerCalculation({
        type: EKilometerType.over,
        km: extraKm,
        value: extraKilometerCharge
          ? toTwoDecimals(extraKm * extraKilometerCharge)
          : 0,
      });
    } else if (usedKm < km) {
      const underKm = km - usedKm;
      const underKilometerCredit = terminateInputs[
        EDealTerminate.underKilometerCredit
      ].value as number;
      setKilometerCalculation({
        type: EKilometerType.under,
        km: underKm,
        value: underKilometerCredit
          ? toTwoDecimals(-(underKm * underKilometerCredit))
          : 0,
      });
    }
  }, [deal, terminateInputs]);

  const copyLinkHandler = () => {
    if (ref.current) {
      copyToClipboard(ref.current);
      alert(t(ETranslation.DEAL_PREVIEW_LINK_COPIED_TEXT));
    }
  };

  const saveHandler = () => {
    if (!deal?.id) return;
    const data = getInputData<IDealTerminationCalculation>(terminateInputs);
    data.finalDebtDate = formatDateString(data.finalDebtDate);
    data.total = total;
    dispatch(actions.saveTerminationCalculation(deal?.id, data));
  };

  const updateTerminationFee = (value: TInputValue) => {
    const val = value as number;
    const vat = val ? toTwoDecimals(val * (deal?.vatPercent ?? VAT_PERCENT_24)) : "";
    const vatAmount = vat ? toTwoDecimals(vat - val) : "";
    setTerminateInputs((inputs) => {
      const newInputs = { ...inputs };
      newInputs[EDealTerminate.terminationFeeVat].value = vat;
      newInputs[EDealTerminate.terminationFeeVatAmount].value = vatAmount;
      return newInputs;
    });
  };

  const updateTerminationFeeVat = (value: TInputValue) => {
    const val = value as number;
    const vat0 = val ? toTwoDecimals(val / (deal?.vatPercent ?? VAT_PERCENT_24)) : "";
    const vatAmount = val ? toTwoDecimals(val - (vat0 || 0)) : "";
    setTerminateInputs((inputs) => {
      const newInputs = { ...inputs };
      newInputs[EDealTerminate.terminationFee].value = vat0;
      newInputs[EDealTerminate.terminationFeeVatAmount].value = vatAmount;
      return newInputs;
    });
  };

  const getExtraKilometers = useCallback(() => {
    if (kilometerCalculation.type === EKilometerType.over) {
      return (
        <InputGroup>
          <DisabledInput
            value={kilometerCalculation.km}
            labelTranslation={ETranslation.DEAL_TERMINATE_EXTRA_KILOMETERS}
            post="km"
          />
          <DisabledInput
            value={kilometerCalculation.value}
            labelTranslation={
              ETranslation.DEAL_TERMINATE_EXTRA_KILOMETER_CHARGE
            }
            post="€"
          />
        </InputGroup>
      );
    } else if (kilometerCalculation.type === EKilometerType.under) {
      return (
        <InputGroup>
          <DisabledInput
            value={kilometerCalculation.km}
            labelTranslation={ETranslation.DEAL_TERMINATE_UNDER_KILOMETERS}
            post="km"
          />
          <DisabledInput
            value={-kilometerCalculation.value}
            labelTranslation={
              ETranslation.DEAL_TERMINATE_UNDER_KILOMETER_CREDIT
            }
            post="€"
          />
        </InputGroup>
      );
    }

    return null;
  }, [kilometerCalculation]);

  return (
    <>
      {deal?.terminationCalculationLink && (
        <div className={classes.LinkContainer}>
          <div className={classes.Link}>
            <span onClick={copyLinkHandler}>
              <FontAwesomeIcon icon={faCopy} />
            </span>
            <input
              className={classes.Input}
              type="text"
              value={deal?.terminationCalculationLink || ""}
              readOnly={true}
              ref={ref}
            />
          </div>
        </div>
      )}
      <Fieldset label={t(ETranslation.DEAL_TERMINATION_CALCULATION_TITLE)}>
        {!isCar && createInput(EDealTerminate.machineId)}

        <InputGroup>
          {createInput(EDealTerminate.purchasePrice, {
            labelTranslation: isCar
              ? ETranslation.DEAL_TERMINATE_PURCHASE_PRICE
              : ETranslation.DEAL_TERMINATE_FUNDABLE_CAPITAL,
          })}
          {createInput(EDealTerminate.handlingFee)}
        </InputGroup>
        <InputGroup>
          {createInput(EDealTerminate.finalDebt)}
          {createInput(EDealTerminate.finalDebtDate)}
        </InputGroup>

        {isCar && (
          <>
            <InputGroup>
              <DisabledInput
                value={
                  deal?.importName
                    ? deal?.selectedVariation?.maintenanceBudgetVAT
                    : deal?.selectedVariation?.maintenanceBudgetTotal
                }
                labelTranslation={
                  ETranslation.DEAL_TERMINATE_MAINTENANCE_BUDGET
                }
                post="€"
              />
              {createInput(EDealTerminate.maintenanceBudget)}
            </InputGroup>

            <InputGroup>
              <DisabledInput
                value={deal?.selectedVariation?.contractKilometers}
                labelTranslation={ETranslation.DEAL_CONTRACT_KILOMETERS}
                post="km"
              />
              {createInput(EDealTerminate.usedKilometers)}
            </InputGroup>

            <InputGroup>
              {createInput(EDealTerminate.extraKilometerCharge)}
              {createInput(EDealTerminate.underKilometerCredit)}
            </InputGroup>

            {getExtraKilometers()}
          </>
        )}

        <InputGroup>
          {createInput(EDealTerminate.terminationFee, {
            onBlur: updateTerminationFee,
          })}
          {createInput(EDealTerminate.terminationFeeVat, {
            onBlur: updateTerminationFeeVat,
          })}
          {createInput(EDealTerminate.terminationFeeVatAmount, {
            disabled: true,
          })}
        </InputGroup>

        {/* {deal?.terminationFee && (
          <p style={{ margin: 0 }}>
            {t(ETranslation.DEAL_TERMINATION_CALCULATION_TERMINATION_FEE)}{" "}
            {deal.terminationFee}
          </p>
        )}
        {deal?.terminationFeeVat && (
          <p style={{ margin: 0 }}>
            {t(ETranslation.DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT)}{" "}
            {deal.terminationFeeVat}
          </p>
        )}
        {deal?.terminationFeeVatAmount && (
          <p style={{ margin: 0 }}>
            {t(
              ETranslation.DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT_AMOUNT
            )}{" "}
            {deal.terminationFeeVatAmount}
          </p>
        )} */}

        <DisabledInput
          value={total}
          labelTranslation={ETranslation.DEAL_TERMINATION_CALCULATION_TOTAL}
          post="€"
        />
      </Fieldset>
      <InputGroup>
        <Button onClick={saveHandler} loading={terminationLoading}>
          {t(ETranslation.COMMON_SAVE)}
        </Button>
        {deal?.terminationCalculationPrintLink && (
          <Button
            onClick={() =>
              window.open(deal.terminationCalculationPrintLink, "_blank")
            }
          >
            {t(ETranslation.DEAL_ACCEPT_PRINT)}
          </Button>
        )}
      </InputGroup>
    </>
  );
};

export default DealTerminate;
