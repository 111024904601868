import { IDealAction, IDealState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IDealState = {
  loading: false,
  deals: null,
  deal: null,
  error: null,
  invoicesLoading: false,
  terminationLoading: false,
  netsUrl: null,
  netsLoading: false,
  confirmLoading: false,
  drafts: null,
  preOffers: null,
  preOffer: null,
  idList: null,
};

const getDealStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    deal: null,
    preOffer: null,
    loading: true,
    error: null,
  });
};

const getDealSuccess = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const getDealFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listDealsStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    deals: null,
    deal: null,
    loading: true,
    error: null,
  });
};

const listDealsSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deals: action.deals,
    loading: false,
    error: null,
  });
};

const listDealsFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveDealStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveDealSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const saveDealFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateDealStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateDealSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const updateDealFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearDeal = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    deal: null,
    preOffer: null,
    loading: false,
    error: null,
  });
};

const getTokenDealStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: null,
    preOffer: null,
    loading: true,
    error: null,
  });
};

const getTokenDealSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const getTokenDealFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const sendDealStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    deal: null,
    loading: true,
    error: null,
  });
};

const sendDealSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const sendDealFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const sendDealReturnInfoStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    deal: null,
    loading: true,
    error: null,
  });
};

const sendDealReturnInfoSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const sendDealReturnInfoFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getPipedriveDealStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: null,
    preOffer: null,
    loading: true,
    error: null,
  });
};

const getPipedriveDealSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const getPipedriveDealFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateDealStatusStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateDealStatusSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const updateDealStatusFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const copyDealStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const copyDealSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const copyDealFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const archiveDealStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const archiveDealSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const archiveDealFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const detachInvoiceStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    invoicesLoading: true,
    error: null,
  });
};

const detachInvoiceSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  let deal = state.deal;
  if (action.invoiceId && deal && deal.invoices && deal.invoices.length > 0) {
    deal.invoices = [...deal.invoices].filter(i => i.id !== action.invoiceId);
  }
  return updateObject(state, {
    deal,
    invoicesLoading: false,
    error: null,
  });
};

const detachInvoiceFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    invoicesLoading: false,
    error: action.error,
  });
};

const editInvoiceRowsStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    invoicesLoading: true,
    error: null,
  });
};

const editInvoiceRowsSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  let deal = state.deal;
  if (action.invoice && deal && deal.invoices && deal.invoices.length > 0) {
    const idx = deal.invoices.findIndex(
      (invoice) => invoice.id === action.invoice?.id
    );
    if (typeof idx !== "undefined") {
      deal.invoices[idx] = action.invoice;
    }
  }
  return updateObject(state, {
    deal,
    invoicesLoading: false,
    error: null,
  });
};

const editInvoiceRowsFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    invoicesLoading: false,
    error: action.error,
  });
};

const saveTerminationCalculationStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    terminationLoading: true,
    error: null,
  });
};

const saveTerminationCalculationSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  let deal = state.deal;
  if (action.deal && deal) {
    deal.terminationCalculation = action.deal.terminationCalculation;
  }
  return updateObject(state, {
    deal,
    terminationLoading: false,
    error: null,
  });
};

const saveTerminationCalculationFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    terminationLoading: false,
    error: action.error,
  });
};

const detachTalenomInvoiceStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    invoicesLoading: true,
    error: null,
  });
};

const detachTalenomInvoiceSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  let deal = state.deal;
  if (action.invoiceId && deal && deal.talenomInvoices && deal.talenomInvoices.length > 0) {
    deal.talenomInvoices = deal.talenomInvoices.filter(
      (invoice) => invoice.id === action.invoiceId
    );
  }
  return updateObject(state, {
    deal,
    invoicesLoading: false,
    error: null,
  });
};

const detachTalenomInvoiceFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    invoicesLoading: false,
    error: action.error,
  });
};

const getTerminateDealStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: null,
    loading: true,
    error: null,
  });
};

const getTerminateDealSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const getTerminateDealFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getMaintenanceInfoDealStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: null,
    loading: true,
    error: null,
  });
};

const getMaintenanceInfoDealSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const getMaintenanceInfoDealFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const dealIdentifyStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    netsUrl: null,
    netsLoading: true,
    error: null,
  });
};

const dealIdentifySuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    netsUrl: action.netsUrl || null,
    netsLoading: false,
    error: null,
  });
};

const dealIdentifyFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    confirmLoading: false,
    error: action.error,
  });
};

const confirmSignatureStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    confirmLoading: true,
    error: null,
  });
};

const confirmSignatureSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    confirmLoading: false,
    error: null,
  });
};

const confirmSignatureFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    netsLoading: false,
    error: action.error,
  });
};

const listDraftsStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    drafts: null,
    deal: null,
    loading: true,
    error: null,
  });
};

const listDraftsSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    drafts: action.deals,
    loading: false,
    error: null,
  });
};

const listDraftsFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listPreoffersStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    preOffers: null,
    deal: null,
    idList: null,
    loading: true,
    error: null,
  });
};

const listPreoffersSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    preOffers: action.deals,
    idList: action.idList,
    loading: false,
    error: null,
  });
};

const listPreoffersFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getDraftStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    deal: null,
    loading: true,
    error: null,
  });
};

const getDraftSuccess = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const getDraftFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const archiveDraftStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const archiveDraftSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const archiveDraftFail = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getPreofferStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    preOffer: null,
    loading: true,
    error: null,
  });
};

const getPreofferSuccess = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    preOffer: action.deal,
    loading: false,
    error: null,
  });
};

const getPreofferFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listPartnerDealsStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: true,
    error: null,
    precontracts: null,
    contracts: null,
    terminates: null,
  });
};

const listPartnerDealsSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    precontracts: action.precontracts,
    contracts: action.contracts,
    terminates: action.terminates,
    loading: false,
    error: null,
  });
};

const listPartnerDealsFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveDraftStart = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveDraftSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const saveDraftFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateDraftStart = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateDraftSuccess = (
  state: IDealState,
  action: IDealAction
): IDealState => {
  return updateObject(state, {
    deal: action.deal,
    loading: false,
    error: null,
  });
};

const updateDraftFail = (state: IDealState, action: IDealAction): IDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IDealState = initialState,
  action: IDealAction
): IDealState => {
  switch (action.type) {
    case ActionTypes.GET_DEAL_START:
      return getDealStart(state, action);
    case ActionTypes.GET_DEAL_SUCCESS:
      return getDealSuccess(state, action);
    case ActionTypes.GET_DEAL_FAIL:
      return getDealFail(state, action);
    case ActionTypes.LIST_DEALS_START:
      return listDealsStart(state, action);
    case ActionTypes.LIST_DEALS_SUCCESS:
      return listDealsSuccess(state, action);
    case ActionTypes.LIST_DEALS_FAIL:
      return listDealsFail(state, action);
    case ActionTypes.SAVE_DEAL_START:
      return saveDealStart(state, action);
    case ActionTypes.SAVE_DEAL_SUCCESS:
      return saveDealSuccess(state, action);
    case ActionTypes.SAVE_DEAL_FAIL:
      return saveDealFail(state, action);
    case ActionTypes.UPDATE_DEAL_START:
      return updateDealStart(state, action);
    case ActionTypes.UPDATE_DEAL_SUCCESS:
      return updateDealSuccess(state, action);
    case ActionTypes.UPDATE_DEAL_FAIL:
      return updateDealFail(state, action);
    case ActionTypes.CLEAR_DEAL:
      return clearDeal(state, action);
    case ActionTypes.GET_TOKEN_DEAL_START:
      return getTokenDealStart(state, action);
    case ActionTypes.GET_TOKEN_DEAL_SUCCESS:
      return getTokenDealSuccess(state, action);
    case ActionTypes.GET_TOKEN_DEAL_FAIL:
      return getTokenDealFail(state, action);
    case ActionTypes.SEND_DEAL_START:
      return sendDealStart(state, action);
    case ActionTypes.SEND_DEAL_SUCCESS:
      return sendDealSuccess(state, action);
    case ActionTypes.SEND_DEAL_FAIL:
      return sendDealFail(state, action);
    case ActionTypes.SEND_DEAL_RETURN_INFO_START:
      return sendDealReturnInfoStart(state, action);
    case ActionTypes.SEND_DEAL_RETURN_INFO_SUCCESS:
      return sendDealReturnInfoSuccess(state, action);
    case ActionTypes.SEND_DEAL_RETURN_INFO_FAIL:
      return sendDealReturnInfoFail(state, action);
    case ActionTypes.GET_PIPEDRIVE_DEAL_START:
      return getPipedriveDealStart(state, action);
    case ActionTypes.GET_PIPEDRIVE_DEAL_SUCCESS:
      return getPipedriveDealSuccess(state, action);
    case ActionTypes.GET_PIPEDRIVE_DEAL_FAIL:
      return getPipedriveDealFail(state, action);
    case ActionTypes.UPDATE_DEAL_STATUS_START:
      return updateDealStatusStart(state, action);
    case ActionTypes.UPDATE_DEAL_STATUS_SUCCESS:
      return updateDealStatusSuccess(state, action);
    case ActionTypes.UPDATE_DEAL_STATUS_FAIL:
      return updateDealStatusFail(state, action);
    case ActionTypes.COPY_DEAL_START:
      return copyDealStart(state, action);
    case ActionTypes.COPY_DEAL_SUCCESS:
      return copyDealSuccess(state, action);
    case ActionTypes.COPY_DEAL_FAIL:
      return copyDealFail(state, action);
    case ActionTypes.ARCHIVE_DEAL_START:
      return archiveDealStart(state, action);
    case ActionTypes.ARCHIVE_DEAL_SUCCESS:
      return archiveDealSuccess(state, action);
    case ActionTypes.ARCHIVE_DEAL_FAIL:
      return archiveDealFail(state, action);
    case ActionTypes.DETACH_INVOICE_START:
      return detachInvoiceStart(state, action);
    case ActionTypes.DETACH_INVOICE_SUCCESS:
      return detachInvoiceSuccess(state, action);
    case ActionTypes.DETACH_INVOICE_FAIL:
      return detachInvoiceFail(state, action);
    case ActionTypes.EDIT_INVOICE_ROWS_START:
      return editInvoiceRowsStart(state, action);
    case ActionTypes.EDIT_INVOICE_ROWS_SUCCESS:
      return editInvoiceRowsSuccess(state, action);
    case ActionTypes.EDIT_INVOICE_ROWS_FAIL:
      return editInvoiceRowsFail(state, action);
    case ActionTypes.SAVE_TERMINATION_CALCULATION_START:
      return saveTerminationCalculationStart(state, action);
    case ActionTypes.SAVE_TERMINATION_CALCULATION_SUCCESS:
      return saveTerminationCalculationSuccess(state, action);
    case ActionTypes.SAVE_TERMINATION_CALCULATION_FAIL:
      return saveTerminationCalculationFail(state, action);
    case ActionTypes.TALENOM_INVOICES_DETACH_START:
      return detachTalenomInvoiceStart(state, action);
    case ActionTypes.TALENOM_INVOICES_DETACH_SUCCESS:
      return detachTalenomInvoiceSuccess(state, action);
    case ActionTypes.TALENOM_INVOICES_DETACH_FAIL:
      return detachTalenomInvoiceFail(state, action);
    case ActionTypes.GET_TERMINATE_DEAL_START:
      return getTerminateDealStart(state, action);
    case ActionTypes.GET_TERMINATE_DEAL_SUCCESS:
      return getTerminateDealSuccess(state, action);
    case ActionTypes.GET_TERMINATE_DEAL_FAIL:
      return getTerminateDealFail(state, action);
    case ActionTypes.GET_MAINTENANCE_INFO_DEAL_START:
      return getMaintenanceInfoDealStart(state, action);
    case ActionTypes.GET_MAINTENANCE_INFO_DEAL_SUCCESS:
      return getMaintenanceInfoDealSuccess(state, action);
    case ActionTypes.GET_MAINTENANCE_INFO_DEAL_FAIL:
      return getMaintenanceInfoDealFail(state, action);
    case ActionTypes.DEAL_IDENTIFY_START:
      return dealIdentifyStart(state, action);
    case ActionTypes.DEAL_IDENTIFY_SUCCESS:
      return dealIdentifySuccess(state, action);
    case ActionTypes.DEAL_IDENTIFY_FAIL:
      return dealIdentifyFail(state, action);
    case ActionTypes.DEAL_CONFIRM_SIGNATURE_START:
      return confirmSignatureStart(state, action);
    case ActionTypes.DEAL_CONFIRM_SIGNATURE_SUCCESS:
      return confirmSignatureSuccess(state, action);
    case ActionTypes.DEAL_CONFIRM_SIGNATURE_FAIL:
      return confirmSignatureFail(state, action);
    case ActionTypes.LIST_DRAFTS_START:
      return listDraftsStart(state, action);
    case ActionTypes.LIST_DRAFTS_SUCCESS:
      return listDraftsSuccess(state, action);
    case ActionTypes.LIST_DRAFTS_FAIL:
      return listDraftsFail(state, action);
    case ActionTypes.LIST_PREOFFERS_START:
      return listPreoffersStart(state, action);
    case ActionTypes.LIST_PREOFFERS_SUCCESS:
      return listPreoffersSuccess(state, action);
    case ActionTypes.LIST_PREOFFERS_FAIL:
      return listPreoffersFail(state, action);
    case ActionTypes.GET_DRAFT_START:
      return getDraftStart(state, action);
    case ActionTypes.GET_DRAFT_SUCCESS:
      return getDraftSuccess(state, action);
    case ActionTypes.GET_DRAFT_FAIL:
      return getDraftFail(state, action);
    case ActionTypes.ARCHIVE_DRAFT_START:
      return archiveDraftStart(state, action);
    case ActionTypes.ARCHIVE_DRAFT_SUCCESS:
      return archiveDraftSuccess(state, action);
    case ActionTypes.ARCHIVE_DRAFT_FAIL:
      return archiveDraftFail(state, action);
    case ActionTypes.GET_PREOFFER_START:
      return getPreofferStart(state, action);
    case ActionTypes.GET_PREOFFER_SUCCESS:
      return getPreofferSuccess(state, action);
    case ActionTypes.GET_PREOFFER_FAIL:
      return getPreofferFail(state, action);
    case ActionTypes.LIST_PARTNER_DEALS_START:
      return listPartnerDealsStart(state, action);
    case ActionTypes.LIST_PARTNER_DEALS_SUCCESS:
      return listPartnerDealsSuccess(state, action);
    case ActionTypes.LIST_PARTNER_DEALS_FAIL:
      return listPartnerDealsFail(state, action);
    case ActionTypes.SAVE_DRAFT_START:
      return saveDraftStart(state, action);
    case ActionTypes.SAVE_DRAFT_SUCCESS:
      return saveDraftSuccess(state, action);
    case ActionTypes.SAVE_DRAFT_FAIL:
      return saveDraftFail(state, action);
    case ActionTypes.UPDATE_DRAFT_START:
      return updateDraftStart(state, action);
    case ActionTypes.UPDATE_DRAFT_SUCCESS:
      return updateDraftSuccess(state, action);
    case ActionTypes.UPDATE_DRAFT_FAIL:
      return updateDraftFail(state, action);
    default:
      return state;
  }
};

export default reducer;
