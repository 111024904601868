import React, { ReactNode, CSSProperties } from "react";
import classes from "./Button.module.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner, { ESpinnerColor, ESpinnerSize } from "../Spinner/Spinner";


export enum EButtonSize {
  SMALL, MEDIUM, LARGE
}

export enum EButtonColor {
  PRIMARY, SECONDARY, SUCCESS, DANGER, NONE,
}

export enum EButtonType {
  BUTTON = "button",
  SUBMIT = "submit",
}

interface IProps {
  icon?: IconDefinition;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  size?: EButtonSize;
  color?: EButtonColor;
  type?: EButtonType;
  loading?: boolean;
  title?: string;
}

const Button: React.FC<IProps> = ({
  icon,
  onClick,
  disabled,
  children,
  className,
  style,
  size,
  color,
  type = EButtonType.BUTTON,
  loading,
  title,
}) => {
  const classNames = [classes.Button];
  if (disabled) {
    classNames.push(classes.Disabled);
  }
  if (className) {
    classNames.push(className);
  }

  switch (size) {
    case EButtonSize.SMALL:
      classNames.push(classes.Small);
      break;
    case EButtonSize.LARGE:
      classNames.push(classes.Large);
      break;
    default:
  }

  switch (color) {
    case EButtonColor.SECONDARY:
      classNames.push(classes.Secondary);
      break;
    case EButtonColor.SUCCESS:
      classNames.push(classes.Success);
      break;
    case EButtonColor.DANGER:
      classNames.push(classes.Danger);
      break;
    case EButtonColor.NONE:
      classNames.push(classes.None);
      break;
    default:
  }

  return (
    <button
      className={classNames.join(" ")}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      style={style}
      title={title}
    >
      {loading ? (
        <Spinner size={ESpinnerSize.SMALL} color={ESpinnerColor.CONTENT} />
      ) : (
        <>
          {icon && <FontAwesomeIcon icon={icon} />} {children}
        </>
      )}
    </button>
  );
};

export default Button;
