import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { TDispatch } from "../../..";
import Alert from "../../../components/UI/Alert/Alert";
import Button from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import Input, { EInputType, IInputField, IInputOptions } from "../../../components/UI/Input/Input";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../../context/InputContext";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { IAppState, IUser } from "../../../interfaces";
import { initForm, updateInputHandler, validateInputs } from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";

interface IMatch {
  id: string;
  organizationId?: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

export enum EUser {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
  PHONE_NUMBER = "phoneNumber",
  TITLE = "title",
  ALLOW_LOGIN = "allowLogin",
  PROFILE_IMAGE = "profileImage",
  STREET_ADDRESS = "streetAddress",
  ZIP = "zip",
  CITY = "city",
  SOCIAL_SECURITY_NUMBER = "socialSecurityNumber",
  PASSWORD = "password",
}

const User: React.FC<IProps> = ({ match, history }) => {
  const dispatch = useDispatch<TDispatch>();
  const [isValid, setValid] = useState(false);

  const authUser = useAuthUser();

  const { loading, error, user, saveOrUpdateOk } = useSelector((state: IAppState) => ({
    loading: state.users.loading,
    error: state.users.error,
    user: state.users.user,
    saveOrUpdateOk: state.users.saveOrUpdateOk
  }))

  const { t } = useTranslation();
  const { id, organizationId } = match.params;

  const isNew = id === "new";

  const initialInputs: IInputField = {
    [EUser.FIRST_NAME]: {
      type: EInputType.text,
      labelTranslation: ETranslation.USER_FIRST_NAME,
      placeholderTranslation: ETranslation.USER_FIRST_NAME,
      value: "",
      validation: {
        required: true,
      }
    },
    [EUser.LAST_NAME]: {
      type: EInputType.text,
      labelTranslation: ETranslation.USER_LAST_NAME,
      placeholderTranslation: ETranslation.USER_LAST_NAME,
      value: "",
      validation: {
        required: true,
      }
    },
    [EUser.EMAIL]: {
      type: EInputType.email,
      labelTranslation: ETranslation.USER_EMAIL,
      placeholderTranslation: ETranslation.USER_EMAIL,
      value: "",
      validation: {
        required: true
      }
    },
    [EUser.PHONE_NUMBER]: {
      type: EInputType.tel,
      labelTranslation: ETranslation.USER_PHONE_NUMBER,
      placeholderTranslation: ETranslation.USER_PHONE_NUMBER,
      value: "",
      validation: {
        required: true
      }
    },
    [EUser.TITLE]: {
      type: EInputType.text,
      labelTranslation: ETranslation.USER_TITLE,
      placeholderTranslation: ETranslation.USER_TITLE,
      value: "",
    },
    [EUser.STREET_ADDRESS]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_STREET_ADDRESS,
      placeholderTranslation: ETranslation.COMMON_STREET_ADDRESS,
      value: "",
    },
    [EUser.ZIP]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_ZIP,
      placeholderTranslation: ETranslation.COMMON_ZIP,
      value: "",
    },
    [EUser.CITY]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_CITY,
      placeholderTranslation: ETranslation.COMMON_CITY,
      value: "",
    },
    [EUser.SOCIAL_SECURITY_NUMBER]: {
      type: EInputType.text,
      labelTranslation: ETranslation.USER_SOCIAL_SECURITY_NUMBER,
      placeholderTranslation: ETranslation.USER_SOCIAL_SECURITY_NUMBER,
      value: "",
    },
    [EUser.PROFILE_IMAGE]: {
      type: EInputType.text,
      labelTranslation: ETranslation.USER_PROFILE_IMAGE,
      placeholderTranslation: ETranslation.USER_PROFILE_IMAGE,
      value: "",
    },
    [EUser.ALLOW_LOGIN]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.USER_ALLOW_LOGIN,
      value: true,
    },
    [EUser.PASSWORD]: {
      type: EInputType.password,
      labelTranslation: ETranslation.USER_PASSWORD,
      placeholderTranslation: ETranslation.USER_PASSWORD,
      value: "",
      validation: {
        required: isNew,
        minLength: 6,
        minLengthMessage: ETranslation.PASSWORD_MIN_LENGTH_MESSAGE,
      },
      readOnly: true
    },
  };

  const [inputs, setInputs] = useState<IInputField>({ ...initialInputs });

  useEffect(() => {
    if (id && !isNew) {
      if (organizationId) {
        dispatch(actions.getUser(id, organizationId));
      } else {
        dispatch(actions.getUser(id));
      }
    }
  }, [id, dispatch, isNew, organizationId]);

  useEffect(() => {
    if (id && !isNew && user) {
      setInputs(stateInputs => initForm(stateInputs, user));
    } else {
      setInputs(_ => initialInputs);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setValid(validateInputs(inputs));
  }, [inputs]);

  useEffect(() => {
    if (saveOrUpdateOk) {
      history.goBack();
    }
  }, [history, saveOrUpdateOk]);

  const createInput = (inputName: EUser, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.USER}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
      showValidation={!isValid}
    />
  };

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const firstName = inputs[EUser.FIRST_NAME].value as string;
    const lastName = inputs[EUser.LAST_NAME].value as string;
    const email = inputs[EUser.EMAIL].value as string;
    const title = inputs[EUser.TITLE].value as string;
    const phoneNumber = inputs[EUser.PHONE_NUMBER].value as string;
    const streetAddress = inputs[EUser.STREET_ADDRESS].value as string;
    const zip = inputs[EUser.ZIP].value as string;
    const city = inputs[EUser.CITY].value as string;
    const socialSecurityNumber = inputs[EUser.SOCIAL_SECURITY_NUMBER].value as string;
    const profileImage = inputs[EUser.PROFILE_IMAGE].value as string;
    const password = inputs[EUser.PASSWORD].value as string;
    const allowLogin = inputs[EUser.ALLOW_LOGIN].value as boolean;

    const newUser: IUser = {
      id: "", firstName, lastName, email, title, phoneNumber, streetAddress, zip, city, socialSecurityNumber, profileImage, password, allowLogin
    }

    if (isNew) {
      if (organizationId) {
        await dispatch(actions.saveUser(newUser, organizationId));
      } else {
        if (authUser && authUser.organization && authUser.organization.id) {
          await dispatch(actions.saveUser(newUser, authUser.organization.id));
        } else {
          // TODO: show error
          return;
        }
      }
    } else {
      newUser.id = id;
      newUser.organization = user?.organization;
      await dispatch(actions.updateUser(newUser));
    }

    console.log(newUser);
    // history.push(Routes.USERS);
  };

  const createInputs = () => {
    return (
      <>
        <InputGroup>
          {createInput(EUser.FIRST_NAME)}
          {createInput(EUser.LAST_NAME)}
        </InputGroup>
        <InputGroup>{createInput(EUser.TITLE)}</InputGroup>
        <InputGroup>{createInput(EUser.EMAIL)}</InputGroup>
        <InputGroup>
          {createInput(EUser.PHONE_NUMBER, { disabled: user?.isMultiFactor })}
        </InputGroup>
        <InputGroup>{createInput(EUser.STREET_ADDRESS)}</InputGroup>
        <InputGroup>
          {createInput(EUser.ZIP)}
          {createInput(EUser.CITY)}
        </InputGroup>
        {/* TODO: remove? */}
        {/* <InputGroup>
          {createInput(EUser.SOCIAL_SECURITY_NUMBER)}
        </InputGroup> */}
        {/* <InputGroup>
          {createInput(EUser.PROFILE_IMAGE)}
        </InputGroup> */}

        {isNew && <InputGroup>{createInput(EUser.PASSWORD)}</InputGroup>}

        {authUser?.id !== user?.id && (
          <InputGroup>{createInput(EUser.ALLOW_LOGIN)}</InputGroup>
        )}
      </>
    );
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{isNew ? t(ETranslation.TITLE_USER_NEW) : t(ETranslation.TITLE_USER)}</h2>
        {loading
          ? <Spinner />
          : createInputs()
        }
        <InputGroup>
          <Button disabled={loading || !isValid} onClick={submitHandler}>
            {t(ETranslation.COMMON_SAVE)}
          </Button>
          <Button onClick={() => history.goBack()}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
        </InputGroup>
      </Container>
    </>
  );
};

export default User;
