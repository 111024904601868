import { ETranslation } from "./../translations/translation-keys";
import { TFunction } from "i18next";

export enum EUserRole {
  ADMIN = "ADMIN",
  SELLER = "SELLER",
  CUSTOMER = "CUSTOMER",
}

export enum EOrganizationLevel {
  PRIMARY = "PRIMARY",
  CUSTOMER = "CUSTOMER",
  PARTNER = "PARTNER",
}

export enum EDealStatus {
  DRAFT = "DRAFT",
  PREOFFER = "PREOFFER",
  OFFER = "OFFER",
  PRECONTRACT = "PRECONTRACT",
  CONTRACT = "CONTRACT",
  TERMINATED = "TERMINATED",
  ARCHIVED = "ARCHIVED",
}

// use translate
export const DealStatus = {
  [EDealStatus.OFFER]: "Tarjous",
  [EDealStatus.PRECONTRACT]: "",
  [EDealStatus.CONTRACT]: "Sopimus",
  [EDealStatus.TERMINATED]: "",
};

export enum EDealType {
  CAR = "CAR",
  MACHINE = "MACHINE",
}

enum EInputSuffix {
  GRAMS_PER_KILOMETER = "GRAMS_PER_KILOMETER",
  EUROS = "EUROS",
  PERCENT = "PERCENT",
  MONTHS = "MONTHS",
  KILOMETERS = "KILOMETERS",
  EUROS_PER_MONTH = "EUROS_PER_MONTH",
  EUROS_PER_SET = "EUROS_PER_SET",
  EUROS_PER_KILOMETER = "EUROS_PER_KILOMETER",
}

export const InputSuffix = {
  [EInputSuffix.GRAMS_PER_KILOMETER]: "g/km",
  [EInputSuffix.EUROS]: "€",
  [EInputSuffix.PERCENT]: "%",
  [EInputSuffix.MONTHS]: (t: TFunction) => t(ETranslation.SUFFIX_MONTHS),
  [EInputSuffix.KILOMETERS]: "km",
  [EInputSuffix.EUROS_PER_MONTH]: (t: TFunction) =>
    t(ETranslation.SUFFIX_EUROS_PER_MONTH),
  [EInputSuffix.EUROS_PER_SET]: (t: TFunction) =>
    t(ETranslation.SUFFIX_EUROS_PER_SET),
  [EInputSuffix.EUROS_PER_KILOMETER]: "€/km",
};

export enum ELeasingModel {
  REILU = "REILU",
  HUOLTO = "HUOLTO",
  RAHOITUS = "RAHOITUS",
}

export const LEASING_MODEL_OPTIONS = [
  { value: ELeasingModel.REILU, labelTranslation: ETranslation.LEASING_MODEL_REILU },
  { value: ELeasingModel.HUOLTO, labelTranslation: ETranslation.LEASING_MODEL_HUOLTO },
  { value: ELeasingModel.RAHOITUS, labelTranslation: ETranslation.LEASING_MODEL_RAHOITUS },
];

export enum EInvoicePayer {
  OLF = "OLF",
  FINANCE_COMPANY = "FINANCE_COMPANY",
}

export enum ESearchStorageKey {
  DEALS_FINANCE_COMPANY_ID = "DEALS_FINANCE_COMPANY_ID",
  DEALS_STATUS = "DEALS_STATUS",
  DEALS_SELLER_ID = "DEALS_SELLER_ID",
  DEALS_CUSTOMER_ID = "DEALS_CUSTOMER_ID",
  DEALS_TYPE = "DEALS_TYPE",
  REPORTS_CUSTOMER_ID = "REPORTS_CUSTOMER_ID",
  REPORTS_YEAR = "REPORTS_YEAR",
}

export enum EAction {
  USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS", 
  USER_LOGIN_FAILED = "USER_LOGIN_FAILED", 
  USER_ADDED = "USER_ADDED", 
  USER_DISABLED = "USER_DISABLED", 
  USER_ENABLED = "USER_ENABLED", 
  DEAL_STATUS_CHANGED = "DEAL_STATUS_CHANGED", 
  APPLICATION_SENT = "APPLICATION_SENT",
  CONTRACT_UPDATED = "CONTRACT_UPDATED",
  DEAL_NEW = "DEAL_NEW", 
  DRAFT_NEW = "DRAFT_NEW", 
  DEAL_SIGNED = "DEAL_SIGNED"
}

export enum ECategory {
  DEAL = "DEAL", 
  APPLICATION = "APPLICATION", 
}