import React, { CSSProperties, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IDeal } from "../../../interfaces";
import { formatDateString } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../UI/Table/Table";
import { ELeasingModel, LEASING_MODEL_OPTIONS } from "../../../enums";

interface IProps {
  deals: IDeal[];
  onClick: (deal?: IDeal) => void;
  containerClassName?: string;
  containerStyle?: CSSProperties;
}

const DealsTableCustomer: React.FC<IProps> = ({ deals, onClick, containerClassName, containerStyle }) => {
  const { t } = useTranslation();

  const mapHeaders = () => {
    return (
      <>
        <th>{t(ETranslation.COMMON_CREATED)}</th>
        <th>{t(ETranslation.DEAL_NAME)}</th>
        <th>{t(ETranslation.DEAL_BRAND)}</th>
        <th>{t(ETranslation.DEAL_MODEL)}</th>
        <th>{t(ETranslation.DEAL_REGISTRATION_NUMBER)}</th>
        <th>{t(ETranslation.DEAL_COST_CENTER)}</th>
        <th>{t(ETranslation.DEAL_RESIDUAL)}</th>
        <th>{t(ETranslation.DEAL_END_DATE)}</th>
        <th>{t(ETranslation.DEAL_MONTHLY_PAYMENT)}</th>
        <th>{t(ETranslation.DEAL_CAR_CO2_EMISSIONS)}</th>
        <th>{t(ETranslation.DEAL_CONTRACT_LENGTH)}</th>
        <th>{t(ETranslation.DEAL_LEASING_MODEL)}</th>
      </>
    )
  }

  // const getDealTypeString = (type: EDealType) => {
  //   switch (type) {
  //     case EDealType.CAR:
  //       return t(ETranslation.DEAL_TYPE_CAR);
  //     case EDealType.MACHINE:
  //       return t(ETranslation.DEAL_TYPE_MACHINE);
  //     default:
  //       return null;
  //   }
  // };

  const getLeasingModelTranslation = useCallback((leasingModel: ELeasingModel) => {
    if (!leasingModel) return "";
    return t(LEASING_MODEL_OPTIONS.find(model => model.value === leasingModel)?.labelTranslation ?? "");
  }, [t]);

  return (
    <div className={containerClassName} style={containerStyle}>
      <Table hover>
        <thead>
          <tr>{mapHeaders()}</tr>
        </thead>
        <tbody>
          {deals.map(deal => (
            <tr key={deal.id} onClick={() => onClick(deal)}>
              <td>{deal.created && formatDateString(deal.created)}</td>
              <td>{deal.name}</td>
              <td>{deal.brand}</td>
              <td>{deal.model}</td>
              <td>{deal.registrationNumber}</td>
              <td>{deal.costCenter}</td>
              <td>{deal.selectedVariation?.residualValue}</td>
              <td>{deal.endDate}</td>
              <td>{deal.selectedVariation?.monthlyPayment}</td>
              <td>{deal.co2Emissions}</td>
              <td>{deal.selectedVariation?.contractLength}</td>
              <td>{deal?.leasingModel ? getLeasingModelTranslation(deal.leasingModel) : ""}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DealsTableCustomer;
