import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { IUserState, IUserAction } from "../../interfaces";

const initialState: IUserState = {
  loading: false,
  users: null,
  user: null,
  error: null,
  saveOrUpdateOk: false,
};

const getUserStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    user: null,
    loading: true,
    error: null,
  });
};

const getUserSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    error: null,
  });
};

const getUserFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listUsersStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const listUsersSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    users: action.users,
    loading: false,
    error: null,
  });
};

const listUsersFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveUserStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveUserSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    error: null,
    saveOrUpdateOk: true,
  });
};

const saveUserFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateUserStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateUserSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    error: null,
    saveOrUpdateOk: true,
  });
};

const updateUserFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IUserState = initialState,
  action: IUserAction
): IUserState => {
  switch (action.type) {
    case ActionTypes.GET_USER_START:
      return getUserStart(state, action);
    case ActionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case ActionTypes.GET_USER_FAIL:
      return getUserFail(state, action);
    case ActionTypes.LIST_USERS_START:
      return listUsersStart(state, action);
    case ActionTypes.LIST_USERS_SUCCESS:
      return listUsersSuccess(state, action);
    case ActionTypes.LIST_USERS_FAIL:
      return listUsersFail(state, action);
    case ActionTypes.SAVE_USER_START:
      return saveUserStart(state, action);
    case ActionTypes.SAVE_USER_SUCCESS:
      return saveUserSuccess(state, action);
    case ActionTypes.SAVE_USER_FAIL:
      return saveUserFail(state, action);
    case ActionTypes.UPDATE_USER_START:
      return updateUserStart(state, action);
    case ActionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case ActionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    default:
      return state;
  }
};

export default reducer;
