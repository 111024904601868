import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Button, { EButtonColor, EButtonSize } from "../../../../../components/UI/Button/Button";
import { IDealNote } from "../../../../../interfaces";
import classes from "./DealNote.module.scss";

interface IProps {
  note: IDealNote;
  onDelete: (id: string) => void;
}

const DealNote: React.FC<IProps> = ({ note, onDelete }) => {
  return (
    <div className={classes.Note}>
      <div>
        <p>{note.date}</p>
        <p>{note.user.name}</p>
        <p className={classes.Text}>{note.text}</p>
      </div>
      <div className={classes.ButtonContainer}>
        <Button size={EButtonSize.SMALL} color={EButtonColor.SECONDARY} onClick={() => onDelete(note.id)} icon={faTrash} />
      </div>
    </div>
  )
};

export default DealNote;
