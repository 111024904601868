import {
  JSON_ORGANIZATIONS_GET,
  JSON_ORGANIZATIONS_LIST,
  JSON_ORGANIZATIONS_ADD,
  JSON_ORGANIZATIONS_UPDATE,
  JSON_ORGANIZATIONS_DELETE,
} from "../../endpoints/index";
import { IOrganization } from "../../interfaces/domain";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IPartnerAction } from "../../interfaces";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { EOrganizationLevel } from "../../enums";

const getPartnerStart = (): IPartnerAction => {
  return {
    type: ActionTypes.GET_PARTNER_START,
  };
};

const getPartnerSuccess = (
  partner: IOrganization
): IPartnerAction => {
  return {
    type: ActionTypes.GET_PARTNER_SUCCESS,
    partner,
  };
};

const getPartnerFail = (error: string): IPartnerAction => {
  return {
    type: ActionTypes.GET_PARTNER_FAIL,
    error,
  };
};

export const getPartner = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPartnerStart());
    try {
      const res = await axios.get(JSON_ORGANIZATIONS_GET(id));
      dispatch(getPartnerSuccess(res.data));
    } catch ({ response }) {
      dispatch(getPartnerFail((response as AxiosResponse).data));
    }
  };
};

const listPartnersStart = (): IPartnerAction => {
  return {
    type: ActionTypes.LIST_PARTNERS_START,
  };
};

const listPartnersSuccess = (
  partners: Array<IOrganization>
): IPartnerAction => {
  return {
    type: ActionTypes.LIST_PARTNERS_SUCCESS,
    partners,
  };
};

const listPartnersFail = (error: string): IPartnerAction => {
  return {
    type: ActionTypes.LIST_PARTNERS_FAIL,
    error,
  };
};

export const listPartners = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listPartnersStart());
    try {
      const res = await axios.get(JSON_ORGANIZATIONS_LIST(EOrganizationLevel.PARTNER));
      dispatch(listPartnersSuccess(res.data));
    } catch ({ response }) {
      dispatch(listPartnersFail((response as AxiosResponse).data));
    }
  };
};

const savePartnerStart = (): IPartnerAction => {
  return {
    type: ActionTypes.SAVE_PARTNER_START,
  };
};

const savePartnerSuccess = (partner: IOrganization): IPartnerAction => {
  return {
    type: ActionTypes.SAVE_PARTNER_SUCCESS,
    partner,
  };
};

const savePartnerFail = (error: string): IPartnerAction => {
  return {
    type: ActionTypes.SAVE_PARTNER_FAIL,
    error,
  };
};

export const savePartner = (partner: IOrganization) => {
  return async (dispatch: Dispatch) => {
    dispatch(savePartnerStart());
    try {
      const res = await axios.post(JSON_ORGANIZATIONS_ADD, partner);
      dispatch(savePartnerSuccess(res.data));
    } catch ({ response }) {
      dispatch(savePartnerFail((response as AxiosResponse).data));
    }
  };
};

const updatePartnerStart = (): IPartnerAction => {
  return {
    type: ActionTypes.UPDATE_PARTNER_START,
  };
};

const updatePartnerSuccess = (partner: IOrganization): IPartnerAction => {
  return {
    type: ActionTypes.UPDATE_PARTNER_SUCCESS,
    partner,
  };
};

const updatePartnerFail = (error: string): IPartnerAction => {
  return {
    type: ActionTypes.UPDATE_PARTNER_FAIL,
    error,
  };
};

export const updatePartner = (partner: IOrganization) => {
  return async (dispatch: Dispatch) => {
    dispatch(updatePartnerStart());
    try {
      const res = await axios.put(JSON_ORGANIZATIONS_UPDATE, partner);
      dispatch(updatePartnerSuccess(res.data));
    } catch ({ response }) {
      dispatch(updatePartnerFail((response as AxiosResponse).data));
    }
  };
};

const deletePartnerStart = (): IPartnerAction => {
  return {
    type: ActionTypes.DELETE_PARTNER_START,
  };
};

const deletePartnerSuccess = (id: string): IPartnerAction => {
  return {
    type: ActionTypes.DELETE_PARTNER_SUCCESS,
    id,
  };
};

const deletePartnerFail = (error: string): IPartnerAction => {
  return {
    type: ActionTypes.DELETE_PARTNER_FAIL,
    error,
  };
};

export const deletePartner = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deletePartnerStart());
    try {
      const res = await axios.delete(JSON_ORGANIZATIONS_DELETE(id));
      dispatch(deletePartnerSuccess(res.data));
    } catch ({ response }) {
      dispatch(deletePartnerFail((response as AxiosResponse).data));
      return null;
    }
  };
};
