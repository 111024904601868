import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { JSON_BILTEMA_EXTENDED_INFO } from "../../endpoints/index";
import { IBiltemaAction, IBiltemaResponse } from "../../interfaces";
import { ActionTypes } from "./actionTypes";

const getBiltemaExtendedInfoStart = (): IBiltemaAction => {
  return {
    type: ActionTypes.BILTEMA_GET_EXTENDED_INFO_START,
  };
};

const getBiltemaExtendedInfoSuccess = (
  biltemaResponse: IBiltemaResponse
): IBiltemaAction => {
  return {
    type: ActionTypes.BILTEMA_GET_EXTENDED_INFO_SUCCESS,
    biltemaResponse,
  };
};

const getBiltemaExtendedInfoFail = (error: string): IBiltemaAction => {
  return {
    type: ActionTypes.BILTEMA_GET_EXTENDED_INFO_FAIL,
    error,
  };
};

export const getBiltemaExtendedInfo = (registrationNumber: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBiltemaExtendedInfoStart());
    try {
      const res = await axios.get(
        `${JSON_BILTEMA_EXTENDED_INFO}?registrationNumber=${
          registrationNumber ?? ""
        }`
      );
      dispatch(getBiltemaExtendedInfoSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getBiltemaExtendedInfoFail((response as AxiosResponse).data));
      return null;
    }
  };
};

export const clearBiltemaState = () => {
  return {
    type: ActionTypes.BILTEMA_CLEAR,
  };
};
