import {
  JSON_FINANCE_COMPANIES_GET,
  JSON_FINANCE_COMPANIES_LIST,
  JSON_FINANCE_COMPANIES_ADD,
  JSON_FINANCE_COMPANIES_UPDATE,
} from "../../endpoints/index";
import { IFinanceCompany } from "../../interfaces/domain";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IFinanceCompanyAction } from "../../interfaces";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";

const getFinanceCompanyStart = (): IFinanceCompanyAction => {
  return {
    type: ActionTypes.GET_FINANCE_COMPANY_START,
  };
};

const getFinanceCompanySuccess = (financeCompany: IFinanceCompany): IFinanceCompanyAction => {
  return {
    type: ActionTypes.GET_FINANCE_COMPANY_SUCCESS,
    financeCompany,
  };
};

const getFinanceCompanyFail = (error: string): IFinanceCompanyAction => {
  return {
    type: ActionTypes.GET_FINANCE_COMPANY_START,
    error,
  };
};

export const getFinanceCompany = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getFinanceCompanyStart());
    try {
      const res = await axios.get(JSON_FINANCE_COMPANIES_GET(id));
      dispatch(getFinanceCompanySuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getFinanceCompanyFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listFinanceCompaniesStart = (): IFinanceCompanyAction => {
  return {
    type: ActionTypes.LIST_FINANCE_COMPANIES_START,
  };
};

const listFinanceCompaniesSuccess = (financeCompanies: Array<IFinanceCompany>): IFinanceCompanyAction => {
  return {
    type: ActionTypes.LIST_FINANCE_COMPANIES_SUCCESS,
    financeCompanies,
  };
};

const listFinanceCompaniesFail = (error: string): IFinanceCompanyAction => {
  return {
    type: ActionTypes.LIST_FINANCE_COMPANIES_FAIL,
    error,
  };
};

export const listFinanceCompanies = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listFinanceCompaniesStart());
    try {
      const res = await axios.get(JSON_FINANCE_COMPANIES_LIST);
      dispatch(listFinanceCompaniesSuccess(res.data));
    } catch ({ response }) {
      dispatch(listFinanceCompaniesFail((response as AxiosResponse).data));
    }
  };
};

const saveFinanceCompanyStart = (): IFinanceCompanyAction => {
  return {
    type: ActionTypes.SAVE_FINANCE_COMPANY_START,
  };
};

const saveFinanceCompanySuccess = (financeCompany: IFinanceCompany): IFinanceCompanyAction => {
  return {
    type: ActionTypes.SAVE_FINANCE_COMPANY_SUCCESS,
    financeCompany,
  };
};

const saveFinanceCompanyFail = (error: string): IFinanceCompanyAction => {
  return {
    type: ActionTypes.SAVE_FINANCE_COMPANY_FAIL,
    error,
  };
};

export const saveFinanceCompany = (financeCompany: IFinanceCompany) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveFinanceCompanyStart());
    try {
      const res = await axios.post(JSON_FINANCE_COMPANIES_ADD, financeCompany);
      dispatch(saveFinanceCompanySuccess(res.data));
    } catch ({ response }) {
      dispatch(saveFinanceCompanyFail((response as AxiosResponse).data));
    }
  };
};

const updateFinanceCompanyStart = (): IFinanceCompanyAction => {
  return {
    type: ActionTypes.UPDATE_FINANCE_COMPANY_START,
  };
};

const updateFinanceCompanySuccess = (financeCompany: IFinanceCompany): IFinanceCompanyAction => {
  return {
    type: ActionTypes.UPDATE_FINANCE_COMPANY_SUCCESS,
    financeCompany,
  };
};

const updateFinanceCompanyFail = (error: string): IFinanceCompanyAction => {
  return {
    type: ActionTypes.UPDATE_FINANCE_COMPANY_FAIL,
    error,
  };
};

export const updateFinanceCompany = (financeCompany: IFinanceCompany) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateFinanceCompanyStart());
    try {
      const res = await axios.put(JSON_FINANCE_COMPANIES_UPDATE, financeCompany);
      dispatch(updateFinanceCompanySuccess(res.data));
    } catch ({ response }) {
      dispatch(updateFinanceCompanyFail((response as AxiosResponse).data));
    }
  };
};
