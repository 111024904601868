import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as qs from "query-string";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import DealSignatureDialog, { EIdentificationType } from "../../../../components/Deals/Deal/DealView/DealSignatureDialog/DealSignatureDialog";
import DesktopLogo from "../../../../components/Logo/DesktopLogo/DesktopLogo";
import Alert from "../../../../components/UI/Alert/Alert";
import Button, { EButtonColor } from "../../../../components/UI/Button/Button";
import { TInputValue } from "../../../../components/UI/Input/Input";
import InputGroup from "../../../../components/UI/InputGroup/InputGroup";
import ModalContext, { EModalSize } from "../../../../components/UI/Modal/ModalContext";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { EDealStatus, EDealType } from "../../../../enums";
import { IAppState } from "../../../../interfaces";
import { getResidualValueLiability } from "../../../../shared/deal-utils";
import { dateToString } from "../../../../shared/utility";
import * as actions from "../../../../store/actions";
import { ETranslation } from "../../../../translations/translation-keys";
import classes from "./DealTerminationCalculation.module.scss";
import DealIdentifications from "../../../../components/Deals/Deal/DealIdentifications/DealIdentifications";

interface IMatch {
  id: string;
}

interface IDealTerminationCalculationQueryParams {
  token: string;
  printable: boolean;
}

const DealTerminationCalculation: React.FC<RouteComponentProps<IMatch>> = ({
  match,
  location,
}) => {
  const queryParams = ((): IDealTerminationCalculationQueryParams => {
    const params = qs.parse(location.search);
    return {
      token: params.token as string,
      printable: params.printable === "true",
    };
  })();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setModal } = useContext(ModalContext);

  const { loading, error, deal, netsUrl, netsLoading } = useSelector((state: IAppState) => ({
    loading: state.deals.loading,
    error: state.deals.error,
    deal: state.deals.deal,
    netsUrl: state.deals.netsUrl,
    netsLoading: state.deals.netsLoading,
    confirmLoading: state.deals.confirmLoading,
  }));

  const { id } = match.params;

  useEffect(() => {
    if (!id) return;
    dispatch(actions.getTerminateDeal(id, queryParams.token));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (netsUrl) window.open(netsUrl, "_self");
  }, [netsUrl]);

  const signatureHandler = useCallback(
    () => {
      dispatch(
        actions.dealIdentify(match.params.id, queryParams.token, EIdentificationType.TERMINATE)
      );
    },
    [dispatch, match.params.id, queryParams.token]
  );

  const showSignatureDialog = useCallback(() => {
    if (!deal) return;
    setModal({
      isOpen: true,
      title: t(ETranslation.DEAL_ACTION_EIDENT_START),
      size: EModalSize.MEDIUM,
      content: <DealSignatureDialog deal={deal} onSubmit={signatureHandler} isTerminate />,
    });
  }, [deal, setModal, signatureHandler, t]);

  const mapLabelAndValue = useCallback((label: ETranslation, value?: TInputValue, italic?: boolean) => {
    const labelTranslation = t(label);
    return (
      <div className={classes.ValueContainer}>
        <div className={classes.Label}>
          <p>{italic ? <i>{labelTranslation}</i> : labelTranslation}:</p>
        </div>
        <div className={classes.Value}>
          <p>{value}</p>
        </div>
      </div>
    )
  }, [t]);

  if (!id) return null;

  const isCar = deal?.type === EDealType.CAR;

  const mapSeller = () => {
    return (
      <div>
        <p className={classes.SectionTitle}>
          {t(ETranslation.DEAL_TERMINATION_CALCULATION_SELLER_TITLE)}
        </p>
        <div className={classes.Section}>
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_ORGANIZATION, "One Fleet Oy")}
          {mapLabelAndValue(ETranslation.CUSTOMER_BUSINESS_ID, "3298584-4")}
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_ADDRESS, "Tietotie 9, 01530 Vantaa")}
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_PHONE, "010 212 7700")}
        </div>
      </div>
    );
  };

  const mapBuyer = () => {
    return (
      <div>
        <p className={classes.SectionTitle}>
          {t(ETranslation.DEAL_TERMINATION_CALCULATION_CUSTOMER_TITLE)}
        </p>
        <div className={classes.Section}>
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_ORGANIZATION, deal?.customer?.name)}
          {mapLabelAndValue(ETranslation.CUSTOMER_BUSINESS_ID, deal?.customer?.businessId)}
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_ADDRESS, deal?.customer?.streetAddress)}
          {mapLabelAndValue(ETranslation.COMMON_ZIP, deal?.customer?.zip)}
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_CITY, deal?.customer?.city)}
        </div>
      </div>
    );
  };

  const mapTarget = () => {
    return (
      <div>
        <p className={classes.SectionTitle}>
          {t(ETranslation.DEAL_TERMINATION_CALCULATION_TARGET_TITLE)}
        </p>
        <div className={classes.Section}>
          {mapLabelAndValue(ETranslation.DEAL_BRAND, deal?.brand)}
          {mapLabelAndValue(ETranslation.DEAL_MODEL, deal?.model)}
          {isCar 
            ? mapLabelAndValue(ETranslation.DEAL_REGISTRATION_NUMBER, deal?.registrationNumber)
            : mapLabelAndValue(ETranslation.DEAL_TERMINATE_MACHINE_ID, deal?.terminationCalculation?.machineId)
          }
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_START_DATE, deal?.startDate)}
          {mapLabelAndValue(ETranslation.DEAL_END_DATE, deal?.endDate)}
        </div>

        {isCar && (
          <div className={classes.Subsection}>
            {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_CONTRACT_KILOMETERS, `${deal?.selectedVariation?.contractKilometers ?? 0} km`)}
            {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_USED_KILOMETERS, `${deal?.terminationCalculation?.usedKilometers ?? 0} km`)}
          </div>
        )}
        
        <div className={classes.Subsection}>
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_RESIDUAL_VALUE, t(getResidualValueLiability(deal?.selectedVariation?.residualValueLiability)).toString())}
        </div>

        {isCar && (
          <div className={classes.Subsection}>
            {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET, `${deal?.terminationCalculation?.maintenanceBudget ?? 0} €`)}
            {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET_USED, `${deal?.maintenanceBudgetUsed ?? 0} €`)}
            {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET_UNUSED, `${deal?.maintenanceBudgetUnused ?? 0} €`)}
          </div>
        )}

        <div className={classes.Subsection}>
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_FINAL_DEBT, `${deal?.terminationCalculation?.finalDebt ?? 0} €`)}
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_HANDLING_FEE, `${deal?.terminationCalculation?.handlingFee ?? 0} €`)}
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_TOTAL, `${deal?.terminationCalculation?.total ?? 0} €`)}
        </div>

        <div className={classes.Subsection}>
          {mapLabelAndValue(isCar ? ETranslation.DEAL_TERMINATION_CALCULATION_PURCHASE_PRICE : ETranslation.DEAL_TERMINATE_FUNDABLE_CAPITAL, `${deal?.terminationCalculation?.purchasePrice ?? 0} €`)}
        </div>

        <div className={classes.Subsection}>
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT, `${deal?.terminationCalculation?.terminationFeeVat ?? 0} €`)}
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_TERMINATION_FEE, `${deal?.terminationCalculation?.terminationFee ?? 0} €`)}
          {mapLabelAndValue(ETranslation.DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT_AMOUNT, `${deal?.terminationCalculation?.terminationFeeVatAmount ?? 0} €`, true)}
        </div>

      </div>
    );
  };

  const mapFooter = () => {
    return (
      <div style={{ marginTop: "1rem", padding: "1rem" }}>
        {deal?.latestTerminationIdentification ? (
          <div className={classes.NoMargin}>
            <p>{t(ETranslation.DEAL_PREVIEW_OFFER_ACCEPTED)}</p>
            <p>{t(ETranslation.DEAL_PREVIEW_OFFER_SIGNED_AT)} {deal?.latestTerminationIdentification?.date}</p>
            <p>{t(ETranslation.DEAL_PREVIEW_OFFER_SIGNATURE)}: {deal?.latestTerminationIdentification?.name}</p>
            <p>{t(ETranslation.DEAL_PREVIEW_OFFER_IDENTIFIED_BANK)}: {deal?.latestTerminationIdentification?.bankName}</p>
          </div>
        ) : (
          <>
            <div className={classes.NoMargin}>
              <p>{t(ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_1)}</p>
              <p>{t(ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_DATE)}: {dateToString(new Date())}</p>
              <p>{t(ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_CUSTOMER_SIGNATURE)}: ________________________________________________</p>
            </div>

            <div className={classes.NoMargin} style={{ marginTop: "2rem" }}>
              <p>{t(ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_CUSTOMER_SIGNATURE_CLARIFICATION)}: ________________________________________________</p>
            </div>

            <div className={classes.NoMargin} style={{ marginTop: "2rem" }}>
              <p>{t(ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_DATE)}: {dateToString(new Date())}</p>
              <p>{t(ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_SELLER_SIGNATURE)}: ________________________________________________</p>
            </div>
          </>
        )}
        <div className={classes.NoMargin} style={{ marginTop: "2rem" }}>
          <p><i>{t(ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_2)}</i></p>
          <p><i>{t(ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_3)}</i></p>
        </div>
      </div>
    )
  };

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <div className={classes.Container}>
        {loading ? (
          <Spinner />
        ) : deal &&
          (deal.status === EDealStatus.CONTRACT ||
            deal.status === EDealStatus.TERMINATED) ? (
          <>
            <DesktopLogo>
              <p>
                {t(ETranslation.DEAL_ORDER_CONFIRMATION_PRINTED)}:{" "}
                {dateToString(new Date())}
              </p>
            </DesktopLogo>

            <div className={classes.Content}>
              <h1 className={classes.Title}>
                {t(ETranslation.DEAL_TERMINATION_CALCULATION_TITLE)}
              </h1>

              {mapSeller()}
              {mapBuyer()}
              {mapTarget()}
              {mapFooter()}

              {/* TODO: ENABLE */}
              <InputGroup className={classes.PrintHidden}>
                <Button color={EButtonColor.SECONDARY} onClick={showSignatureDialog} loading={netsLoading}>
                  <FontAwesomeIcon icon={faFileSignature} />
                  {' '}
                  {t(ETranslation.DEAL_ACTION_EIDENT_START)}
                </Button>
              </InputGroup>
            </div>

            {deal?.terminationIdentifications && (
              <DealIdentifications identifications={deal.terminationIdentifications}/>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default DealTerminationCalculation;
