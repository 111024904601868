import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EDealEdit } from "../../../../../../containers/Deals/Deal/Deal";
import DealContext from "../../../../../../context/DealContext";
import DealVariationConditionContext from "../../../../../../context/DealVariationConditionContext";
import DealVariationContext from "../../../../../../context/DealVariationContext";
import { EInputUpdateAction } from "../../../../../../context/InputContext";
import { EDealStatus, ELeasingModel } from "../../../../../../enums";
import { useAuthUser } from "../../../../../../hooks/useAuthUser";
import { IAppState, IDealPricingVariation } from "../../../../../../interfaces";
import {
  INVALID_DEFAULT,
  VALID_DEFAULT,
  calculateCarPriceForCalculator,
  calculateMaintenanceBudgetTotal,
  calculateTotalPriceIncludingPollution,
  fv,
  getInputData,
  pmt,
  toTwoDecimals,
  updateInputHandler,
} from "../../../../../../shared/utility";
import * as actions from "../../../../../../store/actions";
import { ETranslation } from "../../../../../../translations/translation-keys";
import Button from "../../../../../UI/Button/Button";
import Fieldset from "../../../../../UI/Fieldset/Fieldset";
import Input, { IInputOptions } from "../../../../../UI/Input/Input";
import InputGroup from "../../../../../UI/InputGroup/InputGroup";
import { EDealVariation, EPaymentType } from "../DealVariationEdit";

const DealVariationEditCar: React.FC = () => {
  const { t } = useTranslation();
  const {
    inputs,
    updateVariation,
    removeVariation,
    copyVariation,
    isValid,
    deal,
    isDisabled,
    // updateExtraFee,
    // addExtraFee,
    // removeExtraFee,
    // copyExtraFee,
    additionalExpenses,
    netwheelsHandler,
  } = useContext(DealContext);
  const {
    variation,
    variationInputs,
    setVariationInputs,
    extraFees,
    // setExtraFees,
    index,
  } = useContext(DealVariationContext);
  const {
    variationContractLength,
    variationResidualValue,
    variationContractKilometers,
    variationExtraTireSets,
    setVariationContractLength,
    setVariationResidualValue,
    setVariationContractKilometers,
    setVariationExtraTireSets,
  } = useContext(DealVariationConditionContext);

  const {
    loading: netwheelsLoading,
    residualValue: netwheelsResidualValue,
    variationId: netWheelsVariationId,
  } = useSelector((state: IAppState) => state.netwheels);

  const isNetwheelsVariation = useMemo(
    () => netWheelsVariationId === variation?.id,
    [netWheelsVariationId, variation]
  );

  const dispatch = useDispatch();
  const authUser = useAuthUser();

  const [editableFields, setEditableFields] = useState<EDealVariation[]>([]);
  useEffect(() => {
    if (authUser?.isAdmin) {
      setEditableFields([
        EDealVariation.MAINTENANCE_BUDGET,
        EDealVariation.EXTRA_TIRE_SETS,
        EDealVariation.EXTRA_TIRE_SET_PRICE,
        EDealVariation.TIRE_STORAGE_MONTHLY,
        EDealVariation.WINTER_TIRES_PURCHASE_PRICE,
        EDealVariation.REPLACEMENT_CAR_PRICE,
        EDealVariation.GROSS_PROFIT_VAT,
        EDealVariation.RESIDUAL_VALUE_LIABILITY,
        EDealVariation.RESIDUAL_VALUE_VAT,
        EDealVariation.CONTRACT_LENGTH,
        EDealVariation.CONTRACT_KILOMETERS,
        EDealVariation.EXTRA_KILOMETER_CHARGE,
        EDealVariation.INTEREST_RATE,
        EDealVariation.FIRST_LARGER_RENT,
        EDealVariation.kickbackVAT,
        EDealVariation.PAYMENT_TYPE,
      ]);
    }
  }, [authUser]);

  const leasingModel = inputs[EDealEdit.LEASING_MODEL].value as ELeasingModel;
  const carTax = inputs[EDealEdit.CAR_TAX].value as number;
  const totalPriceVAT = inputs[EDealEdit.TOTAL_PRICE_VAT].value as number;
  // netwheels
  const modelYear = inputs[EDealEdit.modelYear].value as string;
  const brand = inputs[EDealEdit.BRAND].value as string;
  const model = inputs[EDealEdit.MODEL].value as string;
  const registrationDate = inputs[EDealEdit.REGISTRATION_DATE].value as string;
  const registrationNumber = inputs[EDealEdit.REGISTRATION_NUMBER].value as string;
  const serialNumber = inputs[EDealEdit.SERIAL_NUMBER].value as string;
  // netwheels
  const vatPercent = inputs[EDealEdit.vatPercent].value as number;

  const grossProfitVAT = variationInputs[EDealVariation.GROSS_PROFIT_VAT].value as number;
  const interestRate = variationInputs[EDealVariation.INTEREST_RATE].value as number;
  const firstLargerRent = variationInputs[EDealVariation.FIRST_LARGER_RENT].value as number;
  const totalPriceVATIncludingPollution = variationInputs[EDealVariation.TOTAL_PRICE_VAT_INCLUDING_POLLUTION].value as number;
  const contractLength = variationInputs[EDealVariation.CONTRACT_LENGTH].value as number;
  const futureValue = variationInputs[EDealVariation.FUTURE_VALUE].value as number;
  const residualValueVAT = variationInputs[EDealVariation.RESIDUAL_VALUE_VAT].value as number;
  const extraTireSets = variationInputs[EDealVariation.EXTRA_TIRE_SETS].value as number;
  const extraTireSetPrice = variationInputs[EDealVariation.EXTRA_TIRE_SET_PRICE].value as number;
  const maintenanceBudgetVAT = variationInputs[EDealVariation.MAINTENANCE_BUDGET_VAT].value as number;
  const tireStorageMonthly = variationInputs[EDealVariation.TIRE_STORAGE_MONTHLY].value as number;
  const replacementCarPrice = variationInputs[EDealVariation.REPLACEMENT_CAR_PRICE].value as number;
  const maintenanceBudgetTotal = variationInputs[EDealVariation.MAINTENANCE_BUDGET_TOTAL].value as number;
  const winterTiresPurchasePrice = variationInputs[EDealVariation.WINTER_TIRES_PURCHASE_PRICE].value as number;
  const contractKilometers = variationInputs[EDealVariation.CONTRACT_KILOMETERS].value as number;
  const isUsedCar = variationInputs[EDealVariation.isUsedCar].value as boolean;
  const kickbackVAT = variationInputs[EDealVariation.kickbackVAT].value as number;

  const paymentType = variationInputs[EDealVariation.PAYMENT_TYPE].value as EPaymentType;
  const downPayment = variationInputs[EDealVariation.DOWN_PAYMENT].value as number;

  useEffect(() => {
    if (leasingModel === ELeasingModel.RAHOITUS) {
      setVariationInputs((stateInputs) => {
        const newInputs = { ...stateInputs };
        newInputs[EDealVariation.MAINTENANCE_BUDGET].value = 0.0;
        newInputs[EDealVariation.MAINTENANCE_BUDGET_VAT].value = 0.0;
        newInputs[EDealVariation.MAINTENANCE_BUDGET_MONTHLY].value = 0.0;
        newInputs[EDealVariation.MAINTENANCE_BUDGET_TOTAL].value = 0.0;
        newInputs[EDealVariation.EXTRA_TIRE_SETS].value = 0.0;
        newInputs[EDealVariation.EXTRA_TIRE_SET_PRICE].value = 0.0;
        newInputs[EDealVariation.TIRE_STORAGE_MONTHLY].value = 0.0;
        newInputs[EDealVariation.WINTER_TIRES_PURCHASE_PRICE].value = 0.0;
        newInputs[EDealVariation.REPLACEMENT_CAR_PRICE].value = 0.0;
        return newInputs;
      });
    }
    // eslint-disable-next-line
  }, [leasingModel]);

  useEffect(() => {
    if (deal?.importName) return;

    let totalPriceVATWithAdditionalExpenses = Number(totalPriceVAT ?? 0);
    if (additionalExpenses && additionalExpenses.length > 0) {
      for (let i = 0; i < additionalExpenses.length; i++) {
        const additionalExpense = additionalExpenses[i];
        if (additionalExpense.value) {
          totalPriceVATWithAdditionalExpenses += Number(
            additionalExpense.value
          );
        }
      }
    }

    const shouldCalculateFutureValue =
      grossProfitVAT &&
      (interestRate || firstLargerRent || totalPriceVATIncludingPollution);
    const shouldCalculateMaintenanceBudgetTotal =
      grossProfitVAT &&
      contractLength &&
      (extraTireSets ||
        extraTireSetPrice ||
        maintenanceBudgetVAT ||
        tireStorageMonthly ||
        replacementCarPrice);
    const shouldCalculateGrossProfit = !!grossProfitVAT;
    const shouldCalculateResidualValuePercentage =
      totalPriceVATWithAdditionalExpenses && residualValueVAT;
    const shouldCalculateKickback = !!kickbackVAT;

    // setValid(shouldCalculateGrossProfit);

    // only update when changed
    if (contractLength !== variationContractLength) {
      setVariationContractLength(contractLength || "");
    }
    if (contractKilometers !== variationContractKilometers) {
      setVariationContractKilometers(contractKilometers || "");
    }
    if (residualValueVAT !== variationResidualValue) {
      setVariationResidualValue(residualValueVAT || "");
    }
    if (extraTireSets !== variationExtraTireSets) {
      setVariationExtraTireSets(extraTireSets || "");
    }

    setVariationInputs((stateInputs) => {
      const newInputs = { ...stateInputs };

      // huoltobudjetti (total): lisärengassarjat * sarjahinta (pitää lisätä kenttä) + huoltobudjetti + rengashotelli + sijaisauto
      let maintenanceBudgetTotal: string | number = "";
      let maintenanceBudgetMonthly: string | number = "";
      if (shouldCalculateMaintenanceBudgetTotal) {
        let tireStoragePrice = 0.0;
        if (tireStorageMonthly) {
          tireStoragePrice = tireStorageMonthly * contractLength;
        }
        const budgetTotal = calculateMaintenanceBudgetTotal(
          extraTireSets,
          extraTireSetPrice,
          maintenanceBudgetVAT,
          tireStoragePrice,
          replacementCarPrice
        );
        // console.log(maintenanceBudgetTotal);
        maintenanceBudgetTotal = toTwoDecimals(budgetTotal);
        if (contractLength > 1) {
          maintenanceBudgetMonthly = toTwoDecimals(
            budgetTotal / contractLength
          );
        }
      }
      newInputs[EDealVariation.MAINTENANCE_BUDGET_TOTAL].value =
        maintenanceBudgetTotal;
      newInputs[EDealVariation.MAINTENANCE_BUDGET_MONTHLY].value =
        maintenanceBudgetMonthly;

      const shouldCalculateTotalPriceVATIncludingPollution =
        grossProfitVAT &&
        (carTax ||
          totalPriceVATWithAdditionalExpenses ||
          maintenanceBudgetTotal ||
          winterTiresPurchasePrice);

      let totalPriceVATIncludingPollution: string | number = "";
      if (shouldCalculateTotalPriceVATIncludingPollution) {
        // kokonaishinta saastutuksella = autovero + kokonaishinta + huoltobudjetti + kate + talvirenkaat
        totalPriceVATIncludingPollution = toTwoDecimals(
          calculateTotalPriceIncludingPollution(
            // pelkkä veron osuus
            carTax * (vatPercent - 1),
            totalPriceVATWithAdditionalExpenses,
            +maintenanceBudgetTotal,
            grossProfitVAT,
            winterTiresPurchasePrice
          )
        );
      }
      newInputs[EDealVariation.TOTAL_PRICE_VAT_INCLUDING_POLLUTION].value =
        totalPriceVATIncludingPollution;

      const shouldCalculateMonthlyPayment =
        grossProfitVAT &&
        contractLength &&
        contractLength > 1 &&
        ((paymentType === EPaymentType.INSTALLMENT ||
          paymentType === EPaymentType.CASH_OLF ||
          paymentType === EPaymentType.CASH_SELLER
          )
          ? downPayment : true) &&
        (interestRate || totalPriceVATIncludingPollution || residualValueVAT);

      // kk hinta = korko / 100 / 12, sopimuspituus, kokonaishinta saastutuksella, jäännösarvo, 1
      let monthlyPaymentVAT: string | number = "";
      let monthlyPayment: string | number = "";

      // * TULEVA.ARVO(korko; jaksojen_määrä; maksun_määrä; [nykyinen_arvo]; [loppu_tai_alku])
      // /12 , 1 first larger rent, 1
      let futureValue: string | number = "";

      switch (paymentType) {
        case EPaymentType.LEASING:
          console.log("EPaymentType.LEASING")
          newInputs[EDealVariation.DOWN_PAYMENT].value = "";
          if (shouldCalculateFutureValue) {
            futureValue = toTwoDecimals(
              fv(
                +interestRate / 100 / 12,
                1,
                +firstLargerRent,
                -totalPriceVATIncludingPollution,
                1
              )
            );
          }
          if (shouldCalculateMonthlyPayment) {
            const payment = pmt(
              +interestRate / 100 / 12,
              +contractLength,
              -totalPriceVATIncludingPollution,
              +residualValueVAT,
              1
            );
            monthlyPaymentVAT = toTwoDecimals(payment);
            monthlyPayment = toTwoDecimals(payment / vatPercent);
          }
          break;
        case EPaymentType.INSTALLMENT:
        case EPaymentType.CASH_OLF:
        case EPaymentType.CASH_SELLER:
          newInputs[EDealVariation.FIRST_LARGER_RENT].value = "";
          newInputs[EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT].value = "";
          newInputs[EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT].value = "";
          if (shouldCalculateFutureValue) {
            futureValue = toTwoDecimals(
              fv(
                +interestRate / 100 / 12,
                1,
                +downPayment,
                -totalPriceVATIncludingPollution,
                1
              )
            );
          }
          if (shouldCalculateMonthlyPayment) {
            const payment = pmt(
              +interestRate / 100 / 12,
              +contractLength,
              -(+totalPriceVATIncludingPollution - downPayment),
              +residualValueVAT,
              1
            );
            monthlyPaymentVAT = toTwoDecimals(payment);
            monthlyPayment = toTwoDecimals(payment / vatPercent);
          }
          break;
        default:
          break;
      }

      newInputs[EDealVariation.MONTHLY_PAYMENT_VAT].value = monthlyPaymentVAT;
      newInputs[EDealVariation.MONTHLY_PAYMENT].value = monthlyPayment;

      newInputs[EDealVariation.FUTURE_VALUE].value = futureValue;

      // * MAKSU(korko; jaksojen_määrä; nykyinen_arvo; [tuleva_arvo], [loppu_tai_alku])
      const shouldCalculateMonthlyInstallmentFirstInstallment =
        paymentType === EPaymentType.LEASING &&
        grossProfitVAT &&
        contractLength &&
        contractLength > 1 &&
        (interestRate || futureValue || residualValueVAT);

      let monthlyInstallmentFirstInstallmentVat: string | number = "";
      let monthlyInstallmentFirstInstallment: string | number = "";
      if (shouldCalculateMonthlyInstallmentFirstInstallment) {
        const payment = pmt(
          +interestRate / 100 / 12,
          +contractLength - 1,
          -futureValue,
          +residualValueVAT,
          1
        );
        // console.log(monthlyInstallment);
        monthlyInstallmentFirstInstallmentVat = toTwoDecimals(payment);
        monthlyInstallmentFirstInstallment = toTwoDecimals(payment / vatPercent);
      }
      newInputs[
        EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT
      ].value = monthlyInstallmentFirstInstallmentVat;
      newInputs[EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT].value =
        monthlyInstallmentFirstInstallment;

      const calculatedGrossProfit = shouldCalculateGrossProfit
      ? toTwoDecimals(grossProfitVAT / vatPercent)
      : "";
      newInputs[EDealVariation.GROSS_PROFIT].value = calculatedGrossProfit;
      newInputs[EDealVariation.GROSS_PROFIT].validationResult = !!calculatedGrossProfit ? VALID_DEFAULT : INVALID_DEFAULT;

      newInputs[EDealVariation.RESIDUAL_VALUE_PERCENTAGE].value =
        shouldCalculateResidualValuePercentage
          ? toTwoDecimals(
              (residualValueVAT / totalPriceVATWithAdditionalExpenses) * 100
            )
          : "";

      const shouldCalculateCarPriceForCalculator =
        grossProfitVAT &&
        (totalPriceVATWithAdditionalExpenses ||
          maintenanceBudgetTotal ||
          winterTiresPurchasePrice);
      newInputs[EDealVariation.CAR_PRICE_FOR_CALCULATOR].value =
        shouldCalculateCarPriceForCalculator
          ? toTwoDecimals(
              calculateCarPriceForCalculator(
                +totalPriceVATWithAdditionalExpenses,
                +maintenanceBudgetTotal,
                +grossProfitVAT,
                +winterTiresPurchasePrice
              )
            )
          : "";

          newInputs[EDealVariation.kickback].value =
            shouldCalculateKickback
              ? toTwoDecimals(kickbackVAT / vatPercent)
              : "";

      return newInputs;
    });
    // eslint-disable-next-line
  }, [
    grossProfitVAT,
    interestRate,
    firstLargerRent,
    totalPriceVATIncludingPollution,
    contractLength,
    futureValue,
    residualValueVAT,
    extraTireSets,
    extraTireSetPrice,
    maintenanceBudgetVAT,
    tireStorageMonthly,
    replacementCarPrice,
    contractLength,
    carTax,
    totalPriceVAT,
    maintenanceBudgetTotal,
    winterTiresPurchasePrice,
    contractKilometers,
    additionalExpenses,
    kickbackVAT,
    paymentType,
    downPayment,
    vatPercent,
  ]);

  useEffect(() => {
    if (isNetwheelsVariation && netwheelsResidualValue) {
      setVariationInputs((stateInputs) => {
        const newInputs = { ...stateInputs };
        newInputs[EDealVariation.RESIDUAL_VALUE_VAT].value =
          netwheelsResidualValue.residualValueVAT;
        return newInputs;
      });
      dispatch(actions.clearNetwheelsState());
    }
  }, [
    dispatch,
    netwheelsResidualValue,
    setVariationInputs,
    isNetwheelsVariation
  ]);

  useEffect(() => {
    if (variation && variation.id) updateVariation(getData());
    // eslint-disable-next-line
  }, [variationInputs, extraFees]);

  const getData = () => {
    const pricingVariation: IDealPricingVariation =
      getInputData(variationInputs);
    pricingVariation.id = variation!.id;
    pricingVariation.extraFees = extraFees;
    return pricingVariation;
  };

  const createInput = (inputName: EDealVariation, options?: IInputOptions) => {
    const item = variationInputs[inputName];
    return (
      <Input
        disabled={!editableFields.includes(inputName) && isDisabled}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.DEAL_VARIATION}
        onChange={(value) =>
          updateInputHandler(inputName, value, setVariationInputs)
        }
        inputName={inputName}
        showValidation={!isValid}
      />
    );
  };

  return (
    <>
      <Fieldset
        label={
          !deal?.status || deal?.status === EDealStatus.OFFER
            ? `${t(ETranslation.DEAL_VARIATION)} #${index}`
            : t(ETranslation.DEAL_VARIATION)
        }
      >
        {leasingModel !== ELeasingModel.RAHOITUS ? (
          <>
            <InputGroup>
              {createInput(EDealVariation.MAINTENANCE_BUDGET_VAT, {
                labelTranslation: ETranslation.DEAL_CAR_MAINTENANCE_BUDGET,
                placeholderTranslation:
                  ETranslation.DEAL_CAR_MAINTENANCE_BUDGET,
                disabled: !authUser?.isAdmin && isDisabled,
              })}
              {createInput(EDealVariation.MAINTENANCE_BUDGET_TOTAL)}
              {createInput(EDealVariation.MAINTENANCE_BUDGET_MONTHLY)}
            </InputGroup>

            <InputGroup>
              {createInput(EDealVariation.EXTRA_TIRE_SETS)}
              {createInput(EDealVariation.EXTRA_TIRE_SET_PRICE)}
              {createInput(EDealVariation.TIRE_STORAGE_MONTHLY)}
            </InputGroup>

            <InputGroup>
              {createInput(EDealVariation.WINTER_TIRES_PURCHASE_PRICE)}
              {createInput(EDealVariation.REPLACEMENT_CAR_PRICE)}
            </InputGroup>
          </>
        ) : null}

        <InputGroup>
          {createInput(EDealVariation.GROSS_PROFIT_VAT)}
          {createInput(EDealVariation.GROSS_PROFIT, { disabled: true })}
          {createInput(EDealVariation.kickbackVAT)}
          {createInput(EDealVariation.kickback, { disabled: true })}
        </InputGroup>

        <InputGroup>
          {createInput(EDealVariation.CONTRACT_LENGTH)}
          {createInput(EDealVariation.CONTRACT_KILOMETERS)}
          {leasingModel === ELeasingModel.HUOLTO &&
            createInput(EDealVariation.EXTRA_KILOMETER_CHARGE)}
          {createInput(EDealVariation.INTEREST_RATE)}
        </InputGroup>

        <InputGroup>
          {createInput(EDealVariation.RESIDUAL_VALUE_LIABILITY, {
            disabled: !authUser?.isAdmin && isDisabled,
          })}
          {createInput(EDealVariation.isUsedCar, {
            containerStyles: {
              flexGrow: 0,
            },
          })}
          <InputGroup style={{ flexGrow: 0, margin: 0, alignItems: "center" }}>
            <Button
              style={{ height: "fit-content" }}
              onClick={() =>
                netwheelsHandler({
                  variationId: variation?.id ?? "",
                  modelYear: modelYear ?? "",
                  make: brand ?? "",
                  model: model ?? "",
                  firstRegistrationDate: registrationDate ?? "",
                  licenseNumber: registrationNumber ?? "",
                  vehicleIdentificationNumber: serialNumber ?? "",
                  listPriceIncludingVehicleTax: totalPriceVAT ? Number(totalPriceVAT).toFixed() : "",
                  // currentAskingPrice: totalPriceVAT ?? "",
                  residualValuePredictionPeriods: [
                    {
                      mileageDuringOwnership: contractKilometers ?? "",
                      ownershipMonths: contractLength ?? "",
                    },
                  ],
                  isUsedCar,
                })
              }
              icon={faArrowRight}
              loading={netwheelsLoading && isNetwheelsVariation}
            />
          </InputGroup>
          {createInput(EDealVariation.RESIDUAL_VALUE_VAT, {
            loading: netwheelsLoading && isNetwheelsVariation,
          })}
          {createInput(EDealVariation.RESIDUAL_VALUE_PERCENTAGE, {
            loading: netwheelsLoading && isNetwheelsVariation,
          })}
        </InputGroup>

        <InputGroup>
          {createInput(EDealVariation.MONTHLY_PAYMENT_VAT)}
          {createInput(EDealVariation.MONTHLY_PAYMENT)}
        </InputGroup>

        {/*
        <InputGroup>
          {createInput(EDealVariation.FIRST_LARGER_RENT, {
            labelTranslation: ETranslation.DEAL_FIRST_LARGER_RENT_VAT,
          })}
          {createInput(
            EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT
          )}
          {createInput(EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT)}
          {createInput(EDealVariation.FUTURE_VALUE, {
            labelTranslation: ETranslation.DEAL_FUTURE_VALUE_VAT,
            placeholderTranslation: ETranslation.DEAL_FUTURE_VALUE_VAT,
          })}
        </InputGroup>
        */}

        <InputGroup>
          {createInput(EDealVariation.PAYMENT_TYPE, {
            disabled: !authUser?.isAdmin && isDisabled,
          })}
          {(variationInputs[EDealVariation.PAYMENT_TYPE].value === EPaymentType.INSTALLMENT ||
            variationInputs[EDealVariation.PAYMENT_TYPE].value === EPaymentType.CASH_OLF ||
            variationInputs[EDealVariation.PAYMENT_TYPE].value === EPaymentType.CASH_SELLER) &&
              createInput(EDealVariation.DOWN_PAYMENT, {
                labelTranslation: ETranslation.DEAL_DOWN_PAYMENT_VAT,
                placeholderTranslation: ETranslation.DEAL_DOWN_PAYMENT_VAT,
              })}
          {variationInputs[EDealVariation.PAYMENT_TYPE].value ===
            EPaymentType.LEASING && (
            <>
              {createInput(EDealVariation.FIRST_LARGER_RENT, {
                labelTranslation: ETranslation.DEAL_FIRST_LARGER_RENT_VAT,
                placeholderTranslation: ETranslation.DEAL_FIRST_LARGER_RENT_VAT,
              })}
              {createInput(
                EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT
              )}
              {createInput(
                EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT
              )}
            </>
          )}
          {createInput(EDealVariation.FUTURE_VALUE)}
        </InputGroup>

        <InputGroup>
          {createInput(EDealVariation.CAR_PRICE_FOR_CALCULATOR)}
          {createInput(EDealVariation.TOTAL_PRICE_VAT_INCLUDING_POLLUTION)}
        </InputGroup>

        {/* {extraFees && extraFees.length > 0 && (
          <Fieldset label={t(ETranslation.DEAL_EXTRA_FEES)}>
            {extraFees.map((fee) => (
              <ExtraFee
                key={fee.id}
                fee={fee}
                onCopy={(fee) =>
                  copyExtraFee(fee, EDealVariation.EXTRA_FEES, setExtraFees)
                }
                onRemove={(id) => removeExtraFee(id, setExtraFees)}
                onUpdate={(fee) => updateExtraFee(fee, setExtraFees)}
                disabled={isDisabled}
              />
            ))}
          </Fieldset>
        )} */}

        {(!deal?.status || deal?.status === EDealStatus.OFFER) && (
          <InputGroup>
            {/* <Button
              onClick={() =>
                addExtraFee(EDealVariation.EXTRA_FEES, setExtraFees)
              }
            >
              {t(ETranslation.COMMON_ADD)} {t(ETranslation.DEAL_EXTRA_FEE)}
            </Button> */}
            <Button onClick={() => copyVariation(getData())}>
              {t(ETranslation.COMMON_COPY)}
            </Button>
            <Button
              onClick={() => {
                if (variation && variation.id) removeVariation(variation.id);
              }}
            >
              {t(ETranslation.COMMON_REMOVE)}
            </Button>
          </InputGroup>
        )}
      </Fieldset>
    </>
  );
};

export default DealVariationEditCar;
