import React from "react";
import { IDealExtraFee } from "../../../../interfaces";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../../translations/translation-keys";
import Table from "../../../UI/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

const DealAdditionalExpensesTable: React.FC<{ expenses: IDealExtraFee[] }> = ({
  expenses,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ wordBreak: "break-all" }}>
      <h3>{t(ETranslation.DEAL_ADDITIONAL_EXPENSES)}</h3>
      <Table>
        <thead>
          <tr>
            <th>{t(ETranslation.DEAL_EXTRA_FEE_NAME)}</th>
            <th>{t(ETranslation.DEAL_EXTRA_FEE_VALUE)}</th>
            <th>{t(ETranslation.DEAL_EXTRA_FEE_ATTACHMENTS)}</th>
          </tr>
        </thead>
        <tbody>
          {expenses.map(({ id, name, value, attachments }) => (
            <tr key={id}>
              <td>{name}</td>
              <td>{value}</td>
              <td>
                {attachments &&
                  attachments.length > 0 &&
                  attachments.map((attachment) => (
                    <p key={attachment.id}>
                      <a
                        href={attachment.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          size="lg"
                          color={"black"}
                        />{" "}
                        {attachment.clientName}
                      </a>
                    </p>
                  ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DealAdditionalExpensesTable;
