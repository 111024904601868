import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import { searchDeals } from "../../../services/search-service";
import { updateInputHandler } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import Input, { EInputType, IInputField, IInputOptions, IOption } from "../../UI/Input/Input";
import classes from "./DealSearch.module.scss";

enum EDealSearch {
  DEAL = "deal",
}

interface IProps extends RouteComponentProps {
  text: string;
  mobile?: boolean;
}

const DealSearch: React.FC<IProps> = ({ text, history, location, mobile, }) => {
  const { t } = useTranslation();

  const [searchOpen, setSearchOpen] = useState<boolean>(false);

  const initialInputs: IInputField = {
    [EDealSearch.DEAL]: {
      type: EInputType.searchSelect,
      placeholder: t(ETranslation.ATTACH_INVOICE_DEAL_SEARCH),
      value: "",
    }
  };

  const [inputs, setInputs] = useState<IInputField>({ ...initialInputs });

  const createInput = (inputName: EDealSearch, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    />
  };

  useEffect(() => {
    if (searchOpen) setSearchOpen(false);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    const option = inputs[EDealSearch.DEAL].value as IOption;
    if (option.value) {
      setInputs(_ => initialInputs);
      history.push(Routes.DEAL(option.value));
    }
    // eslint-disable-next-line
  }, [inputs[EDealSearch.DEAL].value]);

  const classNames = [classes.Container];

  if (searchOpen) {
    classNames.push(classes.Open);
  }

  if (mobile) {
    classNames.push(classes.Mobile);
  }

  return (
    <div className={classNames.join(" ")}>
      {!searchOpen && (
        <p className={classes.Icon} onClick={() => setSearchOpen(true)}>
          <FontAwesomeIcon icon={faSearch} />
          {" "}
          {text}
        </p>
      )}
      {searchOpen && createInput(EDealSearch.DEAL, { fetchOptions: searchDeals, containerStyles: { width: "100%", margin: 0 } })}
    </div>
  );
}

export default withRouter(DealSearch);
