import { IDealershipAction, IDealershipState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IDealershipState = {
  loading: false,
  dealerships: null,
  dealership: null,
  error: null,
};

const getDealershipStart = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    dealership: null,
    loading: true,
    error: null,
  });
};

const getDealershipSuccess = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    dealership: action.dealership,
    loading: false,
    error: null,
  });
};

const getDealershipFail = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listDealershipsStart = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const listDealershipsSuccess = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    dealerships: action.dealerships,
    loading: false,
    error: null,
  });
};

const listDealershipsFail = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveDealershipStart = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveDealershipSuccess = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    dealership: action.dealership,
    loading: false,
    error: null,
  });
};

const saveDealershipFail = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateDealershipStart = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateDealershipSuccess = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    dealership: action.dealership,
    loading: false,
    error: null,
  });
};

const updateDealershipFail = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateDealershipList = (
  state: IDealershipState,
  action: IDealershipAction
): IDealershipState => {
  if (!action.dealership) return state;
  return updateObject(state, {
    dealerships:
      state.dealerships && state.dealerships?.length > 0
        ? [...state.dealerships, action.dealership]
        : [action.dealership],
    error: action.error,
  });
};

const reducer = (
  state: IDealershipState = initialState,
  action: IDealershipAction
): IDealershipState => {
  switch (action.type) {
    case ActionTypes.GET_DEALERSHIP_START:
      return getDealershipStart(state, action);
    case ActionTypes.GET_DEALERSHIP_SUCCESS:
      return getDealershipSuccess(state, action);
    case ActionTypes.GET_DEALERSHIP_FAIL:
      return getDealershipFail(state, action);
    case ActionTypes.LIST_DEALERSHIPS_START:
      return listDealershipsStart(state, action);
    case ActionTypes.LIST_DEALERSHIPS_SUCCESS:
      return listDealershipsSuccess(state, action);
    case ActionTypes.LIST_DEALERSHIPS_FAIL:
      return listDealershipsFail(state, action);
    case ActionTypes.SAVE_DEALERSHIP_START:
      return saveDealershipStart(state, action);
    case ActionTypes.SAVE_DEALERSHIP_SUCCESS:
      return saveDealershipSuccess(state, action);
    case ActionTypes.SAVE_DEALERSHIP_FAIL:
      return saveDealershipFail(state, action);
    case ActionTypes.UPDATE_DEALERSHIP_START:
      return updateDealershipStart(state, action);
    case ActionTypes.UPDATE_DEALERSHIP_SUCCESS:
      return updateDealershipSuccess(state, action);
    case ActionTypes.UPDATE_DEALERSHIP_FAIL:
      return updateDealershipFail(state, action);
    case ActionTypes.UPDATE_DEALERSHIP_LIST:
      return updateDealershipList(state, action);
    default:
      return state;
  }
};

export default reducer;
