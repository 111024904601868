import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { TDispatch } from "../../..";
import Alert from "../../../components/UI/Alert/Alert";
import Button from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import Input, { EInputType, IInputField, IInputOptions } from "../../../components/UI/Input/Input";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../../context/InputContext";
import { IAppState, IOrganization } from "../../../interfaces";
import { Routes } from "../../../classes/Routes";
import { initForm, updateInputHandler, validateInputs } from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import Users from "../../Users/Users";
import { EOrganizationLevel, InputSuffix } from "../../../enums";
import ModalContext, { EModalSize, defaultModal } from "../../../components/UI/Modal/ModalContext";

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

export enum EPartner {
  name = "name",
  businessId = "businessId",
  streetAddress = "streetAddress",
  zip = "zip",
  city = "city",
  contactPersonName = "contactPersonName",
  contactPersonEmail = "contactPersonEmail",
  contactPersonPhoneNumber = "contactPersonPhoneNumber",
  partnerInterestRate = "partnerInterestRate",
}

const Partner: React.FC<IProps> = ({ history, location, match, }) => {
  const dispatch = useDispatch<TDispatch>();
  const { setModal, closeModal } = useContext(ModalContext);

  const { loading, error, partner } = useSelector((state: IAppState) => ({
    loading: state.partners.loading,
    error: state.partners.error,
    partner: state.partners.partner,
  }));

  const { t } = useTranslation();
  const { id } = match.params;

  const isNew = id === "new";

  const initialInputs: IInputField = {
    [EPartner.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.PARTNER_NAME,
      placeholderTranslation: ETranslation.PARTNER_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [EPartner.businessId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.PARTNER_BUSINESS_ID,
      placeholderTranslation: ETranslation.PARTNER_BUSINESS_ID,
      value: "",
      validation: {
        required: true
      }
    },
    [EPartner.streetAddress]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_STREET_ADDRESS,
      placeholderTranslation: ETranslation.COMMON_STREET_ADDRESS,
      value: "",
      validation: {
        required: true
      }
    },
    [EPartner.zip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_ZIP,
      placeholderTranslation: ETranslation.COMMON_ZIP,
      value: "",
      validation: {
        required: true
      }
    },
    [EPartner.city]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_CITY,
      placeholderTranslation: ETranslation.COMMON_CITY,
      value: "",
      validation: {
        required: true
      }
    },
    [EPartner.contactPersonName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTACT_PERSON_NAME,
      placeholderTranslation: ETranslation.CONTACT_PERSON_NAME,
      value: "",
    },
    [EPartner.contactPersonEmail]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTACT_PERSON_EMAIL,
      placeholderTranslation: ETranslation.CONTACT_PERSON_EMAIL,
      value: "",
    },
    [EPartner.contactPersonPhoneNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTACT_PERSON_PHONE_NUMBER,
      placeholderTranslation: ETranslation.CONTACT_PERSON_PHONE_NUMBER,
      value: "",
    },
    [EPartner.partnerInterestRate]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_INTEREST_RATE,
      placeholderTranslation: ETranslation.DEAL_INTEREST_RATE,
      post: InputSuffix.PERCENT,
      value: "",
    },
  };

  const [inputs, setInputs] = useState<IInputField>({ ...initialInputs });

  const [isValid, setValid] = useState(false);

  useEffect(() => {
    if (id && !isNew) {
      dispatch(actions.getPartner(id));
    }
  }, [id, dispatch, isNew]);

  useEffect(() => {
    if (id && !isNew && partner) {
      setInputs(stateInputs => initForm(stateInputs, partner));
    } else {
      setInputs(_ => initialInputs);
    }
    // eslint-disable-next-line
  }, [partner]);

  const createInput = (inputName: EPartner, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.PARTNER}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
      showValidation={!isValid}
    />
  };

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const name = inputs[EPartner.name].value as string;
    const businessId = inputs[EPartner.businessId].value as string;
    const streetAddress = inputs[EPartner.streetAddress].value as string;
    const zip = inputs[EPartner.zip].value as string;
    const city = inputs[EPartner.city].value as string;
    const contactPersonName = inputs[EPartner.contactPersonName].value as string;
    const contactPersonEmail = inputs[EPartner.contactPersonEmail].value as string;
    const contactPersonPhoneNumber = inputs[EPartner.contactPersonPhoneNumber].value as string;

    const organization: IOrganization = {
      id: "",
      name,
      level: EOrganizationLevel.PARTNER,
      businessId,
      streetAddress, zip, city,
      contactPersonName, contactPersonEmail, contactPersonPhoneNumber,
    }

    if (isNew) {
      await dispatch(actions.savePartner(organization));
    } else {
      organization.id = id;
      await dispatch(actions.updatePartner(organization));
    }

    history.push(Routes.PARTNERS);
  };

  const setDeleteDialog = (id: string) => setModal({
    isOpen: true,
    title: t(ETranslation.PARTNER_DELETE_TITLE),
    size: EModalSize.SMALL,
    content: (
      <div>
        <p>{t(ETranslation.PARTNER_DELETE_TEXT)}</p>
        <InputGroup>
          <Button onClick={() => deleteHandler(id)}>{t(ETranslation.COMMON_YES)}</Button>
          <Button onClick={closeModal}>{t(ETranslation.COMMON_NO)}</Button>
        </InputGroup>
      </div>
    ),
  });

  const deleteHandler = (id: string) => {
    if (!id) return;
    dispatch(actions.deletePartner(id));
    setModal(defaultModal);
  }

  useEffect(() => {
    setValid(validateInputs(inputs));
  }, [inputs]);

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{isNew ? t(ETranslation.TITLE_PARTNER_NEW) : t(ETranslation.TITLE_PARTNER)}</h2>
        {loading ? <Spinner />
          : (
            <>
              <InputGroup>
                {createInput(EPartner.name)}
              </InputGroup>
              <InputGroup>
                {createInput(EPartner.businessId)}
              </InputGroup>
              <InputGroup>
                {createInput(EPartner.streetAddress)}
              </InputGroup>
              <InputGroup>
                {createInput(EPartner.zip)}
                {createInput(EPartner.city)}
                {createInput(EPartner.partnerInterestRate)}
              </InputGroup>
              <InputGroup>
                {createInput(EPartner.contactPersonName)}
                {createInput(EPartner.contactPersonEmail)}
                {createInput(EPartner.contactPersonPhoneNumber)}
              </InputGroup>
            </>
          )
        }
        <InputGroup>
          <Button disabled={loading && !isValid} onClick={submitHandler}>
            {t(ETranslation.COMMON_SAVE)}
          </Button>
          <Button onClick={() => history.goBack()}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
          {!isNew && partner &&
          <Button onClick={() => setDeleteDialog(partner.id)}>
            {t(ETranslation.COMMON_DELETE)}
          </Button>
          }
        </InputGroup>
        {!isNew && <Users organizationId={id} history={history} location={location} match={match} />}
      </Container>
    </>
  );
};

export default Partner;
