import { faFile, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IAttachment } from "../../../../interfaces";

const classes = require("./DropzonePreview.module.scss");

interface IProps {
  attachment: IAttachment;
  onDelete: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
}

const DropzonePreview: React.FC<IProps> = ({
  attachment,
  onDelete,
}) => {
  return (
    <div key={attachment.id} className={classes.PreviewItem} title={attachment.clientName}>
      <FontAwesomeIcon icon={faFile} size="lg" />{' '}{attachment.clientName}
      <div className={classes.PreviewItemDelete} onClick={event => onDelete(event, attachment.id)}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

export default DropzonePreview;
