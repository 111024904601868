import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { ITalenomAction, ITalenomAuth, ITalenomInvoice } from "../../interfaces";
import { ActionTypes } from "./actionTypes";

const apiPath = "/json/talenom";

const getAuthStart = (): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_AUTH_GET_START,
  };
};

const getAuthSuccess = (auth: ITalenomAuth): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_AUTH_GET_SUCCESS,
    auth,
  };
};

const getAuthFail = (error: string): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_AUTH_GET_FAIL,
    error,
  };
};

export const getTalenomAuth = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getAuthStart());
    try {
      const res = await axios.get(`${apiPath}/getauth`);
      dispatch(getAuthSuccess(res.data));
    } catch ({ response }) {
      dispatch(getAuthFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listInvoicesStart = (): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_LIST_START,
  };
};

const listInvoicesSuccess = (invoices: ITalenomInvoice[]): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_LIST_SUCCESS,
    invoices,
  };
};

const listInvoicesFail = (error: string): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_LIST_FAIL,
    error,
  };
};

export const listTalenomInvoices = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listInvoicesStart());
    try {
      const res = await axios.get(`${apiPath}/invoices/list`);
      dispatch(listInvoicesSuccess(res.data));
    } catch ({ response }) {
      dispatch(listInvoicesFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const attachInvoiceStart = (): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_ATTACH_START,
  };
};

const attachInvoiceSuccess = (): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_ATTACH_SUCCESS,
  };
};

const attachInvoiceFail = (error: string): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_ATTACH_FAIL,
    error,
  };
};

export const attachTalenomInvoice = (invoiceId: string, dealId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(attachInvoiceStart());
    try {
      await axios.post(`${apiPath}/invoices/attach?id=${invoiceId}&dealId=${dealId}`);
      dispatch(attachInvoiceSuccess());
    } catch ({ response }) {
      dispatch(attachInvoiceFail((response as AxiosResponse).data));
      return null;
    }
  };
};


const hideInvoiceStart = (): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_HIDE_START,
  };
};

const hideInvoiceSuccess = (invoices: ITalenomInvoice[]): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_HIDE_SUCCESS,
    invoices,
  };
};

const hideInvoiceFail = (error: string): ITalenomAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_HIDE_FAIL,
    error,
  };
};

export const hideTalenomInvoice = (invoiceId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(hideInvoiceStart());
    try {
      const res = await axios.post(`${apiPath}/invoices/hide?id=${invoiceId}`);
      dispatch(hideInvoiceSuccess(res.data));
    } catch ({ response }) {
      dispatch(hideInvoiceFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listOneLeasingInvoicesStart = (): ITalenomAction => {
  return {
    type: ActionTypes.LIST_ONE_LEASING_INVOICES_START,
  };
};

const listOneLeasingInvoicesSuccess = (invoices: ITalenomInvoice[]): ITalenomAction => {
  return {
    type: ActionTypes.LIST_ONE_LEASING_INVOICES_SUCCESS,
    invoices,
  };
};

const listOneLeasingInvoicesFail = (error: string): ITalenomAction => {
  return {
    type: ActionTypes.LIST_ONE_LEASING_INVOICES_FAIL,
    error,
  };
};

export const listOneLeasingInvoices = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listOneLeasingInvoicesStart());
    try {
      const res = await axios.get(`${apiPath}/invoices/list/oneleasing`);
      dispatch(listOneLeasingInvoicesSuccess(res.data));
    } catch ({ response }) {
      dispatch(listOneLeasingInvoicesFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listOneFleetInvoicesStart = (): ITalenomAction => {
  return {
    type: ActionTypes.LIST_ONE_FLEET_INVOICES_START,
  };
};

const listOneFleetInvoicesSuccess = (invoices: ITalenomInvoice[]): ITalenomAction => {
  return {
    type: ActionTypes.LIST_ONE_FLEET_INVOICES_SUCCESS,
    invoices,
  };
};

const listOneFleetInvoicesFail = (error: string): ITalenomAction => {
  return {
    type: ActionTypes.LIST_ONE_FLEET_INVOICES_FAIL,
    error,
  };
};

export const listOneFleetInvoices = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listOneFleetInvoicesStart());
    try {
      const res = await axios.get(`${apiPath}/invoices/list/onefleet`);
      dispatch(listOneFleetInvoicesSuccess(res.data));
    } catch ({ response }) {
      dispatch(listOneFleetInvoicesFail((response as AxiosResponse).data));
      return null;
    }
  };
};