import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { IFinanceCompanyState, IFinanceCompanyAction } from "../../interfaces";

const initialState: IFinanceCompanyState = {
  loading: false,
  financeCompanies: null,
  financeCompany: null,
  error: null,
};

const getFinanceCompanyStart = (state: IFinanceCompanyState, action: IFinanceCompanyAction): IFinanceCompanyState => {
  return updateObject(state, {
    financeCompany: null,
    loading: true,
    error: null,
  });
};

const getFinanceCompanySuccess = (state: IFinanceCompanyState, action: IFinanceCompanyAction): IFinanceCompanyState => {
  return updateObject(state, {
    financeCompany: action.financeCompany,
    loading: false,
    error: null,
  });
};

const getFinanceCompanyFail = (state: IFinanceCompanyState, action: IFinanceCompanyAction): IFinanceCompanyState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listFinanceCompaniesStart = (state: IFinanceCompanyState, action: IFinanceCompanyAction): IFinanceCompanyState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const listFinanceCompaniesSuccess = (
  state: IFinanceCompanyState,
  action: IFinanceCompanyAction
): IFinanceCompanyState => {
  return updateObject(state, {
    financeCompanies: action.financeCompanies,
    loading: false,
    error: null,
  });
};

const listFinanceCompaniesFail = (state: IFinanceCompanyState, action: IFinanceCompanyAction): IFinanceCompanyState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveFinanceCompanyStart = (state: IFinanceCompanyState, action: IFinanceCompanyAction): IFinanceCompanyState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveFinanceCompanySuccess = (
  state: IFinanceCompanyState,
  action: IFinanceCompanyAction
): IFinanceCompanyState => {
  return updateObject(state, {
    financeCompany: action.financeCompany,
    loading: false,
    error: null,
  });
};

const saveFinanceCompanyFail = (state: IFinanceCompanyState, action: IFinanceCompanyAction): IFinanceCompanyState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateFinanceCompanyStart = (
  state: IFinanceCompanyState,
  action: IFinanceCompanyAction
): IFinanceCompanyState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateFinanceCompanySuccess = (
  state: IFinanceCompanyState,
  action: IFinanceCompanyAction
): IFinanceCompanyState => {
  return updateObject(state, {
    financeCompany: action.financeCompany,
    loading: false,
    error: null,
  });
};

const updateFinanceCompanyFail = (state: IFinanceCompanyState, action: IFinanceCompanyAction): IFinanceCompanyState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IFinanceCompanyState = initialState,
  action: IFinanceCompanyAction
): IFinanceCompanyState => {
  switch (action.type) {
    case ActionTypes.GET_FINANCE_COMPANY_START:
      return getFinanceCompanyStart(state, action);
    case ActionTypes.GET_FINANCE_COMPANY_SUCCESS:
      return getFinanceCompanySuccess(state, action);
    case ActionTypes.GET_FINANCE_COMPANY_FAIL:
      return getFinanceCompanyFail(state, action);
    case ActionTypes.LIST_FINANCE_COMPANIES_START:
      return listFinanceCompaniesStart(state, action);
    case ActionTypes.LIST_FINANCE_COMPANIES_SUCCESS:
      return listFinanceCompaniesSuccess(state, action);
    case ActionTypes.LIST_FINANCE_COMPANIES_FAIL:
      return listFinanceCompaniesFail(state, action);
    case ActionTypes.SAVE_FINANCE_COMPANY_START:
      return saveFinanceCompanyStart(state, action);
    case ActionTypes.SAVE_FINANCE_COMPANY_SUCCESS:
      return saveFinanceCompanySuccess(state, action);
    case ActionTypes.SAVE_FINANCE_COMPANY_FAIL:
      return saveFinanceCompanyFail(state, action);
    case ActionTypes.UPDATE_FINANCE_COMPANY_START:
      return updateFinanceCompanyStart(state, action);
    case ActionTypes.UPDATE_FINANCE_COMPANY_SUCCESS:
      return updateFinanceCompanySuccess(state, action);
    case ActionTypes.UPDATE_FINANCE_COMPANY_FAIL:
      return updateFinanceCompanyFail(state, action);
    default:
      return state;
  }
};

export default reducer;
