import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import Table from "../UI/Table/Table";
import { ILogItem } from "../../interfaces/domain";
import { formatDateString } from "../../shared/utility";

interface IProps {
  logItems: ILogItem[];
  onClick?: (deal?: ILogItem) => void;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  dealView?: boolean;
}

const LogItemsTable: React.FC<IProps> = ({ logItems, onClick, containerClassName, containerStyle, dealView }) => {
  const { t } = useTranslation();

  return (
    <div className={containerClassName} style={containerStyle}>
      <Table hover>
        <thead>
          <tr>
            <th>{t(ETranslation.COMMON_CREATED)}</th>
            <th>Tekijä nimi</th>
            <th>Tekijä organisaatio</th>
            {!dealView && <>
            <th>Kohde käyttäjä</th>
            <th>Kohde organisaatio</th>
            <th>Kohde diili</th></>
            }
            <th>Tapahtuma</th>
            <th>Viesti</th>
          </tr>
        </thead>
        <tbody>
          {logItems.map((logItem) => (
            <tr key={logItem.id} onClick={() => onClick && onClick(logItem)}>
              <td>{logItem.created && formatDateString(logItem.created)}</td>
              <td>{logItem.actionUserName}</td>
              <td>{logItem.actionOrganizationName}</td>
              {!dealView && <>
              <td>{logItem.targetUserName}</td>
              <td>{logItem.targetOrganizationName}</td>
              <td>{logItem.targetDealName}</td></>
              }
              <td>{logItem.action}</td>
              <td>{logItem.message}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default LogItemsTable;
