import { faFile, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Button, { EButtonColor } from "../../../components/UI/Button/Button";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import { IAppState, ITalenomInvoice } from "../../../interfaces";
import { toTwoDecimalsString } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import classes from "../Invoice/Invoice.module.scss";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import Spinner from "../../../components/UI/Spinner/Spinner";

interface IProps {
  invoice: ITalenomInvoice;
  hideInvoice?: (invoiceId: string) => void;
  registrationNumber?: string;
  detachInvoice?: (invoiceId: string) => void;
}

const TalenomInvoice: React.FC<IProps> = ({ invoice, hideInvoice, registrationNumber, detachInvoice }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadingAttachmentIds } = useSelector(
    (state: IAppState) => ({
      loadingAttachmentIds: state.attachments.loadingIds
    })
  );

  const invoiceDetailsList = [
    // { label: "sopimus", value: invoice.dealInfo },
    { label: "eräpäivä", value: invoice.info.overDue.date },
    // { label: "tilausnumero", value: invoice.orderNumber },
    { label: "viitteemme", value: invoice.info.referenceNumber },
    { label: "sopimusnumero", value: invoice.info.invoiceNumber },
    // { label: "muistiinpanot", value: invoice.notes },
  ];

  let total = 0;
  let totalVat0 = 0;
  let totalVat24 = 0;

  if (registrationNumber) {
    const row = invoice.accountingRows.find(row => row.projectKey === registrationNumber);
    if (row) {
      total = row.info.totalAmount.value || 0;
      totalVat24 = row.info.vatAmount.value || 0;
      totalVat0 = total - totalVat24;
    }
  } else {
    total = invoice.info.totals.totalAmount.value;
    totalVat0 = invoice.info.totals.totalVatExcludedAmount.value;
    totalVat24 = invoice.info.totals.totalVatAmount.value;
  }

  return (
    <div className={classes.Container}>

      <div className={classes.Content}>
        <div className={classes.Table}>

          <h4 className={classes.InvoiceTitle}>{invoice.seller.organisationName}, {invoice.info.invoiceDate}, lasku nro {invoice.info.invoiceNumber} ({invoice.buyer.organisationName})</h4>

          <div className={classes.TableInfoTop}>
            {invoiceDetailsList.filter(item => item.value).map(item => {
              if (!item.value) return "";
              return `${item.label}: ${item.value}`;
            }).join(", ")}
          </div>

          <div className={classes.TableInfoBottom}>
            {t(ETranslation.INVOICE_TOTAL)} <b>{toTwoDecimalsString(total)} {invoice.info.currency}</b>
            <br />
            {t(ETranslation.INVOICE_TOTAL_VAT_0)} {toTwoDecimalsString(totalVat0)} {invoice.info.currency}
            <br />
            {t(ETranslation.INVOICE_TOTAL_VAT_24)} {toTwoDecimalsString(totalVat24)} {invoice.info.currency}
          </div>
        </div>
        {(hideInvoice || detachInvoice) && (
          <InputGroup className={classes.Buttons}>
            {hideInvoice && (
              <Button color={EButtonColor.SECONDARY} onClick={() => hideInvoice(invoice.id)}>
                <FontAwesomeIcon icon={faTimes} />
                {" "}
                {t(ETranslation.INVOICES_HIDE_INVOICE_TITLE)}
              </Button>
            )}
            {detachInvoice && (
              <Button
                color={EButtonColor.SECONDARY}
                onClick={() => detachInvoice(invoice.id)}
              >
                {t(ETranslation.DETACH_INVOICE_TITLE)}
              </Button>
            )}
          </InputGroup>
        )}
      </div>
      {invoice.documents && invoice.documents.map(document => 
        loadingAttachmentIds?.includes(document.guid) ? <Spinner/> :
        <p className={classes.Attachment} key={document.guid} style={{cursor: "pointer"}}
          onClick={()=> dispatch(actions.openAttachment(document.link, document.guid))}>
          <FontAwesomeIcon icon={faFile} size="lg" color={"black"} />{' '}{document.fileName}
        </p>)}
      <hr />
    </div>
  );
}

export default TalenomInvoice;
