import { ITalenomAction, ITalenomState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: ITalenomState = {
  loading: false,
  auth: null,
  error: null,
  invoices: null,
  oneLeasingInvoices: null,
  oneFleetInvoices: null,
};

const getAuthStart = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    auth: null,
    loading: true,
    error: null,
  });
};

const getAuthSuccess = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    auth: action.auth,
    loading: false,
    error: null,
  });
};

const getAuthFail = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listInvoicesStart = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    invoices: null,
    loading: true,
    error: null,
  });
};

const listInvoicesSuccess = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    invoices: action.invoices,
    loading: false,
    error: null,
  });
};

const listInvoicesFail = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const attachInvoiceStart = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const attachInvoiceSuccess = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    loading: false,
    error: null,
  });
};

const attachInvoiceFail = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const hideInvoiceStart = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const hideInvoiceSuccess = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    loading: false,
    error: null,
  });
};

const hideInvoiceFail = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listOneLeasingInvoicesStart = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    oneLeasingInvoices: null,
    oneLeasingLoading: true,
    error: null,
  });
};

const listOneLeasingInvoicesSuccess = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    oneLeasingInvoices: action.invoices,
    oneLeasingLoading: false,
    error: null,
  });
};

const listOneLeasingInvoicesFail = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    oneLeasingLoading: false,
    error: action.error,
  });
};

const listOneFleetInvoicesStart = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    oneFleetInvoices: null,
    oneFleetLoading: true,
    error: null,
  });
};

const listOneFleetInvoicesSuccess = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    oneFleetInvoices: action.invoices,
    oneFleetLoading: false,
    error: null,
  });
};

const listOneFleetInvoicesFail = (state: ITalenomState, action: ITalenomAction): ITalenomState => {
  return updateObject(state, {
    oneFleetLoading: false,
    error: action.error,
  });
};

const reducer = (state: ITalenomState = initialState, action: ITalenomAction): ITalenomState => {
  switch (action.type) {
    case ActionTypes.TALENOM_AUTH_GET_START: return getAuthStart(state, action);
    case ActionTypes.TALENOM_AUTH_GET_SUCCESS: return getAuthSuccess(state, action);
    case ActionTypes.TALENOM_AUTH_GET_FAIL: return getAuthFail(state, action);
    case ActionTypes.TALENOM_INVOICES_LIST_START: return listInvoicesStart(state, action);
    case ActionTypes.TALENOM_INVOICES_LIST_SUCCESS: return listInvoicesSuccess(state, action);
    case ActionTypes.TALENOM_INVOICES_LIST_FAIL: return listInvoicesFail(state, action);
    case ActionTypes.TALENOM_INVOICES_ATTACH_START: return attachInvoiceStart(state, action);
    case ActionTypes.TALENOM_INVOICES_ATTACH_SUCCESS: return attachInvoiceSuccess(state, action);
    case ActionTypes.TALENOM_INVOICES_ATTACH_FAIL: return attachInvoiceFail(state, action);
    case ActionTypes.TALENOM_INVOICES_HIDE_START: return hideInvoiceStart(state, action);
    case ActionTypes.TALENOM_INVOICES_HIDE_SUCCESS: return hideInvoiceSuccess(state, action);
    case ActionTypes.TALENOM_INVOICES_HIDE_FAIL: return hideInvoiceFail(state, action);
    case ActionTypes.LIST_ONE_LEASING_INVOICES_START: return listOneLeasingInvoicesStart(state, action);
    case ActionTypes.LIST_ONE_LEASING_INVOICES_SUCCESS: return listOneLeasingInvoicesSuccess(state, action);
    case ActionTypes.LIST_ONE_LEASING_INVOICES_FAIL: return listOneLeasingInvoicesFail(state, action);
    case ActionTypes.LIST_ONE_FLEET_INVOICES_START: return listOneFleetInvoicesStart(state, action);
    case ActionTypes.LIST_ONE_FLEET_INVOICES_SUCCESS: return listOneFleetInvoicesSuccess(state, action);
    case ActionTypes.LIST_ONE_FLEET_INVOICES_FAIL: return listOneFleetInvoicesFail(state, action);
    default: return state;
  }
};

export default reducer;
