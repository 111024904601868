import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IOption } from "../components/UI/Input/Input";
import {
  IAppState,
  IAuthState,
  IFinanceCompanyState,
} from "../interfaces/state";
import { createOptions } from "../shared/option-utils";
import * as actions from "../store/actions";
import { ETranslation } from "../translations/translation-keys";

const defaultParams = {
  withPlaceholder: false,
  withBusinessId: false,
};

export const useFinanceCompanyOptions = ({
  withBusinessId,
  withPlaceholder,
} = defaultParams) => {
  const { authUser } = useSelector<IAppState, IAuthState>(
    (state) => state.auth
  );
  const { t } = useTranslation();
  const [financeCompanyOptions, setFinanceCompanyOptions] = useState<IOption[]>(
    []
  );
  const dispatch = useDispatch();

  const [tryFetch, setTryFetch] = useState(false);

  const { financeCompanies, loading } = useSelector<
    IAppState,
    IFinanceCompanyState
  >((state) => state.financeCompanies);

  useEffect(() => {
    if (authUser) {
      setTryFetch(authUser.isPrimary);
    }
  }, [authUser]);

  useEffect(() => {
    if (tryFetch && !financeCompanies) {
      dispatch(actions.listFinanceCompanies());
      setTryFetch(false);
    }
  }, [dispatch, financeCompanies, tryFetch]);

  useEffect(() => {
    let placeholder;
    if (withPlaceholder) {
      placeholder = t(ETranslation.SELECT_FINANCE_COMPANY_ID);
    }
    setFinanceCompanyOptions(
      financeCompanies
        ? createOptions(financeCompanies, (f) => f.name || f.id, placeholder)
        : []
    );
  }, [financeCompanies, t, withPlaceholder]);

  return { financeCompanyOptions, financeCompaniesLoading: loading };
};
