import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { searchDeals } from "../../../services/search-service";
import { updateInputHandler } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../../UI/Button/Button";
import Input, { EInputType, IInputField, IInputOptions, IOption } from "../../UI/Input/Input";
import ModalBody from "../../UI/Modal/ModalBody/ModalBody";
import ModalFooter from "../../UI/Modal/ModalFooter/ModalFooter";

enum EAttachInvoiceDialog {
  DEAL = "deal",
}

interface IProps {
  invoiceId: string;
  attachInvoice: (invoiceId: string, deal: IOption) => void;
}

const AttachInvoiceDialog: React.FC<IProps> = ({ invoiceId, attachInvoice }) => {
  const { t } = useTranslation();
  // const [dealOptions, setDealOptions] = useState<IOption[]>();

  // const { loading, error, deals } = useSelector((state: IAppState) => ({
  //   loading: state.deals.loading,
  //   error: state.deals.error,
  //   deals: state.deals.deals,
  // }));

  // prettier-ignore
  // useEffect(() => deals ? setDealOptions(createOptions(deals)) : undefined, [deals]);

  const [inputs, setInputs] = useState<IInputField>({
    [EAttachInvoiceDialog.DEAL]: {
      type: EInputType.searchSelect,
      label: t(ETranslation.ATTACH_INVOICE_DEAL),
      placeholder: t(ETranslation.ATTACH_INVOICE_DEAL_SEARCH),
      value: "",
      // options: dealOptions,
    }
  });

  // const [dealId, setDealId] = useState<number>();
  const selectedDeal = inputs[EAttachInvoiceDialog.DEAL].value;

  const createInput = (inputName: EAttachInvoiceDialog, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      // updateAction={EInputUpdateAction.USER}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    // showValidation={!isValid}
    />
  };

  return (
    <React.Fragment>
      <ModalBody style={{ display: "flex", flexDirection: "column" }}>
        {createInput(EAttachInvoiceDialog.DEAL, { fetchOptions: searchDeals })}
      </ModalBody>
      <ModalFooter>
        {/* {error && <Alert>{error}</Alert>} */}
        <Button
          disabled={!selectedDeal}
          onClick={() => attachInvoice(invoiceId, selectedDeal as IOption)}
        >
          {t(ETranslation.COMMON_SAVE)}
        </Button>
      </ModalFooter>
    </React.Fragment>
  );
}

export default AttachInvoiceDialog;
