import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomerOptions } from '../../../hooks/useCustomerOptions';
import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';

interface IProps {
  setCustomerId: React.Dispatch<React.SetStateAction<string>>;
  customerId: string;
}

const CustomerSearch: React.FC<IProps> = ({ setCustomerId, customerId }) => {

  const { customerOptions: organizationOptions, customersLoading: organizationsLoading } = useCustomerOptions();
  const { t } = useTranslation();

  return (
    <div>
      <label>{t(ETranslation.DEAL_CUSTOMER)}</label>
      <Select
        inputName={"customerSearch"}
        value={customerId}
        onChange={setCustomerId}
        options={organizationOptions}
        isSearchable
        isClearable
        isLoading={organizationsLoading}
      />
    </div>
  )

}

export default CustomerSearch;
