import { ETranslation } from "./translation-keys";

const commonTranslations = {
  [ETranslation.COMMON_SAVE]: "Tallenna",
  [ETranslation.COMMON_RETURN]: "Palaa",
  [ETranslation.COMMON_ADD]: "Lisää",
  [ETranslation.COMMON_REMOVE]: "Poista",
  [ETranslation.COMMON_COPY]: "Kopioi",
  [ETranslation.COMMON_STREET_ADDRESS]: "Katuosoite",
  [ETranslation.COMMON_ZIP]: "Postinumero",
  [ETranslation.COMMON_CITY]: "Postitoimipaikka",
  [ETranslation.COMMON_NO_CHOICE]: "Ei valintaa",
  [ETranslation.COMMON_CREATED]: "Luontipvm",
  [ETranslation.COMMON_YES]: "Kyllä",
  [ETranslation.COMMON_NO]: "Ei",
  [ETranslation.COMMON_YEAR]: "Vuosi",
  [ETranslation.COMMON_OK]: "Ok",
  [ETranslation.COMMON_DELETE]: "Poista",
  [ETranslation.COMMON_SEND]: "Lähetä",
};

const authTranslations = {
  [ETranslation.TITLE_LEASING_MANAGEMENT]: "Leasing Management",
  [ETranslation.LOGIN]: "Login",
  [ETranslation.USERNAME]: "Username",
  [ETranslation.PASSWORD]: "Password",
  [ETranslation.PASSWORD_MIN_LENGTH_MESSAGE]:
    "Salasanan pitää sisältää vähintään 6 merkkiä.",
};

const headerTranslations = {
  [ETranslation.DEALS]: "Sopimukset",
  [ETranslation.DEALS_NEW]: "Tee tarjous",
  [ETranslation.REPORTS]: "Raportit",
  [ETranslation.CUSTOMERS]: "Asiakkaat",
  [ETranslation.PARTNERS]: "Kumppanit",
  [ETranslation.SETTINGS]: "Asetukset",
  [ETranslation.LOGOUT]: "Kirjaudu ulos",
  [ETranslation.USERS]: "Käyttäjät",
  [ETranslation.REPORTS_OFFER]: "Avoimet",
  [ETranslation.REPORTS_PRECONTRACT]: "Luovutukset",
  [ETranslation.REPORTS_CONTRACT]: "Päättyvät",
  [ETranslation.REPORTS_TERMINATED]: "Päättyneet",
  [ETranslation.DASHBOARD]: "One Leasing Finland",
  [ETranslation.REPORTS_FEES]: "Kulut",
  [ETranslation.INVOICES]: "Laskut",
  [ETranslation.SEARCH]: "Haku",
  [ETranslation.REPORTS_MAINTENANCE_BUDGET]: "Huoltoraharaportti",
  [ETranslation.REPORTS_MAINTENANCE_LEASING]: "Huoltoleasingraportti",
  [ETranslation.REPORTS_ARCHIVED]: "Arkistoidut",
  [ETranslation.PREOFFERS]: "Hakemukset",
  [ETranslation.DRAFTS]: "Rahoituslaskelmat",
  [ETranslation.CONTACTS]: "Kontaktit",
  [ETranslation.LOG]: "Loki",
};

const dealsTranslations = {
  [ETranslation.TITLE_DEALS]: "Sopimukset",
  [ETranslation.DEALS_ADD_NEW]: "Lisää uusi tarjous",
  [ETranslation.NO_DEALS]: "Ei sopimuksia.",
  [ETranslation.DEALS_FETCH_ALL]: "Hae kaikki sopimukset",
};

const dealTranslations = {
  [ETranslation.DEAL_VIEW_EDIT]: "Muokkaa",
  [ETranslation.DEAL_VIEW_PREVIEW]: "Esikatselu",
  [ETranslation.DEAL_VIEW_TERMINATE]: "Päättäminen",
  [ETranslation.TITLE_DEAL]: "Tarjous",
  [ETranslation.TITLE_DEAL_NEW]: "Uusi tarjous",
  [ETranslation.TITLE_PRECONTRACT]: "Tilaussopimus",
  [ETranslation.TITLE_CONTRACT]: "Sopimus",
  [ETranslation.TITLE_TERMINATED]: "Päättynyt sopimus",
  [ETranslation.DEAL_NAME]: "Tarjouksen nimi",
  [ETranslation.DEAL_CUSTOMER]: "Asiakas",
  [ETranslation.DEAL_SELLER]: "Myyjä",
  [ETranslation.DEAL_SEND_DATE]: "Lähetetty",
  [ETranslation.DEAL_OPENED_DATE]: "Avattu",
  [ETranslation.DEAL_STATUS]: "Tila",
  [ETranslation.DEAL_ARCHIVED_DATE]: "Arkistoitu",
  [ETranslation.DEAL_ARCHIVED_USER]: "Arkistoija",
  [ETranslation.DEAL_TYPE]: "Tyyppi",
  [ETranslation.DEAL_INVOICE_REFERENCE]: "Laskuviite",
  [ETranslation.DEAL_COST_CENTER]: "Kustannuspaikka",
  [ETranslation.DEAL_INVOICE_PAYER]: "Laskun maksaja",
  [ETranslation.DEAL_FINANCE_COMPANY_CONTRACT_NUMBER]:
    "Rahoitusyhtiön sopimusnumero",
  [ETranslation.DEAL_HIDE_VAT_PRICES]:
    "Piilota verolliset hinnat esikatselusta",

  [ETranslation.DEAL_CAR_DEALERSHIP]: "Autoliike",
  [ETranslation.DEAL_CAR_DEALERSHIP_SELLER_NAME]: "Automyyjä",
  [ETranslation.DEAL_CAR_DEALERSHIP_SALE_NUMBER]: "Autoliikkeen tarjous nro",

  [ETranslation.DEAL_MACHINE_DEALERSHIP]: "Myyjäliike",
  [ETranslation.DEAL_MACHINE_DEALERSHIP_SELLER_NAME]: "Myyjä",
  [ETranslation.DEAL_MACHINE_DEALERSHIP_SALE_NUMBER]: "Tarjous nro",

  [ETranslation.DEAL_BRAND]: "Auton merkki/kohde",
  [ETranslation.DEAL_CAR_BRAND]: "Auton merkki",
  [ETranslation.DEAL_MODEL]: "Malli",
  [ETranslation.DEAL_MODEL_YEAR]: "Vuosimalli",
  [ETranslation.DEAL_CAR_CO2_EMISSIONS]: "Co2 päästöt",

  [ETranslation.DEAL_MACHINE_BRAND]: "Kohde",

  [ETranslation.DEAL_CAR_TAKE_HOME_VEHICLE]: "Vapaa autoetu",
  [ETranslation.DEAL_CAR_CAR_BENEFIT]: "Käyttöetu",

  [ETranslation.DEAL_REGISTRATION_NUMBER]: "Rekisterinumero",

  [ETranslation.DEAL_REGISTRATION_DATE]: "Rekisteröinti",
  [ETranslation.DEAL_SIGNING_DATE]: "Sopimusallekirjoitus",
  [ETranslation.DEAL_SURETY]: "Takaus",
  [ETranslation.DEAL_HANDOVER_DATE]: "Luovutus",
  [ETranslation.DEAL_HANDOVER_NOTES]: "Muistiinpanot",

  [ETranslation.DEAL_TERMINATED_DATE]: "Päättymispäivä",
  [ETranslation.DEAL_TERMINATED_USER]: "Sopimuksen päättäjä",
  [ETranslation.DEAL_TERMINATED_NOTES]: "Muistiinpanot",

  [ETranslation.DEAL_INSURANCE]: "Vakuutusyhtiö",
  [ETranslation.DEAL_ACCESSORIES]: "Lisävarusteet",
  [ETranslation.DEAL_INVOICE_INFO]: "Laskutusohje",

  // pricing
  [ETranslation.DEAL_PERCENTAGE]: "alv 0%",
  [ETranslation.DEAL_VAT]: "sis. alv",
  [ETranslation.DEAL_VAT_24]: "alv 24%",
  [ETranslation.DEAL_VAT_255]: "alv 25,5%",
  [ETranslation.DEAL_VAT_HELP_TEXT]: "1.9.2024. alkaen",

  [ETranslation.DEAL_RESIDUAL]: "Jäännösarvo",
  [ETranslation.DEAL_RESIDUAL_VALUE]: "Jäännösarvo, alv 0%",
  [ETranslation.DEAL_RESIDUAL_VALUE_PERCENTAGE]: "Jäännösarvo %",
  [ETranslation.DEAL_RESIDUAL_VALUE_VAT]: "Jäännösarvo, sis. alv",

  [ETranslation.DEAL_CONTRACT_LENGTH]: "Sopimuspituus",
  [ETranslation.DEAL_CONTRACT_KILOMETERS]: "Sopimuskilometrit",
  [ETranslation.DEAL_INTEREST_RATE]: "Korko",

  [ETranslation.DEAL_MONTHLY]: "Kuukausierä",
  [ETranslation.DEAL_MONTHLY_PAYMENT_VAT]: "Kuukausierä, sis. alv",
  [ETranslation.DEAL_MONTHLY_PAYMENT]: "Kuukausierä, alv 0%",

  [ETranslation.DEAL_FIRST_RENT]: "Ensimmäinen suurempi vuokra",
  [ETranslation.DEAL_FIRST_LARGER_RENT]: "Ensimmäinen suurempi vuokra, alv 0%",
  [ETranslation.DEAL_FIRST_LARGER_RENT_VAT]:
    "Ensimmäinen suurempi vuokra, sis. alv",
  [ETranslation.DEAL_FUTURE_VALUE]: "Tuleva arvo, alv 0%",
  [ETranslation.DEAL_FUTURE_VALUE_VAT]: "Tuleva arvo, sis. alv",
  [ETranslation.DEAL_FUTURE_VALUE_LOG]: "Tuleva arvo",
  [ETranslation.DEAL_MONTHLY_INSTALLMENT_FIRST]: "Kuukausierä 1. erällä",
  [ETranslation.DEAL_MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT]:
    "Kuukausierä 1. erällä, sis. alv",
  [ETranslation.DEAL_MONTHLY_INSTALLMENT_FIRST_INSTALLMENT]:
    "Kuukausierä 1. erällä, alv 0%",

  // car pricing
  [ETranslation.DEAL_CAR_TOTAL_PRICE]: "Auto kokonaishinta",
  [ETranslation.DEAL_CAR_DISCOUNT]: "Auton alennus",
  [ETranslation.DEAL_CAR_CAR_TAX]: "Autovero",
  [ETranslation.DEAL_MAINTENANCE_BUDGET_TOTAL]: "Huoltobudjetti yhteensä",
  [ETranslation.DEAL_CAR_MAINTENANCE_BUDGET]: "Huoltobudjetti, sis. alv",
  [ETranslation.DEAL_CAR_MAINTENANCE_BUDGET_TOTAL]:
    "Huoltobudjetti yhteensä, sis. alv",
  [ETranslation.DEAL_CAR_MAINTENANCE_BUDGET_MONTHLY]:
    "Huoltobudjetti/kk, sis. alv",
  [ETranslation.DEAL_CAR_EXTRA_TIRE_SETS]: "Lisärengassarja",
  [ETranslation.DEAL_CAR_EXTRA_TIRE_SET_PRICE]: "Sarjan hinta, sis. alv",
  [ETranslation.DEAL_CAR_TIRE_STORAGE_PRICE]: "Rengashotelli",
  [ETranslation.DEAL_CAR_TIRE_STORAGE_MONTHLY]: "Rengashotelli/kk, sis. alv",
  [ETranslation.DEAL_CAR_REPLACEMENT_CAR_PRICE]: "Sijaisauto, sis. alv",
  [ETranslation.DEAL_GROSS_PROFIT_VAT]: "Kate, sis. alv",
  [ETranslation.DEAL_GROSS_PROFIT]: "Kate, alv 0%",
  [ETranslation.DEAL_CAR_WINTER_TIRES_PURCHASE_PRICE]:
    "Talvirenkaat ostohinta, sis. alv",

  [ETranslation.DEAL_CAR_CAR_PRICE_FOR_CALCULATOR]:
    "Autohinta Lähitapiola laskuriin, sis. alv",
  [ETranslation.DEAL_CAR_TOTAL_PRICE_VAT_INCLUDING_POLLUTION]:
    "Kokonaishinta alv saastutuksella, sis. alv",
  [ETranslation.DEAL_EXTRA_KILOMETER_CHARGE]: "Ylikilometriveloitus, sis. alv",
  [ETranslation.DEAL_EXTRA_KILOMETER]: "Ylikilometriveloitus",

  // machine pricing
  [ETranslation.DEAL_MACHINE_PURCHASE_PRICE]: "Hankintahinta, alv 0%",
  [ETranslation.DEAL_MACHINE_MAINTENANCE_CONTRACT]: "Huolenpitosopimus, alv 0%",
  [ETranslation.DEAL_MACHINE_PURCHASE_PRICE_VAT]: "Hankintahinta, sis. alv",
  [ETranslation.DEAL_MACHINE_MAINTENANCE_CONTRACT_VAT]:
    "Huolenpitosopimus, sis. alv",

  [ETranslation.DEAL_MACHINE_FUNDABLE_CAPITAL]: "Rahoitettava pääoma, alv 0%",
  [ETranslation.DEAL_MACHINE_FUNDABLE_CAPITAL_VAT]: "Rahoitettava pääoma, sis. alv",

  [ETranslation.DEAL_BASIC_SERVICES]: "Peruspalvelut",
  [ETranslation.DEAL_MAINTENANCE_SERVICES]: "Huolto- ja ylläpitopalvelut",
  [ETranslation.DEAL_ADDITIONAL_INFORMATION]: "Tarvittavat lisätiedot",
  [ETranslation.DEAL_ADDITIONAL_EXPENSES]: "Lisäkulut",
  [ETranslation.DEAL_ADDITIONAL_EXPENSE]: "Lisäkulu",

  [ETranslation.DEAL_CUSTOMER_NAME]: "Asiakkaan nimi",
  [ETranslation.DEAL_CUSTOMER_BUSINESS_ID]: "Asiakkaan Y-tunnus",
  [ETranslation.DEAL_CUSTOMER_STREET_ADDRESS]: "Asiakkaan osoite",
  [ETranslation.DEAL_CUSTOMER_ZIP]: "Asiakkaan postinumero",
  [ETranslation.DEAL_CUSTOMER_CITY]: "Asiakkaan postitoimipaikka",

  [ETranslation.DEAL_FINANCE_COMPANY]: "Rahoitusyhtiö",
  [ETranslation.DEAL_LEASING_MODEL]: "Leasingmalli",
  [ETranslation.DEAL_VARIATION]: "Rahoituslaskelma",
  [ETranslation.DEAL_BASIC_INFORMATION]: "Perustiedot",
  [ETranslation.DEAL_CUSTOMER_INFORMATION]: "Asiakkaan tiedot",
  [ETranslation.DEAL_NEW_CONDITION]: "Muokkaa tuplaklikkaamalla riviä",
  [ETranslation.DEAL_END_DATE]: "Päättymispvm",
  [ETranslation.DEAL_DOWN_PAYMENT]: "Käsiraha, alv 0%",
  [ETranslation.DEAL_DOWN_PAYMENT_VAT]: "Käsiraha, sis. alv",
  [ETranslation.DEAL_DOWN]: "Käsiraha",
  [ETranslation.DEAL_PAYMENT_TYPE]: "Rahoitusmuoto",
  [ETranslation.DEAL_PAYMENT_TYPE_LEASING]: "Leasing",
  [ETranslation.DEAL_PAYMENT_TYPE_INSTALLMENT]: "Osamaksu",
  [ETranslation.DEAL_PAYMENT_TYPE_CASH_OLF]: "Käsiraha, OLF",
  [ETranslation.DEAL_PAYMENT_TYPE_CASH_SELLER]: "Käsiraha, myyjäliikkeelle",
  [ETranslation.DEAL_EXTRA_FEES]: "Lisäkulut",
  [ETranslation.DEAL_EXTRA_FEE]: "Lisäkulu",
  [ETranslation.DEAL_EXTRA_FEE_NAME]: "Kulun nimi",
  [ETranslation.DEAL_EXTRA_FEE_VALUE]: "Kulun arvo",
  [ETranslation.DEAL_EXTRA_FEE_ATTACHMENTS]: "Kuludokumentti",
  [ETranslation.DEAL_ACTION_SEND]: "Lähetä",
  [ETranslation.DEAL_SERIAL_NUMBER]: "Sarjanumero",
  [ETranslation.DEAL_ACTION_COPY]: "Kopioi pohjaksi",
  [ETranslation.DEAL_ACTION_ARCHIVE]: "Arkistoi",
  [ETranslation.DEAL_ACTION_UNARCHIVE]: "Palauta",
  [ETranslation.DEAL_ACTION_TERMINATE]: "Päätä sopimus",
  [ETranslation.DEAL_TERMINATE_DIALOG_TITLE]: "Päätä sopimus",
  [ETranslation.DEAL_TERMINATE_DIALOG_TEXT]:
    "Oletko varma, että haluat päättää sopimuksen?",
  [ETranslation.DEAL_INSURANCE_CERTIFICATE_RECEIVED]: "Vakuutustodistus saatu",
  [ETranslation.DEAL_ACTION_EIDENT_START]: "Allekirjoita",
  [ETranslation.DEAL_IS_USED_CAR]: "Käytetty",
  [ETranslation.DEAL_KICKBACK_VAT]: "Kick Back sis. alv",
  [ETranslation.DEAL_KICKBACK]: "Kick Back alv 0%",
  [ETranslation.DEAL_VAT_PERCENT]: "Alv-kanta",
};

const dealUserTranslations = {
  [ETranslation.DEAL_USER_INFORMATION]: "Käyttäjän tiedot",
  [ETranslation.DEAL_USER_NAME]: "Käyttäjän nimi",
  [ETranslation.DEAL_USER_EMAIL]: "Käyttäjän email",
  [ETranslation.DEAL_USER_PHONE_NUMBER]: "Käyttäjän puhelinnumero",
  [ETranslation.DEAL_USER_SOCIAL_SECURITY_NUMBER]: "Käyttäjän henkilötunnus",
  [ETranslation.DEAL_USER_STREET_ADDRESS]: "Käyttäjän katuosoite",
  [ETranslation.DEAL_USER_CITY]: "Käyttäjän kaupunki",
  [ETranslation.DEAL_USER_ZIP]: "Käyttäjän postinumero",
};

const dealTypeTranslations = {
  [ETranslation.DEAL_TYPE_CAR]: "Auto",
  [ETranslation.DEAL_TYPE_MACHINE]: "Kone",
};

const dealStatusTranslations = {
  [ETranslation.DEAL_STATUS_OFFER]: "Tarjous",
  [ETranslation.DEAL_STATUS_PRECONTRACT]: "Tilaussopimus",
  [ETranslation.DEAL_STATUS_CONTRACT]: "Sopimus",
  [ETranslation.DEAL_STATUS_TERMINATED]: "Päättynyt",
  [ETranslation.DEAL_STATUS_ARCHIVED]: "Arkistoitu",
  [ETranslation.DEAL_STATUS_DRAFT]: "Luonnos",
  [ETranslation.DEAL_STATUS_PREOFFER]: "Rahoituslaskelma",
};

const dealBasicServicesTranslations = {
  [ETranslation.DEAL_BASIC_SERVICE_ACQUISITION]: "Kohteen hankinta",
  [ETranslation.DEAL_BASIC_SERVICE_TIRES]: "Kesä- ja talvirenkaat luovutuksen yhteydessä",
  [ETranslation.DEAL_BASIC_SERVICE_RELOCATION]: "Ajoneuvon uudelleen sijoitus vuokrakauden päätteeksi",
  [ETranslation.DEAL_BASIC_SERVICE_EXTRA_TIRES_TIRE_SETS]: "lisäsarjaa",
  [ETranslation.DEAL_BASIC_SERVICE_REDEMPTION]: "One Leasing Finland sitoutuu lunastamaan ajoneuvon jäännösarvolla sopimuskauden päätteeksi",
  [ETranslation.DEAL_BASIC_SERVICE_REDEMPTION_HUOLTO]: "One Leasing Finland sitoutuu lunastamaan ajoneuvon rahoitusyhtiöltä sopimuskauden päätteeksi",
  [ETranslation.DEAL_BASIC_SERVICE_REALIZATION_FEE]: "Sopimuksen realisointipalkkio 250€ alv 0%",
};

const dealMaintenanceServicesTranslations = {
  [ETranslation.DEAL_MAINTENANCE_SERVICE_MAINTENANCE]: "Huolto-ohjelman mukaiset huollot",
  [ETranslation.DEAL_MAINTENANCE_SERVICE_WEAR_AND_TEAR]: "Normaalista kulumisesta aiheutuneet kulutusosat ja korjaukset (ei koske vaurioita ja jarrulevyjen vaihtoa alle 80tkm ajetuissa autoissa)",
  [ETranslation.DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES]: "Lisärengassarjat kulutuksen mukaan",
  [ETranslation.DEAL_MAINTENANCE_SERVICE_REPLACEMENT_CAR]: "Sijaisauto määräaikaishuoltojen ajaksi (A-luokka)",
  [ETranslation.DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE]: "Rengashotelli- ja vaihtopalvelut",
  [ETranslation.DEAL_MAINTENANCE_SERVICE_MAINTENANCE_CONTRACT]: "Sisältää merkkikohtaisen maahantuojan tai autoliikkeen huolenpitosopimuksen",
  [ETranslation.DEAL_MAINTENANCE_SERVICE_MAINTENANCE_CONTRACT_OLF]: "Sisältää One Fleet Oy:n huoltorahasopimuksen (lisätiedot www.onefleet.fi)",
};

const dealAdditionalInformationTranslations = {
  [ETranslation.DEAL_ADDITIONAL_INFORMATION_LATEST_FINANCIAL_STATEMENT]:
    "Viimeisin tilinpäätös",
  [ETranslation.DEAL_ADDITIONAL_INFORMATION_FINANCIAL_STATEMENT_INTERVALS]:
    "Tilinpäätöksen väliajot (tulos ja tase)",
  [ETranslation.DEAL_ADDITIONAL_INFORMATION_IS_REPLACEMENT]:
    "Onko kohde korvaava hankinta",
  [ETranslation.DEAL_ADDITIONAL_INFORMATION_THIRD_PARTY_GUARANTEED]:
    "Sale and leaseback, kolmannen osapuolen vahvistama laitteen arvo",
  [ETranslation.DEAL_ADDITIONAL_INFORMATION_COLLATERAL_FREE]:
    "Todistus että laite on vakuuksista vapaa",
};

const dealPreviewTranslations = {
  [ETranslation.DEAL_PREVIEW_REILU_LEASING_TITLE]:
    "Reiluleasing huoltobudjetti:",
  [ETranslation.DEAL_PREVIEW_REILU_LEASING_TEXT]:
    "Auton huoltobudjetti on laskettu pitämään sisällään yllämainitut palvelut. Huoltobudjetti on asiakkaalle korvamerkittyä rahaa, jota käytetään sopimuskauden aikana suoritettaviin huoltoihin, renkaanvaihtoihin sekä muihin ylläpitotarpeisiin. Sopimuskauden päätteeksi huoltobudjetti tasataan leasingyhtiön toimesta vastaamaan toteumaa.",
  [ETranslation.DEAL_PREVIEW_HUOLTO_LEASING_TITLE]:
    "Huoltoleasing huoltobudjetti:",
  [ETranslation.DEAL_PREVIEW_INFO_TEXT_1]: "One Leasing Finland Oy",
  [ETranslation.DEAL_PREVIEW_INFO_TEXT_2]:
    "on kotimainen, räätälöityjä leasingrahoitusratkaisuja ja -palveluja tarjoava yritys. Leasingrahoituksen kohteena ovat henkilö-, paketti- ja kuorma-autot, koneet, laitteet sekä kalusteet. Palvelumme mahdollistavat asiakasyrityksillemme kalustoinvestointien toteuttamisen joustavasti ja nykyaikaisesti. Rahoitusratkaisumme tehostavat yrityksen oman pääoman käyttöä.",
  [ETranslation.DEAL_PREVIEW_INFO_TEXT_3]:
    "Me One Leasing Finlandilla haluamme tarjota asiakkaillemme mahdollisimman helpon tavan hankkia ja hallinnoida kalustoa. Täten tarjoamme Teille seuraavaa kokonaispalvelua:",
  [ETranslation.DEAL_PREVIEW_TITLE_BASIC_SERVICES]:
    "Tarjous pitää sisällään seuraavat peruspalvelut:",
  [ETranslation.DEAL_PREVIEW_TITLE_MAINTENANCE_SERVICES]:
    "Tarjous pitää sisällään seuraavat huolto- ja ylläpitopalvelut:",
  [ETranslation.DEAL_PREVIEW_TITLE_ADDITIONAL_INFORMATION]:
    "Luottopäätöstä varten, tarvitsemme seuraavat tiedot:",
  [ETranslation.DEAL_PREVIEW_FOOTER_TEXT_1]:
    "Toivottavasti tarjouksemme miellyttää Teitä ja johtaa tilaukseen. Mikäli teillä tulee mitään lisäkysymyksiä tarjoukseen liittyen, voitte laittaa viestiä tai soittaa meille, niin autamme Teitä parhaamme mukaan. Tarjous on voimassa 30 vrk tarjouksen päiväyksestä.",
  [ETranslation.DEAL_PREVIEW_FOOTER_TEXT_2]:
    "Pidätämme oikeuden tarjouksen ehtojen muuttamiseen kohteen hintojen muuttuessa. Tarjouksen mukaisen sopimuksen tekeminen edellyttää rahoitusyhtiön voimassaolevaa luottopäätöstä.",
  [ETranslation.DEAL_PREVIEW_FOOTER_TEXT_3]:
    "Pidätämme myös oikeuden muuttaa hyväksytyn tarjouksen sisältöä rahoitusmarkkinoilla tapahtuvien korko- ja muiden muutosten johdosta.",
  [ETranslation.DEAL_PREVIEW_FOOTER_TEXT_4]:
    "One Leasing Finland Oy ei vastaa Leasingkohteiden laadusta, toimivuudesta tai kunnosta eikä myöskään niiden sopivuudesta Vuokralleottajalle. Jos Leasingkohteessa on vika tai puute toimitettaessa tai vika tai puute ilmenee vuokra-ajan aikana, on Vuokralleottajan esitettävä näistä johtuvat vaatimuksensa Leasingkohteen toimittajalle tai tämän osoittamalle takuu- tai muun sen kaltaisen sitoumuksen antajalle sekä ilmoitettava tästä välittömästi One Leasing Finland Oy:lle. Leasingkohteen viat tai puutteet eivät poista taikka vähennä Vuokralleottajan vuokranmaksuvelvollisuutta. Jos tarjouksen saaja hyväksyy tarjouksen ja ilman hyväksyttävää syytä luopuu leasingsopimuksesta ennen kuin kohde on luovutettu hänen hallintaansa, One Leasing Finland Oy saa periä vahingonkorvauksena kymmenen (10) prosenttia sopimuksen mukaisesta kohteen käteishinnasta kohteen varusteet mukaan lukien tai vähintään kaksi tuhatta (2000) euroa.",
  [ETranslation.DEAL_PREVIEW_INVOICE_PROCESSING_FEE]:
    "Laskun käsittelymaksu {{invoicingFee}} €/kk alv 0%",
  [ETranslation.DEAL_PREVIEW_SETUP_COST]:
    "Sopimuksen perustamiskustannus {{openingFee}} € alv 0%",
  [ETranslation.DEAL_PREVIEW_INVOICE_PROCESSING_FEE_VAT]:
    "Laskun käsittelymaksu {{invoicingFee}} €/kk sis. sis. alv",
  [ETranslation.DEAL_PREVIEW_SETUP_COST_VAT]:
    "Sopimuksen perustamiskustannus {{openingFee}} € sis. sis. alv",
  [ETranslation.DEAL_PREVIEW_HELP_TEXT_SIGNATURE]:
    "Voitte vahvistaa tilauksen allekirjoittamalla tarjouksen",
  [ETranslation.DEAL_PREVIEW_BEST_REGARDS_TEXT]: "Ystävällisin terveisin",
  [ETranslation.DEAL_PREVIEW_CREATE_SUBSCRIPTION_AGREEMENT]:
    "Tee tilaussopimus",
  [ETranslation.DEAL_PREVIEW_SUBSCRIPTION_AGREEMENT_TITLE]: "Tilaussopimus",
  [ETranslation.DEAL_PREVIEW_SUBSCRIPTION_AGREEMENT_TEXT]:
    "Oletko varma, että haluat tehdä tarjouksesta tilaussopimuksen?",
  [ETranslation.DEAL_PREVIEW_CONFIRM_DEAL_TITLE]: "Kuittaa luovutus",
  [ETranslation.DEAL_PREVIEW_CONFIRM_DEAL_TEXT]:
    "Oletko varma, että haluat vahvistaa sopimuksen?",
  [ETranslation.DEAL_PREVIEW_CONFIRM_MISSING_REGISTRATION_NUMBER]:
    "Rekisterinumero puuttuu.",
  [ETranslation.DEAL_PREVIEW_CONFIRM_MISSING_SERIAL_NUMBER]:
    "Sarjanumero puuttuu.",
  [ETranslation.DEAL_PREVIEW_CONFIRM_MISSING_FINANCE_COMPANY_CONTRACT_NUMBER]:
    "Rahoitusyhtiön sopimusnumero puuttuu.",
  [ETranslation.DEAL_PREVIEW_CONFIRM_MISSING_INSURANCE_CERTIFICATE_RECEIVED]:
    "Vakuutustodistus puuttuu.",
  [ETranslation.DEAL_ATTACHMENTS]: "Sisäiset liitteet",
  [ETranslation.DEAL_PREVIEW_STARTS]: "Sopimus alkaa:",
  [ETranslation.DEAL_PREVIEW_ENDS]: "Sopimus päättyy:",
  [ETranslation.DEAL_PREVIEW_TERMINATED]: "Alkuperäinen päättymispäivämäärä ollut",
  [ETranslation.DEAL_PREVIEW_TERMINATED_CHOSEN]: "on valinnut päättymispäiväksi",
  [ETranslation.DEAL_PREVIEW_LINK_COPIED_TEXT]:
    "Linkki kopioitu leikepöydälle.",
  [ETranslation.DEAL_PREVIEW_OFFER_ACCEPTED]: "Tarjous hyväksytty",
  [ETranslation.DEAL_PREVIEW_OFFER_SIGNED_AT]: "Allekirjoitettu",
  [ETranslation.DEAL_PREVIEW_OFFER_SIGNATURE]: "Vuokralleottajan allekirjoitus",
  [ETranslation.DEAL_PREVIEW_OFFER_IDENTIFIED_BANK]: "Tunnistettu",
};

const customersTranslations = {
  [ETranslation.TITLE_CUSTOMERS]: "Asiakkaat",
  [ETranslation.CUSTOMERS_ADD_NEW]: "Lisää uusi asiakas",
  [ETranslation.NO_CUSTOMERS]: "Ei asiakkaita.",
};

const customerTranslations = {
  [ETranslation.TITLE_CUSTOMER]: "Asiakas",
  [ETranslation.TITLE_CUSTOMER_NEW]: "Uusi asiakas",
  [ETranslation.CUSTOMER_NAME]: "Nimi",
  [ETranslation.CUSTOMER_LEVEL]: "Taso",
  [ETranslation.CUSTOMER_BUSINESS_ID]: "Y-tunnus",
  [ETranslation.CUSTOMER_PIPEDRIVE_ORGANIZATION_ID]: "Pipedrive",
  [ETranslation.CONTACT_PERSON_NAME]: "Yhteyshenkilön nimi",
  [ETranslation.CONTACT_PERSON_EMAIL]: "Yhteyshenkilön sähköposti",
  [ETranslation.CONTACT_PERSON_PHONE_NUMBER]: "Yhteyshenkilön puhelin",
};

const partnersTranslations = {
  [ETranslation.TITLE_PARTNERS]: "Kumppanit",
  [ETranslation.PARTNERS_ADD_NEW]: "Lisää uusi kumppani",
  [ETranslation.NO_PARTNERS]: "Ei kumppaneita.",
};

const partnerTranslations = {
  [ETranslation.TITLE_PARTNER]: "Kumppani",
  [ETranslation.TITLE_PARTNER_NEW]: "Uusi kumppani",
  [ETranslation.PARTNER_NAME]: "Nimi",
  [ETranslation.PARTNER_BUSINESS_ID]: "Y-tunnus",
  [ETranslation.PARTNER_DELETE_TITLE]: "Poista kumppani",
  [ETranslation.PARTNER_DELETE_TEXT]: "Oletko varma, että haluat poistaa kumppanin?",
};

const settingsTranslations = {
  [ETranslation.TITLE_SETTINGS]: "Asetukset",
  [ETranslation.SEND_PASSWORD_RESET_LINK]: "Vaihda salasana",
  [ETranslation.PASSWORD_RESET_TEXT]:
    "Oletko varma, että haluat lähettää salasanan palautuslinkin sähköpostiisi?",
  [ETranslation.TITLE_INTEGRATIONS]: "Integraatiot",
};

const reportsTranslations = {
  [ETranslation.TITLE_REPORTS]: "Raportit",
  [ETranslation.REPORTS_HANDOVER_DATE]: "Luovutuksen ajankohta",
  [ETranslation.REPORTS_HANDOVER_TOTAL]: "Kate yhteensä",
};

const usersTranslations = {
  [ETranslation.TITLE_USERS]: "Käyttäjät",
  [ETranslation.USERS_ADD_NEW]: "Lisää uusi käyttäjä",
  [ETranslation.NO_USERS]: "Ei käyttäjiä.",
  [ETranslation.USER_NAME]: "Nimi",
  [ETranslation.USER_PASSWORD]: "Salasana",
};

const userTranslations = {
  [ETranslation.TITLE_USER]: "Käyttäjä",
  [ETranslation.TITLE_USER_NEW]: "Uusi käyttäjä",
  [ETranslation.USER_FIRST_NAME]: "Etunimi",
  [ETranslation.USER_LAST_NAME]: "Sukunimi",
  [ETranslation.USER_EMAIL]: "Sähköposti",
  [ETranslation.USER_PHONE_NUMBER]: "Puhelinnumero",
  [ETranslation.USER_TITLE]: "Titteli",
  [ETranslation.USER_ALLOW_LOGIN]: "Salli kirjautuminen",
  [ETranslation.USER_PROFILE_IMAGE]: "Profiilikuva",
  [ETranslation.USER_SOCIAL_SECURITY_NUMBER]: "Henkilötunnus",
  [ETranslation.USER_ADDRESS]: "Kotiosoite",
};

const financeCompaniesTranslation = {
  [ETranslation.TITLE_FINANCE_COMPANIES]: "Rahoitusyhtiöt",
  [ETranslation.FINANCE_COMPANIES_ADD_NEW]: "Lisää uusi rahoitusyhtiö",
  [ETranslation.NO_FINANCE_COMPANIES]: "Ei rahoitusyhtiöitä.",
};

const financeCompanyTranslation = {
  [ETranslation.TITLE_FINANCE_COMPANY]: "Rahoitusyhtiö",
  [ETranslation.TITLE_FINANCE_COMPANY_NEW]: "Uusi rahoitusyhtiö",
  [ETranslation.FINANCE_COMPANY_NAME]: "Rahoitusyhtiö",
  [ETranslation.FINANCE_COMPANY_INTEREST_RATE]: "Korko",
  [ETranslation.FINANCE_COMPANY_INVOICING_FEE]: "Laskutuslisä",
  [ETranslation.FINANCE_COMPANY_OPENING_FEE]: "Avausmaksu",
  [ETranslation.FINANCE_COMPANY_REGISTRATION_INFO]: "Rekisteröintiohje",
  [ETranslation.FINANCE_COMPANY_INVOICE_PAYER]: "Laskun maksaja",
  [ETranslation.FINANCE_COMPANY_BILLING_INFORMATION]: "Laskutustiedot",
  [ETranslation.FINANCE_COMPANY_PAYMENT_TERM]: "Maksuehto",
  [ETranslation.FINANCE_COMPANY_REFERENCE_INFORMATION]: "Viitetiedot",
  [ETranslation.FINANCE_COMPANY_ACQUISITION_PERIOD]: "Hankintajakso",
  [ETranslation.FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_DAY]:
    "Seuraavan kuun ensimmäinen päivä",
  [ETranslation.FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_OR_15TH_DAY]:
    "Seuraavan kuun ensimmäinen päivä tai puolentoista kuukauden jälkeen",
};

const validationTranslations = {
  [ETranslation.VALIDATION_EMAIL]: "Tarkista sähköposti",
  [ETranslation.VALIDATION_MIN_LENGTH]:
    "Syötä vähintään {{minLength}} merkkiä.",
  [ETranslation.VALIDATION_MAX_LENGTH]: "Syötä enintään {{maxLength}} merkkiä.",
  [ETranslation.VALIDATION_MIN_AMOUNT]:
    "Luku ei voi olla pienempi kuin {{minAmount}}.",
  [ETranslation.VALIDATION_MAX_AMOUNT]:
    "Luku ei voi olla suurempi kuin {{maxAmount}}.",
  [ETranslation.VALIDATION_PHONE_NUMBER]:
    "Käytä kansainvälistä muotoa +358...",
};

const suffixTranslations = {
  [ETranslation.SUFFIX_MONTHS]: "kk",
  [ETranslation.SUFFIX_EUROS_PER_MONTH]: "€/kk",
  [ETranslation.SUFFIX_EUROS_PER_SET]: "€/sarja",
};

const dealershipsTranslations = {
  [ETranslation.TITLE_DEALERSHIPS]: "Autoliikkeet",
  [ETranslation.DEALERSHIP_ADD_NEW]: "Lisää uusi autoliike",
  [ETranslation.NO_DEALERSHIPS]: "Ei autoliikkeitä.",
};

const dealershipTranslations = {
  [ETranslation.TITLE_DEALERSHIP]: "Autoliike",
  [ETranslation.TITLE_DEALERSHIP_NEW]: "Uusi autoliike",
  [ETranslation.DEALERSHIP_NAME]: "Autoliikkeen nimi",
};

const reactSelectTranslations = {
  [ETranslation.REACT_SELECT_PLACEHOLDER]: "Valitse",
  [ETranslation.REACT_SELECT_ADD_NEW]: "Lisää",
};

const sortTranslations = {
  [ETranslation.TITLE_SORT]: "Järjestys",
};

const leasingModelTranslations = {
  [ETranslation.LEASING_MODEL_REILU]: "Reiluleasing",
  [ETranslation.LEASING_MODEL_HUOLTO]: "Huoltoleasing",
  [ETranslation.LEASING_MODEL_RAHOITUS]: "Rahoitusleasing",
};

const dropzoneTranslations = {
  [ETranslation.DROPZONE_TEXT_PDF]: "Pudota PDF tähän tai klikkaa valitaksesi.",
  [ETranslation.DROPZONE_DRAG_TEXT_PDF]: "Pudota PDF tähän.",
};

const pipedriveSuccessModalTranslations = {
  [ETranslation.PIPEDRIVE_SUCCESS_TITLE]: "Pipedrive",
  [ETranslation.PIPEDRIVE_SUCCESS_TEXT]:
    "Pipedrive-tilisi liittäminen onnistui.",
};

const dashboardTranslations = {
  [ETranslation.DASHBOARD_OFFERS]: "Tarjoukset",
  [ETranslation.DASHBOARD_CONTRACTS]: "Sopimukset",
  [ETranslation.DASHBOARD_PRECONTRACTS]: "Tilaussopimukset",
  [ETranslation.DASHBOARD_NO_OFFERS]: "Ei tarjouslaskelmia.",
  [ETranslation.DASHBOARD_LEASING_LIABILITIES_REPORT]:
    "Leasing vastuut -raportti",
  [ETranslation.DASHBOARD_REQUESTED_LIMIT]: "haettu limiitti alv 0%",
  [ETranslation.DASHBOARD_REMAINING_AMOUNT]: "jäljellä, alv 0%",
  [ETranslation.DASHBOARD_NO_LIMITS]: "Ei limiittejä.",
  [ETranslation.DASHBOARD_FEES]: "Kulut",
  [ETranslation.DASHBOARD_FEES_TOTAL]: "Kuukausierät yhteensä, alv 0%",
  [ETranslation.DASHBOARD_FEES_TOTAL_12]: "12kk kuukausierät yhteensä, alv 0%",
};

const dealAcceptTranslations = {
  [ETranslation.DEAL_ACCEPT_DIALOG_TITLE]: "Hyväksy sopimus",
  [ETranslation.DEAL_ACCEPT_DIALOG_CONTENT]:
    "Oletko varma, että hyväksyt sopimuksen?",
  [ETranslation.DEAL_ACCEPT_PRINT]: "Tulosta",
};

const dealSendTranslations = {
  [ETranslation.DEAL_SEND_TITLE]: "Lähetä tarjous",
  [ETranslation.DEAL_SEND_CONTENT]:
    "Oletko varma, että haluat lähettää tarjouksen?",
  [ETranslation.DEAL_SEND_RETURN_INFO_TITLE]: "Lähetä palautusohje",
  [ETranslation.DEAL_SEND_RETURN_INFO_CONTENT]: "Oletko varma, että haluat lähettää inCar palautusohjeen?",
  [ETranslation.DEAL_SEND_RETURN_INFO_LABEL]: "Muokkaa viestiä:",
  [ETranslation.DEAL_SEND_RETURN_INFO_RECIPIENTS]: "Vastaanottajat:",
  [ETranslation.DEAL_RETURN_INFO_BUTTON_TITLE]: "Lähetä inCar palautusohje",
  [ETranslation.DEAL_RETURN_INFO_TITLE]: "Palautusohje",
};

const dealOrderConfirmationTranslations = {
  [ETranslation.DEAL_ORDER_CONFIRMATION]: "Tilausvahvistus",
  [ETranslation.DEAL_ORDER_CONFIRMATION_PRINTED]: "Tulostettu",

  [ETranslation.DEAL_ORDER_CONFIRMATION_CAR_INFORMATION]: "Auton tiedot",
  [ETranslation.DEAL_ORDER_CONFIRMATION_MACHINE_INFORMATION]: "Koneen tiedot",
  [ETranslation.DEAL_ORDER_CONFIRMATION_REGISTRATION_INFO]: "Rekisteröintiohje",
  [ETranslation.DEAL_ORDER_CONFIRMATION_BILLING_DETAILS]: "Laskutusohjeet",

  [ETranslation.DEAL_ORDER_CONFIRMATION_BRAND]: "Merkki",
  [ETranslation.DEAL_ORDER_CONFIRMATION_MODEL]: "Malli",
  [ETranslation.DEAL_ORDER_CONFIRMATION_SALE_NUMBER]: "Tarjous nro",
  [ETranslation.DEAL_ORDER_CONFIRMATION_CO2_EMISSIONS]: "CO2 Päästöt",
  [ETranslation.DEAL_ORDER_CONFIRMATION_ACCESSORIES]: "Lisävarusteet",
  [ETranslation.DEAL_ORDER_CONFIRMATION_TOTAL_PRICE]: "Kokonaishinta, alv 0%",
  [ETranslation.DEAL_ORDER_CONFIRMATION_TOTAL_PRICE_VAT]:
    "Kokonaishinta, sis. alv",
  [ETranslation.DEAL_ORDER_CONFIRMATION_DISCOUNT]: "Alennus, sis. alv",
  [ETranslation.DEAL_ORDER_CONFIRMATION_CAR_TAX]: "Autoveron osuus",
  [ETranslation.DEAL_ORDER_CONFIRMATION_REGISTRATION_NUMBER]: "Rekisteri numero",
  [ETranslation.DEAL_ORDER_CONFIRMATION_SERIAL_NUMBER]: "Sarjanumero",

  [ETranslation.DEAL_ORDER_CONFIRMATION_OWNER]: "Omistaja",
  [ETranslation.DEAL_ORDER_CONFIRMATION_HOLDER_1]: "Haltija 1",
  [ETranslation.DEAL_ORDER_CONFIRMATION_HOLDER_2]: "Haltija 2",
  [ETranslation.DEAL_ORDER_CONFIRMATION_HOLDER_3]: "Haltija 3",
  [ETranslation.DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TITLE]:
    "Vakuutus",
  [ETranslation.DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TEXT_CAR]:
    "Autoliike perustaa vakuutuksen ja lähettää vahvistuksen osoitteeseen info@olf.fi",
  [ETranslation.DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TEXT_MACHINE]:
    "Asiakas perustaa vakuutuksen",
  [ETranslation.DEAL_ORDER_CONFIRMATION_INSURANCE_COMPANY]: "Vakuutusyhtiö",

  [ETranslation.DEAL_ORDER_CONFIRMATION_INVOICE_PAYER]: "Laskun maksaja",
  [ETranslation.DEAL_ORDER_CONFIRMATION_INVOICE_DELIVERY]: "Laskun toimitus",
  [ETranslation.DEAL_ORDER_CONFIRMATION_INVOICE_DELIVERY_TEXT]:
    "Lasku tulee toimittaa PDF-tiedostona sähköpostiosoitteeseen info@olf.fi tai invoice-29573216@kollektor.fi",
  [ETranslation.DEAL_ORDER_CONFIRMATION_PAYMENT_TERM_TITLE]: "Maksuehto",
  [ETranslation.DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TITLE]:
    "Viitetiedot",
  [ETranslation.DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TEXT_CAR]:
    "Laskun viitteeksi ajoneuvon rekisterinumero/asiakasyritys",
  [ETranslation.DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TEXT_MACHINE]:
    "Laskun viitteeksi koneen asiakasyritys",

  [ETranslation.DEAL_ORDER_CONFIRMATION_FIRST_PAYMENT_HELP_TITLE]:
    "Ensimmäinen erä, alv 0%",

  [ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TITLE]:
    "Käsirahan maksu",
  [ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TEXT]:
    "Leasingrahoituksen myöntämisen ehtona oleva asiakkaan käsirahasuoritus laskutetaan One Leasing Finland Oy:n toimesta ellei muusta järjestelystä ole erikseen sovittu.",
  [ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_CASH_OLF]:
    " €, maksetaan One Leasing Finlandille",
  [ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_CASH_SELLER]:
    " €, maksetaan myyjäliikkeelle",

  [ETranslation.DEAL_ORDER_CONFIRMATION_HANDOVER]: "Kohteen luovutus",
  [ETranslation.DEAL_ORDER_CONFIRMATION_HANDOVER_INFO]: "Luovutusohje",
  [ETranslation.DEAL_ORDER_CONFIRMATION_HANDOVER_INFO_TEXT]: 
  "Kohdetta ei saa luovuttaa asiakkaalle ilman One Leasing Finland Oy:n kirjallista lupaa. One Leasing Finland ei vastaa myyjälle aiheutuneesta vahingosta mikäli leasingsopimuksen aktivointi viivästyy tai peruuntuu luvattoman luovutuksen johdosta.",
  
  [ETranslation.DEAL_ORDER_CONFIRMATION_VALIDITY]: "Tilausvahvistuksen sitovuus",
  [ETranslation.DEAL_ORDER_CONFIRMATION_FINANCIAL_ELIGIBILITY]: "Asiakkaan rahoitus kelpoisuus",
  [ETranslation.DEAL_ORDER_CONFIRMATION_FINANCIAL_ELIGIBILITY_TEXT]: 
  "One Leasing Finlandilla on oikeus peruuttaa tilaus kustannuksitta mikäli leasingrahoitusta hakeneen asiakkaan luottokelpoisuudessa havaitaan olennainen heikentyminen tämän tilausvahvistuksen antamisen jälkeen tai rahoitusmarkkinoilla tapahtuu sellaisia muutoksia, jotka estävät leasingrahoituksen järjestämisen asiakkaalle.",
  [ETranslation.DEAL_ORDER_CONFIRMATION_CREDIT_DECISION]: "Luottopäätös",
  [ETranslation.DEAL_ORDER_CONFIRMATION_CREDIT_DECISION_TEXT]: 
  "Tilausta koskeva leasingrahoituksen luottopäätös ja vastaavasti tämä tilausvahvistus on voimassa 90 päivää. Mikäli tilauksen kohteen luovutus tapahtuu 90 päivän jälkeen, tilauksen voimassaolon jatkuminen edellyttää uuden myönteisen leasingrahoituspäätöksen.",
};

const residualValueLiabilityTranslations = {
  [ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY]: "Jäännösarvovastuu",
  [ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY_CUSTOMER]: "Vuokralleottaja",
  [ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY_OLF]: "One Leasing Finland Oy",
  [ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY_SELLER]: "Leasingkohteen myyjä",
};

const limitTranslations = {
  [ETranslation.LIMITS]: "Limiitit",
  [ETranslation.LIMIT]: "Limiitti",
  [ETranslation.LIMIT_FINANCE_COMPANY]: "Limiitin rahoitusyhtiö",
  [ETranslation.LIMIT_AMOUNT]: "Limiitin määrä alv 0%",
};

const invoicesTranslations = {
  [ETranslation.INVOICES_ATTACH_INVOICE_TITLE]: "Liitä sopimukseen",
  [ETranslation.INVOICES_HIDE_INVOICE_TITLE]: "Piilota",
  [ETranslation.INVOICES_HIDE_INVOICE_TEXT]:
    "Oletko varma, että haluat piilottaa laskun?",
};

const invoiceTranslations = {
  [ETranslation.NO_INVOICES]: "Ei laskuja.",
  [ETranslation.INVOICE_TOTAL_VAT_0]: "Alv 0%:",
  [ETranslation.INVOICE_TOTAL_VAT_10]: "Alv 10 %:",
  [ETranslation.INVOICE_TOTAL_VAT_24]: "sis. alv:",
  [ETranslation.INVOICE_TOTAL]: "Summa yhteensä:",

  [ETranslation.INVOICE_ROW_PRODUCT]: "Tuote",
  [ETranslation.INVOICE_ROW_QUANTITY]: "Määrä",
  [ETranslation.INVOICE_ROW_UNIT]: "Yksikkö",
  [ETranslation.INVOICE_ROW_UNIT_PRICE]: "Yks.hinta",
  [ETranslation.INVOICE_ROW_TOTAL]: "Veroton arvo",
  [ETranslation.INVOICE_ROW_VAT_PERCENT]: "Alv-%",
};

const attachInvoiceDialogTranslations = {
  [ETranslation.ATTACH_INVOICE_DEAL]: "Sopimus",
  [ETranslation.ATTACH_INVOICE_DEAL_SEARCH]: "Hae sopimusta",
};

const leasingLiabilitiesTranslations = {
  [ETranslation.LEASING_LIABILITIES_SHORT]: "Yhteensä 12kk, alv 0%",
  [ETranslation.LEASING_LIABILITIES_LONG]: "Yhteensä 12kk:n jälkeen, alv 0%",
};

const maintenanceBudgetStatusTranslations = {
  [ETranslation.MAINTENANCE_BUDGET_STATUS_TITLE]: "Huoltobudjetin tilanne",
  [ETranslation.MAINTENANCE_BUDGET_STATUS_TOTAL]: "Huoltobudjetti yhteensä",
  [ETranslation.MAINTENANCE_BUDGET_STATUS_USED]: "Huoltobudjetti käytetty",
  [ETranslation.MAINTENANCE_BUDGET_STATUS_REMAINING]: "Huoltobudjetti jäljellä",
};

const errorTranslations = {
  [ETranslation.TOKEN_ERROR]: "Kirjautumisvirhe.",
  [ETranslation.REFRESH_TOKEN_ERROR]: "Kirjautumisvirhe.",
  [ETranslation.FILE_UPLOAD_ERROR]: "Tiedoston lisääminen epäonnistui.",
  [ETranslation.IDENTIFIER_ERROR]: "Virheellinen tunniste.",
  [ETranslation.DATABASE_ERROR]: "Tietokantavirhe.",
  [ETranslation.DATA_EMPTY_ERROR]: "Virheellinen syöte.",
  [ETranslation.ORGANIZATION_ERROR]: "Kirjautumisvirhe.",
  [ETranslation.NO_ACCESS_ERROR]:
    "Sinulla ei ole käyttöoikeutta tai linkki on virheellinen. Ole hyvä ja tarkista linkki.",
  [ETranslation.NOT_FOUND_ERROR]: "Ei tuloksia.",
  [ETranslation.PIPEDRIVE_NOT_CONNECTED_ERROR]:
    "Tiliisi ei ole liitetty Pipedriveä.",
  [ETranslation.NO_CONTRACT_LENGTH_ERROR]:
    "Sopimuksen hyväksyminen epäonnistui, sopimuksen kesto oli tyhjä.",
  [ETranslation.SEARCH_QUERY_STRING_ERROR]: "Virheellinen syöte.",
  [ETranslation.API_ERROR_TITLE]: "Virhe tietoja hakiessa",
  [ETranslation.API_ERROR_TEXT]: "Tietojen haussa tapahtui virhe.",
  [ETranslation.API_ERROR_REQUIRED_FIELDS_MISSING_BILTEMA]: "Täytä auton rekisterinumero.",
  [ETranslation.API_ERROR_REQUIRED_FIELDS_MISSING_NETWHEELS]: "Täytä auton merkki, malli, vuosimalli ja kokonaishinta sekä rahoituslaskelman sopimuskilometrit ja sopimuspituus",
  [ETranslation.ARCHIVED_ERROR]:
    "Valitettavasti tarjous on arkistoitu, ota yhteyttä One Leasing Finlandin myyntiin osoitteessa: oneleasingfinland.fi/yhteystiedot",
};

const invoicePayerTranslations = {
  [ETranslation.INVOICE_PAYER_OLF]: "OLF",
  [ETranslation.INVOICE_PAYER_FINANCE_COMPANY]: "Rahoitusyhtiö",
};

const maintenanceBudgetReportTranslations = {
  [ETranslation.MAINTENANCE_BUDGET_REPORT_CHOOSE_DATE]: "Valitse pvm",
  [ETranslation.MAINTENANCE_BUDGET_REPORT_USE_END_DATE]: "Hae sopimuksen päättymispäivällä",
  [ETranslation.MAINTENANCE_BUDGET_REPORT_CHOOSE_END_DATE]: "Valitse päättymispvm",
};

const selectTranslations = {
  [ETranslation.SELECT_FINANCE_COMPANY_ID]: "Valitse rahoitusyhtiö",
  [ETranslation.SELECT_STATUS]: "Valitse tila",
  [ETranslation.SELECT_SELLER_ID]: "Valitse myyjä",
  [ETranslation.SELECT_CUSTOMER_ID]: "Valitse asiakas",
  [ETranslation.SELECT_TYPE]: "Valitse tyyppi",
};

const dealNoteTranslations = {
  [ETranslation.DEAL_NOTES]: "Sisäiset muistiinpanot",
  [ETranslation.DEAL_NOTE_TEXT]: "Muistiinpano",
  [ETranslation.DEAL_NOTES_NEW]: "Lisää uusi muistiinpano",
  [ETranslation.DEAL_NOTES_NEW_ERROR]: "Muistiinpano ei voi olla tyhjä.",
  [ETranslation.DEAL_NOTES_DELETE_TITLE]: "Poista muistiinpano",
  [ETranslation.DEAL_NOTES_DELETE_TEXT]:
    "Oletko varma, että haluat poistaa muistiinpanon?",
};

const editInvoiceTranslations = {
  [ETranslation.EDIT_INVOICE_TITLE]: "Muokkaa laskua",
  [ETranslation.EDIT_INVOICE_TEXT]: "",
}

const detachInvoiceTranslations = {
  [ETranslation.DETACH_INVOICE_TITLE]: "Poista lasku sopimukselta",
  [ETranslation.DETACH_INVOICE_TEXT]: "Oletko varma, että haluat poistaa laskun sopimukselta?",
}

const dealTerminateTranslations = {
  [ETranslation.DEAL_TERMINATE_FINAL_DEBT]: "Loppuvelka",
  [ETranslation.DEAL_TERMINATE_FINAL_DEBT_DATE]: "Loppuvelan pvm",
  [ETranslation.DEAL_TERMINATE_PURCHASE_PRICE]: "Ostohinta",
  [ETranslation.DEAL_TERMINATE_MAINTENANCE_BUDGET]: "Huoltobudjetti",
  [ETranslation.DEAL_TERMINATE_FUNDABLE_CAPITAL]: "Pääoma",
  [ETranslation.DEAL_TERMINATE_HANDLING_FEE]: "Käsittelykulu",
  [ETranslation.DEAL_TERMINATE_TERMINATION_FEE]: "Sopimuksen päättämiskustannus (alv. 0%)",
  [ETranslation.DEAL_TERMINATE_TERMINATION_FEE_VAT]: "Sopimuksen päättämiskustannus (sis. alv)",
  [ETranslation.DEAL_TERMINATE_TERMINATION_FEE_VAT_AMOUNT]: "Sopimuksen päättämiskustannus alv määrä",
  [ETranslation.DEAL_TERMINATE_UNDER_KILOMETERS]: "Alikilometrit",
  [ETranslation.DEAL_TERMINATE_UNDER_KILOMETER_CREDIT]: "Alikilometrihyvitys",
  [ETranslation.DEAL_TERMINATE_USED_KILOMETERS]: "Toteutuneet kilometrit",
  [ETranslation.DEAL_TERMINATE_EXTRA_KILOMETERS]: "Ylikilometrit",
  [ETranslation.DEAL_TERMINATE_EXTRA_KILOMETER_CHARGE]: "Ylikilometriveloitus",
  [ETranslation.DEAL_TERMINATE_MACHINE_ID]: "Tunnus",
}

const dealTerminationCalculationTranslations = {
  [ETranslation.DEAL_TERMINATION_CALCULATION_TITLE]: "Päättämislaskelma",
  [ETranslation.DEAL_TERMINATION_CALCULATION_SELLER_TITLE]: "Ostaja",
  [ETranslation.DEAL_TERMINATION_CALCULATION_CUSTOMER_TITLE]: "Vuokralleottaja",
  [ETranslation.DEAL_TERMINATION_CALCULATION_TARGET_TITLE]: "Kohde",
  [ETranslation.DEAL_TERMINATION_CALCULATION_ORGANIZATION]: "Yritys",
  [ETranslation.DEAL_TERMINATION_CALCULATION_ADDRESS]: "Osoite",
  [ETranslation.DEAL_TERMINATION_CALCULATION_PHONE]: "Puhelin",
  [ETranslation.DEAL_TERMINATION_CALCULATION_CITY]: "Paikkakunta",
  [ETranslation.DEAL_TERMINATION_CALCULATION_START_DATE]: "Sopimus alkaa",
  [ETranslation.DEAL_TERMINATION_CALCULATION_END_DATE]: "Sopimus päättyy",
  [ETranslation.DEAL_TERMINATION_CALCULATION_CONTRACT_KILOMETERS]: "Sopimus km",
  [ETranslation.DEAL_TERMINATION_CALCULATION_USED_KILOMETERS]: "Ajoneuvon kilometrilukema",
  [ETranslation.DEAL_TERMINATION_CALCULATION_RESIDUAL_VALUE]: "Jäännösarvovastuu",
  [ETranslation.DEAL_TERMINATION_CALCULATION_FINAL_DEBT]: "Rahoitusyhtiön loppuvelka",
  [ETranslation.DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET]: "Huoltobudjetti",
  [ETranslation.DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET_USED]: "Huoltobudjetti käytetty",
  [ETranslation.DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET_UNUSED]: "Huoltobudjetti toteuma",
  [ETranslation.DEAL_TERMINATION_CALCULATION_HANDLING_FEE]: "Käsittelykulu",
  [ETranslation.DEAL_TERMINATION_CALCULATION_TOTAL]: "Yhteensä",
  [ETranslation.DEAL_TERMINATION_CALCULATION_PURCHASE_PRICE]: "One Leasing Finland Oy ostohinta",
  [ETranslation.DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT]: "Sopimuksen päättämiskustannus (sis. alv)",
  [ETranslation.DEAL_TERMINATION_CALCULATION_TERMINATION_FEE]: "Sopimuksen päättämiskustannus (alv. 0%)",
  [ETranslation.DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT_AMOUNT]: "Summa sisältää arvonlisäveroa sis. alv",
  [ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_1]: "Hyväksymme yllä mainitun tarjouksen.",
  [ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_2]: "Ostotarjous on tehty olettamalla, että kaupankohdetta koskevat keskeiset seikat, joilla voidaan olettaa olevanvaikutusta autoliikkeen ostopäätökseen, kuten mahdollinen renkaiden uusinnan tarve, on saatettu ostajan tietoon.",
  [ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_3]: "*summasta vähennetään mahdolliset käsittelyssä olevat huoltolaskut",
  [ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_DATE]: "Päivämäärä",
  [ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_CUSTOMER_SIGNATURE]: "Vuokralleottajan allekirjoitus",
  [ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_CUSTOMER_SIGNATURE_CLARIFICATION]: "Nimen selvennys",
  [ETranslation.DEAL_TERMINATION_CALCULATION_FOOTER_SELLER_SIGNATURE]: "Ostajan allekirjoitus"
}

const dealMaintenanceInfoTranslations = {
  [ETranslation.DEAL_PRINT_MAINTENANCE_INFO_TITLE]: "Tulosta huoltosopimusohje",
  [ETranslation.DEAL_MAINTENANCE_DIALOG_TEXT]: "Ohjeet laskutusluvan kysymiseen",
  [ETranslation.DEAL_MAINTENANCE_INFO_TITLE]: "Leasingajoneuvon huoltosopimusohje",
  [ETranslation.DEAL_MAINTENANCE_INFO_CAR_OWNER]: "Ajoneuvon haltija:",
  [ETranslation.DEAL_MAINTENANCE_INFO_SERVICES]: "Ajoneuvollanne on leasingsopimukseen sisältyviä huolto- ja ylläpito palveluja seuraavasti:",
  [ETranslation.DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER]: "One Leasing Finland Oy:n hallinnoimien leasing-ajoneuvojen huolto- ja ylläpitopalvelut tuottaa",
  [ETranslation.DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER_OLF]: "One Fleet Oy.",
  [ETranslation.DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER_IMPORTER]: "maahantuoja.",
  [ETranslation.DEAL_MAINTENANCE_INFO_CONFIRM]: "Pyydämme ystävällisesti varmistamaan auton huolto-, rengas- tai sijaisautopalvelun laskutusluvan tilatulle palvelulle puhelimitse tai sähköpostitse One Fleet Oy:ltä.",
  [ETranslation.DEAL_MAINTENANCE_INFO_EINVOICE_INSTRUCTIONS]: "One Fleet Oy:n verkkolaskutusohjeet:",
  [ETranslation.DEAL_MAINTENANCE_INFO_EINVOICE]: "Verkkolaskuosoite:",
  [ETranslation.DEAL_MAINTENANCE_INFO_BROKER]: "Välittäjä:",
  [ETranslation.DEAL_MAINTENANCE_INFO_BROKER_ID]: "Välittäjätunnus:",
  [ETranslation.DEAL_MAINTENANCE_INFO_BROKER_ID_BANK]: "Välittäjätunnus pankkiverkosta lähetettäessä:",
  [ETranslation.DEAL_MAINTENANCE_INFO_EXTRA_INFO]: "Lisätiedot:",
}

const netsTranslations = {
  [ETranslation.NETS_EIDENT_TITLE]: "Nets tunnistautuminen",
  [ETranslation.NETS_EIDENT_SUCCESS]: "Tunnistautuminen onnistui",
  [ETranslation.NETS_EIDENT_FAIL]: "Tunnistautuminen epäonnistui",
};

const signatureDialogTranslations = {
  [ETranslation.DEAL_SIGNATURE_DIALOG_TEXT_1]: "Oletko varma, että haluat allekirjoittaa sopimuksen?",
  [ETranslation.DEAL_SIGNATURE_DIALOG_TEXT_2]: "Rahoituslaskelman valinnan jälkeen sinut ohjataan tunnistautumaan.",
  [ETranslation.DEAL_SIGNATURE_IS_AUTHORIZED]: "Vahvistan että minulla on nimenkirjoitus- ja tilausoikeus",
  [ETranslation.DEAL_CONFIRM_SIGNATURE_DIALOG_TEXT]: "Oletko varma, että haluat vahvistaa allekirjoituksen?",
};

const dealIdentificationsTranslations = {
  [ETranslation.DEAL_IDENTIFICATION_TITLE]: "Allekirjoitus",
  [ETranslation.DEAL_IDENTIFICATION_IDENTIFIED]: "Tunnistautunut",
  [ETranslation.DEAL_IDENTIFICATION_BANK_NAME]: "Käyttäjä tunnistautui pankissa",
}

const multiFactorAuthDialogTranslations = {
  [ETranslation.MULTI_FACTOR_AUTH_DIALOG_TITLE]: "Anna koodi",
  [ETranslation.MULTI_FACTOR_AUTH_DIALOG_CODE]: "Saat pian koodin tekstiviestillä.",
  [ETranslation.MULTI_FACTOR_AUTH_DIALOG_CODE_PLACEHOLDER]: "Koodi on 6 merkkiä pitkä",
}

const draftTranslations = {
  [ETranslation.DRAFT_ACCESSORIES]: "Rahoitettavan kohteen kuvaus",
  [ETranslation.DRAFT_TOTAL_PRICE_VAT]: "Kohteen hankintahinta (sis alv)",
  [ETranslation.DRAFT_EXCHANGE_DESCRIPTION]: "Vaihdossa luovutetavan kohteen kuvaus",
  [ETranslation.DRAFT_EXCHANGE_VALUE]: "Käsiraha / vaihdossa luovutetavan kohteen arvo",
  [ETranslation.DRAFT_GROSS_PROFIT]: "Oletettu kate",
  [ETranslation.DRAFT_ARCHIVE_CONFIRM]: "Haluatko arkistoida?",
  [ETranslation.DRAFT_UNARCHIVE_CONFIRM]: "Haluatko palauttaa?",
  [ETranslation.DRAFT_ACQUISITION]: "Hankinnan kohde",
  [ETranslation.DRAFT_VARIATION]: "Alustava rahoituslaskelma",
  [ETranslation.DRAFT_PARTNER]: "Kumppani",
  [ETranslation.DRAFT_ATTACHMENTS]: "Liitteet",
  [ETranslation.DRAFT_COPY]: "Vie tarjoukselle",
  [ETranslation.DRAFT_NOT_SAVED]: "Tallenna muokatut arvot ennen lähettämistä",
  [ETranslation.DRAFT_SAVED]: "Hakemus tallennettu",
  [ETranslation.DRAFT_RESIDUAL]: "Jäännösarvo / kohteen arvo sopimuskauden päättyessä",
}

const draftsTranslations = {
  [ETranslation.DRAFT_NEW]: "Lisää uusi laskelma",
  [ETranslation.DRAFTS_SENT]: "Lähetetyt rahoitushakemukset",
  [ETranslation.DRAFTS_START]: "Aloita luomalla uusi rahoituslaskelma. Voit lähettää rahoituslaskelman One Fleet Oy:lle käsiteltäväksi ja yhteyshenkilömme ottavat pian yhteyttä.",
  [ETranslation.DRAFTS_ARCHIVED_SEARCH]: "Näytä myös arkistoidut",
  [ETranslation.PARTNER_DEALS]: "Tilaussopimukset",
  [ETranslation.DRAFTS_PARTNER_ORGANIZATION]: "Asiakkaan yritys",
  [ETranslation.DRAFTS_DEAL_NAME]: "Sopimuksen nimi",
  [ETranslation.DRAFTS_PRICE]: "Hankinta hinta (€)",
  [ETranslation.DRAFTS_CONTRACT]: "Sopimuskauden pituus (kk)",
  [ETranslation.DRAFTS_NO_DEALS]: "Ei voimassa olevia sopimuksia."
}

const logItemsTranslations = {
  [ETranslation.LOGS]: "Lokit",
  [ETranslation.START_DATE]: "Alku pvm",
  [ETranslation.END_DATE]: "Loppu pvm",
  [ETranslation.ACTION]: "Tapahtuma",
  [ETranslation.LOG_FIELD_NAME]: "Kentän nimi",
  [ETranslation.LOG_OLD_VALUE]: "Vanha arvo",
  [ETranslation.LOG_NEW_VALUE]: "Uusi arvo",

  [ETranslation.LOG_TEXT]: "Teksti",
  [ETranslation.LOG_ACCEPTED]: "Hyväksytty",
  [ETranslation.LOG_TRUE]: "Kyllä",
  [ETranslation.LOG_FALSE]: "Ei",
  [ETranslation.LOG_NAME]: "Nimi",
  [ETranslation.LOG_CONDITION]: "Ehto",
  [ETranslation.LOG_ORGANIZATION]: "Asiakas",
  [ETranslation.LOG_EXTRA_FEE]: "Lisäkulu",
  [ETranslation.LOG_VALUE]: "Arvo",
}

const translationsEn = {
  ...commonTranslations,
  ...authTranslations,
  ...headerTranslations,
  ...dealsTranslations,
  ...dealTranslations,
  ...dealTypeTranslations,
  ...dealStatusTranslations,
  ...dealBasicServicesTranslations,
  ...dealMaintenanceServicesTranslations,
  ...dealAdditionalInformationTranslations,
  ...dealPreviewTranslations,
  ...customersTranslations,
  ...customerTranslations,
  ...partnersTranslations,
  ...partnerTranslations,
  ...settingsTranslations,
  ...reportsTranslations,
  ...usersTranslations,
  ...userTranslations,
  ...financeCompaniesTranslation,
  ...financeCompanyTranslation,
  ...validationTranslations,
  ...suffixTranslations,
  ...dealershipsTranslations,
  ...dealershipTranslations,
  ...reactSelectTranslations,
  ...sortTranslations,
  ...leasingModelTranslations,
  ...dropzoneTranslations,
  ...pipedriveSuccessModalTranslations,
  ...dashboardTranslations,
  ...dealAcceptTranslations,
  ...dealSendTranslations,
  ...dealOrderConfirmationTranslations,
  ...dealUserTranslations,
  ...residualValueLiabilityTranslations,
  ...limitTranslations,
  ...invoicesTranslations,
  ...invoiceTranslations,
  ...attachInvoiceDialogTranslations,
  ...leasingLiabilitiesTranslations,
  ...maintenanceBudgetStatusTranslations,
  ...errorTranslations,
  ...invoicePayerTranslations,
  ...maintenanceBudgetReportTranslations,
  ...selectTranslations,
  ...dealNoteTranslations,
  ...editInvoiceTranslations,
  ...detachInvoiceTranslations,
  ...dealTerminateTranslations,
  ...dealTerminationCalculationTranslations,
  ...dealMaintenanceInfoTranslations,
  ...netsTranslations,
  ...signatureDialogTranslations,
  ...dealIdentificationsTranslations,
  ...multiFactorAuthDialogTranslations,
  ...draftTranslations,
  ...draftsTranslations,
  ...logItemsTranslations,
};

export default translationsEn;
