import React, { useEffect, useState } from 'react';
//import { useTranslation } from 'react-i18next';
//import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';
import { usePartnerOptions } from '../../../hooks/usePartnerOptions';
import { useCustomerOptions } from '../../../hooks/useCustomerOptions';
import { IOption } from '../../UI/Input/Input';
import { usePrimaryOptions } from '../../../hooks/usePrimaryOptions';

interface IProps {
  setOrganizationId: React.Dispatch<React.SetStateAction<string>>;
  organizationId: string;
}

const OrganizationSearch: React.FC<IProps> = ({ setOrganizationId, organizationId }) => {

  const { customerOptions, customersLoading } = useCustomerOptions();
  const { partnerOptions, partnersLoading } = usePartnerOptions();
  const { primaryOptions, primaryLoading } = usePrimaryOptions();
  //const { t } = useTranslation();

   const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    let list = new Array<IOption>();
    if (customerOptions) list = list.concat(customerOptions);
    if (partnerOptions) list = list.concat(partnerOptions);
    if (primaryOptions) list = list.concat(primaryOptions);
    setOptions(list);
  }, [customerOptions, partnerOptions, primaryOptions]);

  return (
    <div>
      <label>Organisaatio</label>
      <Select
        inputName={"organizationSearch"}
        value={organizationId}
        onChange={setOrganizationId}
        options={options}
        isSearchable
        isClearable
        isLoading={customersLoading || partnersLoading || primaryLoading}
      />
    </div>
  )

}

export default OrganizationSearch;
