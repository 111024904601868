import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { IPipedriveDeal, IPipedriveDealAction } from "../../interfaces";
import { JSON_PIPEDRIVE_DEALS_GET_NEW } from "./../../endpoints/index";
import { ActionTypes } from "./actionTypes";

const getNewPipedriveDealStart = (): IPipedriveDealAction => {
  return {
    type: ActionTypes.GET_NEW_PIPEDRIVE_DEAL_START,
  };
};

const getNewPipedriveDealSuccess = (pipedriveDeal: IPipedriveDeal): IPipedriveDealAction => {
  return {
    type: ActionTypes.GET_NEW_PIPEDRIVE_DEAL_SUCCESS,
    pipedriveDeal,
  };
};

const getNewPipedriveDealFail = (error: string): IPipedriveDealAction => {
  return {
    type: ActionTypes.GET_NEW_PIPEDRIVE_DEAL_FAIL,
    error,
  };
};

export const getNewPipedriveDeal = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getNewPipedriveDealStart());
    try {
      const res = await axios.get(JSON_PIPEDRIVE_DEALS_GET_NEW(id));
      dispatch(getNewPipedriveDealSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getNewPipedriveDealFail((response as AxiosResponse).data));
      return null;
    }
  };
};
