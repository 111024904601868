import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EInputUpdateAction } from "../../../context/InputContext";
import { InputSuffix } from "../../../enums";
import { IFinanceCompany, IOrganizationLimit } from "../../../interfaces";
import { updateInputHandler } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../Button/Button";
import Input, { EInputType, IInputField, IInputOptions, IOption } from "../Input/Input";
import InputGroup from "../InputGroup/InputGroup";
import classes from "./Limit.module.scss";

export enum ELimit {
  FINANCE_COMPANY = "financeCompany",
  AMOUNT = "amount",
}

interface IProps {
  limit: IOrganizationLimit;
  financeCompanies: IFinanceCompany[];
  onRemove: (id: string) => void;
  onUpdate: (limit: IOrganizationLimit) => void;
}

const Limit: React.FC<IProps> = ({ limit, financeCompanies, onRemove, onUpdate }) => {
  const { t } = useTranslation();

  const mapOptions = (): IOption[] => {
    const options: IOption[] = [{ value: "", label: t(ETranslation.COMMON_NO_CHOICE) }];
    financeCompanies.forEach(f => {
      if (f.id) {
        options.push({ value: f.id, label: f.name });
      }
    });
    return options;
  }

  const [limitInputs, setLimitInputs] = useState<IInputField>({
    [ELimit.FINANCE_COMPANY]: {
      type: EInputType.select,
      labelTranslation: ETranslation.LIMIT_FINANCE_COMPANY,
      placeholderTranslation: ETranslation.LIMIT_FINANCE_COMPANY,
      options: mapOptions(),
      value: limit.financeCompany?.id || "",
    },
    [ELimit.AMOUNT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.LIMIT_AMOUNT,
      placeholderTranslation: ETranslation.LIMIT_AMOUNT,
      post: InputSuffix.EUROS,
      value: limit.amount,
    },
  });

  const createInput = (inputName: ELimit, options?: IInputOptions) => {
    const item = limitInputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.CUSTOMER_LIMIT}
      onChange={value => updateInputHandler(inputName, value, setLimitInputs)}
      onBlur={() => {
        // console.log("onBlur", limit.id);
        if (limit.id) {
          // if (inputName === ELimit.FINANCE_COMPANY) {
          //   const financeCompany = financeCompanies.find(f => value === f.id?.toString());
          //   onUpdate(limit.id, inputName, financeCompany);
          //   return;
          // }
          onUpdate(getData());
        }
      }}
      inputName={inputName}
    />
  };

  const getData = () => {
    const financeCompanyId = limitInputs[ELimit.FINANCE_COMPANY].value as string;
    let financeCompany = undefined;
    if (financeCompanyId) {
      financeCompany = financeCompanies.find(f => financeCompanyId === f.id?.toString());
    }
    const amount = limitInputs[ELimit.AMOUNT].value as number;
    const newLimit: IOrganizationLimit = {
      id: limit.id, financeCompany, amount
    };
    return newLimit;
  }

  return (
    <div className={classes.Limit}>
      <InputGroup>
        {createInput(ELimit.FINANCE_COMPANY)}
        {createInput(ELimit.AMOUNT)}
        <Button size={EButtonSize.SMALL} color={EButtonColor.SECONDARY} onClick={() => { if (limit.id) onRemove(limit.id) }} icon={faTrash} />
      </InputGroup>
    </div >
  )
};

export default Limit;
