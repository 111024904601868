import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import Table from "../../components/UI/Table/Table";
import { IAppState, IOrganization } from "../../interfaces";
import { Routes } from "../../classes/Routes";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import pipedriveLetterLogo from "../../assets/images/pipedrive_letter_logo.png";
import classes from "./Customers.module.scss";

interface IProps extends RouteComponentProps { }

const Customers: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { loading, error, customers } = useSelector((state: IAppState) => ({
    loading: state.customers.loading,
    error: state.customers.error,
    customers: state.customers.customers,
  }));

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.listCustomers());
  }, [dispatch]);

  const showCustomer = (id?: string) => {
    if (id) {
      history.push(Routes.CUSTOMER(id));
    }
  }

  const createCustomersTable = (customers: Array<IOrganization>) => {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>{t(ETranslation.CUSTOMER_NAME)}</th>
            <th>{t(ETranslation.CUSTOMER_BUSINESS_ID)}</th>
            <th>{t(ETranslation.CUSTOMER_PIPEDRIVE_ORGANIZATION_ID)}</th>
            <th>{t(ETranslation.COMMON_STREET_ADDRESS)}</th>
            <th>{t(ETranslation.COMMON_ZIP)}</th>
            <th>{t(ETranslation.COMMON_CITY)}</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <tr key={customer.id} onClick={() => showCustomer(customer.id)}>
              <td>{customer.name}</td>
              <td>{customer.businessId}</td>
              <td>{customer.pipedriveUrl && (
                <div className={classes.PipedriveLogo}>
                  <img src={pipedriveLetterLogo} alt="Pipedrive" onClick={(event) => {
                    event.stopPropagation();
                    window.open(customer.pipedriveUrl, "_blank");
                  }} />
                </div>
              )}</td>
              <td>{customer.streetAddress}</td>
              <td>{customer.zip}</td>
              <td>{customer.city}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{t(ETranslation.TITLE_CUSTOMERS)}</h2>
        <Button onClick={() => history.push(Routes.CUSTOMERS_NEW)} icon={faPlus} style={{ marginBottom: "1rem" }}>{t(ETranslation.CUSTOMERS_ADD_NEW)}</Button>
        {loading
          ? <Spinner />
          : customers
            ? createCustomersTable(customers)
            : <p>{t(ETranslation.NO_CUSTOMERS)}</p>
        }
      </Container>
    </>
  );
}

export default Customers;
