import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import Container from "../../components/UI/Container/Container";
import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState, IDeal } from "../../interfaces";
import Alert from "../../components/UI/Alert/Alert";
import Spinner from "../../components/UI/Spinner/Spinner";
import DealsTablePartner from "../../components/Deals/DealsTable/DealsTablePartner";
import { ETranslation } from "../../translations/translation-keys";

interface IProps extends RouteComponentProps { }

const PartnerDeals: React.FC<IProps> = ({history}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { error, loading, precontracts, contracts, terminates } = useSelector((state: IAppState) => ({
    error: state.deals.error,
    loading: state.deals.loading,
    precontracts: state.deals.precontracts,
    contracts: state.deals.contracts,
    terminates: state.deals.terminates,
  }));

  useEffect(() => {
    dispatch(actions.listPartnerDeals());
  }, [dispatch]);

  const showDeal = (deal?: IDeal) => {
    if (deal) {
      window.open(deal.link);
    }
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      {loading ? <Spinner/> : 
      <Container>
        {precontracts &&
        <>
          <h3>{t(ETranslation.PARTNER_DEALS)}</h3>
          <DealsTablePartner deals={precontracts} onClick={showDeal} showPartnerOrganization={true} dealView={true}/>
        </>
        }
        {contracts && 
        <>
          <h3>{t(ETranslation.DEALS)}</h3>
          <DealsTablePartner deals={contracts} onClick={showDeal} showPartnerOrganization={true} dealView={true}/>
        </>
        }
        {terminates &&
        <>
          <h3>{t(ETranslation.REPORTS_TERMINATED)}</h3>
          <DealsTablePartner deals={terminates} onClick={showDeal} showPartnerOrganization={true} dealView={true}/>
        </>
        }
        {!precontracts && !contracts && !terminates &&
          <p>{t(ETranslation.NO_DEALS)}</p>
        }
      </Container>
      }
    </>
  );
}

export default PartnerDeals;