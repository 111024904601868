import React from "react";
import { AuthUser } from "../classes/AuthUser";
import { IDeal, IDealExtraFee, IResidualValueRequest } from "../interfaces";
import { IInputField, IOption } from "./../components/UI/Input/Input";
import { IDealPricingVariation, IFinanceCompany } from "./../interfaces/domain";

const DealContext = React.createContext<{
  id: string | null;
  saveOrUpdateDeal: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    inputs: IInputField
  ) => Promise<void>;
  inputs: IInputField;
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>;
  currentUser?: AuthUser | null;
  deal?: IDeal | null;
  variations: IDealPricingVariation[];
  setVariations: React.Dispatch<React.SetStateAction<IDealPricingVariation[]>>;
  goBack: () => void;
  updateVariation: (pricingVariation: IDealPricingVariation) => void;
  removeVariation: (id: string) => void;
  copyVariation: (pricingVariation: IDealPricingVariation) => void;
  isValid: boolean;
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
  setDealHandoverDialog: (variation: IDealPricingVariation) => void;
  setDealConfirmModal: () => void;
  setDealSendDialog: () => void;
  isDisabled: boolean;
  // invoices: IInvoice[] | null;
  getDealData: () => IDeal | null;
  copyDeal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  archiveDeal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  customerOptions: IOption[];
  financeCompanyOptions: IOption[];
  dealershipOptions: IOption[];
  financeCompany: IFinanceCompany | null;
  setDealTerminateDialog: () => void;
  updateExtraFee: (fee: IDealExtraFee, setExtraFees: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>) => void;
  addExtraFee: (inputName: string, setExtraFees: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>) => void;
  removeExtraFee: (id: string, setExtraFees: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>) => void;
  copyExtraFee: (fee: IDealExtraFee, inputName: string, setExtraFees: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>) => void;
  additionalExpenses: IDealExtraFee[];
  setAdditionalExpenses: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>;
  showDealMaintenanceInfoDialog?: (link: string) => void;
  biltemaHandler: (registrationNumber: string) => void;
  netwheelsHandler: (request: IResidualValueRequest) => void;
}>({
  id: "new",
  saveOrUpdateDeal: () => Promise.reject(),
  inputs: {},
  setInputs: () => {},
  currentUser: null,
  deal: null,
  variations: [],
  setVariations: () => {},
  goBack: () => {},
  updateVariation: () => {},
  removeVariation: () => {},
  copyVariation: () => {},
  isValid: false,
  setValid: () => {},
  setDealHandoverDialog: () => {},
  setDealConfirmModal: () => {},
  setDealSendDialog: () => {},
  isDisabled: false,
  // invoices: [],
  getDealData: () => null,
  copyDeal: () => null,
  archiveDeal: () => null,
  customerOptions: [],
  financeCompanyOptions: [],
  dealershipOptions: [],
  financeCompany: null,
  setDealTerminateDialog: () => {},
  updateExtraFee: () => {},
  addExtraFee: () => {},
  removeExtraFee: () => {},
  copyExtraFee: () => {},
  additionalExpenses: [],
  setAdditionalExpenses: () => {},
  showDealMaintenanceInfoDialog: () => {},
  biltemaHandler: () => {},
  netwheelsHandler: () => {},
});

export default DealContext;
