import React, { ReactNode, CSSProperties } from "react";
import classes from "./InputGroup.module.scss";

interface IProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const InputGroup: React.FC<IProps> = ({ children, style, className }) => {
  const containerClassNames = [classes.Container];
  if (className) containerClassNames.push(className);
  return (
    <div className={containerClassNames.join(" ")} style={style}>
      {children}
    </div>
  )
}

export default InputGroup;
