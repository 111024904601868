import React, { useRef, useState } from 'react';

import { ICondition } from '../../../../interfaces';
import classes from './Condition.module.scss';

interface IProps {
  condition: ICondition;
  isActive: boolean;
  onChange: (value: ICondition) => void;
  name: string;
  disabled?: boolean;
  invalid?: boolean;
  inputClassName?: string;
  onRemove: (id: string) => Promise<void>;
}

const Condition: React.FC<IProps> = ({
  condition,
  isActive,
  name,
  disabled,
  invalid,
  onChange,
  inputClassName,
  onRemove
}) => {
  const [edit, setEdit] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const clickHandler = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (disabled) return;

    if (ref.current) {
      ref.current.click();
    }

    onChange({ ...condition, accepted: !condition.accepted });
  };

  const classNames = [classes.FakeInput];
  if (isActive) {
    classNames.push(classes.IsActive);
  }
  if (disabled) {
    classNames.push(classes.Disabled);
  }

  const containerClassNames = [classes.Container]
  if (invalid) {
    containerClassNames.push(classes.Invalid);
  }

  const labelChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const label = event && event.target && event.target.value;
    onChange({ ...condition, text: label, autoUpdate: false });
  }

  const keyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Enter / Esc https://keycode.info/
    if (event.keyCode === 13 || event.keyCode === 27) {
      setEdit(false);
      if (!condition.text) {
        onRemove(condition.id);
      }
    }
  }

  const editLabel = () => {
    if (disabled) {
      return;
    }
    setEdit(true);
  }

  let editField = edit && (
    <input className={inputClassName} type="text" value={condition.text} onChange={labelChanged} onKeyDown={keyPressed} autoFocus={edit} />
  )

  return (
    <div className={containerClassNames.join(' ')}>
      <div className={classes.CheckboxContainer}>
        <input
          className={classes.Input}
          name={name}
          type="checkbox"
          value={condition.id}
          checked={isActive}
          ref={ref}
          readOnly
        />
        <span className={classNames.join(" ")} onClick={clickHandler} />
      </div>
      <div className={classes.LabelContainer} onDoubleClick={editLabel}>
        {editField}
        {!edit && (
          <label className={classes.Label}>{condition.text}</label>
        )}
      </div>
    </div>
  );
};

export default Condition;
