import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { TDispatch } from "../..";
import { Routes } from "../../classes/Routes";
import DealsSearch from "../../components/Deals/DealsSearch/DealsSearch";
import DealsTable from "../../components/Deals/DealsTable/DealsTable";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAuthUser } from "../../hooks/useAuthUser";
import { IAppState, IDeal } from "../../interfaces";
import * as actions from "../../store/actions";
import { IDealsSearchOptions } from "../../store/actions/dealsActions";
import { ETranslation } from "../../translations/translation-keys";

interface IProps extends RouteComponentProps { }

const Deals: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch<TDispatch>();
  const authUser = useAuthUser();

  const { loading, error, deals } = useSelector((state: IAppState) => ({
    loading: state.deals.loading,
    error: state.deals.error,
    deals: state.deals.deals,
  }));

  const { t } = useTranslation();
  const [searchOptions, setSearchOptions] = useState<IDealsSearchOptions>();

  const searchHandler = useCallback((searchOptions) => {
    dispatch(actions.listDeals(searchOptions));
    setSearchOptions(searchOptions);
  }, [dispatch]);

  const showDeal = (deal?: IDeal) => {
    if (deal) {
      if (authUser?.isPrimary && deal.id) {
        history.push(Routes.DEAL(deal.id));
      }
      if (authUser?.isCustomer && deal.link) {
        window.open(deal.link);
      }
    }
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{t(ETranslation.TITLE_DEALS)}</h2>
        {authUser?.isPrimary && (
          <Button
            onClick={() => history.push(Routes.DEALS_NEW)}
            icon={faPlus}
            style={{ marginBottom: "1rem" }}
          >
            {t(ETranslation.DEALS_ADD_NEW)}
          </Button>
        )}
        <DealsSearch onSearch={searchHandler} />
        {loading ? (
          <Spinner />
        ) : deals ? (
          <DealsTable
            deals={deals}
            onClick={showDeal}
            sortable
            searchOptions={searchOptions}
          />
        ) : (
          <p>{t(ETranslation.NO_DEALS)}</p>
        )}
        {!searchOptions?.customerId &&
          !searchOptions?.financeCompanyId &&
          !searchOptions?.sellerId &&
          !searchOptions?.status &&
          !searchOptions?.type &&
          !searchOptions?.fetchAll && (
            <Button
              onClick={() => searchHandler({ fetchAll: true })}
              style={{ marginTop: "1rem" }}
            >
              {t(ETranslation.DEALS_FETCH_ALL)}
            </Button>
          )}
      </Container>
    </>
  );
};

export default Deals;
