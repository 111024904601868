import { IPipedriveDealAction, IPipedriveDealState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IPipedriveDealState = {
  loading: false,
  pipedriveDeal: null,
  error: null,
};

const getNewPipedriveDealStart = (
  state: IPipedriveDealState,
  action: IPipedriveDealAction
): IPipedriveDealState => {
  return updateObject(state, {
    pipedriveDeal: null,
    loading: true,
    error: null,
  });
};

const getNewPipedriveDealSuccess = (
  state: IPipedriveDealState,
  action: IPipedriveDealAction
): IPipedriveDealState => {
  return updateObject(state, {
    pipedriveDeal: action.pipedriveDeal,
    loading: false,
    error: null,
  });
};

const getNewPipedriveDealFail = (
  state: IPipedriveDealState,
  action: IPipedriveDealAction
): IPipedriveDealState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IPipedriveDealState = initialState,
  action: IPipedriveDealAction
): IPipedriveDealState => {
  switch (action.type) {
    case ActionTypes.GET_NEW_PIPEDRIVE_DEAL_START:
      return getNewPipedriveDealStart(state, action);
    case ActionTypes.GET_NEW_PIPEDRIVE_DEAL_SUCCESS:
      return getNewPipedriveDealSuccess(state, action);
    case ActionTypes.GET_NEW_PIPEDRIVE_DEAL_FAIL:
      return getNewPipedriveDealFail(state, action);
    default:
      return state;
  }
};

export default reducer;
