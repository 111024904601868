import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ESearchStorageKey } from "../../../enums";
import { IReportsSearchOptions } from "../../../store/actions/reportsActions";
import { ETranslation } from "../../../translations/translation-keys";
import CustomerSearch from "../../Searches/CustomerSearch/CustomerSearch";
import SearchContainer from "../../Searches/SearchContainer/SearchContainer";
import { IOption } from "../../UI/Input/Input";
import Select from "../../UI/Select/Select";

interface IProps {
  onSearch: (search: IReportsSearchOptions) => void;
  status: string;
};

const ReportsSearch: React.FC<IProps> = ({ onSearch, status }) => {
  const [isInit, setInit] = useState(false);
  const { t } = useTranslation();

  const [customerId, setCustomerId] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    setCustomerId(localStorage.getItem(ESearchStorageKey.REPORTS_CUSTOMER_ID) || '');
    setYear(localStorage.getItem(ESearchStorageKey.REPORTS_YEAR) || '');
    setInit(true);
  }, []);

  const mapYears = (): IOption[] => {
    let year = new Date().getFullYear();
    const options: IOption[] = [];

    for (let index = 0; index < 11; index++) {
      const yearString = (year + index).toString();
      options.push({ value: yearString, label: yearString });
    }

    return options;
  }

  useEffect(() => {
    if (isInit) {
      onSearch({ status, customerId, year });
      localStorage.setItem(ESearchStorageKey.REPORTS_CUSTOMER_ID, customerId);
      localStorage.setItem(ESearchStorageKey.REPORTS_YEAR, year);
    }
  }, [status, customerId, year, onSearch, isInit]);

  return (
    <SearchContainer>
      <CustomerSearch customerId={customerId} setCustomerId={setCustomerId} />
      <div>
        <label>{t(ETranslation.COMMON_YEAR)}</label>
        <Select
          inputName={"yearSearch"}
          value={year}
          onChange={setYear}
          options={mapYears()}
          isSearchable
          isClearable
          isCreatable={false}
        />
      </div>
    </SearchContainer>
  );
};

export default ReportsSearch;
