import React from "react";
import ReactDropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TDispatch } from "../../..";
import { IAppState, IAttachment } from "../../../interfaces";
import * as actions from "../../../store/actions";
import Alert from "../Alert/Alert";
import Spinner from "../Spinner/Spinner";
import DropzonePreview from "./DropzonePreview/DropzonePreview";

const classes = require("./Dropzone.module.scss");

// TODO: add content types if needed
export enum EContentType {
  PDF = "application/pdf",
}

export interface IDropzoneConfig {
  accept: EContentType;
  text?: string;
  dragText?: string;
  folder?: string;
}

interface IProps {
  className?: string;
  onChange: (attachments: IAttachment[] | IAttachment | null) => void;
  value: IAttachment[] | IAttachment | null;
  multiple?: boolean;
  config?: IDropzoneConfig;
}

const Dropzone: React.FC<IProps> = ({
  className,
  onChange,
  value,
  multiple,
  config = { accept: EContentType.PDF, dragText: "", text: "", folder: "" },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<TDispatch>();
  const { error, loading } = useSelector((state: IAppState) => ({
    // attachments: state.attachments.attachments,
    error: state.attachments.error,
    loading: state.attachments.loading,
  }));

  const onDropHandler = async (files: File[]) => {
    const formData = new FormData();
    for (let file of files) {
      // TODO: remove check if other files should be allowed
      if (file.type !== config.accept) return;
      formData.append("files[]", file);
    }

    if (!formData.has("files[]")) return;
    const newAttachments = await dispatch(
      actions.uploadAttachment(formData, config.folder)
    );
    if (!newAttachments) return;

    if (multiple) {
      const newValue = (value as IAttachment[]).concat(newAttachments);
      onChange(newValue);
    } else {
      onChange(newAttachments[0]);
    }
  };

  // useEffect(() => {
  //   console.log("dropzone onChange called:", attachments);
  //   if (!attachments) return;
  //   // eslint-disable-next-line
  // }, [attachments]);

  const onDeleteHandler = (
    event: React.MouseEvent<HTMLDivElement>,
    id: string
  ) => {
    event.stopPropagation();
    if (multiple) {
      const newValue = (value as IAttachment[]).filter(
        (attachment) => attachment.id !== id
      );
      onChange(newValue);
    } else {
      onChange(null);
    }
  };

  const renderPreview = (attachments: IAttachment[] | IAttachment | null) => {
    if (!attachments) return null;
    if (multiple) {
      return (attachments as IAttachment[]).map((attachment) => (
        <DropzonePreview
          key={attachment.id}
          attachment={attachment}
          onDelete={onDeleteHandler}
        />
      ));
    }
    return (
      <DropzonePreview
        attachment={attachments as IAttachment}
        onDelete={onDeleteHandler}
      />
    );
  };

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <ReactDropzone
        onDrop={onDropHandler}
        multiple={multiple}
        accept={config.accept}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div className={className || classes.Dropzone} {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={classes.Container}>
                {loading ? <Spinner /> : null}
                <p className={classes.Text}>
                  {isDragActive ? config.dragText : config.text}
                </p>
              </div>
              <div className={classes.PreviewContainer}>
                {renderPreview(value)}
              </div>
            </div>
          );
        }}
      </ReactDropzone>
    </>
  );
};

export default Dropzone;
