import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import pipedriveLetterLogo from "../../../../assets/images/pipedrive_letter_logo.png";
import DealContext from "../../../../context/DealContext";
import { IDeal } from "../../../../interfaces";
import { copyToClipboard } from "../../../../shared/utility";
import { ETranslation } from "../../../../translations/translation-keys";
import Container from "../../../UI/Container/Container";
import DealView from "../DealView/DealView";
import classes from "./DealPreview.module.scss";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {}

const DealPreview: React.FC<IProps> = ({history, location, match}) => {
  const {
    inputs, currentUser, setDealConfirmModal, setDealSendDialog, getDealData, copyDeal, showDealMaintenanceInfoDialog,
  } = useContext(DealContext);
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const [deal, setDeal] = useState<IDeal | null>();

  useEffect(() => {
    if (!inputs) return;
    setDeal(stateDeal => {
      const newDeal = getDealData();
      // console.log("useEffect", newDeal?.dealPricingVariations);
      if (!newDeal) return stateDeal;
      return newDeal;
    });
  }, [inputs, getDealData]);

  if (!inputs) {
    return null;
  }

  // const deal = getDealData();
  if (!deal) return null;

  const copyLinkHandler = () => {
    if (ref.current) {
      copyToClipboard(ref.current);
      alert(t(ETranslation.DEAL_PREVIEW_LINK_COPIED_TEXT));
    }
  };

  return (
    <Container>
      {deal?.link && (
        <div className={classes.LinkContainer}>
          <div className={classes.Link}>
            <span onClick={copyLinkHandler}>
              <FontAwesomeIcon icon={faCopy} />
            </span>
            <input
              className={classes.Input}
              type="text"
              value={deal?.link || ""}
              readOnly={true}
              ref={ref}
            />
          </div>
          {deal?.pipedriveDealLink && (
            <div className={classes.PipedriveLogo}>
              <img src={pipedriveLetterLogo} alt="Pipedrive" onClick={() => window.open(deal.pipedriveDealLink, "_blank")} />
            </div>
          )}
        </div>
      )}
      <DealView deal={deal} currentUser={currentUser} setDealSendDialog={setDealSendDialog} setDealConfirmModal={setDealConfirmModal} copyDeal={copyDeal} showDealMaintenanceInfoDialog={showDealMaintenanceInfoDialog} history={history} location={location} match={match} />
    </Container>
  );
};

export default DealPreview;
