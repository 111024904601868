import { fi } from 'date-fns/locale';
import React, { CSSProperties, useCallback, useContext, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import InputContext, { EInputUpdateAction } from "../../../context/InputContext";
import { IAttachment, ICondition, IDealExtraFee, IFinanceCompany } from '../../../interfaces';
import { ETranslation } from "../../../translations/translation-keys";
import AutoTextArea from '../AutoTextArea/AutoTextArea';
import ConditionList from '../ConditionList/ConditionList';
import Dropzone, { IDropzoneConfig } from '../Dropzone/Dropzone';
import RadioButton from "../RadioButton/RadioButton";
import SearchSelect, { TFetchOption } from '../SearchSelect/SearchSelect';
import Select from '../Select/Select';
import classes from "./Input.module.scss";
import Spinner, { ESpinnerSize } from '../Spinner/Spinner';

export enum EInfoColor {
  DANGER
}

export interface IOption {
  value: string;
  label?: string;
  labelTranslation?: ETranslation;
}

export enum EInputType {
  static = "static",
  text = "text",
  number = "number",
  radio = "radio",
  date = "date",
  time = "time",
  email = "email",
  tel = "tel",
  select = "select",
  checkbox = "checkbox",
  textarea = "textarea",
  datepicker = "datepicker",
  password = "password",
  conditionList = "conditionList",
  reactSelect = "reactSelect",
  dropzone = "dropzone",
  searchSelect = "searchSelect",
}

export interface IValidSamples {
  [key: string]: boolean;
}

export interface IValidationResult {
  isValid: boolean;
  message: ETranslation | null;
  messageParams?: {
    [key: string]: string;
  };
}

export interface IInputValidation {
  required?: boolean;
  requiredCompareValue?: string;
  requiredIf?: string;
  requiredIfValue?: string | string[];
  requiredIfNot?: string;
  dependencies?: string[];
  minLength?: number;
  minLengthMessage?: ETranslation;
  maxLength?: number;
  maxLengthMessage?: ETranslation;
  minAmount?: number;
  minAmountMessage?: ETranslation;
  maxAmount?: number;
  maxAmountMessage?: ETranslation;
}

export interface IInputFieldItem {
  type: EInputType;
  label?: string;
  labelTranslation?: ETranslation;
  value: TInputValue;
  options?: IOption[];
  placeholder?: string;
  placeholderTranslation?: ETranslation;
  disabled?: boolean;
  validation?: IInputValidation;
  validationResult?: IValidationResult;
  max?: string;
  min?: string;
  onAdd?: () => void;
  pre?: string;
  post?: string;
  multiple?: boolean;
  config?: IDropzoneConfig;
  readOnly?: boolean;
}

export interface IInputField {
  [key: string]: IInputFieldItem;
}

export interface IInputData {
  id?: string;
}

export type TInputValue =
  | string
  | string[]
  | number
  | Date
  | ICondition
  | ICondition[]
  | IAttachment[]
  | IAttachment
  | IDealExtraFee[]
  | IFinanceCompany
  | IOption
  | IOption[]
  | boolean
  | null
  | undefined;

export interface IInputOptions {
  updateAction?: EInputUpdateAction;
  weight?: number;
  info?: string;
  topInfo?: string | JSX.Element;
  infoColor?: EInfoColor;
  data?: IInputData;
  disabled?: boolean;
  max?: string;
  min?: string;
  showLabel?: boolean;
  containerStyles?: CSSProperties;
  showValidation?: boolean;
  onAdd?: () => void;
  onRemove?: (id: string) => Promise<void>;
  onSetValidSample?: React.Dispatch<React.SetStateAction<IValidSamples>>;
  onSetValid?: React.Dispatch<React.SetStateAction<boolean>>;
  onBlurModifyValue?: (value: TInputValue) => TInputValue;
  onBlur?: (value: TInputValue) => void;
  onClick?: () => void;
  hideUnselected?: boolean;
  pre?: string;
  post?: string;
  options?: IOption[];
  multiple?: boolean;
  config?: IDropzoneConfig;
  fetchOptions?: TFetchOption;
  labelTranslation?: ETranslation;
  placeholderTranslation?: ETranslation;
  onConditionChange?: (condition: ICondition) => void;
  loading?: boolean;
}

interface IProps extends IInputOptions {
  label?: string;
  labelTranslation?: ETranslation;
  value: TInputValue;
  type: EInputType;
  validationResult?: IValidationResult;
  valid?: boolean;
  onChange: (value: TInputValue) => void;
  inputName: string;
  placeholder?: string;
  placeholderTranslation?: ETranslation;
  onConditionChange?: (condition: ICondition) => void;
  readOnly?: boolean;
  validation?: IInputValidation;
}

export const isInputDisabled = (
  disableFields: boolean,
  item: IInputFieldItem,
  options?: IInputOptions
) => {
  return disableFields || item.disabled || (options && options.disabled);
};

export const inputsToObject = function <T>(inputs: IInputField): T {
  const result: { [key: string]: TInputValue } = {};
  for (let key in inputs) {
    result[key] = inputs[key].value;
  }
  return (result as unknown) as T;
};

const Input: React.FC<IProps> = ({
  label,
  labelTranslation,
  value,
  onChange,
  type,
  options,
  weight,
  inputName,
  info,
  topInfo,
  infoColor,
  placeholder,
  placeholderTranslation,
  validationResult = { isValid: true, message: "", messageParams: {} },
  data,
  disabled,
  max,
  min,
  showLabel,
  updateAction,
  containerStyles,
  showValidation,
  pre,
  post,
  onClick,
  hideUnselected,
  onAdd = () => console.log("onAdd needs to be passed as parameter"),
  onRemove = () =>
    Promise.reject(console.log("onRemove needs to be passed as parameter")),
  onSetValidSample = () =>
    console.log("onSetValidSample needs to be passed as paramater"),
  onSetValid = () => console.log("onSetValid needs to be passed as paramater"),
  onBlurModifyValue,
  onBlur,
  onConditionChange,
  multiple,
  config,
  fetchOptions,
  readOnly,
  validation,
  loading,
}) => {
  //console.log("inputUpdated", inputName)
  const { t } = useTranslation();
  const { onAutoUpdate, initDone } = useContext(InputContext);
  const [initValue, setInitValue] = useState(value);

  useEffect(() => {
    if (initDone) {
      setInitValue(value);
    }
    // eslint-disable-next-line
  }, [initDone, setInitValue]);

  const autoUpdateHandler = (value: TInputValue) => {
    if (onBlur) {
      onBlur(value);
    }

    if (onBlurModifyValue) {
      value = onBlurModifyValue(value);
    }

    if (initValue !== value) {
      if (updateAction && onAutoUpdate) {
        onAutoUpdate(inputName, value, updateAction, data); // Maybe needs some checks here if context missing.
      }
      setInitValue(value);
    }
  };

  const singleChangeHandler = (value: TInputValue) => {
    onChange(value);
    autoUpdateHandler(value);
  };

  const conditionSingleChangeHandler = (condition: ICondition) => {
    if (onConditionChange) {
      onConditionChange(condition);
    }
  };

  const infoClasses = [classes.Info];

  switch (infoColor) {
    case EInfoColor.DANGER:
      infoClasses.push(classes.InfoDanger);
      break;
    default:
  }

  const getLabel = useCallback(
    (inputName: string, label?: string, showLabel?: boolean, labelTranslation?: ETranslation, required?: boolean) => {
      if (!label && !showLabel && !labelTranslation) return null;
      return (
        <label className={classes.Label} htmlFor={inputName}>
          {labelTranslation ? t(labelTranslation) : label}{required ? "*" : ""}{" "}
          {showLabel && <>&nbsp;</>}
        </label>
      );
    },
    [t]
  );

  const invalid = showValidation && !validationResult.isValid && !disabled;
  const invalidMessage =
    showValidation && !validationResult.isValid && validationResult.message;

  const classNames = [classes.Container];
  if (invalid) {
    classNames.push(classes.Invalid);
  }

  const inputGroupClassNames = [classes.InputGroup];
  if (pre) {
    inputGroupClassNames.push(classes.HasPre);
  }
  if (post) {
    inputGroupClassNames.push(classes.HasPost);
  }

  return (
    <div
      className={classNames.join(" ")}
      style={{ flexGrow: weight, ...containerStyles }}
    >
      {getLabel(inputName, label, showLabel, labelTranslation, validation?.required)}
      {topInfo && <div className={classes.TopInfo}>{topInfo}</div>}
      <div className={inputGroupClassNames.join(" ")}>
        {pre && <div className={classes.Pre}>{pre}</div>}
        {(() => {
          if (loading) {
            return (
              <div className={classes.Input}>
                <Spinner size={ESpinnerSize.SMALL} />
              </div>
            );
          }
          
          switch (type) {
            case EInputType.text:
            case EInputType.number:
            case EInputType.date:
            case EInputType.time:
            case EInputType.email:
            case EInputType.tel:
            case EInputType.password:
              return (
                <input
                  className={classes.Input}
                  disabled={disabled}
                  type={type}
                  value={value as string}
                  placeholder={placeholderTranslation ? t(placeholderTranslation) : placeholder ? placeholder : label}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                  onBlur={() => autoUpdateHandler(value)}
                  onFocus={readOnly ? (e) => e.target.removeAttribute("readonly") : undefined}
                  readOnly={readOnly}
                />
              );
            case EInputType.select:
              return (
                <select
                  disabled={disabled}
                  className={classes.Input}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    onChange(event.target.value)
                  }
                  onBlur={() => autoUpdateHandler(value)}
                  value={value as string}
                >
                  {options &&
                    options.map(option => (
                      <option key={option.value} value={option.value}>
                        {" "}
                        {option.labelTranslation ? t(option.labelTranslation) : option.label}
                      </option>
                    ))}
                </select>
              );
            case EInputType.radio:
              return (
                <RadioButton
                  value={value as string}
                  options={options}
                  onChange={singleChangeHandler}
                  disabled={disabled}
                  invalid={invalid}
                />
              );
            case EInputType.checkbox:
              return (
                <input
                  name={inputName}
                  id={inputName}
                  type="checkbox"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => singleChangeHandler(event.target.checked)}
                  checked={value as boolean}
                  disabled={disabled}
                />
              );
            case EInputType.textarea:
              return (
                <AutoTextArea
                  className={classes.Input}
                  value={value as string}
                  placeholder={placeholderTranslation ? t(placeholderTranslation) : placeholder ? placeholder : label}
                  onChange={(value) =>
                    onChange(value)
                  }
                  onBlur={() => autoUpdateHandler(value)}
                  disabled={disabled}
                />
              );
            case EInputType.static:
              return (
                <p style={{ margin: 0 }}>{value}</p>
              );
            case EInputType.datepicker:
              value = value ? new Date(value as string) : undefined;
              return (
                <ReactDatePicker
                  className={['form-control', classes.Input].join(' ')}
                  selected={value as Date}
                  onChange={date => onChange(date)}
                  onBlur={() => autoUpdateHandler(value)}
                  dateFormat="dd.MM.yyyy"
                  locale={fi}
                  isClearable={!disabled}
                  placeholderText={placeholderTranslation ? t(placeholderTranslation) : placeholder ? placeholder : label}
                  disabled={disabled}
                />
              );
            case EInputType.conditionList:
              return (
                <ConditionList
                  name={inputName}
                  value={value as ICondition[]}
                  onChange={conditionSingleChangeHandler}
                  disabled={disabled}
                  invalid={invalid}
                  onAdd={onAdd}
                  inputClassName={classes.Input}
                  onRemove={onRemove}
                />
              );
            case EInputType.reactSelect:
              return (
                <Select
                  inputName={inputName}
                  value={value as string}
                  onChange={singleChangeHandler}
                  // onInputChange={value => singleChangeHandler(value as string)}
                  options={options}
                  disabled={disabled}
                  invalid={invalid}
                  // className={classes.Input}
                  isSearchable
                  isClearable={!disabled}
                  isCreatable
                // isLoading
                />
              );
            case EInputType.dropzone:
              return (
                <Dropzone
                  className={classes.Input}
                  onChange={(attachments: IAttachment[] | IAttachment | null) => {
                    singleChangeHandler(attachments)
                  }}
                  value={value as IAttachment[] | IAttachment | null}
                  multiple={multiple}
                  config={config}
                />
              );
            case EInputType.searchSelect:
              return (
                <SearchSelect
                  inputName={inputName}
                  value={value as IOption | IOption[]}
                  onChange={(value) => singleChangeHandler(value)}
                  fetchOptions={fetchOptions}
                  options={options || []}
                  multiple={multiple}
                  placeholder={placeholder}
                  disabled={disabled}
                />
              );
          }
        })()}
        {post && <div className={classes.Post}>{post}</div>}
      </div>
      {info && <div className={infoClasses.join(" ")}>{info}</div>}
      {invalidMessage && (
        <div className={[classes.Info, classes.InfoDanger].join(" ")}>
          {t(invalidMessage, {
            ...(validationResult.messageParams
              ? validationResult.messageParams
              : {}),
          })}
        </div>
      )}
    </div>
  );
};

export default React.memo(Input, (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.validationResult === nextProps.validationResult &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.showValidation === nextProps.showValidation &&
    prevProps.options === nextProps.options &&
    prevProps.min === nextProps.min &&
    prevProps.info === nextProps.info &&
    prevProps.topInfo === nextProps.topInfo &&
    prevProps.pre === nextProps.pre &&
    prevProps.labelTranslation === nextProps.labelTranslation &&
    prevProps.placeholderTranslation === nextProps.placeholderTranslation &&
    prevProps.onAdd === nextProps.onAdd &&
    prevProps.loading === nextProps.loading
  );
});
