import { fi } from "date-fns/locale";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import axios from "../../../axios";
import { Routes } from "../../../classes/Routes";
import CompactTable from "../../../components/UI/CompactTable/CompactTable";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { IMaintenanceBudgetReport } from "../../../interfaces";
import { dateToString, toTwoDecimals } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import classes from "./MaintenanceBudgetReport.module.scss";
import Button from "../../../components/UI/Button/Button";

interface IProps extends RouteComponentProps {}

const MaintenanceBudgetReport: React.FC<IProps> = ({ location, history }) => {
  const isHuolto = useMemo(
    () => location.pathname === Routes.MAINTENANCE_LEASING_REPORT,
    [location.pathname]
  );

  const dateParam = useMemo((): string => {
    const params = new URLSearchParams(location.search);
    return params.get("date") as string;
  }, [location.search]);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<IMaintenanceBudgetReport[] | null>();
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [selectedDateHuolto, setSelectedDateHuolto] = useState<Date | null>();
  const [useEndDate, setUseEndDate] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>();

  // When switching between views
  useEffect(() => {
    setLoading(false);
    setReports(null);
    setUseEndDate(false);
    setEndDate(null);
  }, [isHuolto, dateParam]);

  let date = useMemo(
    () =>
      (!isHuolto && selectedDate) ? dateToString(selectedDate) : 
      (isHuolto && selectedDateHuolto) ? dateToString(selectedDateHuolto) :
      dateParam ? dateParam : null,
    [dateParam, isHuolto, selectedDate, selectedDateHuolto]
  );

  const searchHandler = useCallback(() => {
    if (!date) {
      setReports(null);
      return;
    }
    setLoading(true);
    axios
      .post(`/json/reports/maintenancebudget`, {
        date: date,
        maintenance: isHuolto,
        endDate: endDate ? dateToString(endDate) : null,
      })
      .then((res) => {
        if (res.data) {
          setReports(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error instanceof Error) {
          throw error;
        } else {
          throw new Error(error);
        }
      });
  }, [date, isHuolto, endDate]);

  const showDeal = useCallback(
    (id: string) => {
      history.push(Routes.DEAL(id));
    },
    [history]
  );

  const createReportsTable = useCallback(
    (reports: IMaintenanceBudgetReport[]) => {
      if (!reports) return null;
      let totalMaintenanceBudget = 0;
      let totalUsed = 0;
      let totalRemaining = 0;

      reports.forEach((report) => {
        totalMaintenanceBudget += report.maintenanceBudget || 0;
        totalUsed += report.used || 0;
        totalRemaining += report.remaining || 0;
      });

      return (
        <div className={classes.TableContainer}>
          <CompactTable
            containerClassName={classes.Scroll}
            className={classes.Table}
          >
            <thead>
              <tr>
                <th>{t(ETranslation.DEAL_CUSTOMER)}</th>
                <th>{t(ETranslation.DEAL_BRAND)}</th>
                <th>{t(ETranslation.DEAL_REGISTRATION_NUMBER)}</th>
                <th>{t(ETranslation.MAINTENANCE_BUDGET_STATUS_USED)} €</th>
                <th>{t(ETranslation.MAINTENANCE_BUDGET_STATUS_REMAINING)} €</th>
                <th>{t(ETranslation.MAINTENANCE_BUDGET_STATUS_TOTAL)} €</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => (
                <tr
                  className={classes.Report}
                  key={report.dealId}
                  onClick={() => showDeal(report.dealId)}
                >
                  <td>{report.customerName}</td>
                  <td>{report.brand}</td>
                  <td>{report.registrationNumber}</td>
                  <td>{report.used && toTwoDecimals(report.used)}</td>
                  <td>{report.remaining && toTwoDecimals(report.remaining)}</td>
                  <td>
                    {report.maintenanceBudget &&
                      toTwoDecimals(report.maintenanceBudget)}
                  </td>
                </tr>
              ))}
            </tbody>
          </CompactTable>
          <CompactTable
            containerClassName={classes.Scroll}
            className={classes.Table}
          >
            <thead>
              <tr>
                <th>{t(ETranslation.MAINTENANCE_BUDGET_STATUS_USED)} €</th>
                <th>{t(ETranslation.MAINTENANCE_BUDGET_STATUS_REMAINING)} €</th>
                <th>{t(ETranslation.MAINTENANCE_BUDGET_STATUS_TOTAL)} €</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{toTwoDecimals(totalUsed)}</td>
                <td>{toTwoDecimals(totalRemaining)}</td>
                <td>{toTwoDecimals(totalMaintenanceBudget)}</td>
              </tr>
            </tbody>
          </CompactTable>
        </div>
      );
    },
    [showDeal, t]
  );

  return (
    <div className={classes.Container}>
      <div>
        <h2>
          {isHuolto
            ? t(ETranslation.REPORTS_MAINTENANCE_LEASING)
            : t(ETranslation.REPORTS_MAINTENANCE_BUDGET)}{" "}
          {date}
        </h2>
        <div className={classes.Inputs}>
          <ReactDatePicker
            className={classes.Input}
            selected={isHuolto ? selectedDateHuolto : selectedDate}
            onChange={(date) => isHuolto ? setSelectedDateHuolto(date) : setSelectedDate(date)}
            dateFormat="dd.MM.yyyy"
            locale={fi}
            isClearable={true}
            placeholderText={t(
              ETranslation.MAINTENANCE_BUDGET_REPORT_CHOOSE_DATE
            )}
          />
          <label className={classes.Checkbox}>
            <input type="checkbox" onChange={() => setUseEndDate(!useEndDate)} checked={useEndDate} />{' '}{t(ETranslation.MAINTENANCE_BUDGET_REPORT_USE_END_DATE)}
          </label>
          {useEndDate && (
            <ReactDatePicker
              className={classes.Input}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd.MM.yyyy"
              locale={fi}
              isClearable={true}
              placeholderText={t(ETranslation.MAINTENANCE_BUDGET_REPORT_CHOOSE_END_DATE)}
            />
          )}
          <Button onClick={searchHandler}>{t(ETranslation.SEARCH)}</Button>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : reports ? (
        createReportsTable(
          reports.sort((a, b) =>
            (a.customerName || 0) > (b.customerName || 0) ? 1 : -1
          )
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default MaintenanceBudgetReport;
