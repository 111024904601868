import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EInputUpdateAction } from "../../../../../context/InputContext";
import { EDealStatus } from "../../../../../enums";
import { updateInputHandler, validateInputs } from "../../../../../shared/utility";
import { ETranslation } from "../../../../../translations/translation-keys";
import Button from "../../../../UI/Button/Button";
import Input, { EInputType, IInputField, IInputOptions } from "../../../../UI/Input/Input";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import ModalBody from "../../../../UI/Modal/ModalBody/ModalBody";
import ModalContext from "../../../../UI/Modal/ModalContext";
import ModalFooter from "../../../../UI/Modal/ModalFooter/ModalFooter";

interface IProps {
  updateStatus: EDealStatus;
  updateDealStatus: (status: EDealStatus, date: Date, notes?: string, id?: string) => void;
}

export enum EDealTerminateDialog {
  terminatedDate = "terminatedDate",
  teminatedNotes = "terminatedNotes",
}

const DealTerminateDialog: React.FC<IProps> = ({ updateStatus, updateDealStatus }) => {
  const { t } = useTranslation();
  const [isValid, setValid] = useState(false);
  const { closeModal } = useContext(ModalContext);

  const [inputs, setInputs] = useState<IInputField>({
    [EDealTerminateDialog.terminatedDate]: {
      type: EInputType.datepicker,
      labelTranslation: ETranslation.DEAL_TERMINATED_DATE,
      placeholderTranslation: ETranslation.DEAL_TERMINATED_DATE,
      value: null,
      validation: {
        required: true,
      }
    },
    [EDealTerminateDialog.teminatedNotes]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.DEAL_TERMINATED_NOTES,
      placeholderTranslation: ETranslation.DEAL_TERMINATED_NOTES,
      value: "",
    },
  });

  const createInput = (inputName: EDealTerminateDialog, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.DEAL_CONFIRM_DIALOG}
      onChange={value => {
        updateInputHandler(inputName, value, setInputs);
      }}
      inputName={inputName}
      showValidation={!isValid}
    />
  };

  useEffect(() => {
    setValid(validateInputs(inputs));
  }, [inputs]);

  return (
    <React.Fragment>
      <ModalBody style={{ display: "flex", flexDirection: "column" }}>
        <p>{t(ETranslation.DEAL_TERMINATE_DIALOG_TEXT)}</p>
        {createInput(EDealTerminateDialog.terminatedDate)}
        {createInput(EDealTerminateDialog.teminatedNotes)}
      </ModalBody>
      <ModalFooter>
        <InputGroup>
          <Button
            disabled={!isValid}
            onClick={() =>
              updateDealStatus(updateStatus, inputs[EDealTerminateDialog.terminatedDate].value as Date, inputs[EDealTerminateDialog.teminatedNotes].value as string)
            }
          >
            {t(ETranslation.COMMON_YES)}
          </Button>
          <Button
            onClick={() => closeModal()}
          >
            {t(ETranslation.COMMON_NO)}
          </Button>
        </InputGroup>
      </ModalFooter>
    </React.Fragment>
  );
}

export default DealTerminateDialog;
