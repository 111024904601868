import { useDispatch } from 'react-redux';
import { IOrganization } from '../interfaces/domain';
import { useCallback } from 'react';
import { ETranslation } from '../translations/translation-keys';
import { useTranslation } from 'react-i18next';
import { IAppState, IAuthState, IPartnerState } from '../interfaces/state';
import { useSelector } from 'react-redux';
import { IOption } from '../components/UI/Input/Input';
import { useEffect, useState } from 'react';
import { createOptions } from '../shared/option-utils';

import * as actions from '../store/actions';

const defaultParams = {
  withPlaceholder: false,
  withBusinessId: false
}

export const usePartnerOptions = ({ withBusinessId, withPlaceholder } = defaultParams) => {
  const { authUser } = useSelector<IAppState, IAuthState>(state => state.auth);
  const { t } = useTranslation()
  const [partnerOptions, setPartnersOptions] = useState<IOption[]>([])
  const dispatch = useDispatch();

  const [tryFetch, setTryFetch] = useState(false);

  const { partners, loading } = useSelector<IAppState, IPartnerState>((state) => state.partners);

  useEffect(() => {
    if(authUser) {
      setTryFetch(authUser.isPrimary);
    }
  }, [authUser]);

  useEffect(() => {
    if(tryFetch && !partners) {
      dispatch(actions.listPartners());
      setTryFetch(false);
    }
  }, [dispatch, partners, tryFetch]);

  const createLabel = useCallback((organization: IOrganization) => {
    let labelArray = [organization.name || organization.id];

    if(withBusinessId && organization.businessId) {
      labelArray.push(`(${organization.businessId})`)
    }
    return labelArray.join(' ');
  }, [withBusinessId]);

  useEffect(() => {
    let placeholder;
    if(withPlaceholder) {
      placeholder = t(ETranslation.SELECT_CUSTOMER_ID);
    }
    setPartnersOptions(partners ? createOptions(partners, createLabel, placeholder) : []);
  }, [partners, t, withPlaceholder, createLabel]);

  return { partnerOptions, partnersLoading: loading };

}
