import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ETranslation } from "../../translations/translation-keys";
import Alert from "../UI/Alert/Alert";
import Button, { EButtonColor } from "../UI/Button/Button";
import { EInputType, IInputField } from "../UI/Input/Input";
import InputGroup from "../UI/InputGroup/InputGroup";
import { getInputData, validateInputs } from "../../shared/utility";

interface IProps {
  onCancel: () => void;
  onConfirm: (verificationCode: string) => Promise<boolean>;
}

enum EMultiFactorAuthDialog {
  code = "code",
}

const MultiFactorAuthDialog: React.FC<IProps> = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState("");

  const [inputs, setInputs] = useState<IInputField>({
    [EMultiFactorAuthDialog.code]: {
      type: EInputType.text,
      labelTranslation: ETranslation.MULTI_FACTOR_AUTH_DIALOG_CODE,
      placeholderTranslation:
        ETranslation.MULTI_FACTOR_AUTH_DIALOG_CODE_PLACEHOLDER,
      value: "",
      validation: {
        required: true,
        minLength: 6,
        maxLength: 6,
      },
      //autoFocus: true
    },
  });

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = useCreateInput(inputs, setInputs);

  const submitHandler = async (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    try {
      await onConfirm(getInputData(inputs).code);
      onCancel();
    } catch (error) {
      setError(error as string);
    }
  };

  return (
    <>
      {error && (
        <Alert noHorizontalMargin style={{ marginTop: 0 }}>
          {t(error)}
        </Alert>
      )}
      <form onSubmit={submitHandler}>
        {createInput(EMultiFactorAuthDialog.code)}
        <InputGroup>
          <Button onClick={submitHandler} disabled={!isValid}>
            {t(ETranslation.COMMON_SEND)}
          </Button>
          <Button onClick={onCancel} color={EButtonColor.NONE}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
        </InputGroup>
      </form>
    </>
  );
};

export default MultiFactorAuthDialog;
