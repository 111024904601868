import React from "react";

import classes from "./RadioButton.module.scss";
import { IOption } from "../Input/Input";
import RadioButtonOption from "./RadioButtonOption/RadioButtonOption";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  options: IOption[] | undefined;
  disabled?: boolean;
  invalid?: boolean;
}

const RadioButton: React.FC<IProps> = ({ value, onChange, options, disabled, invalid }) => {
  return (
      <div className={classes.OptionContainer}>
        {options &&
          options.map((option, i) => (
            <React.Fragment key={option.value}>
              <RadioButtonOption
                value={option.value}
                label={option.label}
                labelTranslation={option.labelTranslation}
                isActive={option.value === value}
                onClick={onChange}
                disabled={disabled}
                invalid={invalid}
              />
              {i < (options.length - 1) && (
                <span style={{width: '1px'}}></span>
              )}
            </React.Fragment>
            
          ))}
      </div>
  );
};

export default RadioButton;
