import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import Table from "../../components/UI/Table/Table";
import { IAppState, IFinanceCompany } from "../../interfaces";
import { Routes } from "../../classes/Routes";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import { EAcquisitionPeriod } from "./FinanceCompany/FinanceCompany";

interface IProps extends RouteComponentProps { }

const FinanceCompanies: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { loading, error, financeCompanies } = useSelector((state: IAppState) => ({
    loading: state.financeCompanies.loading,
    error: state.financeCompanies.error,
    financeCompanies: state.financeCompanies.financeCompanies,
  }));

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.listFinanceCompanies());
  }, [dispatch]);

  const showFinanceCompany = (id?: string) => {
    if (id) {
      history.push(Routes.FINANCE_COMPANY(id));
    }
  }

  const createFinanceCompaniesTable = (financeCompanies: Array<IFinanceCompany>) => {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>{t(ETranslation.FINANCE_COMPANY_NAME)}</th>
            <th>{t(ETranslation.FINANCE_COMPANY_INTEREST_RATE)}</th>
            <th>{t(ETranslation.FINANCE_COMPANY_INVOICING_FEE)}</th>
            <th>{t(ETranslation.FINANCE_COMPANY_OPENING_FEE)}</th>
            <th>{t(ETranslation.FINANCE_COMPANY_REGISTRATION_INFO)}</th>
            <th>{t(ETranslation.FINANCE_COMPANY_INVOICE_PAYER)}</th>
            <th>{t(ETranslation.FINANCE_COMPANY_BILLING_INFORMATION)}</th>
            <th>{t(ETranslation.FINANCE_COMPANY_PAYMENT_TERM)}</th>
            <th>{t(ETranslation.FINANCE_COMPANY_REFERENCE_INFORMATION)}</th>
            <th>{t(ETranslation.FINANCE_COMPANY_ACQUISITION_PERIOD)}</th>
          </tr>
        </thead>
        <tbody>
          {financeCompanies.map((financeCompany) => (
            <tr key={financeCompany.id} onClick={() => showFinanceCompany(financeCompany.id)}>
              <td>{financeCompany.name}</td>
              <td>{financeCompany.interestRate}</td>
              <td>{financeCompany.invoicingFee}</td>
              <td>{financeCompany.openingFee}</td>
              <td>{financeCompany.registrationInfo}</td>
              <td>{financeCompany.invoicePayer}</td>
              <td>{financeCompany.billingInformation}</td>
              <td>{financeCompany.paymentTerm}</td>
              <td>{financeCompany.referenceInformation}</td>
              <td>
                {financeCompany.acquisitionPeriod
                  ? financeCompany.acquisitionPeriod === EAcquisitionPeriod.FIRST_DAY
                    ? t(ETranslation.FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_DAY)
                    : t(ETranslation.FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_OR_15TH_DAY)
                  : null
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{t(ETranslation.TITLE_FINANCE_COMPANIES)}</h2>
        <Button onClick={() => history.push(Routes.FINANCE_COMPANIES_NEW)} icon={faPlus} style={{ marginBottom: "1rem" }}>{t(ETranslation.FINANCE_COMPANIES_ADD_NEW)}</Button>
        {loading
          ? <Spinner />
          : financeCompanies
            ? createFinanceCompaniesTable(financeCompanies)
            : <p>{t(ETranslation.NO_FINANCE_COMPANIES)}</p>
        }
      </Container>
    </>
  )
};

export default FinanceCompanies;
