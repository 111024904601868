import { IReportAction, IReportState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "./../actions/actionTypes";

const initialState: IReportState = {
  loading: false,
  reports: null,
  report: null,
  handovers: null,
  handoversLoading: false,
  handoversError: null,
  error: null,
};

const listReportsStart = (
  state: IReportState,
  action: IReportAction
): IReportState => {
  return updateObject(state, {
    reports: null,
    report: null,
    loading: true,
    error: null,
  });
};

const listReportsSuccess = (
  state: IReportState,
  action: IReportAction
): IReportState => {
  return updateObject(state, {
    reports: action.reports,
    loading: false,
    error: null,
  });
};

const listReportsFail = (
  state: IReportState,
  action: IReportAction
): IReportState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listHandoversStart = (
  state: IReportState,
  action: IReportAction
): IReportState => {
  return updateObject(state, {
    handovers: null,
    handoversLoading: true,
    handoversError: null,
  });
};

const listHandoversSuccess = (
  state: IReportState,
  action: IReportAction
): IReportState => {
  return updateObject(state, {
    handovers: action.handovers,
    handoversLoading: false,
    handoversError: null,
  });
};

const listHandoversFail = (
  state: IReportState,
  action: IReportAction
): IReportState => {
  return updateObject(state, {
    handoversLoading: false,
    handoversError: action.error,
  });
};

const reducer = (
  state: IReportState = initialState,
  action: IReportAction
): IReportState => {
  switch (action.type) {
    case ActionTypes.LIST_REPORTS_START:
      return listReportsStart(state, action);
    case ActionTypes.LIST_REPORTS_SUCCESS:
      return listReportsSuccess(state, action);
    case ActionTypes.LIST_REPORTS_FAIL:
      return listReportsFail(state, action);
    case ActionTypes.LIST_HANDOVERS_START:
      return listHandoversStart(state, action);
    case ActionTypes.LIST_HANDOVERS_SUCCESS:
      return listHandoversSuccess(state, action);
    case ActionTypes.LIST_HANDOVERS_FAIL:
      return listHandoversFail(state, action);
    default:
      return state;
  }
};

export default reducer;
