import { IBiltemaAction, IBiltemaState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IBiltemaState = {
  loading: false,
  biltemaResponse: null,
  error: null,
};

const getBiltemaExtendedInfoStart = (
  state: IBiltemaState,
  action: IBiltemaAction
): IBiltemaState => {
  return updateObject(state, {
    biltemaResponse: null,
    loading: true,
    error: null,
  });
};

const getBiltemaExtendedInfoSuccess = (
  state: IBiltemaState,
  action: IBiltemaAction
): IBiltemaState => {
  return updateObject(state, {
    biltemaResponse: action.biltemaResponse,
    loading: false,
    error: null,
  });
};

const getBiltemaExtendedInfoFail = (
  state: IBiltemaState,
  action: IBiltemaAction
): IBiltemaState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearBiltemaState = (
  state: IBiltemaState,
  action: IBiltemaAction
): IBiltemaState => {
  return initialState;
};

const reducer = (
  state: IBiltemaState = initialState,
  action: IBiltemaAction
): IBiltemaState => {
  switch (action.type) {
    case ActionTypes.BILTEMA_GET_EXTENDED_INFO_START:
      return getBiltemaExtendedInfoStart(state, action);
    case ActionTypes.BILTEMA_GET_EXTENDED_INFO_SUCCESS:
      return getBiltemaExtendedInfoSuccess(state, action);
    case ActionTypes.BILTEMA_GET_EXTENDED_INFO_FAIL:
      return getBiltemaExtendedInfoFail(state, action);
    case ActionTypes.BILTEMA_CLEAR:
      return clearBiltemaState(state, action);
    default:
      return state;
  }
};

export default reducer;
