import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { AuthUser } from "../../classes/AuthUser";
import firebase from "../../firebase";
import { IAuthAction } from "../../interfaces";
import { JSON_AUTH_LOGIN } from "./../../endpoints/index";
import { ActionTypes } from "./actionTypes";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";

export const storeAuthRedirect = (redirect: string): IAuthAction => {
  return {
    type: ActionTypes.STORE_AUTH_REDIRECT,
    redirect,
  };
};

const authStart = (): IAuthAction => {
  return {
    type: ActionTypes.AUTH_START,
  };
};

const authSuccess = (authUser: AuthUser | null): IAuthAction => {
  return {
    type: ActionTypes.AUTH_SUCCESS,
    authUser,
  };
};

const authFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.AUTH_FAIL,
    error,
  };
};

export const auth = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(authStart());
    try {
      await signInWithEmailAndPassword(getAuth(firebase), email, password)
        .then(async (response) => {
          if (response.user) {
            const res = await axios.post(JSON_AUTH_LOGIN);
            dispatch(authSuccess(new AuthUser(res.data)));
          } else {
            dispatch(authFail("error"));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(authFail(error.message));
        });
    } catch ({ response }) {
      dispatch(authFail((response as AxiosResponse).data));
    }
  };
};

/*
const logoutStart = (): IAuthAction => {
  return {
    type: ActionTypes.LOGOUT_START,
  };
};

const logoutSuccess = (): IAuthAction => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
  };
};

const logoutFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.LOGOUT_FAIL,
    error,
  };
};

export const logout = () => {
  return async (dispatch: Dispatch) => {
    dispatch(logoutStart());
    try {
      firebase.auth().signOut();
      dispatch(logoutSuccess());
    } catch (error) {
      dispatch(logoutFail(error as string));
    }
  };
};
*/

const getCurrentUserStart = (): IAuthAction => {
  return {
    type: ActionTypes.GET_CURRENT_USER_START,
  };
};

const getCurrentUserSuccess = (authUser: AuthUser | null): IAuthAction => {
  return {
    type: ActionTypes.GET_CURRENT_USER_SUCCESS,
    authUser,
  };
};

const getCurrentUserFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.GET_CURRENT_USER_FAIL,
    error,
  };
};

export const getCurrentUser = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getCurrentUserStart());
    try {
      const res = await axios.post(JSON_AUTH_LOGIN);
      dispatch(getCurrentUserSuccess(new AuthUser(res.data)));
    } catch ({ response }) {
      dispatch(getCurrentUserFail((response as AxiosResponse).data));
    }
  };
};

export const getCurrentUserClear = (): IAuthAction => {
  return {
    type: ActionTypes.AUTH_GET_CLEAR,
  };
};