import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import Table from "../../components/UI/Table/Table";
import { IAppState, IUser } from "../../interfaces";
import { Routes } from "../../classes/Routes";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";

interface IProps extends RouteComponentProps {
  organizationId?: string;
}

const Users: React.FC<IProps> = ({ history, organizationId }) => {
  const dispatch = useDispatch();

  const { loading, error, users } = useSelector((state: IAppState) => ({
    loading: state.users.loading,
    error: state.users.error,
    users: state.users.users,
  }));

  const { t } = useTranslation();

  useEffect(() => {
    organizationId ? dispatch(actions.listUsers(organizationId)) : dispatch(actions.listUsers());
  }, [dispatch, organizationId]);

  const showUser = (id?: string) => {
    if (id) {
      if (organizationId) {
        history.push(Routes.ORGANIZATION_USER(organizationId, id));
      } else {
        history.push(Routes.USER(id));
      }
    }
  }

  const createUsersTable = (users: Array<IUser>) => {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>{t(ETranslation.USER_NAME)}</th>
            <th>{t(ETranslation.USER_EMAIL)}</th>
            <th>{t(ETranslation.USER_TITLE)}</th>
            <th>{t(ETranslation.USER_PHONE_NUMBER)}</th>
            <th>{t(ETranslation.USER_ADDRESS)}</th>
            <th>{t(ETranslation.USER_ALLOW_LOGIN)}</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id} onClick={() => showUser(user.id)}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.title}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.streetAddress} {user.zip} {user.city}</td>
              <td>{user.allowLogin ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  const addUser = () => {
    if (organizationId) {
      history.push(Routes.ORGANIZATION_USERS_NEW(organizationId));
    } else {
      history.push(Routes.USERS_NEW);
    }
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container style={organizationId ? { padding: 0, margin: 0, marginTop: "1rem" } : {}}>
        <h2>{t(ETranslation.TITLE_USERS)}</h2>
        <Button onClick={addUser} icon={faPlus} style={{ marginBottom: "1rem" }}>{t(ETranslation.USERS_ADD_NEW)}</Button>
        {loading
          ? <Spinner />
          : users
            ? createUsersTable(users)
            : <p>{t(ETranslation.NO_USERS)}</p>
        }
      </Container>
    </>
  );
}

export default Users;
