import { faChevronDown, faChevronUp, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EInputUpdateAction } from "../../../context/InputContext";
import { EDealStatus, EDealType } from "../../../enums";
import { IDeal } from "../../../interfaces";
import { getDealStatus } from "../../../shared/deal-utils";
import { formatDateString, generateUUID, updateInputHandler } from "../../../shared/utility";
import { IDealsSearchOptions } from "../../../store/actions/dealsActions";
import { ETranslation } from "../../../translations/translation-keys";
import Input, { EInputType, IInputField, IInputOptions, IOption } from "../../UI/Input/Input";
import Table from "../../UI/Table/Table";
import classes from "./DealsTable.module.scss";
import ModalContext, { EModalSize, defaultModal } from "../../UI/Modal/ModalContext";
import DealSendDialog from "../Deal/DealEdit/DealSendDialog/DealSendDialog";
import Button, { EButtonColor } from "../../UI/Button/Button";
import { useDispatch } from "react-redux";
import { TDispatch } from "../../..";
import * as actions from '../../../store/actions';

interface IProps {
  deals: Array<IDeal>
  statusProp?: string;
  onClick: (deal?: IDeal) => void;
  sortable?: boolean;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  searchOptions?: IDealsSearchOptions;
  reportView?: boolean;
}

enum EDealsTableSortBy {
  TYPE = "type",
  CREATED = "created",
  NAME = "name",
  CUSTOMER_NAME = "customer",
  SELLER_NAME = "seller",
  STATUS = "status",
  BRAND = "brand",
  MODEL = "model",
  REGISTRATION_NUMBER = "registrationNumber",
  FINANCE_COMPANY_NAME = "financeCompany",
}

enum EDealsTable {
  SORT_BY = "sortBy",
}

const DealsTable: React.FC<IProps> = ({ deals, statusProp, onClick, sortable, containerClassName, containerStyle, searchOptions, reportView }) => {
  const { t } = useTranslation();

  const [sortedDeals, setSortedDeals] = useState<IDeal[]>(deals);
  const [sort, setSort] = useState<EDealsTableSortBy>(EDealsTableSortBy.CREATED);
  // true = up, false = down
  const [sortDirection, setSortDirection] = useState<boolean>(false);

  const dispatch = useDispatch<TDispatch>();
  const { setModal } = useContext(ModalContext);

  const [inputs, setInputs] = useState<IInputField>({
    [EDealsTable.SORT_BY]: {
      type: EInputType.select,
      labelTranslation: ETranslation.TITLE_SORT,
      value: EDealsTableSortBy.CREATED,
      options: [
        { value: EDealsTableSortBy.CREATED, labelTranslation: ETranslation.COMMON_CREATED },
        { value: EDealsTableSortBy.NAME, labelTranslation: ETranslation.DEAL_NAME },
        { value: EDealsTableSortBy.BRAND, labelTranslation: ETranslation.DEAL_BRAND },
        { value: EDealsTableSortBy.MODEL, labelTranslation: ETranslation.DEAL_MODEL },
        { value: EDealsTableSortBy.REGISTRATION_NUMBER, labelTranslation: ETranslation.DEAL_REGISTRATION_NUMBER },
        { value: EDealsTableSortBy.CUSTOMER_NAME, labelTranslation: ETranslation.DEAL_CUSTOMER_NAME },
        { value: EDealsTableSortBy.SELLER_NAME, labelTranslation: ETranslation.DEAL_SELLER },
        { value: EDealsTableSortBy.FINANCE_COMPANY_NAME, labelTranslation: ETranslation.DEAL_FINANCE_COMPANY },
        { value: EDealsTableSortBy.STATUS, labelTranslation: ETranslation.DEAL_STATUS },
        { value: EDealsTableSortBy.TYPE, labelTranslation: ETranslation.DEAL_TYPE },
      ]
    },
  });

  useEffect(() => {
    if (!sortable) return;
    // console.log("sort options changed to:", sort, sortDirection);

    const newReports = [...sortedDeals];

    if (sort && newReports) {
      if (sortDirection) {
        newReports.reverse();
        setSortedDeals(newReports);
        return;
      }

      newReports.sort((a, b) => {
        switch (sort) {
          case EDealsTableSortBy.CUSTOMER_NAME:
            if (a.customer === undefined) return 1;
            if (b.customer === undefined) return -1;
            if (a.customer === b.customer) return 0;
            if (a.customer.name === b.customer.name) return 0;
            return ((a.customer && a.customer.name) || "") > ((b.customer && b.customer.name) || "") ? 1 : -1;
          case EDealsTableSortBy.SELLER_NAME:
            if (a.seller === undefined) return 1;
            if (b.seller === undefined) return -1;
            if (a.seller === b.seller) return 0;
            if (a.seller.name === b.seller.name) return 0;
            return ((a.seller && a.seller.name) || "") > ((b.seller && b.seller.name) || "") ? 1 : -1;
          case EDealsTableSortBy.FINANCE_COMPANY_NAME:
            if (a.financeCompany === undefined) return 1;
            if (b.financeCompany === undefined) return -1;
            if (a.financeCompany === b.financeCompany) return 0;
            if (a.financeCompany.name === b.financeCompany.name) return 0;
            return ((a.financeCompany && a.financeCompany.name) || "") > ((b.financeCompany && b.financeCompany.name) || "") ? 1 : -1;
          default:
            if (a[sort] === "" || a[sort] === null || a[sort] === undefined) return 1;
            if (b[sort] === "" || b[sort] === null || b[sort] === undefined) return -1;
            if (a[sort] === b[sort]) return 0;
            if (sort === EDealsTableSortBy.CREATED) return (a[sort] || "") < (b[sort] || "") ? 1 : -1;
            return (a[sort] || "") > (b[sort] || "") ? 1 : -1;
        }
      });
      setSortedDeals(newReports);
    }

    // eslint-disable-next-line
  }, [sort, sortDirection, sortable]);

  const createInput = (inputName: EDealsTable, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.DEALS_TABLE}
      onChange={value => {
        updateInputHandler(inputName, value, setInputs);
        setSort(value as EDealsTableSortBy);
        setSortDirection(false);
      }}
      inputName={inputName}
    />
  };

  const getChevron = () => {
    // console.log(sortDirection);
    return <FontAwesomeIcon className={classes.Icon} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  const changeSortOrDirection = (sortBy: EDealsTableSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
    setInputs(stateInputs => {
      const newInputs = { ...stateInputs };
      newInputs[EDealsTable.SORT_BY].value = sortBy;
      return newInputs;
    });
  }

  const getStatusBasedDateHeader = (status: string) => {
    if (!status) return;
    switch (status) {
      case EDealStatus.OFFER:
        return (
          <th>{t(ETranslation.DEAL_SEND_DATE)}</th>
        );
      case EDealStatus.PRECONTRACT:
        return (
          <th>{t(ETranslation.DEAL_HANDOVER_DATE)}</th>
        );
      case EDealStatus.CONTRACT:
        return (
          <th>{t(ETranslation.DEAL_END_DATE)}</th>
        );
      case EDealStatus.TERMINATED:
        return (
          <th>{t(ETranslation.DEAL_TERMINATED_DATE)}</th>
        );
      case EDealStatus.ARCHIVED:
        return (
          <th>{t(ETranslation.DEAL_ARCHIVED_DATE)}</th>
        );
      default:
        return null;
    }
  };

  const getStatusBasedDateValue = (deal: IDeal, statusProp: string) => {
    if (statusProp) {
      switch (statusProp) {
        case EDealStatus.OFFER:
          return <td>{deal.sendDate && deal.sendDate}</td>;
        case EDealStatus.PRECONTRACT:
          return (
            <td>{deal.handoverDate && formatDateString(deal.handoverDate)}</td>
          );
        case EDealStatus.CONTRACT:
          return <td>{deal.endDate && deal.endDate}</td>;
        case EDealStatus.TERMINATED:
          return <td>{deal.terminatedDate && deal.terminatedDate}</td>;
        case EDealStatus.ARCHIVED:
          return <td>{deal.archivedDate && deal.archivedDate}</td>;
        default:
          return null;
      }
    }
    if (!deal || !deal.status) return;
    switch (deal.status) {
      case EDealStatus.OFFER:
        return <td>{deal.sendDate && deal.sendDate}</td>;
      case EDealStatus.PRECONTRACT:
        return <td>{deal.handoverDate && formatDateString(deal.handoverDate)}</td>;
      case EDealStatus.CONTRACT:
        return <td>{deal.endDate && deal.endDate}</td>;
      case EDealStatus.TERMINATED:
        return <td>{deal.terminatedDate && deal.terminatedDate}</td>;
      case EDealStatus.ARCHIVED:
        return <td>{deal.archivedDate && deal.archivedDate}</td>;
      default:
        return null;
    }
  };

  const mapHeaders = () => {
    if (sortable) {
      return (
        <>
          <th onClick={() => changeSortOrDirection(EDealsTableSortBy.CREATED)}>
            {t(ETranslation.COMMON_CREATED)}
            {sort === EDealsTableSortBy.CREATED && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EDealsTableSortBy.NAME)}>
            {t(ETranslation.DEAL_NAME)}
            {sort === EDealsTableSortBy.NAME && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EDealsTableSortBy.BRAND)}>
            {t(ETranslation.DEAL_BRAND)}
            {sort === EDealsTableSortBy.BRAND && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EDealsTableSortBy.MODEL)}>
            {t(ETranslation.DEAL_MODEL)}
            {sort === EDealsTableSortBy.MODEL && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EDealsTableSortBy.REGISTRATION_NUMBER)}>
            {t(ETranslation.DEAL_REGISTRATION_NUMBER)}
            {sort === EDealsTableSortBy.REGISTRATION_NUMBER && getChevron()}
          </th>
          {searchOptions?.customerId ? null : (
            <th onClick={() => changeSortOrDirection(EDealsTableSortBy.CUSTOMER_NAME)}>
              {t(ETranslation.DEAL_CUSTOMER)}
              {sort === EDealsTableSortBy.CUSTOMER_NAME && getChevron()}
            </th>
          )}
          {searchOptions?.sellerId ? null : (
            <th onClick={() => changeSortOrDirection(EDealsTableSortBy.SELLER_NAME)}>
              {t(ETranslation.DEAL_SELLER)}
              {sort === EDealsTableSortBy.SELLER_NAME && getChevron()}
            </th>
          )}
          {searchOptions?.financeCompanyId ? null : (
            <th onClick={() => changeSortOrDirection(EDealsTableSortBy.FINANCE_COMPANY_NAME)}>
              {t(ETranslation.DEAL_FINANCE_COMPANY)}
              {sort === EDealsTableSortBy.FINANCE_COMPANY_NAME && getChevron()}
            </th>
          )}
          {statusProp !== EDealStatus.OFFER &&
            < th >
              {t(ETranslation.DEAL_SEND_DATE)}
            </th>
          }
          <th>{t(ETranslation.DEAL_OPENED_DATE)}</th>
          {searchOptions?.status ? null : (
            <th onClick={() => changeSortOrDirection(EDealsTableSortBy.STATUS)}>
              {t(ETranslation.DEAL_STATUS)}
              {sort === EDealsTableSortBy.STATUS && getChevron()}
            </th>
          )}
          {searchOptions?.type ? null : (
            <th onClick={() => changeSortOrDirection(EDealsTableSortBy.TYPE)}>
              {t(ETranslation.DEAL_TYPE)}
              {sort === EDealsTableSortBy.TYPE && getChevron()}
            </th>
          )}
          {/* <th>{t(ETranslation.DEAL_ARCHIVED_DATE)}</th>
          <th>{t(ETranslation.DEAL_ARCHIVED_USER)}</th> */}
        </>
      )
    }
    return (
      <>
        <th>{t(ETranslation.COMMON_CREATED)}</th>
        <th>{t(ETranslation.DEAL_NAME)}</th>
        <th>{t(ETranslation.DEAL_BRAND)}</th>
        <th>{t(ETranslation.DEAL_MODEL)}</th>
        <th>{t(ETranslation.DEAL_REGISTRATION_NUMBER)}</th>
        <th>{t(ETranslation.DEAL_CUSTOMER)}</th>
        <th>{t(ETranslation.DEAL_SELLER)}</th>
        <th>{t(ETranslation.DEAL_FINANCE_COMPANY)}</th>
        {statusProp !== EDealStatus.OFFER && (
          <th>{t(ETranslation.DEAL_SEND_DATE)}</th>
        )}
        <th>{t(ETranslation.DEAL_OPENED_DATE)}</th>
        {searchOptions?.status ? null : <th>{t(ETranslation.DEAL_STATUS)}</th>}
        <th>{t(ETranslation.DEAL_TYPE)}</th>
        {/* <th>{t(ETranslation.DEAL_ARCHIVED_DATE)}</th>
        <th>{t(ETranslation.DEAL_ARCHIVED_USER)}</th> */}
      </>
    );
  }

  const getDealTypeString = (type: EDealType) => {
    switch (type) {
      case EDealType.CAR:
        return t(ETranslation.DEAL_TYPE_CAR);
      case EDealType.MACHINE:
        return t(ETranslation.DEAL_TYPE_MACHINE);
      default:
        return null;
    }
  };

  const setReturnInfoEmailsDialog = (deal: IDeal, event: React.MouseEvent) => {
    event.stopPropagation();
    const emails:IOption[] = [];
    const sellerEmail = deal.seller?.email;
    const contactUserEmail = deal.customer?.contactPersonEmail;
    const userEmail = deal.user?.email;
    if (sellerEmail) emails.push({value: generateUUID("email"), label: sellerEmail});
    if (contactUserEmail) emails.push({value: generateUUID("email"), label: contactUserEmail});
    if (userEmail) emails.push({value: generateUUID("email"), label: userEmail});
    const message = "Hei, \n"
      +"Autonne palautukseen liittyen seuraavaa ohjeistusta:\n\n"
      +"Varatkaa aika INCARin pisteeseen linkin kautta: https://www.incar.fi/olf/ ja valitse “Palautustarkastus One Leasing Finland”.\n"
      +"Vie auto valitsemananne ajankohtana toimipisteeseen, viimeistään "+(deal.endDate ?? "")+".\n\n\n"
      +"Autoon mukaan kesärenkaat ja molemmat avaimet ja kaikki muun autoon kuuluva.\n\n\n"
      +"Mikäli autossa on jotain vaurioita, kuten tuulilasi rikki/halki tai muita jälkiä, joista pitää tehdä vahinkoilmoitus, niin nämä tulisi hoitaa ennen tarkastukseen vientiä.\n\n\n\n"
      +"Ystävällisin terveisin\n\n"
      +"One Leasing Finland Oy\n\n"
      +"Tietotie 9\n\n"
      +"01530 Vantaa\n\n"
      +"www.oneleasingfinland.fi\n\n"
      +"www.olf.fi";
    setModal({
      isOpen: true,
      title: t(ETranslation.DEAL_SEND_RETURN_INFO_TITLE),
      size: EModalSize.SMALL,
      content: (
        <DealSendDialog 
          sendDeal={sendReturnInfoEmails} 
          closeDialog={() => setModal(defaultModal)} 
          emails={emails} 
          content={t(ETranslation.DEAL_SEND_RETURN_INFO_CONTENT)}
          closeText={t(ETranslation.COMMON_RETURN)}
          id={deal.id}
          messageInput={true}
          messageValue={message}
        />
      )
    })
  };

  const sendReturnInfoEmails = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, recipients: string[], message: string, id?: string, ) => {
    event.preventDefault();
    if (!recipients || recipients.length === 0) return;
    dispatch(actions.sendDealReturnInfo(id ?? "", recipients, message));
    setModal(defaultModal);
  };

  return (
    <div className={containerClassName} style={containerStyle}>
      {sortable && (
        <div style={{ width: "fit-content" }}>
          {createInput(EDealsTable.SORT_BY)}
        </div>
      )}
      <Table hover>
        <thead>
          <tr className={classes.TableHeader}>
            {reportView && (statusProp && getStatusBasedDateHeader(statusProp))}
            {mapHeaders()}
            {reportView && statusProp === EDealStatus.CONTRACT && <th>{t(ETranslation.DEAL_RETURN_INFO_TITLE)}</th>}
          </tr>
        </thead>
        <tbody>
          {sortedDeals.map((deal) => (
            <tr key={deal.id} onClick={() => onClick(deal)}>
              {reportView && (
                statusProp && getStatusBasedDateValue(deal, statusProp)
              )}
              <td>{deal.created && formatDateString(deal.created)}</td>
              <td>{deal.name}</td>
              <td>{deal.brand}</td>
              <td>{deal.model}</td>
              <td>{deal.registrationNumber}</td>
              {searchOptions?.customerId ? (reportView ? <td>{deal?.customerName}</td> : null) : <td>{deal?.customerName}</td>}
              {searchOptions?.sellerId ? null : <td>{deal.seller?.name}</td>}
              {searchOptions?.financeCompanyId ? null : (
                <td>{deal?.financeCompanyName}</td>
              )}
              {statusProp !== EDealStatus.OFFER && <td>{deal.sendDate}</td>}
              <td>{deal.openedDate}</td>
              {searchOptions?.status ? null : (
                <td>{deal.status && t(getDealStatus(deal.status))}</td>
              )}
              {searchOptions?.type ? null : (
                <td>{deal.type && getDealTypeString(deal.type)}</td>
              )}
              {/* <td>{deal.archivedDate}</td>
              <td>{deal.archivedUser?.name}</td> */}
              {reportView && statusProp === EDealStatus.CONTRACT && 
                <td><Button color={EButtonColor.SECONDARY} onClick={(event) => setReturnInfoEmailsDialog(deal, event)} icon={faMailBulk} title={t(ETranslation.DEAL_RETURN_INFO_BUTTON_TITLE)}></Button></td>
              }
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DealsTable;
