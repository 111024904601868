import classes from './SearchContainer.module.scss';
import React, { ReactNode } from 'react';

interface IProps {
  children: ReactNode
}

const SearchContainer: React.FC<IProps> = ({ children }) => {
  return (
    <div className={classes.Container}>
      {children}
    </div>
  )
}

export default SearchContainer
