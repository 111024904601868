import React from "react";

const classes = require("./Auth.module.scss");

const AuthError: React.FC = () => {
  return (
    <div className={classes.Container}>
      <h3>error occurred</h3>
    </div>
  );
};

export default AuthError;
