import React from "react";

const DealVariationConditionContext = React.createContext<{
  variationContractLength: string | number;
  variationResidualValue: string | number;
  variationContractKilometers: string | number;
  variationExtraTireSets: string | number;
  setVariationContractLength: React.Dispatch<React.SetStateAction<string | number>>;
  setVariationResidualValue: React.Dispatch<React.SetStateAction<string | number>>;
  setVariationContractKilometers: React.Dispatch<React.SetStateAction<string | number>>;
  setVariationExtraTireSets: React.Dispatch<React.SetStateAction<string | number>>;
}>({
  variationContractLength: '',
  variationResidualValue: '',
  variationContractKilometers: '',
  variationExtraTireSets: '',
  setVariationContractLength: () => {},
  setVariationResidualValue: () => {},
  setVariationContractKilometers: () => {},
  setVariationExtraTireSets: () => {},
});

export default DealVariationConditionContext;
