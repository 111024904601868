import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EDealStatus, EDealType, ESearchStorageKey } from "../../../enums";
import { IDealsSearchOptions } from "../../../store/actions/dealsActions";
import { ETranslation } from "../../../translations/translation-keys";
import CustomerSearch from "../../Searches/CustomerSearch/CustomerSearch";
import FinanceCompanySearch from "../../Searches/FinanceCompanySearch/FinanceCompanySearch";
import SearchContainer from "../../Searches/SearchContainer/SearchContainer";
import SellerSearch from "../../Searches/SellerSearch/SellerSearch";
import Select from "../../UI/Select/Select";
import { useDispatch } from "react-redux";
import { TDispatch } from "../../..";
import { listUsers } from "../../../store/actions";

interface IProps {
  onSearch: (search: IDealsSearchOptions) => void;
};

const DealsSearch: React.FC<IProps> = ({ onSearch }) => {
  const [isInit, setInit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch<TDispatch>();

  const [financeCompanyId, setFinanceCompanyId] = useState("");
  const [status, setStatus] = useState("");
  const [sellerId, setSellerId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    dispatch(listUsers(undefined, false));
    setFinanceCompanyId(localStorage.getItem(ESearchStorageKey.DEALS_FINANCE_COMPANY_ID) || '');
    setStatus(localStorage.getItem(ESearchStorageKey.DEALS_STATUS) || '');
    setSellerId(localStorage.getItem(ESearchStorageKey.DEALS_SELLER_ID) || '');
    setCustomerId(localStorage.getItem(ESearchStorageKey.DEALS_CUSTOMER_ID) || '');
    setType(localStorage.getItem(ESearchStorageKey.DEALS_TYPE) || '');
    setInit(true);
  }, [dispatch]);

  const statuses = [
    { value: EDealStatus.OFFER, label: t(ETranslation.DEAL_STATUS_OFFER) },
    { value: EDealStatus.PRECONTRACT, label: t(ETranslation.DEAL_STATUS_PRECONTRACT) },
    { value: EDealStatus.CONTRACT, label: t(ETranslation.DEAL_STATUS_CONTRACT) },
  ];

  const types = [
    { value: EDealType.CAR, label: t(ETranslation.DEAL_TYPE_CAR) },
    { value: EDealType.MACHINE, label: t(ETranslation.DEAL_TYPE_MACHINE) },
  ];

  useEffect(() => {
    if (isInit) {
      onSearch({ financeCompanyId, status, sellerId, customerId, type });
      localStorage.setItem(ESearchStorageKey.DEALS_FINANCE_COMPANY_ID, financeCompanyId);
      localStorage.setItem(ESearchStorageKey.DEALS_STATUS, status);
      localStorage.setItem(ESearchStorageKey.DEALS_SELLER_ID, sellerId);
      localStorage.setItem(ESearchStorageKey.DEALS_CUSTOMER_ID, customerId);
      localStorage.setItem(ESearchStorageKey.DEALS_TYPE, type);
    }
  }, [financeCompanyId, status, sellerId, customerId, type, onSearch, isInit]);

  return (
    <SearchContainer>
      <FinanceCompanySearch financeCompanyId={financeCompanyId} setFinanceCompanyId={setFinanceCompanyId} />
      <div>
        <label>{t(ETranslation.DEAL_STATUS)}</label>
        <Select
          inputName={"statusSearch"}
          value={status}
          onChange={setStatus}
          options={statuses}
          isSearchable
          isClearable
          isCreatable={false}
        />
      </div>
      <SellerSearch sellerId={sellerId} setSellerId={setSellerId} />
      <CustomerSearch customerId={customerId} setCustomerId={setCustomerId} />
      <div>
        <label>{t(ETranslation.DEAL_TYPE)}</label>
        <Select
          inputName={"typeSearch"}
          value={type}
          onChange={setType}
          options={types}
          isSearchable
          isClearable
          isCreatable={false}
        />
      </div>
    </SearchContainer>
  );
};

export default DealsSearch;
