import React from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';
import { usePartnerOptions } from '../../../hooks/usePartnerOptions';

interface IProps {
  setPartnerId: React.Dispatch<React.SetStateAction<string>>;
  partnerId: string;
}

const PartnerSearch: React.FC<IProps> = ({ setPartnerId, partnerId }) => {

  const { partnerOptions, partnersLoading } = usePartnerOptions();
  const { t } = useTranslation();

  return (
    <div>
      <label>{t(ETranslation.DRAFT_PARTNER)}</label>
      <Select
        inputName={"partnerSearch"}
        value={partnerId}
        onChange={setPartnerId}
        options={partnerOptions}
        isSearchable
        isClearable
        isLoading={partnersLoading}
      />
    </div>
  )

}

export default PartnerSearch;
