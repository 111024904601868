import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import {
  INetwheelsAction,
  IResidualValueRequest,
  IResidualValueResponse,
} from "../../interfaces";
import { JSON_NETWHEELS_RESIDUAL_VALUE } from "./../../endpoints/index";
import { ActionTypes } from "./actionTypes";

const getNetwheelsResidualValueStart = (variationId: string): INetwheelsAction => {
  return {
    type: ActionTypes.NETWHEELS_GET_RESIDUAL_VALUE_START,
    variationId,
  };
};

const getNetwheelsResidualValueSuccess = (
  residualValue: IResidualValueResponse
): INetwheelsAction => {
  return {
    type: ActionTypes.NETWHEELS_GET_RESIDUAL_VALUE_SUCCESS,
    residualValue,
  };
};

const getNetwheelsResidualValueFail = (error: string): INetwheelsAction => {
  return {
    type: ActionTypes.NETWHEELS_GET_RESIDUAL_VALUE_FAIL,
    error,
  };
};

export const getNetwheelsResidualValue = (request: IResidualValueRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch(getNetwheelsResidualValueStart(request.variationId));
    try {
      const res = await axios.post(JSON_NETWHEELS_RESIDUAL_VALUE, request);
      dispatch(getNetwheelsResidualValueSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getNetwheelsResidualValueFail((response as AxiosResponse).data));
      return null;
    }
  };
};

export const clearNetwheelsState = () => {
  return {
    type: ActionTypes.NETWHEELS_CLEAR,
  };
};
