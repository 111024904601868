import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Invoice from "../../../../../containers/Invoices/Invoice/Invoice";
import TalenomInvoice from "../../../../../containers/Invoices/TalenomInvoice/TalenomInvoice";
import { EDealType, InputSuffix } from "../../../../../enums";
import { useConfirmModal } from "../../../../../hooks/useConfirmModal";
import {
  IDealPricingVariation,
  IInvoice,
  IInvoiceRow,
  ITalenomInvoice,
} from "../../../../../interfaces";
import {
  dateToString,
  toTwoDecimalsString,
} from "../../../../../shared/utility";
import * as actions from "../../../../../store/actions";
import { ETranslation } from "../../../../../translations/translation-keys";
import EditInvoiceDialog from "../../../../Invoices/EditInvoiceRowsDialog/EditInvoiceRowsDialog";
import ModalContext, { EModalSize } from "../../../../UI/Modal/ModalContext";
import classes from "./MaintenanceBudgetStatus.module.scss";
import { useAuthUser } from "../../../../../hooks/useAuthUser";

interface IProps {
  type?: EDealType;
  variation?: IDealPricingVariation;
  invoices?: IInvoice[];
  talenomInvoices?: ITalenomInvoice[];
  registrationNumber?: string;
}

const MaintenanceBudgetStatus: React.FC<IProps> = ({
  type = EDealType.CAR,
  variation,
  invoices,
  talenomInvoices,
  registrationNumber,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const confirm = useConfirmModal();
  const { setModal, closeModal } = useContext(ModalContext);
  const authUser = useAuthUser();

  // console.log(variation);

  const editInvoiceRows = useCallback(
    (id: string, rows: IInvoiceRow[]) => {
      dispatch(actions.editInvoiceRows(id, rows));
      closeModal();
    },
    [closeModal, dispatch]
  );

  const showEditInvoiceRowsDialog = useCallback(
    (invoice: IInvoice) =>
      setModal({
        isOpen: true,
        title: t(ETranslation.EDIT_INVOICE_TITLE),
        size: EModalSize.MEDIUM,
        content: (
          <EditInvoiceDialog
            invoice={invoice}
            onAccept={editInvoiceRows}
            onCancel={closeModal}
          />
        ),
      }),
    [closeModal, editInvoiceRows, setModal, t]
  );

  const detachInvoice = useCallback(
    async (invoiceId: string, talenom?: boolean) => {
      const success = await confirm(t(ETranslation.DETACH_INVOICE_TEXT));
      if (success) {
        if (talenom) {
          dispatch(actions.detachTalenomInvoice(invoiceId, registrationNumber ?? ""));
        } else {
          dispatch(actions.detachInvoice(invoiceId));
        }
      }
    },
    [confirm, dispatch, t, registrationNumber]
  );

  const countTotals = useCallback(() => {
    if (!variation) return null;

    const maintenanceBudget = variation.maintenanceBudgetTotal
      ? variation.maintenanceBudgetTotal
      : type === EDealType.CAR
      ? variation.maintenanceBudgetVAT
      : variation.maintenanceBudget;

    if (!maintenanceBudget) return null;

    let invoicesTotalVAT0 = 0;
    let invoicesTotal = 0;

    invoices?.forEach((invoice) => {
      invoicesTotalVAT0 += invoice.rowTotals || 0;
      invoicesTotal += invoice.rowTotalsVat || 0;
    });

    if (registrationNumber) {
      talenomInvoices?.forEach((invoice) => {
        const row = invoice.accountingRows.find(
          (row) => row.projectKey === registrationNumber
        );
        if (row) {
          const totalAmount = row.info.totalAmount.value || 0;
          const vatAmount = row.info.vatAmount.value || 0;
          invoicesTotal += totalAmount;
          invoicesTotalVAT0 += totalAmount - vatAmount;
        }
      });
    }

    const used = type === EDealType.CAR ? invoicesTotal : invoicesTotalVAT0;
    const remaining = maintenanceBudget - used;

    return (
      <table className={classes.Totals}>
        <tbody>
          <tr>
            <th>{t(ETranslation.MAINTENANCE_BUDGET_STATUS_TOTAL)}</th>
            <td>
              {toTwoDecimalsString(maintenanceBudget)} {InputSuffix.EUROS}
            </td>
          </tr>
          <tr>
            <th>{t(ETranslation.MAINTENANCE_BUDGET_STATUS_USED)}</th>
            <td>
              {toTwoDecimalsString(used)} {InputSuffix.EUROS}
            </td>
          </tr>
          <tr>
            <th>{t(ETranslation.MAINTENANCE_BUDGET_STATUS_REMAINING)}</th>
            <td>
              {toTwoDecimalsString(remaining)} {InputSuffix.EUROS}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }, [invoices, registrationNumber, t, talenomInvoices, type, variation]);

  const createInvoices = useCallback(() => {
    invoices?.reverse();
    return invoices?.map((invoice) => (
      <Invoice
        key={invoice.id}
        invoice={invoice}
        detachInvoice={authUser?.isPrimary ? detachInvoice : undefined}
        editInvoice={authUser?.isPrimary ? showEditInvoiceRowsDialog : undefined}
      />
    ));
  }, [detachInvoice, invoices, showEditInvoiceRowsDialog, authUser]);

  return (
    //  style={{ overflowX: "scroll" }}
    <div>
      <h2>
        {t(ETranslation.MAINTENANCE_BUDGET_STATUS_TITLE)}{" "}
        {dateToString(new Date())}
      </h2>
      {countTotals()}
      {createInvoices()}
      {talenomInvoices?.map((invoice) => (
        <TalenomInvoice
          key={invoice.id}
          invoice={invoice}
          registrationNumber={registrationNumber}
          detachInvoice={authUser?.isPrimary ? (invoiceId) => detachInvoice(invoiceId, true) : undefined}
        />
      ))}
    </div>
  );
};

export default MaintenanceBudgetStatus;
