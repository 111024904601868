import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../UI/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../interfaces/state";
import * as actions from '../../../../store/actions';
import { TDispatch } from "../../../..";
import Spinner from "../../../UI/Spinner/Spinner";
import LogItemChangesTable from "../../../LogItemChangesTable/LogItemChangesTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const DealLog: React.FC<{ id: string }> = ({id}) => {

    const dispatch = useDispatch<TDispatch>();
    const [openedLogs, setOpenedLogs] = useState<string[]>([]);

    const { logItems, logItemsLoading } = useSelector((state: IAppState) => ({
        logItems: state.logItems.logItems,
        logItemsLoading: state.logItems.loading,
        //logItemsError: state.logItems.error,
    }));

    useEffect(() => {
        dispatch(actions.clearLogItemsState());
    }, [dispatch]);

    const getLogItems = useCallback(() => {
        if (id) {
        dispatch(actions.listLogItems({targetDealId: id}));
        }
    }, [dispatch, id]);

    const openedLogsHandler = (id:string) => {
        let newArray = openedLogs.concat([]);
        if (openedLogs.includes(id)) {
        newArray = newArray.filter(string => string !== id)
        } else {
        newArray.push(id);
        }
        setOpenedLogs(newArray);
    }

    return(<>
        <h3>Loki</h3>
        <Button onClick={getLogItems}>Hae loki</Button>
        {logItemsLoading ? <Spinner/> : (
            logItems && logItems.map((logItem) => (
            <div key={logItem.id} onClick={()=>openedLogsHandler(logItem.id)} style={{cursor:"pointer"}}>
                <p>{logItem.dateString+": "+logItem.actionUserName+" ("+logItem.actionOrganizationName+") "+logItem.message+" "}
                {logItem.logItemChanges && (openedLogs.includes(logItem.id) ?
                <FontAwesomeIcon icon={faChevronUp} color={"black"} /> :
                <FontAwesomeIcon icon={faChevronDown} color={"black"} />
                )}
                </p>
                {openedLogs.includes(logItem.id) && logItem.logItemChanges &&
                <LogItemChangesTable id={logItem.id} logItemChanges={logItem.logItemChanges}/>
                }
            </div>
            ))
        )}
        </>
    )
}
export default DealLog;