import { ILogItemAction, ILogItemState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: ILogItemState = {
  loading: false,
  logItems: null,
  error: null,
};

const listLogItemsStart = (
  state: ILogItemState,
  action: ILogItemAction
): ILogItemState => {
  return updateObject(state, {
    logItems: null,
    loading: true,
    error: null,
  });
};

const listLogItemsSuccess = (
  state: ILogItemState,
  action: ILogItemAction
): ILogItemState => {
  return updateObject(state, {
    logItems: action.logItems,
    loading: false,
    error: null,
  });
};

const listLogItemsFail = (
  state: ILogItemState,
  action: ILogItemAction
): ILogItemState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getLogItemStart = (state: ILogItemState, action: ILogItemAction): ILogItemState => {
  return updateObject(state, {
    logItem: null,
    loading: true,
    error: null,
  });
};

const getLogItemSuccess = (state: ILogItemState, action: ILogItemAction): ILogItemState => {
  return updateObject(state, {
    logItem: action.logItem,
    loading: false,
    error: null,
  });
};

const getLogItemFail = (state: ILogItemState, action: ILogItemAction): ILogItemState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearLogItems = (state: ILogItemState, action: ILogItemAction): ILogItemState => {
  return updateObject(state, {
    logItems: null,
    loading: false,
    error: null,
  });
};

const reducer = (
  state: ILogItemState = initialState,
  action: ILogItemAction
): ILogItemState => {
  switch (action.type) {
    case ActionTypes.LIST_LOG_ITEMS_START:
      return listLogItemsStart(state, action);
    case ActionTypes.LIST_LOG_ITEMS_SUCCESS:
      return listLogItemsSuccess(state, action);
    case ActionTypes.LIST_LOG_ITEMS_FAIL:
      return listLogItemsFail(state, action);
    case ActionTypes.GET_LOG_ITEM_START:
      return getLogItemStart(state, action);
    case ActionTypes.GET_LOG_ITEM_SUCCESS:
      return getLogItemSuccess(state, action);
    case ActionTypes.GET_LOG_ITEM_FAIL:
      return getLogItemFail(state, action);
    case ActionTypes.CLEAR_LOG_ITEMS:
      return clearLogItems(state, action);
    default:
      return state;
  }
};

export default reducer;
