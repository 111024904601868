import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DealContext from "../../../../../context/DealContext";
import { EDealStatus, ELeasingModel, InputSuffix } from "../../../../../enums";
import { IDealPricingVariation } from "../../../../../interfaces";
import { toTwoDecimals } from "../../../../../shared/utility";
import { ETranslation } from "../../../../../translations/translation-keys";
import Button, { EButtonColor } from "../../../../UI/Button/Button";
import Fieldset from "../../../../UI/Fieldset/Fieldset";
import classes from "./DealVariationPreview.module.scss";
import { VAT_PERCENT_24, VAT_PERCENT_255 } from "../../../../../shared/vat";

interface IProps {
  variation: IDealPricingVariation;
  index?: number
  status?: EDealStatus | null;
  accept?: boolean
  leasingModel?: ELeasingModel | null;
  isCar?: boolean;
  hideVatPrices?: boolean;
  vatPercent?: number;
}

const DealVariationPreview: React.FC<IProps> = ({ variation, index, status, accept, leasingModel, isCar, hideVatPrices, vatPercent }) => {
  const { setDealHandoverDialog } = useContext(DealContext);
  const { t } = useTranslation();

  const vatPercentage = useMemo(() => +(vatPercent ?? VAT_PERCENT_24), [vatPercent]);
  const isLegacyVat = useMemo(() => +vatPercentage === VAT_PERCENT_24, [vatPercentage]);
  const vatPercentTranslation = useMemo(() => isLegacyVat ? t(ETranslation.DEAL_VAT_24) : t(ETranslation.DEAL_VAT_255), [isLegacyVat, t]);
  const vat255Translation = useMemo(() => t(ETranslation.DEAL_VAT_255), [t]);

  if (
    (!variation.contractLength
      && !variation.contractKilometers
      && !variation.monthlyPaymentVAT
      && !variation.monthlyPayment
      && !variation.residualValueVAT
      && !variation.residualValue
      && !variation.firstLargerRent
      && !variation.maintenanceBudgetTotal
      && !variation.downPayment)
    || variation.hidden) {
    return null;
  };

  const mapVariation = () => {
    const {
      contractLength, contractKilometers, monthlyPaymentVAT, monthlyPayment,
      residualValueVAT, residualValue, firstLargerRent, downPayment,
      maintenanceBudgetTotal, extraKilometerCharge, monthlyInstallmentFirstInstallment, monthlyInstallmentFirstInstallmentVAT,
    } = variation;

    return (
      <table className={classes.Variation}>
        <tbody>
          {contractLength ? (
            <tr>
              <th>{t(ETranslation.DEAL_CONTRACT_LENGTH)}</th>
              <td>{contractLength} {InputSuffix.MONTHS(t)}</td>
            </tr>
          ) : null}
          {contractKilometers ? (
            <tr>
              <th>{t(ETranslation.DEAL_CONTRACT_KILOMETERS)}</th>
              <td>{contractKilometers} {InputSuffix.KILOMETERS}</td>
            </tr>
          ) : null}
          {!firstLargerRent && (monthlyPayment || monthlyPaymentVAT) ? (
            <tr>
              <th>{t(ETranslation.DEAL_MONTHLY)}</th>
              <td>{monthlyPayment && `${monthlyPayment} ${InputSuffix.EUROS_PER_MONTH(t)} ${t(ETranslation.DEAL_PERCENTAGE)}`}</td>
              <td>{!hideVatPrices && monthlyPaymentVAT && `${monthlyPaymentVAT} ${InputSuffix.EUROS_PER_MONTH(t)} ${vatPercentTranslation}`}</td>
              <td>{!hideVatPrices && monthlyPayment && isLegacyVat && `${toTwoDecimals(monthlyPayment * VAT_PERCENT_255)} ${InputSuffix.EUROS_PER_MONTH(t)} ${vat255Translation}`}</td>
            </tr>
          ) : null}
          {firstLargerRent && (monthlyInstallmentFirstInstallment || monthlyInstallmentFirstInstallmentVAT) ? (
            <tr>
              <th>{t(ETranslation.DEAL_MONTHLY_INSTALLMENT_FIRST)}</th>
              <td>{monthlyInstallmentFirstInstallment && `${monthlyInstallmentFirstInstallment} ${InputSuffix.EUROS} ${t(ETranslation.DEAL_PERCENTAGE)}`}</td>
              <td>{!hideVatPrices && monthlyInstallmentFirstInstallmentVAT && `${monthlyInstallmentFirstInstallmentVAT} ${InputSuffix.EUROS} ${vatPercentTranslation}`}</td>
              <td>{!hideVatPrices && monthlyInstallmentFirstInstallment && isLegacyVat && `${toTwoDecimals(monthlyInstallmentFirstInstallment * VAT_PERCENT_255)} ${InputSuffix.EUROS} ${vat255Translation}`}</td>
            </tr>
          ) : null}
          {leasingModel && leasingModel !== ELeasingModel.HUOLTO && (residualValue || residualValueVAT) ? (
            <tr>
              <th>{t(ETranslation.DEAL_RESIDUAL)}</th>
              <td>{isCar && residualValueVAT
                ? `${toTwoDecimals(residualValueVAT / vatPercentage)} ${InputSuffix.EUROS} ${t(ETranslation.DEAL_PERCENTAGE)}`
                : residualValue ? `${residualValue} ${InputSuffix.EUROS} ${t(ETranslation.DEAL_PERCENTAGE)}`
                  : null
              }</td>
              <td>{!hideVatPrices && residualValueVAT && `${residualValueVAT} ${InputSuffix.EUROS} ${vatPercentTranslation}`}</td>
              <td>{!hideVatPrices && isLegacyVat && 
              residualValueVAT ? `${toTwoDecimals((residualValueVAT / VAT_PERCENT_24) * VAT_PERCENT_255)} ${InputSuffix.EUROS} ${vat255Translation}` : 
              residualValue ? `${toTwoDecimals(residualValue * VAT_PERCENT_255)} ${InputSuffix.EUROS} ${vat255Translation}` : null}</td>
            </tr>
          ) : null}
          {firstLargerRent ? (
            <tr>
              <th>{t(ETranslation.DEAL_FIRST_RENT)}</th>
              {isCar ? (
                <>
                  <td>{toTwoDecimals(firstLargerRent / (isLegacyVat ? VAT_PERCENT_24 : vatPercentage))} {InputSuffix.EUROS} {t(ETranslation.DEAL_PERCENTAGE)}</td>
                  <td>{firstLargerRent} {InputSuffix.EUROS} {vatPercentTranslation}</td>
                  {isLegacyVat && <td>{toTwoDecimals((firstLargerRent / VAT_PERCENT_24) * VAT_PERCENT_255)} {InputSuffix.EUROS} {vat255Translation}</td>}
                </>
              ) : (
                <>
                  <td>{firstLargerRent} {InputSuffix.EUROS} {t(ETranslation.DEAL_PERCENTAGE)}</td>
                  <td>{!hideVatPrices && `${toTwoDecimals(firstLargerRent * vatPercentage)} ${InputSuffix.EUROS} ${vatPercentTranslation}`}</td>
                  <td>{!hideVatPrices && isLegacyVat && `${toTwoDecimals(firstLargerRent * VAT_PERCENT_255)} ${InputSuffix.EUROS} ${vat255Translation}`}</td>
                </>
              )}
            </tr>
          ) : null}
          {downPayment ? isCar ? (
            <tr>
              <th>{t(ETranslation.DEAL_DOWN)}</th>
              <td>{toTwoDecimals(downPayment / (isLegacyVat ? VAT_PERCENT_24 : vatPercentage))} {InputSuffix.EUROS} {t(ETranslation.DEAL_PERCENTAGE)}</td>
              <td>{!hideVatPrices && `${downPayment} ${InputSuffix.EUROS} ${vatPercentTranslation}`}</td>
              <td>{!hideVatPrices && isLegacyVat && `${toTwoDecimals((downPayment / VAT_PERCENT_24) * VAT_PERCENT_255)} ${InputSuffix.EUROS} ${vat255Translation}`}</td>
            </tr>
          ) : (
            <tr>
              <th>{t(ETranslation.DEAL_DOWN)}</th>
              <td>{downPayment} {InputSuffix.EUROS} {t(ETranslation.DEAL_PERCENTAGE)}</td>
              <td>{!hideVatPrices && `${toTwoDecimals(downPayment * vatPercentage)} ${InputSuffix.EUROS} ${vatPercentTranslation}`}</td>
              <td>{!hideVatPrices && isLegacyVat && `${toTwoDecimals(downPayment * VAT_PERCENT_255)} ${InputSuffix.EUROS} ${vat255Translation}`}</td>
            </tr>
          ) : null}
          {leasingModel && leasingModel === ELeasingModel.REILU && maintenanceBudgetTotal ? (
            <tr>
              <th>{t(ETranslation.DEAL_MAINTENANCE_BUDGET_TOTAL)}</th>
              <td>{toTwoDecimals(maintenanceBudgetTotal / (isLegacyVat ? VAT_PERCENT_24 : vatPercentage))} {InputSuffix.EUROS} {t(ETranslation.DEAL_PERCENTAGE)}</td>
              <td>{maintenanceBudgetTotal} {InputSuffix.EUROS} {vatPercentTranslation}</td>
              {isLegacyVat && <td>{toTwoDecimals((maintenanceBudgetTotal / VAT_PERCENT_24) * VAT_PERCENT_255)} {InputSuffix.EUROS} {vat255Translation}</td>}
            </tr>
          ) : null}
          {leasingModel && leasingModel === ELeasingModel.HUOLTO && extraKilometerCharge ? (
            <tr>
              <th>{t(ETranslation.DEAL_EXTRA_KILOMETER)}</th>
              <td>{toTwoDecimals(extraKilometerCharge / (isLegacyVat ? VAT_PERCENT_24 : vatPercentage))} {InputSuffix.EUROS_PER_KILOMETER} {t(ETranslation.DEAL_PERCENTAGE)}</td>
              <td>{extraKilometerCharge} {InputSuffix.EUROS_PER_KILOMETER} {vatPercentTranslation}</td>
              {isLegacyVat && <td>{toTwoDecimals((extraKilometerCharge / VAT_PERCENT_24) * VAT_PERCENT_255)} {InputSuffix.EUROS_PER_KILOMETER} {vat255Translation}</td>}
            </tr>
          ) : null}
          {isLegacyVat && (
            <tr>
              <th></th>
              <td></td>
              <td></td>
              <td>{t(ETranslation.DEAL_VAT_HELP_TEXT)}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  return (
    <div className={classes.Container}>
      <Fieldset label={(!status || status === EDealStatus.OFFER) ? `${t(ETranslation.DEAL_VARIATION)} #${index}` : t(ETranslation.DEAL_VARIATION)}>
        {mapVariation()}
        {!accept && status && status === EDealStatus.OFFER && (
          <Button color={EButtonColor.SECONDARY} onClick={() => setDealHandoverDialog(variation)} style={{ marginTop: "0.5rem" }}>
            {t(ETranslation.DEAL_PREVIEW_CREATE_SUBSCRIPTION_AGREEMENT)}
          </Button>
        )}
      </Fieldset>
    </div>
  );
};

export default DealVariationPreview;
