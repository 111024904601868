import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import axios from "../../axios";
import {
  IAppState,
  IDealAction,
  IDealTerminationCalculation,
  IIdListObject,
  IInvoice,
  IInvoiceRow,
} from "../../interfaces";
import {
  JSON_DEALS_ADD,
  JSON_DEALS_ADD_DRAFT,
  JSON_DEALS_ARCHIVE,
  JSON_DEALS_ARCHIVE_DRAFT,
  JSON_DEALS_CONFIRM_SIGNATURE,
  JSON_DEALS_COPY,
  JSON_DEALS_GET,
  JSON_DEALS_GET_DRAFT,
  JSON_DEALS_GET_PREOFFER,
  JSON_DEALS_IDENTIFY,
  JSON_DEALS_LIST,
  JSON_DEALS_LIST_DRAFTS,
  JSON_DEALS_MAINTENANCE_INFO_GET,
  JSON_DEALS_SEND,
  JSON_DEALS_SEND_RETURN_INFO,
  JSON_DEALS_TERMINATE_GET,
  JSON_DEALS_TERMINATION_CALCULATION,
  JSON_DEALS_TOKEN_GET,
  JSON_DEALS_UPDATE,
  JSON_DEALS_UPDATE_DRAFT,
  JSON_DEALS_UPDATE_STATUS,
  JSON_INVOICES_DETACH,
  JSON_INVOICES_ROWS_EDIT,
  JSON_PIPEDRIVE_DEALS_GET,
  JSON_TALENOM_INVOICES_DETACH
} from "./../../endpoints/index";
import { TDispatch } from "./../../index";
import { IDeal } from "./../../interfaces/domain";
import { ActionTypes } from "./actionTypes";
import { EIdentificationType } from "../../components/Deals/Deal/DealView/DealSignatureDialog/DealSignatureDialog";
import { EDealStatus } from "../../enums";

const getDealStart = (): IDealAction => {
  return {
    type: ActionTypes.GET_DEAL_START,
  };
};

const getDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.GET_DEAL_SUCCESS,
    deal,
  };
};

const getDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.GET_DEAL_FAIL,
    error,
  };
};

export const getDeal = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getDealStart());
    try {
      const res = await axios.get(JSON_DEALS_GET(id));
      dispatch(getDealSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getDealFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listDealsStart = (): IDealAction => {
  return {
    type: ActionTypes.LIST_DEALS_START,
  };
};

const listDealsSuccess = (deals: Array<IDeal>): IDealAction => {
  return {
    type: ActionTypes.LIST_DEALS_SUCCESS,
    deals,
  };
};

const listDealsFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.LIST_DEALS_FAIL,
    error,
  };
};

type TListDealsPromise = Promise<IDeal[] | null>;
type TListDealsAction = ThunkAction<
  TListDealsPromise,
  IAppState,
  any,
  IDealAction
>;

export interface IDealsSearchOptions {
  financeCompanyId?: string;
  status?: string;
  sellerId?: string;
  customerId?: string;
  type?: string;
  fetchAll?: boolean;
}

export const listDeals =
  (options?: IDealsSearchOptions): TListDealsAction =>
  (dispatch: TDispatch): TListDealsPromise => {
    dispatch(listDealsStart());
    return axios
      .post(JSON_DEALS_LIST, options)
      .then((res) => {
        dispatch(listDealsSuccess(res.data));
        return Promise.resolve(res.data);
      })
      .catch(({ response }) => {
        dispatch(listDealsFail((response as AxiosResponse).data));
        return Promise.resolve((response as AxiosResponse).data);
      });
  };

const saveDealStart = (): IDealAction => {
  return {
    type: ActionTypes.SAVE_DEAL_START,
  };
};

const saveDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.SAVE_DEAL_SUCCESS,
    deal,
  };
};

const saveDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.SAVE_DEAL_FAIL,
    error,
  };
};

type TSaveDealPromise = Promise<IDeal | null>;
type TSaveDealAction = ThunkAction<
  TSaveDealPromise,
  IAppState,
  any,
  IDealAction
>;

export const saveDeal =
  (deal: IDeal): TSaveDealAction =>
  (dispatch: TDispatch): TSaveDealPromise => {
    dispatch(saveDealStart());
    return axios
      .post(JSON_DEALS_ADD, deal)
      .then((res) => {
        dispatch(saveDealSuccess(res.data));
        return Promise.resolve(res.data);
      })
      .catch(({ response }) => {
        dispatch(saveDealFail((response as AxiosResponse).data));
        return Promise.resolve((response as AxiosResponse).data);
      });
  };

const updateDealStart = (): IDealAction => {
  return {
    type: ActionTypes.UPDATE_DEAL_START,
  };
};

const updateDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.UPDATE_DEAL_SUCCESS,
    deal,
  };
};

const updateDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.UPDATE_DEAL_FAIL,
    error,
  };
};

export const updateDeal =
  (deal: IDeal): TSaveDealAction =>
  (dispatch: TDispatch): TSaveDealPromise => {
    dispatch(updateDealStart());
    return axios
      .put(JSON_DEALS_UPDATE, deal)
      .then((res) => {
        dispatch(updateDealSuccess(res.data));
        return Promise.resolve(res.data);
      })
      .catch(({ response }) => {
        dispatch(updateDealFail((response as AxiosResponse).data));
        return Promise.resolve((response as AxiosResponse).data);
      });
  };

export const clearDeal = () => {
  return {
    type: ActionTypes.CLEAR_DEAL,
  };
};

const getTokenDealStart = (): IDealAction => {
  return {
    type: ActionTypes.GET_TOKEN_DEAL_START,
  };
};

const getTokenDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.GET_TOKEN_DEAL_SUCCESS,
    deal,
  };
};

const getTokenDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.GET_TOKEN_DEAL_FAIL,
    error,
  };
};

export const getTokenDeal = (id: string, token: string, printable: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(getTokenDealStart());
    try {
      const res = await axios.get(JSON_DEALS_TOKEN_GET(id, token, printable));
      dispatch(getTokenDealSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getTokenDealFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const sendDealStart = (): IDealAction => {
  return {
    type: ActionTypes.SEND_DEAL_START,
  };
};

const sendDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.SEND_DEAL_SUCCESS,
    deal,
  };
};

const sendDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.SEND_DEAL_FAIL,
    error,
  };
};

export const sendDeal = (id: string, recipients: string[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(sendDealStart());
    try {
      const res = await axios.post(JSON_DEALS_SEND, { id, recipients });
      dispatch(sendDealSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(sendDealFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const sendDealReturnInfoStart = (): IDealAction => {
  return {
    type: ActionTypes.SEND_DEAL_RETURN_INFO_START,
  };
};

const sendDealReturnInfoSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.SEND_DEAL_RETURN_INFO_SUCCESS,
    deal,
  };
};

const sendDealReturnInfoFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.SEND_DEAL_RETURN_INFO_FAIL,
    error,
  };
};

export const sendDealReturnInfo = (id: string, recipients: string[], message: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(sendDealReturnInfoStart());
    try {
      const res = await axios.post(JSON_DEALS_SEND_RETURN_INFO, { id, recipients, message });
      dispatch(sendDealReturnInfoSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(sendDealReturnInfoFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const getPipedriveDealStart = (): IDealAction => {
  return {
    type: ActionTypes.GET_PIPEDRIVE_DEAL_START,
  };
};

const getPipedriveDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.GET_PIPEDRIVE_DEAL_SUCCESS,
    deal,
  };
};

const getPipedriveDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.GET_PIPEDRIVE_DEAL_FAIL,
    error,
  };
};

export const getPipedriveDeal = (
  pipedriveDealId: string,
  pipedriveOrganizationId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPipedriveDealStart());
    try {
      const res = await axios.get(
        JSON_PIPEDRIVE_DEALS_GET(pipedriveDealId, pipedriveOrganizationId)
      );
      dispatch(getPipedriveDealSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getPipedriveDealFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const updateDealStatusStart = (): IDealAction => {
  return {
    type: ActionTypes.UPDATE_DEAL_STATUS_START,
  };
};

const updateDealStatusSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.UPDATE_DEAL_STATUS_SUCCESS,
    deal,
  };
};

const updateDealStatusFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.UPDATE_DEAL_STATUS_FAIL,
    error,
  };
};

export const updateDealStatus =
  (deal: IDeal): TSaveDealAction =>
  (dispatch: TDispatch): TSaveDealPromise => {
    dispatch(updateDealStatusStart());
    return axios
      .put(JSON_DEALS_UPDATE_STATUS, deal)
      .then((res) => {
        dispatch(updateDealStatusSuccess(res.data));
        return Promise.resolve(res.data);
      })
      .catch(({ response }) => {
        dispatch(updateDealStatusFail((response as AxiosResponse).data));
        return Promise.resolve((response as AxiosResponse).data);
      });
  };

const copyDealStart = (): IDealAction => {
  return {
    type: ActionTypes.COPY_DEAL_START,
  };
};

const copyDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.COPY_DEAL_SUCCESS,
    deal,
  };
};

const copyDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.COPY_DEAL_FAIL,
    error,
  };
};

export const copyDeal =
  (deal: IDeal): TSaveDealAction =>
  (dispatch: TDispatch): TSaveDealPromise => {
    dispatch(copyDealStart());
    return axios
      .post(JSON_DEALS_COPY, deal)
      .then((res) => {
        dispatch(copyDealSuccess(res.data));
        return Promise.resolve(res.data);
      })
      .catch(({ response }) => {
        dispatch(copyDealFail((response as AxiosResponse).data));
        return Promise.resolve((response as AxiosResponse).data);
      });
  };

const archiveDealStart = (): IDealAction => {
  return {
    type: ActionTypes.ARCHIVE_DEAL_START,
  };
};

const archiveDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.ARCHIVE_DEAL_SUCCESS,
    deal,
  };
};

const archiveDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.ARCHIVE_DEAL_FAIL,
    error,
  };
};

export const archiveDeal =
  (id: string): TSaveDealAction =>
  (dispatch: TDispatch): TSaveDealPromise => {
    dispatch(archiveDealStart());
    return axios
      .put(JSON_DEALS_ARCHIVE(id))
      .then((res) => {
        dispatch(archiveDealSuccess(res.data));
        return Promise.resolve(res.data);
      })
      .catch(({ response }) => {
        dispatch(archiveDealFail((response as AxiosResponse).data));
        return Promise.resolve((response as AxiosResponse).data);
      });
  };

const detachInvoiceStart = (): IDealAction => {
  return {
    type: ActionTypes.DETACH_INVOICE_START,
  };
};

const detachInvoiceSuccess = (invoiceId: string): IDealAction => {
  return {
    type: ActionTypes.DETACH_INVOICE_SUCCESS,
    invoiceId,
  };
};

const detachInvoiceFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.DETACH_INVOICE_FAIL,
    error,
  };
};

export const detachInvoice = (invoiceId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(detachInvoiceStart());
    try {
      const res = await axios.put(JSON_INVOICES_DETACH(invoiceId));
      dispatch(detachInvoiceSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(detachInvoiceFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const editInvoiceRowsStart = (): IDealAction => {
  return {
    type: ActionTypes.EDIT_INVOICE_ROWS_START,
  };
};

const editInvoiceRowsSuccess = (invoice: IInvoice): IDealAction => {
  return {
    type: ActionTypes.EDIT_INVOICE_ROWS_SUCCESS,
    invoice,
  };
};

const editInvoiceRowsFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.EDIT_INVOICE_ROWS_FAIL,
    error,
  };
};

export const editInvoiceRows = (invoiceId: string, rows: IInvoiceRow[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(editInvoiceRowsStart());
    try {
      const res = await axios.put(JSON_INVOICES_ROWS_EDIT(invoiceId), { rows });
      dispatch(editInvoiceRowsSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(editInvoiceRowsFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const saveTerminationCalculationStart = (): IDealAction => {
  return {
    type: ActionTypes.SAVE_TERMINATION_CALCULATION_START,
  };
};

const saveTerminationCalculationSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.SAVE_TERMINATION_CALCULATION_SUCCESS,
    deal,
  };
};

const saveTerminationCalculationFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.SAVE_TERMINATION_CALCULATION_FAIL,
    error,
  };
};

export const saveTerminationCalculation = (dealId: string, terminationCalculation: IDealTerminationCalculation) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveTerminationCalculationStart());
    try {
      const res = await axios.put(JSON_DEALS_TERMINATION_CALCULATION(dealId), { terminationCalculation });
      dispatch(saveTerminationCalculationSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(saveTerminationCalculationFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const detachTalenomInvoiceStart = (): IDealAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_DETACH_START,
  };
};

const detachTalenomInvoiceSuccess = (invoiceId: string): IDealAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_DETACH_SUCCESS,
    invoiceId,
  };
};

const detachTalenomInvoiceFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.TALENOM_INVOICES_DETACH_FAIL,
    error,
  };
};

export const detachTalenomInvoice = (invoiceId: string, projectKey: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(detachTalenomInvoiceStart());
    try {
      const res = await axios.post(`${JSON_TALENOM_INVOICES_DETACH}?invoiceId=${invoiceId}&projectKey=${projectKey}`);
      dispatch(detachTalenomInvoiceSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(detachTalenomInvoiceFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const getTerminateDealStart = (): IDealAction => {
  return {
    type: ActionTypes.GET_TERMINATE_DEAL_START,
  };
};

const getTerminateDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.GET_TERMINATE_DEAL_SUCCESS,
    deal,
  };
};

const getTerminateDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.GET_TERMINATE_DEAL_FAIL,
    error,
  };
};

export const getTerminateDeal = (id: string, token: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getTerminateDealStart());
    try {
      const res = await axios.get(JSON_DEALS_TERMINATE_GET(id, token));
      dispatch(getTerminateDealSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getTerminateDealFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const getMaintenanceInfoDealStart = (): IDealAction => {
  return {
    type: ActionTypes.GET_MAINTENANCE_INFO_DEAL_START,
  };
};

const getMaintenanceInfoDealSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.GET_MAINTENANCE_INFO_DEAL_SUCCESS,
    deal,
  };
};

const getMaintenanceInfoDealFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.GET_MAINTENANCE_INFO_DEAL_FAIL,
    error,
  };
};

export const getMaintenanceInfoDeal = (id: string, token: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getMaintenanceInfoDealStart());
    try {
      const res = await axios.get(JSON_DEALS_MAINTENANCE_INFO_GET(id, token));
      dispatch(getMaintenanceInfoDealSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getMaintenanceInfoDealFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const dealIdentifyStart = (): IDealAction => {
  return {
    type: ActionTypes.DEAL_IDENTIFY_START,
  };
};

const dealIdentifySuccess = (netsUrl: string): IDealAction => {
  return {
    type: ActionTypes.DEAL_IDENTIFY_SUCCESS,
    netsUrl,
  };
};

const dealIdentifyFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.DEAL_IDENTIFY_FAIL,
    error,
  };
};

export const dealIdentify = (id: string, token: string, type: EIdentificationType, variationId?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(dealIdentifyStart());
    try {
      const res = await axios.get(JSON_DEALS_IDENTIFY(id, token, type, variationId));
      dispatch(dealIdentifySuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(dealIdentifyFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const confirmSignatureStart = (): IDealAction => {
  return {
    type: ActionTypes.DEAL_CONFIRM_SIGNATURE_START,
  };
};

const confirmSignatureSuccess = (): IDealAction => {
  return {
    type: ActionTypes.DEAL_CONFIRM_SIGNATURE_SUCCESS,
  };
};

const confirmSignatureFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.DEAL_CONFIRM_SIGNATURE_FAIL,
    error,
  };
};

export const confirmSignature = (id: string, token: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(confirmSignatureStart());
    try {
      const res = await axios.get(JSON_DEALS_CONFIRM_SIGNATURE(id, token));
      dispatch(confirmSignatureSuccess());
      return res.data;
    } catch ({ response }) {
      dispatch(confirmSignatureFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const saveDraftStart = (): IDealAction => {
  return {
    type: ActionTypes.SAVE_DRAFT_START,
  };
};

const saveDraftSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.SAVE_DRAFT_SUCCESS,
    deal,
  };
};

const saveDraftFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.SAVE_DRAFT_FAIL,
    error,
  };
};

export const saveDraft = (deal: IDeal) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveDraftStart());
    try {
      const res = await axios.post(JSON_DEALS_ADD_DRAFT, deal);
      dispatch(saveDraftSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveDraftFail((response as AxiosResponse).data));
    }
  };
};

const updateDraftStart = (): IDealAction => {
  return {
    type: ActionTypes.UPDATE_DRAFT_START,
  };
};

const updateDraftSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.UPDATE_DRAFT_SUCCESS,
    deal,
  };
};

const updateDraftFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.UPDATE_DRAFT_FAIL,
    error,
  };
};

//"send" is used to set sendDate and Status draft -> preoffer
export const updateDraft = (deal: IDeal, send: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateDraftStart());
    try {
      const res = await axios.put(JSON_DEALS_UPDATE_DRAFT(send), deal);
      dispatch(updateDraftSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateDraftFail((response as AxiosResponse).data));
    }
  };
};

const listDraftsStart = (): IDealAction => {
  return {
    type: ActionTypes.LIST_DRAFTS_START,
  };
};

const listDraftsSuccess = (
  json: PartnerDealsJson
): IDealAction => {
  return {
    type: ActionTypes.LIST_DRAFTS_SUCCESS,
    deals: json.deals,
  };
};

const listDraftsFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.LIST_DRAFTS_FAIL,
    error,
  };
};

export const listDrafts = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listDraftsStart());
    try {
      const res = await axios.post(JSON_DEALS_LIST_DRAFTS(EDealStatus.DRAFT));
      dispatch(listDraftsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listDraftsFail((response as AxiosResponse).data));
    }
  };
};

const listPreoffersStart = (): IDealAction => {
  return {
    type: ActionTypes.LIST_PREOFFERS_START,
  };
};

const listPreoffersSuccess = (
  json: PartnerDealsJson
): IDealAction => {
  return {
    type: ActionTypes.LIST_PREOFFERS_SUCCESS,
    deals: json.deals,
    idList: json.idList
  };
};

const listPreoffersFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.LIST_PREOFFERS_FAIL,
    error,
  };
};

export interface IPreoffersSearchOptions {
  partnerId?: string;
  archived?: boolean;
}

export const listPreoffers = (options?: IPreoffersSearchOptions) => {
  return async (dispatch: Dispatch) => {
    dispatch(listPreoffersStart());
    try {
      const res = await axios.post(JSON_DEALS_LIST_DRAFTS(EDealStatus.PREOFFER), options);
      dispatch(listPreoffersSuccess(res.data));
    } catch ({ response }) {
      dispatch(listPreoffersFail((response as AxiosResponse).data));
    }
  };
};

const getDraftStart = (): IDealAction => {
  return {
    type: ActionTypes.GET_DRAFT_START,
  };
};

const getDraftSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.GET_DRAFT_SUCCESS,
    deal,
  };
};

const getDraftFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.GET_DRAFT_FAIL,
    error,
  };
};

export const getDraft = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getDraftStart());
    try {
      const res = await axios.get(JSON_DEALS_GET_DRAFT(id));
      dispatch(getDraftSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getDraftFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const archiveDraftStart = (): IDealAction => {
  return {
    type: ActionTypes.ARCHIVE_DRAFT_START,
  };
};

const archiveDraftSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.ARCHIVE_DRAFT_SUCCESS,
    deal,
  };
};

const archiveDraftFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.ARCHIVE_DRAFT_FAIL,
    error,
  };
};

export const archiveDraft = (id: string): TSaveDealAction =>
  (dispatch: TDispatch): TSaveDealPromise => {
    dispatch(archiveDraftStart());
    return axios
      .put(JSON_DEALS_ARCHIVE_DRAFT(id))
      .then((res) => {
        dispatch(archiveDraftSuccess(res.data));
        return Promise.resolve(res.data);
      })
      .catch(({ response }) => {
        dispatch(archiveDraftFail((response as AxiosResponse).data));
        return Promise.resolve((response as AxiosResponse).data);
      });
  };

const getPreofferStart = (): IDealAction => {
  return {
    type: ActionTypes.GET_PREOFFER_START,
  };
};

const getPreofferSuccess = (deal: IDeal): IDealAction => {
  return {
    type: ActionTypes.GET_PREOFFER_SUCCESS,
    deal,
  };
};

const getPreofferFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.GET_PREOFFER_FAIL,
    error,
  };
};

export const getPreoffer = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPreofferStart());
    try {
      const res = await axios.get(JSON_DEALS_GET_PREOFFER(id));
      dispatch(getPreofferSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getPreofferFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listPartnerDealsStart = (): IDealAction => {
  return {
    type: ActionTypes.LIST_PARTNER_DEALS_START,
  };
};

interface PartnerDealsJson {
  precontracts: Array<IDeal>;
  contracts: Array<IDeal>;
  terminates: Array<IDeal>;
  deals: Array<IDeal>;
  idList: Array<IIdListObject>;
}
const listPartnerDealsSuccess = (
  json: PartnerDealsJson
): IDealAction => {
  return {
    type: ActionTypes.LIST_PARTNER_DEALS_SUCCESS,
    precontracts: json.precontracts,
    contracts: json.contracts,
    terminates: json.terminates
  };
};

const listPartnerDealsFail = (error: string): IDealAction => {
  return {
    type: ActionTypes.LIST_PARTNER_DEALS_FAIL,
    error,
  };
};

export const listPartnerDeals = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listPartnerDealsStart());
    try {
      const res = await axios.post(JSON_DEALS_LIST_DRAFTS());
      dispatch(listPartnerDealsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listPartnerDealsFail((response as AxiosResponse).data));
    }
  };
};