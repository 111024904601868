import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { generateUUID } from "../../../../../shared/utility";
import { ETranslation } from "../../../../../translations/translation-keys";
import Button from "../../../../UI/Button/Button";
import Input, { EInputType, IOption } from "../../../../UI/Input/Input";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import ModalBody from "../../../../UI/Modal/ModalBody/ModalBody";
import ModalFooter from "../../../../UI/Modal/ModalFooter/ModalFooter";
import TextInput from "../../../../UI/TextInput/TextInput";

interface IProps {
  sendDeal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, recipients: string[], message: string, id?: string, ) => void;
  closeDialog: () => void;
  emails?: IOption[];
  content?: string;
  closeText?: string;
  id?: string;
  messageInput?: boolean;
  messageValue?: string;
}

const DealSendDialog: React.FC<IProps> = ({ sendDeal, closeDialog, emails, content, closeText, id, messageInput, messageValue }) => {
  const { t } = useTranslation();
  const [recipients, setRecipients] = useState<IOption[]>(emails ?? [{ value: generateUUID("email"), label: "" }]);
  const [message, setMessage] = useState(messageValue ?? "");

  const getData = () => {
    let emails: string[] = [];
    recipients.forEach(r => {
      if (r.label?.trim().length !== 0) emails.push(r.label as string);
    });
    return emails;
  }

  const updateEmail = (option: IOption) => {
    setRecipients(prevRecipients =>
      prevRecipients.map(el => el.value === option.value ? { ...option } : el)
    );
  };

  const addEmail = () => {
    setRecipients(prevRecipients => [
      ...prevRecipients, 
      { value: generateUUID("email"), label: "" }
    ]);
  };

  const removeEmail = (option: IOption) => {
    setRecipients(prevRecipients =>
      prevRecipients.filter(f => f.value !== option.value)
    );
  };

  return (
    <React.Fragment>
      {!messageInput && (<p>{content ?? t(ETranslation.DEAL_SEND_CONTENT)}</p>)}
      <ModalBody style={{ display: "flex", flexDirection: "column" }}>
        {messageInput &&
          <Input
            inputName="sendDialogMessage"
            type={EInputType.textarea}
            value={message}
            onChange={(value) => setMessage(value as string)}
            label={t(ETranslation.DEAL_SEND_RETURN_INFO_LABEL)}
          />
        }
        {messageInput && <p>{t(ETranslation.DEAL_SEND_RETURN_INFO_RECIPIENTS)}</p>}
        {recipients.map(r => <TextInput key={r.value} id={r.value} value={r.label} onUpdate={updateEmail} onRemove={removeEmail} />)}
        <InputGroup>
          <Button onClick={addEmail}>
            {t(ETranslation.COMMON_ADD)} {t(ETranslation.USER_EMAIL)}
          </Button>
        </InputGroup>
      </ModalBody>
      <ModalFooter>
        {messageInput && <p>{(content ?? t(ETranslation.DEAL_SEND_CONTENT))}</p>}
        <InputGroup>
          <Button
            onClick={(event) => {
              sendDeal(event, getData(), message, id)
            }}
          >
            {t(ETranslation.DEAL_ACTION_SEND)}
          </Button>
          <Button onClick={closeDialog}>{closeText ?? t(ETranslation.COMMON_NO)}</Button>
        </InputGroup>
      </ModalFooter>
    </React.Fragment>
  );
}

export default DealSendDialog;
