// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs

// Add the Firebase services that you want to use
import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyCkq-3b71C__hk9TlxSL4BxP_-tJ-cr6EY",
  authDomain: "leasingmanagement.firebaseapp.com",
  databaseURL: "https://leasingmanagement.firebaseio.com",
  projectId: "leasingmanagement",
  storageBucket: "leasingmanagement.appspot.com",
  messagingSenderId: "28324948880",
  appId: "1:28324948880:web:c9df3b049365f99e9da115"
};

// Initialize Firebase
export default initializeApp(firebaseConfig);
