import { faFile, faSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { IIdentificationInfo } from "../../../../../interfaces";
import { ETranslation } from "../../../../../translations/translation-keys";
import classes from "./DealIdentification.module.scss";
import Button, { EButtonColor } from "../../../../UI/Button/Button";

interface IProps {
  identification: IIdentificationInfo;
}

const DealIdentification: React.FC<IProps> = ({ identification }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.Info}>
      <div className={classes.Item}>
        <FontAwesomeIcon icon={faSignature} />
        {t(ETranslation.DEAL_IDENTIFICATION_IDENTIFIED)}
      </div>
      <div>
        <p>{identification.date}</p>
      </div>
      <div className={classes.Item}>
        <span>
          {t(ETranslation.DEAL_IDENTIFICATION_BANK_NAME)}{" "}
          {identification.bankName}
        </span>
        <span>{identification.name}</span>
      </div>
      <div className={classes.PrintHidden}>
        {identification.attachment && (
          <Button
            icon={faFile}
            color={EButtonColor.NONE}
            onClick={() =>
              window.open(identification.attachment.link, "_blank")
            }
          >{identification.attachment.clientName}</Button>
        )}
      </div>
    </div>
  );
};

export default DealIdentification;
