import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "../../classes/Routes";
import DealsTableCustomer from "../../components/Deals/DealsTable/DealsTableCustomer";
import RemainingLimits from "../../components/RemainingLimits/RemainingLimits";
import Alert from "../../components/UI/Alert/Alert";
import Button, { EButtonColor } from "../../components/UI/Button/Button";
import CompactTable from "../../components/UI/CompactTable/CompactTable";
import Spinner from "../../components/UI/Spinner/Spinner";
import { IAppState, IDeal } from "../../interfaces";
import { toTwoDecimals } from "../../shared/utility";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import classes from "./Dashboard.module.scss";

interface IProps extends RouteComponentProps { }

const Dashboard: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { error, loading, dashboard } = useSelector((state: IAppState) => ({
    error: state.dashboard.error,
    loading: state.dashboard.loading,
    dashboard: state.dashboard.dashboard,
  }));

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.getDashboard());
    // eslint-disable-next-line
  }, [dispatch]);

  const showDeal = (deal?: IDeal) => {
    if (deal?.link) {
      window.open(deal.link);
    }
  }

  const mapRemainingLimits = () => {
    if (!dashboard?.organization?.limits) return <p>{t(ETranslation.DASHBOARD_NO_LIMITS)}</p>;
    return <RemainingLimits limits={dashboard.organization.limits} showDeal={showDeal} />;
  }

  const sortDeals = () => {
    return (a: IDeal, b: IDeal) => {
      return (a.updated || "") < (b.updated || "") ? 1 : -1;
    };
  }

  const createFeesTable = () => {
    // if (!fees) return;
    // console.log(fees);
    return (
      <CompactTable containerStyle={{ marginTop: "1rem" }}>
        <thead>
          <tr>
            <th>{t(ETranslation.DASHBOARD_FEES_TOTAL)}</th>
            <th>{t(ETranslation.DASHBOARD_FEES_TOTAL_12)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(dashboard?.fees?.totalMonth && toTwoDecimals(dashboard.fees.totalMonth)) || 0} €</td>
            <td>{(dashboard?.fees?.totalShort && toTwoDecimals(dashboard.fees.totalShort)) || 0} €</td>
          </tr>
        </tbody>
      </CompactTable>
    );
  }

  // const getLiasingLiabilitiesReportLink = () => {
  //   if (!currentUser?.organization?.id) return Routes.AUTH_ERROR;
  //   let link = `https://europe-west3-leasingmanagement.cloudfunctions.net/print?url=${encodeURIComponent("https://fleet.olf.fi")}`;
  //   if (isDevelopment()) link = `http://localhost:3001/screenshot?url=${encodeURIComponent("http://localhost:3000")}`;
  //   return link + encodeURIComponent(Routes.LEASING_LIABILITIES_REPORT(currentUser?.organization?.id));
  // }

  return (
    <div className={classes.Container}>
      {error && <Alert>{t(error)}</Alert>}
      {loading
        ? <Spinner />
        : (
          <>
            <h2>{dashboard?.organization && dashboard?.organization?.name}</h2>
            <Button
              onClick={() => history.push(Routes.LEASING_LIABILITIES_REPORT)}
              color={EButtonColor.SECONDARY}
              style={{ marginBottom: "1rem" }}
            >
              <FontAwesomeIcon icon={faFilePdf} />
              {" "}
              {t(ETranslation.DASHBOARD_LEASING_LIABILITIES_REPORT)}
            </Button>
            
            {dashboard?.contracts && dashboard?.contracts.length > 0 && (
              <div>
                <h3>{t(ETranslation.DASHBOARD_CONTRACTS)}</h3>
                <div className={classes.TableContainer}>
                  <DealsTableCustomer deals={dashboard?.contracts.sort(sortDeals())} onClick={showDeal} />
                </div>
              </div>
              )
            }

            {dashboard?.precontracts && dashboard?.precontracts.length > 0 && (
              <div>
                <h3>{t(ETranslation.DASHBOARD_PRECONTRACTS)}</h3>
                <div className={classes.TableContainer}>
                  <DealsTableCustomer deals={dashboard?.precontracts.sort(sortDeals())} onClick={showDeal} />
                </div>
              </div>
              )
            }

            {dashboard?.offers && dashboard?.offers.length > 0 && (
              <div>
                <h3>{t(ETranslation.DASHBOARD_OFFERS)}</h3>
                <div className={classes.TableContainer}>
                  <DealsTableCustomer deals={dashboard?.offers.sort(sortDeals())} onClick={showDeal} />
                </div>
              </div>
              )
            }

            {dashboard?.fees && 
              <div>
                <h3>{t(ETranslation.DASHBOARD_FEES)}</h3>
                {createFeesTable()}
              </div>
            }

            <div>
              <h3>{t(ETranslation.LIMITS)}</h3>
              {mapRemainingLimits()}
            </div>
          </>
        )
      }
    </div>
  );
}

export default Dashboard;
