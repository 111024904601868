import React, {useCallback } from "react";
import Container from "../../components/UI/Container/Container";
//import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState, ILogItem } from "../../interfaces";
import Alert from "../../components/UI/Alert/Alert";
import Spinner from "../../components/UI/Spinner/Spinner";
import LogItemsTable from "../../components/LogItemsTable/LogItemsTable";
import LogItemsSearch from "../../components/LogItemsSearch/LogItemsSearch";
import { Routes } from "../../classes/Routes";
import { RouteComponentProps } from "react-router-dom";
import { ETranslation } from "../../translations/translation-keys";

interface IProps extends RouteComponentProps { }

const Preoffers: React.FC<IProps> = ({history}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { error, loading, logItems } = useSelector((state: IAppState) => ({
    error: state.logItems.error,
    loading: state.logItems.loading,
    logItems: state.logItems.logItems,
  }));

  const searchHandler = useCallback((searchOptions) => {
    dispatch(actions.listUsers(undefined, true));
    dispatch(actions.listLogItems(searchOptions));
  }, [dispatch]);

  const showLogItem = (logItem?: ILogItem) => {
    if (logItem) {
      history.push(Routes.LOG_ITEM(logItem.id));
    }
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
       
      <Container>
        <h2>{t(ETranslation.LOGS)}</h2>
        <LogItemsSearch onSearch={searchHandler} />
        {loading ? <Spinner/> :
        (logItems &&
          <LogItemsTable logItems={logItems} onClick={showLogItem}/>
        )
        }
      </Container>
      
    </>
  );
}

export default Preoffers;