import { IPartnerAction, IPartnerState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IPartnerState = {
  loading: false,
  partners: null,
  partner: null,
  error: null,
};

const getPartnerStart = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    partner: null,
    loading: true,
    error: null,
  });
};

const getPartnerSuccess = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    partner: action.partner,
    loading: false,
    error: null,
  });
};

const getPartnerFail = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listPartnersStart = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const listPartnersSuccess = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    partners: action.partners,
    loading: false,
    error: null,
  });
};

const listPartnersFail = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const savePartnerStart = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const savePartnerSuccess = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    partner: action.partner,
    loading: false,
    error: null,
  });
};

const savePartnerFail = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updatePartnerStart = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updatePartnerSuccess = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    partner: action.partner,
    loading: false,
    error: null,
  });
};

const updatePartnerFail = (
  state: IPartnerState,
  action: IPartnerAction
): IPartnerState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const deletePartnerStart = (state: IPartnerState, action: IPartnerAction): IPartnerState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const deletePartnerSuccess = (state: IPartnerState, action: IPartnerAction): IPartnerState => {
  return updateObject(state, {
    partners: state.partners?.filter(partner => partner.id !== action.id),
    loading: false,
    error: null,
  });
};

const deletePartnerFail = (state: IPartnerState, action: IPartnerAction): IPartnerState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IPartnerState = initialState,
  action: IPartnerAction
): IPartnerState => {
  switch (action.type) {
    case ActionTypes.GET_PARTNER_START:
      return getPartnerStart(state, action);
    case ActionTypes.GET_PARTNER_SUCCESS:
      return getPartnerSuccess(state, action);
    case ActionTypes.GET_PARTNER_FAIL:
      return getPartnerFail(state, action);
    case ActionTypes.LIST_PARTNERS_START:
      return listPartnersStart(state, action);
    case ActionTypes.LIST_PARTNERS_SUCCESS:
      return listPartnersSuccess(state, action);
    case ActionTypes.LIST_PARTNERS_FAIL:
      return listPartnersFail(state, action);
    case ActionTypes.SAVE_PARTNER_START:
      return savePartnerStart(state, action);
    case ActionTypes.SAVE_PARTNER_SUCCESS:
      return savePartnerSuccess(state, action);
    case ActionTypes.SAVE_PARTNER_FAIL:
      return savePartnerFail(state, action);
    case ActionTypes.UPDATE_PARTNER_START:
      return updatePartnerStart(state, action);
    case ActionTypes.UPDATE_PARTNER_SUCCESS:
      return updatePartnerSuccess(state, action);
    case ActionTypes.UPDATE_PARTNER_FAIL:
      return updatePartnerFail(state, action);
    case ActionTypes.DELETE_PARTNER_START:
      return deletePartnerStart(state, action);
    case ActionTypes.DELETE_PARTNER_SUCCESS:
      return deletePartnerSuccess(state, action);
    case ActionTypes.DELETE_PARTNER_FAIL:
      return deletePartnerFail(state, action);
    default:
      return state;
  }
};

export default reducer;
