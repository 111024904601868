import { useDispatch } from 'react-redux';
import { IOrganization } from '../interfaces/domain';
import { useCallback } from 'react';
import { ETranslation } from '../translations/translation-keys';
import { useTranslation } from 'react-i18next';
import { IAppState, ICustomerState, IAuthState } from '../interfaces/state';
import { useSelector } from 'react-redux';
import { IOption } from '../components/UI/Input/Input';
import { useEffect, useState } from 'react';
import { createOptions } from '../shared/option-utils';

import * as actions from '../store/actions';

const defaultParams = {
  withPlaceholder: false,
  withBusinessId: false
}

export const useCustomerOptions = ({ withBusinessId, withPlaceholder } = defaultParams) => {
  const { authUser } = useSelector<IAppState, IAuthState>(state => state.auth);
  const { t } = useTranslation()
  const [customerOptions, setCustomerOptions] = useState<IOption[]>([])
  const dispatch = useDispatch();

  const [tryFetch, setTryFetch] = useState(false);

  const { customers, loading } = useSelector<IAppState, ICustomerState>((state) => state.customers);

  useEffect(() => {
    if(authUser) {
      setTryFetch(authUser.isPrimary);
    }
  }, [authUser]);

  useEffect(() => {
    if(tryFetch && !customers) {
      dispatch(actions.listCustomers());
      setTryFetch(false);
    }
  }, [dispatch, customers, tryFetch]);

  const createLabel = useCallback((organization: IOrganization) => {
    let labelArray = [organization.name || organization.id];

    if(withBusinessId && organization.businessId) {
      labelArray.push(`(${organization.businessId})`)
    }
    return labelArray.join(' ');
  }, [withBusinessId]);

  useEffect(() => {
    let placeholder;
    if(withPlaceholder) {
      placeholder = t(ETranslation.SELECT_CUSTOMER_ID);
    }
    const filteredCustomers = customers ? customers.filter(customer => customer.name) : [];
  
    setCustomerOptions(filteredCustomers.length > 0 
      ? createOptions(filteredCustomers, createLabel, placeholder) 
      : []);
  }, [customers, t, withPlaceholder, createLabel]);

  return { customerOptions: customerOptions, customersLoading: loading };

}
