import { EOrganizationLevel, EUserRole } from '../enums';
import { IOrganization } from '../interfaces/domain';
import { ERoute } from './Routes';

interface IAuthUser {
  id: number;
  firebaseUserId: string;
  organization: IOrganization;
  name: string;
  email: string;
  title: string;
  phoneNumber: string;
  role: EUserRole;
}

export class AuthUser implements IAuthUser {
  public id: number;
  public firebaseUserId: string;
  public organization: IOrganization;
  public name: string;
  public email: string;
  public title: string;
  public phoneNumber: string;
  public role: EUserRole;

  public constructor(authUser: IAuthUser) {
    this.id = authUser.id;
    this.firebaseUserId = authUser.firebaseUserId
    this.organization = authUser.organization;
    this.name = authUser.name;
    this.email = authUser.email;
    this.title = authUser.title;
    this.phoneNumber = authUser.phoneNumber;
    this.role = authUser.role;
  }

  public get routes(): ERoute[] {
    const routes: ERoute[] = [
      ERoute.USERS,
      ERoute.USER,
      ERoute.DEAL_TOKEN,
    ];

    const { isPrimary, isCustomer, isPartner } = this;

    if (isCustomer) {
      routes.push.apply(routes, [
        ERoute.DASHBOARD,
        ERoute.LEASING_LIABILITIES_REPORT
      ]);
    }

    if (isPartner) {
      routes.push.apply(routes, [
        ERoute.DRAFTS,
        ERoute.DRAFT,
        ERoute.PARTNER_DEALS
      ]);
    }

    if(isPrimary) {
      routes.push.apply(routes, [
        ERoute.DEALS,
        ERoute.PIPEDRIVE_DEAL,
        ERoute.DEAL,
        ERoute.MAINTENANCE_BUDGET_REPORT,
        ERoute.MAINTENANCE_LEASING_REPORT,
        ERoute.REPORTS,
        ERoute.CUSTOMERS,
        ERoute.CUSTOMER,
        ERoute.PARTNERS,
        ERoute.PARTNER,
        ERoute.SETTINGS,
        ERoute.FINANCE_COMPANIES,
        ERoute.FINANCE_COMPANY,
        ERoute.DEALERSHIPS,
        ERoute.DEALERSHIP,
        ERoute.ORGANIZATION_USER,
        ERoute.INVOICES,
        ERoute.PREOFFERS,
        ERoute.DRAFT,
        ERoute.LOG_ITEMS,
        ERoute.LOG_ITEM
      ]);
    }

    return routes;
  }

  public get isPrimary(): boolean {
    return this.organization.level === EOrganizationLevel.PRIMARY;
  }

  public get isCustomer(): boolean {
    return this.organization.level === EOrganizationLevel.CUSTOMER;
  }

  public get isPartner(): boolean {
    return this.organization.level === EOrganizationLevel.PARTNER;
  }

  public get isAdmin(): boolean {
    return this.role === EUserRole.ADMIN;
  }

  public get redirect(): ERoute {
    if (this.isPrimary) {
      return ERoute.DEALS;
    }
    if (this.isPartner) {
      return ERoute.DRAFTS;
    }
    return ERoute.DASHBOARD;
  }

  public get allowSearch(): boolean {
    return this.isPrimary || this.isCustomer || this.isPartner;
  }
}
