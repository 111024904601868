import React from "react";
import { AuthUser } from "../../classes/AuthUser";
import Header from "../Header/Header";

const classes = require("./Layout.module.scss");

interface IProps {
  currentUser: AuthUser | null;
  onLogout: () => void;
  children: any;
}

const Layout: React.FC<IProps> = (props) => {
  if (props.currentUser) {
    return (
      <div className={classes.root}>
        <Header currentUser={props.currentUser} logout={props.onLogout} />
        <div className={classes.Container}>
          {props.children}
        </div>
      </div>
    );
  }
  return props.children;
};

export default Layout;
