import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { ISettingsAction } from "../../interfaces";
import { JSON_SETTINGS_GET } from "../../endpoints/index";
import { ActionTypes } from "./actionTypes";

const getSettingsStart = (): ISettingsAction => {
  return {
    type: ActionTypes.SETTINGS_GET_START,
  };
};

const getSettingsSuccess = (netsUrl: string): ISettingsAction => {
  return {
    type: ActionTypes.SETTINGS_GET_SUCCESS,
    netsUrl,
  };
};

const getSettingsFail = (error: string): ISettingsAction => {
  return {
    type: ActionTypes.SETTINGS_GET_FAIL,
    error,
  };
};

export const getSettings = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getSettingsStart());
    try {
      const res = await axios.get(JSON_SETTINGS_GET);
      dispatch(getSettingsSuccess(res.data.netsUrl));
      return res.data;
    } catch ({ response }) {
      dispatch(getSettingsFail((response as AxiosResponse).data));
      return null;
    }
  };
};
