import React from "react";
import Input, { IInputField, IInputOptions } from "../components/UI/Input/Input";
import { updateInputHandler } from "../shared/utility";

export type TCreateInput = (inputName: string, options?: IInputOptions) => JSX.Element;

export const useCreateInput = (inputs: IInputField, setInputs: React.Dispatch<React.SetStateAction<IInputField>>, commonOptions?: IInputOptions) => {

  const createInput: TCreateInput = (inputName: string, options?: IInputOptions) => {
    const item = inputs[inputName];
    return (
      <Input
        {...commonOptions}
        {...item}
        {...options}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
      />
    );
  };

  return createInput;
}
