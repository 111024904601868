import { AxiosResponse } from "axios";
import { ThunkAction } from "redux-thunk";
import { TDispatch } from "../..";
import axios from "../../axios";
import { JSON_ATTACHMENTS_PRINT, JSON_ATTACHMENTS_UPLOAD } from "../../endpoints";
import { IAppState, IAttachment, IAttachmentAction } from "../../interfaces";
import { ActionTypes } from "./actionTypes";
import { Dispatch } from "redux";

export const clearAttachmentState = (): IAttachmentAction => {
  return {
    type: ActionTypes.CLEAR_ATTACHMENT_STATE,
  };
};

const uploadAttachmentStart = (): IAttachmentAction => {
  return {
    type: ActionTypes.UPLOAD_ATTACHMENT_START,
  };
};

const uploadAttachmentSuccess = (
  attachments: Array<IAttachment>
): IAttachmentAction => {
  return {
    type: ActionTypes.UPLOAD_ATTACHMENT_SUCCESS,
    attachments,
  };
};

const uploadAttachmentFail = (error: string): IAttachmentAction => {
  return {
    type: ActionTypes.UPLOAD_ATTACHMENT_FAIL,
    error,
  };
};

const openAttachmentStart = (attachmentId: string): IAttachmentAction => {
  return {
    type: ActionTypes.OPEN_ATTACHMENT_START,
    attachmentId
  };
};

const openAttachmentSuccess = (attachmentId: string): IAttachmentAction => {
  return {
    type: ActionTypes.OPEN_ATTACHMENT_SUCCESS,
    attachmentId,
  };
};

const openAttachmentFail = (attachmentId: string): IAttachmentAction => {
  return {
    type: ActionTypes.OPEN_ATTACHMENT_FAIL,
    attachmentId,
  };
};

type TUploadAttachmentPromise = Promise<IAttachment[] | null>;
type TUploadAttachmentAction = ThunkAction<
  TUploadAttachmentPromise,
  IAppState,
  any,
  IAttachmentAction
>;

export const uploadAttachment =
  (data: FormData, folder?: string): TUploadAttachmentAction =>
  (dispatch: TDispatch): TUploadAttachmentPromise => {
    dispatch(uploadAttachmentStart());
    return axios
      .post(JSON_ATTACHMENTS_UPLOAD(folder), data)
      .then((res) => {
        const attachments = res.data;
        dispatch(uploadAttachmentSuccess(attachments));
        return Promise.resolve(attachments);
      })
      .catch(({ response }) => {
        dispatch(uploadAttachmentFail((response as AxiosResponse).data));
        return Promise.resolve(null);
      });
  };

export const openAttachment = (link: string, id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(openAttachmentStart(id));
    try {
      await axios.get(link, {responseType: 'blob'}).then((response) => {
        window.open(URL.createObjectURL(response.data));
      });
      dispatch(openAttachmentSuccess(id));
    } catch ({ response }) {
      dispatch(openAttachmentFail(id));
    }
  };
};

export const uploadPrint = (id: string) => {
  return async () => {
    try {
      await axios.get(JSON_ATTACHMENTS_PRINT(id));
    } catch ({ response }) {
    }
  };
};