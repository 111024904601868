import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { IUserAction } from "../../interfaces";
import { IUser } from "../../interfaces/domain";
import {
  JSON_ORGANIZATION_USERS_GET,
  JSON_ORGANIZATION_USERS_LIST,
  JSON_ORGANIZATION_USERS_LIST_ALL,
  JSON_USERS_ADD,
  JSON_USERS_GET,
  JSON_USERS_LIST,
  JSON_USERS_UPDATE,
} from "./../../endpoints/index";
import { ActionTypes } from "./actionTypes";

const getUserStart = (): IUserAction => {
  return {
    type: ActionTypes.GET_USER_START,
  };
};

const getUserSuccess = (user: IUser): IUserAction => {
  return {
    type: ActionTypes.GET_USER_SUCCESS,
    user,
  };
};

const getUserFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.GET_USER_FAIL,
    error,
  };
};

export const getUser = (id: string, organizationId?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getUserStart());
    try {
      const res = await axios.get(
        organizationId
          ? JSON_ORGANIZATION_USERS_GET(id, organizationId)
          : JSON_USERS_GET(id)
      );
      dispatch(getUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(getUserFail((response as AxiosResponse).data));
    }
  };
};

const listUsersStart = (): IUserAction => {
  return {
    type: ActionTypes.LIST_USERS_START,
  };
};

const listUsersSuccess = (users: Array<IUser>): IUserAction => {
  return {
    type: ActionTypes.LIST_USERS_SUCCESS,
    users,
  };
};

const listUsersFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.LIST_USERS_FAIL,
    error,
  };
};

export const listUsers = (organizationId?: string, getAll?: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(listUsersStart());
    try {
      const res = await axios.get(
        organizationId
          ? JSON_ORGANIZATION_USERS_LIST(organizationId)
          : getAll 
          ? JSON_ORGANIZATION_USERS_LIST_ALL(getAll)
          : JSON_USERS_LIST
      );
      dispatch(listUsersSuccess(res.data));
    } catch ({ response }) {
      dispatch(listUsersFail((response as AxiosResponse).data));
    }
  };
};

const saveUserStart = (): IUserAction => {
  return {
    type: ActionTypes.SAVE_USER_START,
  };
};

const saveUserSuccess = (user: IUser): IUserAction => {
  return {
    type: ActionTypes.SAVE_USER_SUCCESS,
    user,
  };
};

const saveUserFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.SAVE_USER_FAIL,
    error,
  };
};

export const saveUser = (user: IUser, organizationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveUserStart());
    try {
      // TODO: route
      const res = await axios.post(
        `${JSON_USERS_ADD}?organizationId=${organizationId}`,
        user
      );
      dispatch(saveUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveUserFail((response as AxiosResponse).data));
    }
  };
};

const updateUserStart = (): IUserAction => {
  return {
    type: ActionTypes.UPDATE_USER_START,
  };
};

const updateUserSuccess = (user: IUser): IUserAction => {
  return {
    type: ActionTypes.UPDATE_USER_SUCCESS,
    user,
  };
};

const updateUserFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.UPDATE_USER_FAIL,
    error,
  };
};

export const updateUser = (user: IUser) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    try {
      const res = await axios.put(JSON_USERS_UPDATE, user);
      dispatch(updateUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateUserFail((response as AxiosResponse).data));
    }
  };
};
