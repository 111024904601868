import { EIdentificationType } from "../components/Deals/Deal/DealView/DealSignatureDialog/DealSignatureDialog";
import { EDealStatus, EOrganizationLevel } from "../enums";

export const DEV_URL_PREFIX =
  process.env.NODE_ENV === "development" ? "http://localhost:8080" : "";

export const JSON_ORGANIZATIONS = "/json/organizations";
export const JSON_ORGANIZATIONS_ADD = JSON_ORGANIZATIONS + "/add";
export const JSON_ORGANIZATIONS_UPDATE = JSON_ORGANIZATIONS + "/update";
export const JSON_ORGANIZATIONS_LIST = (level: EOrganizationLevel) => 
  `${JSON_ORGANIZATIONS}/list?level=${level}`;
export const JSON_ORGANIZATIONS_GET = (id: string) =>
  `${JSON_ORGANIZATIONS}/get?id=${id}`;
export const JSON_ORGANIZATIONS_DELETE = (id: string) =>
  `${JSON_ORGANIZATIONS}/delete?id=${id}`;

export const JSON_USERS = "/json/users";
export const JSON_USERS_ADD = JSON_USERS + "/add";
export const JSON_USERS_UPDATE = JSON_USERS + "/update";
export const JSON_USERS_LIST = JSON_USERS + "/list";
export const JSON_USERS_GET = (id: string) => `${JSON_USERS}/get?id=${id}`;
export const JSON_USERS_DELETE = (id: string) =>
  `${JSON_USERS}/delete?id=${id}`;

export const JSON_ORGANIZATION_USERS_LIST = (organizationId: string) =>
  `${JSON_USERS_LIST}?organizationId=${organizationId}`;
export const JSON_ORGANIZATION_USERS_LIST_ALL = (getAll: boolean) =>
  `${JSON_USERS_LIST}?getAll=${getAll}`;
export const JSON_ORGANIZATION_USERS_GET = (
  id: string,
  organizationId: string
) => `${JSON_USERS}/get?id=${id}&organizationId=${organizationId}`;

export const JSON_DEALS = "/json/deals";
export const JSON_DEALS_ADD = JSON_DEALS + "/add";
export const JSON_DEALS_UPDATE = JSON_DEALS + "/update";
export const JSON_DEALS_LIST = JSON_DEALS + "/list";
export const JSON_DEALS_GET = (id: string) => `${JSON_DEALS}/get?id=${id}`;
export const JSON_DEALS_TOKEN_GET = (
  id: string,
  token: string,
  printable: boolean
) =>
  printable
    ? `${JSON_DEALS}/token?id=${id}&token=${token}&printable=${printable}`
    : `${JSON_DEALS}/token?id=${id}&token=${token}`;
export const JSON_DEALS_DELETE = (id: string) =>
  `${JSON_DEALS}/delete?id=${id}`;
export const JSON_DEALS_SEND = JSON_DEALS + "/send";
export const JSON_DEALS_SEND_RETURN_INFO = JSON_DEALS + "/send-return-info";
export const JSON_DEALS_UPDATE_STATUS = JSON_DEALS + "/update-status";
export const JSON_DEALS_COPY = JSON_DEALS + "/copy";
export const JSON_DEALS_ARCHIVE = (id: string) =>
  `${JSON_DEALS}/archive?id=${id}`;
export const JSON_DEALS_TERMINATION_CALCULATION = (id: string) =>
  `${JSON_DEALS}/termination-calculation?id=${id}`;
export const JSON_DEALS_TERMINATE_GET = (id: string, token: string) => `${JSON_DEALS}/terminate?id=${id}&token=${token}`;
export const JSON_DEALS_MAINTENANCE_INFO_GET = (id: string, token: string) => `${JSON_DEALS}/maintenance-info?id=${id}&token=${token}`;
export const JSON_DEALS_IDENTIFY = (id: string, token: string, type: EIdentificationType, variationId?: string) => `${JSON_DEALS}/identify?id=${id}&token=${token}&type=${type}&variationId=${variationId ?? ""}`;
export const JSON_DEALS_CONFIRM_SIGNATURE = (id: string, token: string) => `${JSON_DEALS}/confirm-signature?id=${id}&token=${token}`;

export const JSON_DEALS_ADD_DRAFT = JSON_DEALS + "/add-draft";
export const JSON_DEALS_UPDATE_DRAFT = (send: boolean) => 
`${JSON_DEALS}/update-draft?send=${send}`;
export const JSON_DEALS_LIST_DRAFTS = (status?: EDealStatus) =>
  `${JSON_DEALS}/list-drafts?status=${status ?? ''}`;
export const JSON_DEALS_GET_DRAFT = (id: string) => `${JSON_DEALS}/get-draft?id=${id}`;
export const JSON_DEALS_ARCHIVE_DRAFT = (id: string) => `${JSON_DEALS}/archive-draft?id=${id}`;
export const JSON_DEALS_GET_PREOFFER = (id: string) => `${JSON_DEALS}/get-preoffer?id=${id}`;

export const JSON_AUTH = "/json/auth";
export const JSON_AUTH_REGISTER = JSON_AUTH + "/register";
export const JSON_AUTH_LOGIN = JSON_AUTH + "/login";
export const JSON_AUTH_REFRESH_TOKEN = (refreshToken: string) =>
  `${JSON_AUTH}/refreshToken?refreshToken=${refreshToken}`;

export const JSON_FINANCE_COMPANIES = "/json/finance-companies";
export const JSON_FINANCE_COMPANIES_ADD = JSON_FINANCE_COMPANIES + "/add";
export const JSON_FINANCE_COMPANIES_UPDATE = JSON_FINANCE_COMPANIES + "/update";
export const JSON_FINANCE_COMPANIES_LIST = JSON_FINANCE_COMPANIES + "/list";
export const JSON_FINANCE_COMPANIES_GET = (id: string) =>
  `${JSON_FINANCE_COMPANIES}/get?id=${id}`;
export const JSON_FINANCE_COMPANIES_DELETE = (id: string) =>
  `${JSON_FINANCE_COMPANIES}/delete?id=${id}`;

export const JSON_DEALERSHIPS = "/json/dealerships";
export const JSON_DEALERSHIPS_ADD = JSON_DEALERSHIPS + "/add";
export const JSON_DEALERSHIPS_UPDATE = JSON_DEALERSHIPS + "/update";
export const JSON_DEALERSHIPS_LIST = JSON_DEALERSHIPS + "/list";
export const JSON_DEALERSHIPS_GET = (id: string) =>
  `${JSON_DEALERSHIPS}/get?id=${id}`;
export const JSON_DEALERSHIPS_DELETE = (id: string) =>
  `${JSON_DEALERSHIPS}/delete?id=${id}`;

export const JSON_ATTACHMENTS = "/json/attachments";
export const JSON_ATTACHMENTS_UPLOAD = (folder?: string) =>
  JSON_ATTACHMENTS + `/upload?folder=${folder ?? ""}`;
export const JSON_ATTACHMENTS_PRINT = (id: string) =>
  JSON_ATTACHMENTS + `/print?id=${id}`;

export const JSON_REPORTS = "/json/reports";
export const JSON_REPORTS_LIST = JSON_REPORTS + "/list";
export const JSON_REPORTS_HANDOVERS = JSON_REPORTS + "/handovers";

export const JSON_DASHBOARD = "/json/dashboard";
export const JSON_DASHBOARD_LIST = JSON_DASHBOARD + "/list";
export const JSON_DASHBOARD_LEASING_LIABILITIES = JSON_DASHBOARD + `/leasing-liabilities`;

export const ATTACHMENTS = "/attachments";

export const JSON_PIPEDRIVE_DEALS = JSON_DEALS + "/pipedrive";
export const JSON_PIPEDRIVE_DEALS_GET_NEW = (id: string) =>
  `${JSON_PIPEDRIVE_DEALS}/get?id=${id}`;
export const JSON_PIPEDRIVE_DEALS_GET = (
  pipedriveDealId: string,
  pipedriveOrganizationId: string
) =>
  `${JSON_PIPEDRIVE_DEALS}/get?pipedriveDealId=${pipedriveDealId}&pipedriveOrganizationId=${pipedriveOrganizationId}`;

// export const JSON_PIPEDRIVE_DEALS = "/tools/init";
// export const JSON_PIPEDRIVE_DEALS_GET = (id: string) => `${JSON_PIPEDRIVE_DEALS}?action=getPDDeal&id=${id}`;

export const JSON_DEALS_AUDIT_LOGS = (id: string, token: string) =>
  `${JSON_DEALS}/auditlogs?id=${id}&token=${token}`;

export const JSON_INVOICES = "/json/invoices";
export const JSON_INVOICES_ROWS = JSON_INVOICES + "/rows";
export const JSON_INVOICES_LIST = JSON_INVOICES + "/list";
export const JSON_INVOICES_DEAL_LIST = (dealId: string) =>
  `${JSON_INVOICES}/deallist?dealId=${dealId}`;
export const JSON_INVOICES_ATTACH = (
  invoiceId: string,
  dealId: string,
  dealInfo: string
) =>
  `${JSON_INVOICES}/attach?invoiceId=${invoiceId}&dealId=${dealId}&dealInfo=${dealInfo}`;
export const JSON_INVOICES_HIDE = (invoiceId: string) =>
  `${JSON_INVOICES}/hide?invoiceId=${invoiceId}`;
export const JSON_INVOICES_DETACH = (invoiceId: string) =>
  `${JSON_INVOICES}/detach?invoiceId=${invoiceId}`;
export const JSON_INVOICES_ROWS_EDIT = (invoiceId: string) =>
`${JSON_INVOICES_ROWS}/edit?invoiceId=${invoiceId}`;

export const JSON_NETWHEELS = "/json/netwheels";
export const JSON_NETWHEELS_RESIDUAL_VALUE = JSON_NETWHEELS + "/residual-value";

export const JSON_BILTEMA = "/json/biltema";
export const JSON_BILTEMA_EXTENDED_INFO = JSON_BILTEMA + "/extendedinfo";

export const JSON_TALENOM_INVOICES_DETACH = "/json/talenom/invoices/detach";

const JSON_SETTINGS = "/json/settings";
export const JSON_SETTINGS_GET = JSON_SETTINGS + "/get";

export const JSON_LOG_ITEMS_LIST = () => `/json/log-items/list`;
export const JSON_LOG_ITEMS_SAVE_LOGIN = (
  email: string,
  success: boolean,
  error?: string
) => 
`/json/log-items/save-login?email=${email}&success=${success}&error=${error ?? ""}`;
export const JSON_LOG_ITEMS_GET = (id: string) => `/json/log-items/get?id=${id}`;