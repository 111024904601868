import React, { useEffect, useState } from "react";
//import { useTranslation } from "react-i18next";
import SearchContainer from "../Searches/SearchContainer/SearchContainer";
import { EAction } from "../../enums";
import { ILogItemsSearchOptions } from "../../store/actions/logItemsActions";
import UserSearch from "../Searches/UserSearch/UserSearch";
import OrganizationSearch from "../Searches/OrganizationSearch/OrganizationSearch";
import Select from "../UI/Select/Select";
import ReactDatePicker from "react-datepicker";
import { fi } from "date-fns/locale";
import classes from './LogItemsSearch.module.scss';
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";

interface IProps {
  onSearch: (search: ILogItemsSearchOptions) => void;
};

const LogItemsSearch: React.FC<IProps> = ({ onSearch }) => {
  const { t } = useTranslation();

  const [actionUserId, setActionUserId] = useState("");
  const [actionOrganizationId, setActionOrganizationId] = useState("");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [action, setAction] = useState("");

  const actions = Array.from(Object.values(EAction)).map(action => {
    return {value: action, label: action.toString()}
  })

  useEffect(() => {
    onSearch({ actionUserId, actionOrganizationId, startDate, endDate, action });
  }, [action, actionOrganizationId, actionUserId, endDate, onSearch, startDate]);

  return (
    <SearchContainer>
      <UserSearch userId={actionUserId} setUserId={setActionUserId} />
      <OrganizationSearch organizationId={actionOrganizationId} setOrganizationId={setActionOrganizationId} />
      <div>
        <label>{t(ETranslation.START_DATE)}</label><br/>
        <ReactDatePicker
          wrapperClassName={classes.datePicker}
          className={classes.Input}
          selected={startDate}
          onChange={(date) => setStartDate(date ?? undefined)}
          dateFormat="dd.MM.yyyy"
          locale={fi}
          isClearable={true}
          placeholderText={t(ETranslation.START_DATE)}
        />
      </div>
      <div>
        <label>{t(ETranslation.END_DATE)}</label><br/>
        <ReactDatePicker
          wrapperClassName={classes.datePicker}
          className={classes.Input}
          selected={endDate}
          onChange={(date) => setEndDate(date ?? undefined)}
          dateFormat="dd.MM.yyyy"
          locale={fi}
          isClearable={true}
          placeholderText={t(ETranslation.END_DATE)}
        />
      </div>
      <div>
        <label>{t(ETranslation.ACTION)}</label>
        <Select
          inputName={"actionSearch"}
          value={action}
          onChange={setAction}
          options={actions}
          isSearchable
          isClearable
          isCreatable={false}
        />
      </div>
    </SearchContainer>
  );
};

export default LogItemsSearch;
