import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "../../classes/Routes";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import { ETranslation } from "../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState, IDeal } from "../../interfaces";
import Alert from "../../components/UI/Alert/Alert";
import Spinner from "../../components/UI/Spinner/Spinner";
import DealsTablePartner from "../../components/Deals/DealsTable/DealsTablePartner";

interface IProps extends RouteComponentProps { }

const Drafts: React.FC<IProps> = ({history}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { error, loading, drafts, preoffers, idList } = useSelector((state: IAppState) => ({
    error: state.deals.error,
    loading: state.deals.loading,
    drafts: state.deals.drafts,
    preoffers: state.deals.preOffers,
    idList: state.deals.idList,
  }));

  useEffect(() => {
    dispatch(actions.listDrafts());
    dispatch(actions.listPreoffers());
  }, [dispatch]);

  const showDeal = (deal?: IDeal) => {
    if (deal) {
      history.push(Routes.DRAFT(deal.id));
    }
  }

  const dealFromPreoffer = (link?: string) => {
    if (link) {
      //history.push(link);
      window.open(link);
    }
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      {loading ? <Spinner/> : 
      <Container>
        <h3>{t(ETranslation.DRAFTS)}</h3>
        <Button
          onClick={() => history.push(Routes.DRAFTS_NEW)}
          icon={faPlus}
          style={{ marginBottom: "1rem" }}
        >
          {t(ETranslation.DRAFT_NEW)}
        </Button>
        {drafts && 
        <>
          <DealsTablePartner deals={drafts} onClick={showDeal} />
        </>
        }
        {preoffers &&
        <>
          <h3>{t(ETranslation.DRAFTS_SENT)}</h3>
          <DealsTablePartner deals={preoffers} onClick={showDeal} sentDate={true} idList={idList ?? undefined} tokenLink={true} dealFromPreoffer={dealFromPreoffer}/>
        </>
        }
        {!drafts && !preoffers &&
          <p>{t(ETranslation.DRAFTS_START)}</p>
        }
      </Container>
      }
    </>
  );
}

export default Drafts;