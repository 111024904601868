import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPreoffersSearchOptions } from "../../../store/actions/dealsActions";
import { ETranslation } from "../../../translations/translation-keys";
import PartnerSearch from "../../Searches/PartnerSearch/PartnerSearch";

interface IProps {
  onSearch: (search: IPreoffersSearchOptions) => void;
};

const PreoffersSearch: React.FC<IProps> = ({ onSearch }) => {
  const { t } = useTranslation();

  const [partnerId, setPartnerId] = useState("");
  const [archived, setArchived] = useState(false);

  useEffect(() => {
    onSearch({ partnerId, archived });
  }, [partnerId, archived, onSearch]);

  return (
    <>
      <div style={{width:"25%"}}>
        <PartnerSearch partnerId={partnerId} setPartnerId={setPartnerId}/>
      </div>
      <div style={{marginTop:"1rem", marginBottom:"1rem"}}>
        <label><input type="checkbox" onChange={() => setArchived(!archived)} checked={archived} /> {t(ETranslation.DRAFTS_ARCHIVED_SEARCH)}</label>
      </div>
    </>
  );
};

export default PreoffersSearch;
