import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Alert from "../../../components/UI/Alert/Alert";
import Button from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import Input, { EInputType, IInputField, IInputOptions } from "../../../components/UI/Input/Input";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../../context/InputContext";
import { IAppState, IDealership } from "../../../interfaces";
import { Routes } from "../../../classes/Routes";
import { initForm, updateInputHandler } from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import { TDispatch } from "../../..";

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

export enum EDealership {
  NAME = "name",
}

const Dealership: React.FC<IProps> = ({
  match, history
}) => {
  const dispatch = useDispatch<TDispatch>();

  const { loading, error, dealership } = useSelector((state: IAppState) => ({
    loading: state.dealerships.loading,
    error: state.dealerships.error,
    dealership: state.dealerships.dealership,
  }));

  const { t } = useTranslation();
  const { id } = match.params;

  const isNew = id === "new";

  const initialInputs: IInputField = {
    [EDealership.NAME]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEALERSHIP_NAME,
      placeholderTranslation: ETranslation.DEALERSHIP_NAME,
      value: "",
      validation: {
        required: true
      }
    },
  };

  const [inputs, setInputs] = useState<IInputField>({ ...initialInputs });

  useEffect(() => {
    if (id && !isNew) {
      dispatch(actions.getDealership(id));
    }
  }, [id, dispatch, isNew]);

  useEffect(() => {
    if (id && !isNew && dealership) {
      setInputs(stateInputs => initForm(stateInputs, dealership));
    } else {
      setInputs(_ => initialInputs);
    }
    // eslint-disable-next-line
  }, [dealership]);


  const createInput = (inputName: EDealership, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.DEALERSHIP}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    />
  };

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const name = inputs[EDealership.NAME].value as string;

    const dealership: IDealership = {
      id: "", name
    }

    if (isNew) {
      await dispatch(actions.saveDealership(dealership));
    } else {
      dealership.id = id;
      await dispatch(actions.updateDealership(dealership));
    }

    console.log(dealership);
    history.push(Routes.DEALERSHIPS);
  };

  const createInputs = () => {
    return (
      <>
        <InputGroup>
          {createInput(EDealership.NAME)}
        </InputGroup>
      </>
    );
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{isNew ? t(ETranslation.TITLE_DEALERSHIP_NEW) : t(ETranslation.TITLE_DEALERSHIP)}</h2>
        {loading
          ? <Spinner />
          : createInputs()
        }
        <InputGroup>
          <Button disabled={loading} onClick={submitHandler}>
            {t(ETranslation.COMMON_SAVE)}
          </Button>
          <Button onClick={() => history.goBack()}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
        </InputGroup>
      </Container>
    </>
  );
};

export default Dealership;
