import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { TDispatch } from "../..";
import DealsTable from "../../components/Deals/DealsTable/DealsTable";
import ReportsSearch from "../../components/Deals/ReportsSearch/ReportsSearch";
import Alert from "../../components/UI/Alert/Alert";
import CompactTable from "../../components/UI/CompactTable/CompactTable";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import { EDealStatus } from "../../enums";
import { useAuthUser } from "../../hooks/useAuthUser";
import { IAppState, IDeal } from "../../interfaces";
import { Routes } from "../../classes/Routes";
import { toTwoDecimals } from "../../shared/utility";
import * as actions from "../../store/actions";
import { IReportsSearchOptions } from "../../store/actions/reportsActions";
import { ETranslation } from "../../translations/translation-keys";

interface IMatch {
  status: string;
}

interface IProps extends RouteComponentProps<IMatch> { };

const Reports: React.FC<IProps> = ({ history, match }) => {
  const dispatch = useDispatch<TDispatch>();

  const { status } = match.params;
  const statusProp = status.toUpperCase();

  const authUser = useAuthUser();

  const { loading, error, reports, handovers, handoversLoading, handoversError } = useSelector((state: IAppState) => ({
    loading: state.reports.loading,
    error: state.reports.error,
    reports: state.reports.reports,
    handovers: state.reports.handovers,
    handoversLoading: state.reports.handoversLoading,
    handoversError: state.reports.handoversError,
  }));

  const { t } = useTranslation();
  const [searchOptions, setSearchOptions] = useState<IReportsSearchOptions>({ status: statusProp });
  const [title, setTitle] = useState<string>();

  useEffect(() => {
    const getReportsTitle = () => {
      switch (statusProp) {
        case EDealStatus.OFFER:
          return t(ETranslation.REPORTS_OFFER);
        case EDealStatus.PRECONTRACT:
          return t(ETranslation.REPORTS_PRECONTRACT);
        case EDealStatus.CONTRACT:
          return t(ETranslation.REPORTS_CONTRACT);
        case EDealStatus.TERMINATED:
          return t(ETranslation.REPORTS_TERMINATED);
        case EDealStatus.ARCHIVED:
          return t(ETranslation.REPORTS_ARCHIVED);
      }
    };

    const year = searchOptions.year ? searchOptions.year + " " : null;
    setTitle(year && statusProp === EDealStatus.CONTRACT ? year + getReportsTitle() : getReportsTitle());
  }, [statusProp, t, searchOptions]);

  useEffect(() => {
    if (statusProp !== EDealStatus.CONTRACT) dispatch(actions.listReports(statusProp === EDealStatus.ARCHIVED ? { archived: true } : { status: statusProp }));
    if (statusProp === EDealStatus.PRECONTRACT) dispatch(actions.listHandovers());
  }, [dispatch, statusProp]);

  const searchHandler = useCallback((searchOptions) => {
    dispatch(actions.listReports(searchOptions));
    setSearchOptions(searchOptions);
  }, [dispatch]);

  const showDeal = (deal?: IDeal) => {
    if (deal) {
      if (authUser?.isPrimary && deal.id) {
        history.push(Routes.DEAL(deal.id));
      }
      if (authUser?.isCustomer && deal.link) {
        window.open(deal.link);
      }
    }
  }

  const createPrecontractsReportTable = () => {
    if (statusProp !== EDealStatus.PRECONTRACT || !handovers) return null;

    return (
      <CompactTable containerStyle={{ marginBottom: "1rem" }}>
        <thead>
          <tr>
            <th>{t(ETranslation.REPORTS_HANDOVER_DATE)}</th>
            <th>{t(ETranslation.REPORTS_HANDOVER_TOTAL)}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(handovers).sort((a, b) => a[0] > b[0] ? 1 : -1).map(([handoverId, handoverValue]) => {
            return (
              <tr key={handoverId}>
                <td>{handoverId}</td>
                <td>{toTwoDecimals(handoverValue)} €</td>
              </tr>
            )
          })}
        </tbody>
      </CompactTable>
    )

  };

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      {handoversError && <Alert>{t(handoversError)}</Alert>}
      <Container>
        <h2>{title}</h2>
        {(statusProp === EDealStatus.CONTRACT) && <ReportsSearch status={statusProp} onSearch={searchHandler} />}
        {loading || handoversLoading
          ? <Spinner />
          : reports
            ? (
              <>
                {createPrecontractsReportTable()}
                <DealsTable deals={reports} onClick={showDeal} statusProp={statusProp} searchOptions={searchOptions} reportView={true}/>
              </>
            )
            : <p>{t(ETranslation.NO_DEALS)}</p>
        }
      </Container>
    </>
  );
}

export default Reports;
