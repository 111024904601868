import { IDashboard, IFees } from "./../../interfaces/domain";
import { Dispatch } from "redux";
import axios from "../../axios";
import { IDashboardAction } from "../../interfaces";
import {
  JSON_DASHBOARD_LEASING_LIABILITIES,
  JSON_DASHBOARD_LIST,
} from "./../../endpoints/index";
import { ActionTypes } from "./actionTypes";
import { AxiosResponse } from "axios";

const getDashboardStart = (): IDashboardAction => {
  return {
    type: ActionTypes.GET_DASHBOARD_START,
  };
};

const getDashboardSuccess = (
  dashboard: IDashboard
): IDashboardAction => {
  return {
    type: ActionTypes.GET_DASHBOARD_SUCCESS,
    dashboard,
  };
};

const getDashboardFail = (error: string): IDashboardAction => {
  return {
    type: ActionTypes.GET_DASHBOARD_FAIL,
    error,
  };
};

export const getDashboard = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getDashboardStart());
    try {
      const res = await axios.get(JSON_DASHBOARD_LIST);
      dispatch(getDashboardSuccess(res.data));
    } catch ({ response }) {
      dispatch(getDashboardFail((response as AxiosResponse).data));
    }
  };
};

const getLeasingLiabilitiesStart = (): IDashboardAction => {
  return {
    type: ActionTypes.GET_LEASING_LIABILITIES_START,
  };
};

const getLeasingLiabilitiesSuccess = (fees: IFees): IDashboardAction => {
  return {
    type: ActionTypes.GET_LEASING_LIABILITIES_SUCCESS,
    fees,
  };
};

const getLeasingLiabilitiesFail = (error: string): IDashboardAction => {
  return {
    type: ActionTypes.GET_LEASING_LIABILITIES_FAIL,
    error,
  };
};

export const getLeasingLiabilities = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getLeasingLiabilitiesStart());
    try {
      const res = await axios.get(
        JSON_DASHBOARD_LEASING_LIABILITIES
      );
      dispatch(getLeasingLiabilitiesSuccess(res.data));
    } catch ({ response }) {
      dispatch(getLeasingLiabilitiesFail((response as AxiosResponse).data));
    }
  };
};
