import { IInvoiceAction, IInvoiceState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IInvoiceState = {
  loading: false,
  invoices: null,
  invoice: null,
  error: null,
};

const listInvoicesStart = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    invoices: null,
    invoice: null,
    loading: true,
    error: null,
  });
};

const listInvoicesSuccess = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    invoices: action.invoices,
    loading: false,
    error: null,
  });
};

const listInvoicesFail = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listDealInvoicesStart = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    invoices: null,
    invoice: null,
    loading: true,
    error: null,
  });
};

const listDealInvoicesSuccess = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    invoices: action.invoices,
    loading: false,
    error: null,
  });
};

const listDealInvoicesFail = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const attachInvoiceStart = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    invoice: null,
    loading: true,
    error: null,
  });
};

const attachInvoiceSuccess = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    invoice: action.invoice,
    loading: false,
    error: null,
  });
};

const attachInvoiceFail = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const hideInvoiceStart = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    invoice: null,
    loading: true,
    error: null,
  });
};

const hideInvoiceSuccess = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    invoice: action.invoice,
    loading: false,
    error: null,
  });
};

const hideInvoiceFail = (
  state: IInvoiceState,
  action: IInvoiceAction
): IInvoiceState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IInvoiceState = initialState,
  action: IInvoiceAction
): IInvoiceState => {
  switch (action.type) {
    case ActionTypes.LIST_INVOICES_START:
      return listInvoicesStart(state, action);
    case ActionTypes.LIST_INVOICES_SUCCESS:
      return listInvoicesSuccess(state, action);
    case ActionTypes.LIST_INVOICES_FAIL:
      return listInvoicesFail(state, action);
    case ActionTypes.LIST_DEAL_INVOICES_START:
      return listDealInvoicesStart(state, action);
    case ActionTypes.LIST_DEAL_INVOICES_SUCCESS:
      return listDealInvoicesSuccess(state, action);
    case ActionTypes.LIST_DEAL_INVOICES_FAIL:
      return listDealInvoicesFail(state, action);
    case ActionTypes.ATTACH_INVOICE_START:
      return attachInvoiceStart(state, action);
    case ActionTypes.ATTACH_INVOICE_SUCCESS:
      return attachInvoiceSuccess(state, action);
    case ActionTypes.ATTACH_INVOICE_FAIL:
      return attachInvoiceFail(state, action);
    case ActionTypes.HIDE_INVOICE_START:
      return hideInvoiceStart(state, action);
    case ActionTypes.HIDE_INVOICE_SUCCESS:
      return hideInvoiceSuccess(state, action);
    case ActionTypes.HIDE_INVOICE_FAIL:
      return hideInvoiceFail(state, action);
    default:
      return state;
  }
};

export default reducer;
