import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { ERoute } from "./classes/Routes";
import Layout from "./components/Layout/Layout";
import Modal from "./components/UI/Modal/Modal";
import ModalContext, {
  defaultModal,
  IModal,
} from "./components/UI/Modal/ModalContext";
import Spinner from "./components/UI/Spinner/Spinner";
import Auth from "./containers/Auth/Auth";
import AuthError from "./containers/Auth/AuthError";
import Customer from "./containers/Customers/Customer/Customer";
import Customers from "./containers/Customers/Customers";
import Dashboard from "./containers/Dashboard/Dashboard";
import Dealership from "./containers/Dealerships/Dealership/Dealership";
import Dealerships from "./containers/Dealerships/Dealerships";
import Deal from "./containers/Deals/Deal/Deal";
import DealAccept from "./containers/Deals/Deal/DealAccept/DealAccept";
import DealOrderConfirmation from "./containers/Deals/Deal/DealOrderConfirmation/DealOrderConfirmation";
import PipedriveDeal from "./containers/Deals/Deal/PipedriveDeal/PipedriveDeal";
import Deals from "./containers/Deals/Deals";
import FinanceCompanies from "./containers/FinanceCompanies/FinanceCompanies";
import FinanceCompany from "./containers/FinanceCompanies/FinanceCompany/FinanceCompany";
import Invoices from "./containers/Invoices/Invoices";
import LeasingLiabilitiesReport from "./containers/Reports/LeasingLiabilitiesReport/LeasingLiabilitiesReport";
import MaintenanceBudgetReport from "./containers/Reports/MaintenanceBudgetReport/MaintenanceBudgetReport";
import Reports from "./containers/Reports/Reports";
import Settings from "./containers/Settings/Settings";
import User from "./containers/Users/User/User";
import Users from "./containers/Users/Users";
import firebase from "./firebase";
import GuardedRoute from "./GuardedRoute";
import * as actions from "./store/actions";
import DealTerminationCalculation from "./containers/Deals/Deal/DealTerminationCalculation/DealTerminationCalculation";
import DealMaintenanceInfo from "./containers/Deals/Deal/DealMaintenanceInfo/DealMaintenanceInfo";
import Partners from "./containers/Partners/Partners";
import Partner from "./containers/Partners/Partner/Partner";
import Drafts from "./containers/Drafts/Drafts";
import Draft from "./containers/Drafts/Draft/Draft";
import Preoffers from "./containers/Preoffers/Preoffers";
import PartnerDeals from "./containers/PartnerDeals/PartnerDeals";
import LogItems from "./containers/LogItems/LogItems";
import { useAuthUser } from "./hooks/useAuthUser";
import { useAuthentication } from "./hooks/useAuthentication";
import { getAuth } from "firebase/auth";
import LogItem from "./containers/LogItems/LogItem/LogItem";
import { IAppState } from "./interfaces";

const auth = getAuth(firebase);

const App: React.FC = () => {
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const { signOut } = useAuthentication();
  
  const { error } = useSelector((state: IAppState) => ({
    error: state.auth.error,
  }));

  useEffect(() => {
    if (authUser) {
      setLoading(false);
    }
  }, [dispatch, authUser]);

  useEffect(() => {
    if (error) {
      auth.signOut().then(() => {
        dispatch(actions.getCurrentUserClear());
        setLoading(false);
      });
    }
  }, [dispatch, error]);

  // Automatically redirect to login when token expires.
  useEffect(() => {
    auth.onIdTokenChanged((fbUser) => {
      if (fbUser) {
        dispatch(actions.getCurrentUser());
      } else {
        setLoading(false);
      }
    });
  }, [dispatch]);

  const closeModalHandler = useCallback(() => setModal(defaultModal), []);

  return (
    <ModalContext.Provider
      value={{
        setModal,
        closeModal: closeModalHandler,
      }}
    >
      <Modal modal={modal} />
      <Layout currentUser={authUser} onLogout={signOut}>
        {loading ? (
          <Spinner middle />
        ) : authUser ? (
          <Switch>
            <GuardedRoute
              user={authUser}
              path={ERoute.DASHBOARD}
              exact
              component={Dashboard}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.LEASING_LIABILITIES_REPORT}
              exact
              component={LeasingLiabilitiesReport}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.DRAFTS}
              exact
              component={Drafts}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.DRAFT}
              exact
              component={Draft}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.DEALS}
              exact
              component={Deals}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.PREOFFERS}
              exact
              component={Preoffers}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.PARTNER_DEALS}
              exact
              component={PartnerDeals}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.PIPEDRIVE_DEAL}
              exact
              component={PipedriveDeal}
            />
            <GuardedRoute user={authUser} path={ERoute.DEAL} component={Deal} />
            <GuardedRoute
              user={authUser}
              path={ERoute.DEAL_TOKEN}
              component={DealAccept}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.MAINTENANCE_BUDGET_REPORT}
              exact
              component={MaintenanceBudgetReport}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.MAINTENANCE_LEASING_REPORT}
              exact
              component={MaintenanceBudgetReport}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.REPORTS}
              exact
              component={Reports}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.CUSTOMERS}
              exact
              component={Customers}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.CUSTOMER}
              exact
              component={Customer}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.PARTNERS}
              exact
              component={Partners}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.PARTNER}
              exact
              component={Partner}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.SETTINGS}
              exact
              component={Settings}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.USERS}
              exact
              component={Users}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.USER}
              exact
              component={User}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.FINANCE_COMPANIES}
              exact
              component={FinanceCompanies}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.FINANCE_COMPANY}
              exact
              component={FinanceCompany}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.DEALERSHIPS}
              exact
              component={Dealerships}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.DEALERSHIP}
              exact
              component={Dealership}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.ORGANIZATION_USER}
              exact
              component={User}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.INVOICES}
              exact
              component={Invoices}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.LOG_ITEMS}
              exact
              component={LogItems}
            />
            <GuardedRoute
              user={authUser}
              path={ERoute.LOG_ITEM}
              exact
              component={LogItem}
            
            />
            <Redirect to={authUser.redirect} />
          </Switch>
        ) : (
          <Switch>
            <Route path={ERoute.DEAL_TOKEN} component={DealAccept} />
            <Route
              path={ERoute.ORDER_CONFIRMATION}
              exact
              component={DealOrderConfirmation}
            />
            <Route
              path={ERoute.TERMINATION_CALCULATION}
              exact
              component={DealTerminationCalculation}
            />
            <Route
              path={ERoute.MAINTENANCE_INFO}
              exact
              component={DealMaintenanceInfo}
            />
            <Route path={ERoute.LOGIN} exact component={Auth} />
            <Route path={ERoute.AUTH_ERROR} component={AuthError} />
            <Redirect to={ERoute.LOGIN} />
          </Switch>
        )}
      </Layout>
    </ModalContext.Provider>
  );
};

export default App;
