import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TDispatch } from "../..";
import { EDealStatus } from "../../enums";
import { IAppState, IDeal, IOrganizationLimit } from "../../interfaces";
import { toTwoDecimals } from "../../shared/utility";
import * as actions from "../../store/actions";
import { IReportsSearchOptions } from "../../store/actions/reportsActions";
import { ETranslation } from "../../translations/translation-keys";
import Alert from "../UI/Alert/Alert";
import CompactTable from "../UI/CompactTable/CompactTable";
import Spinner from "../UI/Spinner/Spinner";
import classes from "./RemainingLimits.module.scss";

interface IProps {
  limits: IOrganizationLimit[];
  showDeal: (deal?: IDeal) => void;
}

interface IRemainingLimit {
  limit: IOrganizationLimit;
  deals: IDeal[];
}

interface ILimitDeal {
  id: string;
  name: string;
  cost: number;
  link?: string;
}

const RemainingLimits: React.FC<IProps> = ({ limits, showDeal }) => {
  const dispatch = useDispatch<TDispatch>();
  const { t } = useTranslation();

  const { error } = useSelector((state: IAppState) => ({
    error: state.reports.error,
  }));

  useEffect(() => {
    const fetchData = async () => {
      let remainingLimits: IRemainingLimit[] = [];
      setLoading(true);
      for (const limit of limits) {
        const remainingLimit: IRemainingLimit = { limit, deals: [{ id: "" }] };
        if (limit.financeCompany) {
          const searchOptions: IReportsSearchOptions = {
            status: EDealStatus.CONTRACT,
            financeCompanyId: limit.financeCompany.id,
          }
          const deals = await dispatch(actions.listReports(searchOptions));
          if (deals) remainingLimit.deals = deals;
        }
        remainingLimits.push(remainingLimit);
      }
      setRemainingLimits(remainingLimits);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line
  }, [dispatch]);

  const [remainingLimits, setRemainingLimits] = useState<IRemainingLimit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const mapLimit = (remainingLimit: IRemainingLimit) => {
    let total = 0;
    const limitDeals: ILimitDeal[] = [];

    const { limit, deals } = remainingLimit;

    deals.forEach(r => {
      if (r.totalCost) {
        total += r.totalCost;
        limitDeals.push({ id: r.id!, name: r.name || "", cost: r.totalCost, link: r.link });
      }
    });

    return (
      <React.Fragment key={limit.id}>
        <tr>
          <td>{limit.financeCompany?.name}</td>
          <td>{limit.amount} €</td>
          <td>{toTwoDecimals((limit.amount || 0) - total)} €</td>
        </tr>
        {limitDeals.map(deal => (
          <tr key={deal.id} onClick={() => showDeal(deal)} className={classes.Deal}>
            <td className={classes.DealName}>{deal.name}</td>
            <td>{deal.cost} €</td>
            <td></td>
          </tr>
        ))}
      </React.Fragment>
    );
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      {loading
        ? <Spinner />
        : remainingLimits.length
          ? (
            <CompactTable>
              <thead>
                <tr>
                  <th>{t(ETranslation.DEAL_FINANCE_COMPANY)}</th>
                  <th>{t(ETranslation.DASHBOARD_REQUESTED_LIMIT)}</th>
                  <th>{t(ETranslation.DASHBOARD_REMAINING_AMOUNT)}</th>
                </tr>
              </thead>
              <tbody>
                {remainingLimits.map(limit => mapLimit(limit))}
              </tbody>
            </CompactTable>
          )
          : <p>{t(ETranslation.DASHBOARD_NO_LIMITS)}</p>
      }
    </>
  );
}

export default RemainingLimits;
