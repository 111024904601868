import * as qs from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { EPaymentType } from "../../../../components/Deals/Deal/DealVariation/DealVariationEdit/DealVariationEdit";
import Alert from "../../../../components/UI/Alert/Alert";
import { TInputValue } from "../../../../components/UI/Input/Input";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { EDealType, EInvoicePayer, InputSuffix } from "../../../../enums";
import { IAppState } from "../../../../interfaces";
import { dateToString } from "../../../../shared/utility";
import * as actions from "../../../../store/actions";
import { ETranslation } from "../../../../translations/translation-keys";
import classes from "./DealOrderConfirmation.module.scss";
import DesktopLogo from "./Logo/Logo";

interface IMatch {
  id: string;
}

interface IDealOrderConfirmationQueryParams {
  token: string;
  // printable: boolean;
}

const DealOrderConfirmation: React.FC<RouteComponentProps<IMatch>> = ({ match, location }) => {
  const queryParams = ((): IDealOrderConfirmationQueryParams => {
    const params = qs.parse(location.search);
    return {
      token: params.token as string,
      // printable: params.printable === "true",
    };
  })();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { loading, error, deal } = useSelector((state: IAppState) => ({
    loading: state.deals.loading,
    error: state.deals.error,
    deal: state.deals.deal,
  }));

  const { id } = match.params;

  useEffect(() => {
    if (!id) return;
    dispatch(actions.getTokenDeal(id, queryParams.token, true));
    // dispatch(actions.getDeal(+id));
    // eslint-disable-next-line
  }, [dispatch]);

  if (!id) return null;

  const isCar = deal?.type === EDealType.CAR;

  const mapLabelAndValue = (label: ETranslation, value?: TInputValue, bold?: boolean, suffix?: string) => {
    return (
      <div className={classes.ValueContainer}>
        <div className={classes.Label}>
          <p>{t(label)}:</p>
        </div>
        <div className={classes.Value}>
          <p>
            {value
              ? bold
                ? <b>{value}</b>
                : value
              : null}
            {' '}
            {suffix}
          </p>
        </div>
      </div>
    )
  };

  const mapBasicDetails = () => {
    return (
      <div>
        <p className={classes.SectionTitle}>
          {isCar ? t(ETranslation.DEAL_ORDER_CONFIRMATION_CAR_INFORMATION) : t(ETranslation.DEAL_ORDER_CONFIRMATION_MACHINE_INFORMATION)}
        </p>
        <div className={classes.Section}>
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_BRAND, deal?.brand, true)}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_MODEL, deal?.model, true)}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_SALE_NUMBER, deal?.dealershipSaleNumber, true)}
          {isCar && mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_CO2_EMISSIONS, deal?.co2Emissions, true, InputSuffix.GRAMS_PER_KILOMETER)}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_ACCESSORIES, deal?.accessories, true)}
          {isCar
            ? mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_TOTAL_PRICE_VAT, deal?.totalPriceVAT, true, InputSuffix.EUROS)
            : mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_TOTAL_PRICE, deal?.totalPrice, true, InputSuffix.EUROS)
          }
          {isCar && mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_DISCOUNT, deal?.discount, false, InputSuffix.EUROS)}
          {isCar && mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_CAR_TAX, deal?.carTax, false, InputSuffix.EUROS)}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_REGISTRATION_NUMBER, deal?.registrationNumber, true)}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_SERIAL_NUMBER, deal?.serialNumber, true)}
        </div>
      </div>
    );
  };

  const getUserDetails = () => {
    let user = "";
    if (deal?.user?.name) user += deal.user.name;
    if (deal?.user?.socialSecurityNumber) user += `, ${deal.user.socialSecurityNumber}`;
    if (deal?.user?.phoneNumber) user += `, ${deal.user.phoneNumber}`;
    if (deal?.user?.streetAddress) user += `, ${deal.user.streetAddress}`;
    if (deal?.user?.zip) user += `, ${deal.user.zip}`;
    if (deal?.user?.city) user += `, ${deal.user.city}`;
    return user;
  }

  const getCustomerDetails = () => {
    let customer = "";
    if (deal?.customer?.name) customer += deal.customer.name;
    if (deal?.customer?.businessId) customer += `, ${deal.customer.businessId}`
    if (deal?.customer?.streetAddress) customer += `, ${deal.customer.streetAddress}`
    if (deal?.customer?.zip) customer += `, ${deal.customer.zip}`
    if (deal?.customer?.city) customer += `, ${deal.customer.city}`
    return customer;
  }

  const olfInfo = "One Leasing Finland Oy, 2957321-6, Tietotie 9 01530 Vantaa";

  const mapRegistrationInfo = () => {
    return (
      <div>
        <p className={classes.SectionTitle}>
          {t(ETranslation.DEAL_ORDER_CONFIRMATION_REGISTRATION_INFO)}
        </p>
        <div className={classes.Section}>
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_OWNER, deal?.financeCompany?.registrationInfo, true)}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_HOLDER_1, getCustomerDetails())}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_HOLDER_2,
            isCar
              ? getUserDetails()
              : olfInfo

          )}
          {isCar && mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_HOLDER_3, olfInfo)}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TITLE,
            isCar
              ? t(ETranslation.DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TEXT_CAR).toString()
              : t(ETranslation.DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TEXT_MACHINE).toString()
          )}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_INSURANCE_COMPANY, deal?.insurance)}
        </div>
      </div>
    );
  };

  const mapBillingDetails = () => {
    const invoicePayer = deal?.invoicePayer === EInvoicePayer.FINANCE_COMPANY
      ? deal?.financeCompany?.invoicePayer
      : olfInfo;

    return (
      <div>
        <p className={classes.SectionTitle}>
          {t(ETranslation.DEAL_ORDER_CONFIRMATION_BILLING_DETAILS)}
        </p>
        <div className={classes.Section}>
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_INVOICE_PAYER, invoicePayer, true)}
          {mapLabelAndValue(
            ETranslation.DEAL_ORDER_CONFIRMATION_INVOICE_DELIVERY,
            t(ETranslation.DEAL_ORDER_CONFIRMATION_INVOICE_DELIVERY_TEXT, { email: deal?.seller?.email }).toString()
          )}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_PAYMENT_TERM_TITLE, deal?.financeCompany?.paymentTerm)}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TITLE, deal?.financeCompany?.referenceInformation)}
          {deal?.selectedVariation?.paymentType === EPaymentType.INSTALLMENT && (
            <>
              {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TITLE, t(ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TEXT).toString())}
              {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TITLE, deal?.selectedVariation?.downPayment)}
            </>
          )}
          {deal?.selectedVariation?.paymentType === EPaymentType.CASH_OLF && (
            <>
              {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TITLE, deal?.selectedVariation?.downPayment + t(ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_CASH_OLF).toString())}
            </>
          )}
          {deal?.selectedVariation?.paymentType === EPaymentType.CASH_SELLER && (
            <>
              {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TITLE, deal?.selectedVariation?.downPayment + t(ETranslation.DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_CASH_SELLER).toString())}
            </>
          )}
        </div>
      </div>
    );
  };

  const mapHandover = () => {
    return (
      <div>
        <p className={classes.SectionTitle}>
          {t(ETranslation.DEAL_ORDER_CONFIRMATION_HANDOVER)}
        </p>
        <div className={classes.Section}>
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_HANDOVER_INFO, t(ETranslation.DEAL_ORDER_CONFIRMATION_HANDOVER_INFO_TEXT).toString())}
        </div>
      </div>
    );
  };

  const mapValidity = () => {
    return (
      <div>
        <p className={classes.SectionTitle}>
          {t(ETranslation.DEAL_ORDER_CONFIRMATION_VALIDITY)}
        </p>
        <div className={classes.Section}>
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_FINANCIAL_ELIGIBILITY, t(ETranslation.DEAL_ORDER_CONFIRMATION_FINANCIAL_ELIGIBILITY_TEXT).toString())}
          {mapLabelAndValue(ETranslation.DEAL_ORDER_CONFIRMATION_CREDIT_DECISION, t(ETranslation.DEAL_ORDER_CONFIRMATION_CREDIT_DECISION_TEXT).toString())}
        </div>
      </div>
    );
  };

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <div className={classes.Container}>
        {
          loading
            ? <Spinner />
            : deal // && deal.status === EDealStatus.PRECONTRACT
              ? (
                <>
                  <DesktopLogo>
                    <p>{t(ETranslation.DEAL_ORDER_CONFIRMATION_PRINTED)}: {dateToString(new Date())}</p>
                  </DesktopLogo>

                  <div className={classes.Content}>
                    <h1 className={classes.Title}>{t(ETranslation.DEAL_ORDER_CONFIRMATION)}</h1>

                    {/* Koneen/auton tiedot */}
                    {mapBasicDetails()}

                    {/* Rekisteröintiohje */}
                    {mapRegistrationInfo()}

                    {/* Laskutusohjeet */}
                    {mapBillingDetails()}

                    {/* Kohteen luovutus */}
                    {mapHandover()}

                    {/* Tilausvahvistuksen sitovuus */}
                    {mapValidity()}

                  </div>

                </>
              )
              : null
        }
      </div>
    </>
  )
};

export default DealOrderConfirmation;
