import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { IDeal, IIdListObject } from "../../../interfaces";
import { formatDateString } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../UI/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faLink } from "@fortawesome/free-solid-svg-icons";
import Button from "../../UI/Button/Button";

interface IProps {
  deals: IDeal[];
  onClick: (deal?: IDeal) => void;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  showPartnerOrganization?: boolean;
  dealView?: boolean;
  preofferView?: boolean;
  idList?: IIdListObject[]
  sentDate?: boolean;
  dealFromPreoffer?: (id?: string) => void;
  tokenLink?: boolean;
}

const DealsTablePartner: React.FC<IProps> = ({ deals, onClick, containerClassName, containerStyle, showPartnerOrganization, dealView, preofferView, idList, sentDate, dealFromPreoffer, tokenLink }) => {
  const { t } = useTranslation();
  
  const getDealIdFromPreofferId = (id: string | undefined) => {
    if (idList && id) {
      const idObject = idList.find((obj) => obj.preofferId === id);
      if (idObject) {
        return idObject.dealId;
      }
    }
    return undefined;
  }

  const getTokenLinkFromPreofferId = (id: string | undefined) => {
    if (idList && id) {
      const idObject = idList.find((obj) => obj.preofferId === id);
      if (idObject) {
        return idObject.tokenLink;
      }
    }
    return undefined;
  }
  
  return (
    <div className={containerClassName} style={containerStyle}>
      <Table hover>
        <thead>
          <tr>
            {sentDate ?
            <th>{t(ETranslation.DEAL_SEND_DATE)}</th>
            :
            <th>{t(ETranslation.COMMON_CREATED)}</th>
            }
            {preofferView &&
              <th>{t(ETranslation.DEAL_SELLER)}</th>
            }
            <th>{t(ETranslation.DRAFTS_PARTNER_ORGANIZATION)}</th>
            {dealView ? 
              <th>{t(ETranslation.DRAFTS_DEAL_NAME)}</th> 
              :<>
              <th>{t(ETranslation.DEAL_NAME)}</th>
              <th>{t(ETranslation.DRAFT_ACQUISITION)}</th></>
            }
            <th>{t(ETranslation.DRAFTS_PRICE)}</th>
            <th>{t(ETranslation.DRAFTS_CONTRACT)}</th>
            {dealView && <>
              <th>{t(ETranslation.DEAL_END_DATE)}</th>
              <th>{t(ETranslation.DEAL_KICKBACK)}</th></>
            }
            {showPartnerOrganization &&
            <th>{t(ETranslation.DRAFT_PARTNER)}</th>
            }
            {(preofferView || tokenLink ) &&
            <th>{t(ETranslation.TITLE_DEAL)}</th>
            }
          </tr>
        </thead>
        <tbody>
          {deals.map(deal => (
            <tr key={deal.id} onClick={() => onClick(deal)}>
              {sentDate ?
              <td>{deal.sendDate}</td>
              :
              <td>{deal.created && formatDateString(deal.created)}</td>
              }
              {preofferView &&
                <td>{deal.seller?.name ? deal.seller?.name : (<FontAwesomeIcon icon={faExclamationTriangle} size="lg" color={"orange"}/>)}</td>
              }
              <td>{deal.customer?.name} {deal.customer?.businessId && `(${deal.customer.businessId})`} </td>
              <td>{deal.name}</td>
              {!dealView &&
                <td>{deal.accessories?.substring(0,250)}</td>
              }
              <td>{deal.totalPriceVAT}</td>
              {dealView ? <>
                <td>{deal.selectedVariation?.contractLength}</td>
                <td>{deal.endDate}</td>
                <td>{deal.selectedVariation?.kickback}</td>
              </>:
                <td>{deal.partnerContractLength}</td>
              }
              {showPartnerOrganization &&
                <td>{deal.partnerUser?.organization?.name}</td>
              }
              {preofferView && ((idList && dealFromPreoffer && getDealIdFromPreofferId(deal.id)) ?
                <td>
                  <Button
                    style={{ height: "fit-content" }}
                    onClick={(e) =>{
                      e.stopPropagation();
                      dealFromPreoffer(getDealIdFromPreofferId(deal.id))
                    }
                    }
                    icon={faLink}
                  />
                </td>
                :
                <td></td>
                )
              }
              {tokenLink && ((idList && dealFromPreoffer && getTokenLinkFromPreofferId(deal.id)) ?
                <td>
                  <Button
                    style={{ height: "fit-content" }}
                    onClick={(e) =>{
                      e.stopPropagation();
                      dealFromPreoffer(getTokenLinkFromPreofferId(deal.id))
                    }
                    }
                    icon={faLink}
                  />
                </td>
                :
                <td></td>
                )
              }
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DealsTablePartner;
