import { RouteComponentProps } from "react-router-dom";
import * as actions from '../../../store/actions';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import React from "react";
import Alert from "../../../components/UI/Alert/Alert";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Container from "../../../components/UI/Container/Container";
import { IAppState } from "../../../interfaces/state";
import LogItemChangesTable from "../../../components/LogItemChangesTable/LogItemChangesTable";
import Button from "../../../components/UI/Button/Button";
import { Routes } from "../../../classes/Routes";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { ECategory } from "../../../enums";

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const LogItem: React.FC<IProps> = ({match, history}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = match.params;

  const { error, loading, logItem } = useSelector((state: IAppState) => ({
    error: state.logItems.error,
    loading: state.logItems.loading,
    logItem: state.logItems.logItem,
  }));

  useEffect(() => {
    dispatch(actions.getLogItem(id));
  }, [dispatch, id]);

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      {loading ? <Spinner/> : 
      logItem ? 
      <Container>
        <p>{logItem.dateString +" "+logItem.action}</p>
        <p>Tekijä käyttäjä: {logItem.actionUserName +" ("+logItem.actionUserId+")"}</p>
        <p>Tekijä organisaatio: {logItem.actionOrganizationName +" ("+logItem.actionOrganizationId+")"}</p>
        {logItem.targetDealName && <p>Sopimuksen nimi: {logItem.targetDealName}</p>}
        {logItem.targetDealId && logItem.targetDealCategory && (
          logItem.targetDealCategory === ECategory.DEAL.toString() ?
          <Button icon={faLink} onClick={()=> history.push(Routes.DEAL(logItem.targetDealId ?? ""))}/>
          :
          //logItem.targetDealCategory === ECategory.APPLICATION.toString() ?
          <Button icon={faLink} onClick={()=> history.push(Routes.DRAFT(logItem.targetDealId ?? ""))}/>
        )}
        {logItem.logItemChanges &&
        <LogItemChangesTable id={logItem.id} logItemChanges={logItem.logItemChanges}/>
        }
      </Container>
      : "not found"
      }
    </>
  );
}

export default LogItem;