import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button, { EButtonColor } from "../../../../components/UI/Button/Button";
import Input, { EInputType, IInputField, IInputOptions } from "../../../../components/UI/Input/Input";
import InputGroup from "../../../../components/UI/InputGroup/InputGroup";
import ModalContext, { defaultModal, EModalSize } from "../../../../components/UI/Modal/ModalContext";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import DealContext from "../../../../context/DealContext";
import { IAppState, INoteState } from "../../../../interfaces";
import { updateInputHandler } from "../../../../shared/utility";
import * as actions from "../../../../store/actions";
import { ETranslation } from "../../../../translations/translation-keys";
import DealNote from "./DealNote/DealNote";
import classes from "./DealNotes.module.scss";

enum EDealNote {
  text = "text",
}

const DealNotes: React.FC = () => {
  const { t } = useTranslation();
  const { id, currentUser } = useContext(DealContext);
  const { setModal, closeModal } = useContext(ModalContext);
  const dispatch = useDispatch();

  const { notes, loading } = useSelector<IAppState, INoteState>(state => state.notes);

  useEffect(() => {
    if (id) dispatch(actions.listNotes(id));
  }, [dispatch, id]);

  const [inputs, setInputs] = useState<IInputField>({
    [EDealNote.text]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.DEAL_NOTES_NEW,
      placeholderTranslation: ETranslation.DEAL_NOTE_TEXT,
      value: '',
    },
  });

  const createInput = (inputName: EDealNote, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      // updateAction={EInputUpdateAction.FINANCE_COMPANY}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    />
  };

  const saveHandler = () => {
    if (!id || !currentUser) return;
    const text = inputs[EDealNote.text].value as string;
    if (text.length === 0 || text === '') {
      alert(t(ETranslation.DEAL_NOTES_NEW_ERROR));
      return;
    }
    dispatch(actions.saveNote({ id: "", dealId: id, user: currentUser, text }));
    setInputs(inputs => {
      inputs[EDealNote.text].value = "";
      return inputs;
    })
  }

  const setDeleteDialog = (noteId: string) => setModal({
    isOpen: true,
    title: t(ETranslation.DEAL_NOTES_DELETE_TITLE),
    size: EModalSize.SMALL,
    content: (
      <div>
        <p>{t(ETranslation.DEAL_NOTES_DELETE_TEXT)}</p>
        <InputGroup>
          <Button onClick={() => deleteHandler(noteId)}>{t(ETranslation.COMMON_YES)}</Button>
          <Button onClick={closeModal}>{t(ETranslation.COMMON_NO)}</Button>
        </InputGroup>
      </div>
    ),
  });

  const deleteHandler = (noteId: string) => {
    if (!id || !currentUser) return;
    dispatch(actions.deleteNote(noteId, id));
    setModal(defaultModal);
  }

  return (
    loading ? <Spinner /> : (
      <div className={classes.Container}>
        <h3>{t(ETranslation.DEAL_NOTES)}</h3>
        <InputGroup className={classes.TextContainer}>
          {createInput(EDealNote.text)}
          <Button className={classes.Button} color={EButtonColor.SECONDARY} onClick={saveHandler}>{t(ETranslation.COMMON_ADD)}</Button>
        </InputGroup>
        {notes && notes?.map(note => <DealNote key={note.id} note={note} onDelete={setDeleteDialog} />)}
      </div>
    )
  );
}

export default DealNotes;
