import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AttachInvoiceDialog from "../../components/Invoices/AttachInvoiceDialog/AttachInvoiceDialog";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import { IOption } from "../../components/UI/Input/Input";
import InputGroup from "../../components/UI/InputGroup/InputGroup";
import ModalContext, { defaultModal, EModalSize } from "../../components/UI/Modal/ModalContext";
import Spinner from "../../components/UI/Spinner/Spinner";
import { IAppState } from "../../interfaces";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import Invoice from "./Invoice/Invoice";
import TalenomInvoice from "./TalenomInvoice/TalenomInvoice";

enum EInvoiceType {
  PROCOUNTOR = "PROCOUNTOR",
  ONE_LEASING = "ONE_LEASING",
  ONE_FLEET = "ONE_FLEET",
}

const Invoices: React.FC = () => {
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, error, invoices, talenomError, oneLeasingLoading, oneLeasingInvoices, oneFleetLoading, oneFleetInvoices } = useSelector((state: IAppState) => ({
    loading: state.invoices.loading,
    error: state.invoices.error,
    invoices: state.invoices.invoices,
    talenomError: state.talenom.error,
    oneLeasingLoading: state.talenom.oneLeasingLoading,
    oneLeasingInvoices: state.talenom.oneLeasingInvoices,
    oneFleetLoading: state.talenom.oneFleetLoading,
    oneFleetInvoices: state.talenom.oneFleetInvoices,
  }));

  const [searchProcountor, setSearchProcountor] = useState<boolean>(false);
  const [searchOneLeasing, setSearchOneLeasing] = useState<boolean>(false);
  const [searchOneFleet, setSearchOneFleet] = useState<boolean>(false);

  useEffect(() => {
    if (searchProcountor && !invoices && !loading) dispatch(actions.listInvoices());
    if (searchOneLeasing && !oneLeasingInvoices && !oneLeasingLoading) dispatch(actions.listOneLeasingInvoices());
    if (searchOneFleet && !oneFleetInvoices && !oneFleetLoading) dispatch(actions.listOneFleetInvoices());
  }, [dispatch, searchProcountor, invoices, loading, searchOneLeasing, oneLeasingLoading, oneLeasingInvoices, searchOneFleet, oneFleetLoading, oneFleetInvoices]);

  const setAttachInvoiceDialog = (type: EInvoiceType, invoiceId: string) => setModal({
    isOpen: true,
    title: t(ETranslation.INVOICES_ATTACH_INVOICE_TITLE),
    size: EModalSize.SMALL,
    content: (
      <AttachInvoiceDialog invoiceId={invoiceId} attachInvoice={(invoiceId, dealId) => attachInvoice(type, invoiceId, dealId)} />
    ),
    noScroll: true,
  });

  const attachInvoice = (type: EInvoiceType, invoiceId: string, deal: IOption) => {
    // console.log("attach invoice", deal);
    if (type === EInvoiceType.PROCOUNTOR) {
      dispatch(actions.attachInvoice(invoiceId, deal.value, deal?.label || ""));
      const invoice = invoices?.find(invoice => invoice.id === invoiceId);
      if (invoice) {
        invoice.dealId = deal.value;
        invoice.dealInfo = deal.label;
      }
    } else {
      // DISABLED FOR TALENOM
      // dispatch(actions.attachTalenomInvoice(invoiceId, deal.value));
      // const talenomInvoice = talenomInvoices?.find(invoice => invoice.id === invoiceId);
      // if (talenomInvoice) talenomInvoice.dealId = deal.value;
    }
    setModal(defaultModal);
  };

  const hideInvoice = (type: EInvoiceType, invoiceId: string) => {
    console.log("hide invoice", invoiceId);
    if (type === EInvoiceType.PROCOUNTOR) {
      dispatch(actions.hideInvoice(invoiceId));
      const invoice = invoices?.find(invoice => invoice.id === invoiceId);
      if (invoice) invoice.hidden = true;
    } else if (type === EInvoiceType.ONE_LEASING) {
      dispatch(actions.hideTalenomInvoice(invoiceId));
      const talenomInvoice = oneLeasingInvoices?.find(invoice => invoice.id === invoiceId);
      if (talenomInvoice) talenomInvoice.hidden = true;
    } else if (type === EInvoiceType.ONE_FLEET) {
      dispatch(actions.hideTalenomInvoice(invoiceId));
      const talenomInvoice = oneFleetInvoices?.find(invoice => invoice.id === invoiceId);
      if (talenomInvoice) talenomInvoice.hidden = true;
    }
    setModal(defaultModal);
  };

  const setHideInvoiceConfirmModal = (type: EInvoiceType, invoiceId: string) => setModal({
    isOpen: true,
    title: t(ETranslation.INVOICES_HIDE_INVOICE_TITLE),
    size: EModalSize.SMALL,
    content: (
      <div>
        <p>{t(ETranslation.INVOICES_HIDE_INVOICE_TEXT)}</p>
        <InputGroup>
          <Button onClick={() => hideInvoice(type, invoiceId)}>{t(ETranslation.COMMON_YES)}</Button>
          <Button onClick={() => setModal(defaultModal)}>{t(ETranslation.COMMON_NO)}</Button>
        </InputGroup>
      </div>
    ),
  });

  const createInvoices = () => {
    invoices?.reverse();
    return invoices?.map(invoice => (!invoice.hidden && !invoice.dealId) && (
      <Invoice
        key={invoice.id}
        invoice={invoice}
        attachInvoice={(invoiceId) => setAttachInvoiceDialog(EInvoiceType.PROCOUNTOR, invoiceId)}
        hideInvoice={(invoiceId) => setHideInvoiceConfirmModal(EInvoiceType.PROCOUNTOR, invoiceId)}
      />
    ));
  }

  const toggleProcountor = () => setSearchProcountor(!searchProcountor);
  const toggleOneLeasing = () => setSearchOneLeasing(!searchOneLeasing);
  const toggleOneFleet = () => setSearchOneFleet(!searchOneFleet);

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      {talenomError && <Alert>{t(talenomError)}</Alert>}
      <div style={{ padding: "1rem" }}>
        <h2>{t(ETranslation.INVOICES)}</h2>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div onClick={toggleProcountor}>
            <input type="checkbox" onChange={toggleProcountor} checked={searchProcountor} />{' '}Procountor
          </div>
          <div onClick={toggleOneLeasing}>
            <input type="checkbox" onChange={toggleOneLeasing} checked={searchOneLeasing} />{' '}Talenom, One Leasing
          </div>
          <div onClick={toggleOneFleet}>
            <input type="checkbox" onChange={toggleOneFleet} checked={searchOneFleet} />{' '}Talenom, One Fleet
          </div>
        </div>
        {searchOneLeasing ? oneLeasingLoading
          ? <Spinner />
          : oneLeasingInvoices
            ? oneLeasingInvoices?.map(invoice => (!invoice.hidden) && (
              <TalenomInvoice
                key={invoice.id}
                invoice={invoice}
                hideInvoice={(invoiceId) => setHideInvoiceConfirmModal(EInvoiceType.ONE_LEASING, invoiceId)}
              />
            ))
            : null
          : null
        }
        {searchOneFleet ? oneFleetLoading
          ? <Spinner />
          : oneFleetInvoices
            ? oneFleetInvoices?.map(invoice => (!invoice.hidden) && (
              <TalenomInvoice
                key={invoice.id}
                invoice={invoice}
                hideInvoice={(invoiceId) => setHideInvoiceConfirmModal(EInvoiceType.ONE_FLEET, invoiceId)}
              />
            ))
            : null
          : null
        }
        {searchProcountor ? loading
          ? <Spinner />
          : invoices
            ? createInvoices()
            : null
          : null
        }
      </div>
    </>
  );
}

export default Invoices;
