export enum ActionTypes {
  // Auth
  STORE_AUTH_REDIRECT,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  AUTH_GET_CLEAR,

  // Deals
  GET_DEAL_START,
  GET_DEAL_SUCCESS,
  GET_DEAL_FAIL,
  LIST_DEALS_START,
  LIST_DEALS_SUCCESS,
  LIST_DEALS_FAIL,
  SAVE_DEAL_START,
  SAVE_DEAL_SUCCESS,
  SAVE_DEAL_FAIL,
  UPDATE_DEAL_START,
  UPDATE_DEAL_SUCCESS,
  UPDATE_DEAL_FAIL,
  CLEAR_DEAL,
  GET_TOKEN_DEAL_START,
  GET_TOKEN_DEAL_SUCCESS,
  GET_TOKEN_DEAL_FAIL,
  SEND_DEAL_START,
  SEND_DEAL_SUCCESS,
  SEND_DEAL_FAIL,
  SEND_DEAL_RETURN_INFO_START,
  SEND_DEAL_RETURN_INFO_SUCCESS,
  SEND_DEAL_RETURN_INFO_FAIL,
  UPDATE_DEAL_STATUS_START,
  UPDATE_DEAL_STATUS_SUCCESS,
  UPDATE_DEAL_STATUS_FAIL,
  COPY_DEAL_START,
  COPY_DEAL_SUCCESS,
  COPY_DEAL_FAIL,
  ARCHIVE_DEAL_START,
  ARCHIVE_DEAL_SUCCESS,
  ARCHIVE_DEAL_FAIL,
  DETACH_INVOICE_START,
  DETACH_INVOICE_SUCCESS,
  DETACH_INVOICE_FAIL,
  EDIT_INVOICE_ROWS_START,
  EDIT_INVOICE_ROWS_SUCCESS,
  EDIT_INVOICE_ROWS_FAIL,
  SAVE_TERMINATION_CALCULATION_START,
  SAVE_TERMINATION_CALCULATION_SUCCESS,
  SAVE_TERMINATION_CALCULATION_FAIL,
  GET_TERMINATE_DEAL_START,
  GET_TERMINATE_DEAL_SUCCESS,
  GET_TERMINATE_DEAL_FAIL,
  GET_MAINTENANCE_INFO_DEAL_START,
  GET_MAINTENANCE_INFO_DEAL_SUCCESS,
  GET_MAINTENANCE_INFO_DEAL_FAIL,

  DEAL_IDENTIFY_START,
  DEAL_IDENTIFY_SUCCESS,
  DEAL_IDENTIFY_FAIL,

  DEAL_CONFIRM_SIGNATURE_START,
  DEAL_CONFIRM_SIGNATURE_SUCCESS,
  DEAL_CONFIRM_SIGNATURE_FAIL,

  SAVE_DRAFT_START,
  SAVE_DRAFT_SUCCESS,
  SAVE_DRAFT_FAIL,
  UPDATE_DRAFT_START,
  UPDATE_DRAFT_SUCCESS,
  UPDATE_DRAFT_FAIL,
  LIST_DRAFTS_START,
  LIST_DRAFTS_SUCCESS,
  LIST_DRAFTS_FAIL,
  LIST_PREOFFERS_START,
  LIST_PREOFFERS_SUCCESS,
  LIST_PREOFFERS_FAIL,
  GET_DRAFT_START,
  GET_DRAFT_SUCCESS,
  GET_DRAFT_FAIL,
  ARCHIVE_DRAFT_START,
  ARCHIVE_DRAFT_SUCCESS,
  ARCHIVE_DRAFT_FAIL,
  GET_PREOFFER_START,
  GET_PREOFFER_SUCCESS,
  GET_PREOFFER_FAIL,
  LIST_PARTNER_DEALS_START,
  LIST_PARTNER_DEALS_SUCCESS,
  LIST_PARTNER_DEALS_FAIL,

  // Customers
  GET_CUSTOMER_START,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  LIST_CUSTOMERS_START,
  LIST_CUSTOMERS_SUCCESS,
  LIST_CUSTOMERS_FAIL,
  SAVE_CUSTOMER_START,
  SAVE_CUSTOMER_SUCCESS,
  SAVE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_START,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,

  // Partners
  GET_PARTNER_START,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_FAIL,
  LIST_PARTNERS_START,
  LIST_PARTNERS_SUCCESS,
  LIST_PARTNERS_FAIL,
  SAVE_PARTNER_START,
  SAVE_PARTNER_SUCCESS,
  SAVE_PARTNER_FAIL,
  UPDATE_PARTNER_START,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAIL,
  DELETE_PARTNER_START,
  DELETE_PARTNER_SUCCESS,
  DELETE_PARTNER_FAIL,

  // primary organizations
  LIST_PRIMARY_ORGANIZATIONS_START,
  LIST_PRIMARY_ORGANIZATIONS_SUCCESS,
  LIST_PRIMARY_ORGANIZATIONS_FAIL,

  // Users
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  LIST_USERS_START,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAIL,
  SAVE_USER_START,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,

  // FinanceCompanies
  GET_FINANCE_COMPANY_START,
  GET_FINANCE_COMPANY_SUCCESS,
  GET_FINANCE_COMPANY_FAIL,
  LIST_FINANCE_COMPANIES_START,
  LIST_FINANCE_COMPANIES_SUCCESS,
  LIST_FINANCE_COMPANIES_FAIL,
  SAVE_FINANCE_COMPANY_START,
  SAVE_FINANCE_COMPANY_SUCCESS,
  SAVE_FINANCE_COMPANY_FAIL,
  UPDATE_FINANCE_COMPANY_START,
  UPDATE_FINANCE_COMPANY_SUCCESS,
  UPDATE_FINANCE_COMPANY_FAIL,

  // Dealerships
  GET_DEALERSHIP_START,
  GET_DEALERSHIP_SUCCESS,
  GET_DEALERSHIP_FAIL,
  LIST_DEALERSHIPS_START,
  LIST_DEALERSHIPS_SUCCESS,
  LIST_DEALERSHIPS_FAIL,
  SAVE_DEALERSHIP_START,
  SAVE_DEALERSHIP_SUCCESS,
  SAVE_DEALERSHIP_FAIL,
  UPDATE_DEALERSHIP_START,
  UPDATE_DEALERSHIP_SUCCESS,
  UPDATE_DEALERSHIP_FAIL,
  UPDATE_DEALERSHIP_LIST,

  // Attachments
  CLEAR_ATTACHMENT_STATE,
  UPLOAD_ATTACHMENT_START,
  UPLOAD_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENT_FAIL,
  OPEN_ATTACHMENT_START,
  OPEN_ATTACHMENT_SUCCESS,
  OPEN_ATTACHMENT_FAIL,

  // Reports
  LIST_REPORTS_START,
  LIST_REPORTS_SUCCESS,
  LIST_REPORTS_FAIL,

  LIST_HANDOVERS_START,
  LIST_HANDOVERS_SUCCESS,
  LIST_HANDOVERS_FAIL,

  // Pipedrive Deal
  GET_PIPEDRIVE_DEAL_START,
  GET_PIPEDRIVE_DEAL_SUCCESS,
  GET_PIPEDRIVE_DEAL_FAIL,
  GET_NEW_PIPEDRIVE_DEAL_START,
  GET_NEW_PIPEDRIVE_DEAL_SUCCESS,
  GET_NEW_PIPEDRIVE_DEAL_FAIL,

  // Audit logs
  LIST_AUDIT_LOGS_START,
  LIST_AUDIT_LOGS_SUCCESS,
  LIST_AUDIT_LOGS_FAIL,

  // Invoices
  LIST_INVOICES_START,
  LIST_INVOICES_SUCCESS,
  LIST_INVOICES_FAIL,
  LIST_DEAL_INVOICES_START,
  LIST_DEAL_INVOICES_SUCCESS,
  LIST_DEAL_INVOICES_FAIL,
  ATTACH_INVOICE_START,
  ATTACH_INVOICE_SUCCESS,
  ATTACH_INVOICE_FAIL,
  HIDE_INVOICE_START,
  HIDE_INVOICE_SUCCESS,
  HIDE_INVOICE_FAIL,

  // Dashboard
  GET_DASHBOARD_START,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,

  GET_LEASING_LIABILITIES_START,
  GET_LEASING_LIABILITIES_SUCCESS,
  GET_LEASING_LIABILITIES_FAIL,

  // Talenom
  TALENOM_AUTH_GET_START,
  TALENOM_AUTH_GET_SUCCESS,
  TALENOM_AUTH_GET_FAIL,

  TALENOM_INVOICES_LIST_START,
  TALENOM_INVOICES_LIST_SUCCESS,
  TALENOM_INVOICES_LIST_FAIL,

  TALENOM_INVOICES_ATTACH_START,
  TALENOM_INVOICES_ATTACH_SUCCESS,
  TALENOM_INVOICES_ATTACH_FAIL,

  TALENOM_INVOICES_HIDE_START,
  TALENOM_INVOICES_HIDE_SUCCESS,
  TALENOM_INVOICES_HIDE_FAIL,

  TALENOM_INVOICES_DETACH_START,
  TALENOM_INVOICES_DETACH_SUCCESS,
  TALENOM_INVOICES_DETACH_FAIL,

  LIST_ONE_LEASING_INVOICES_START,
  LIST_ONE_LEASING_INVOICES_SUCCESS,
  LIST_ONE_LEASING_INVOICES_FAIL,
  LIST_ONE_FLEET_INVOICES_START,
  LIST_ONE_FLEET_INVOICES_SUCCESS,
  LIST_ONE_FLEET_INVOICES_FAIL,

  // Deal note
  NOTES_LIST_START,
  NOTES_LIST_SUCCESS,
  NOTES_LIST_FAIL,
  NOTES_SAVE_START,
  NOTES_SAVE_SUCCESS,
  NOTES_SAVE_FAIL,
  NOTES_DELETE_START,
  NOTES_DELETE_SUCCESS,
  NOTES_DELETE_FAIL,

  // Residual value
  NETWHEELS_GET_RESIDUAL_VALUE_START,
  NETWHEELS_GET_RESIDUAL_VALUE_SUCCESS,
  NETWHEELS_GET_RESIDUAL_VALUE_FAIL,
  NETWHEELS_CLEAR,

  // Extended Info
  BILTEMA_GET_EXTENDED_INFO_START,
  BILTEMA_GET_EXTENDED_INFO_SUCCESS,
  BILTEMA_GET_EXTENDED_INFO_FAIL,
  BILTEMA_CLEAR,

  // Settings
  SETTINGS_GET_START,
  SETTINGS_GET_SUCCESS,
  SETTINGS_GET_FAIL, 

  // LogItems
  LIST_LOG_ITEMS_START,
  LIST_LOG_ITEMS_SUCCESS,
  LIST_LOG_ITEMS_FAIL,

  GET_LOG_ITEM_START,
  GET_LOG_ITEM_SUCCESS,
  GET_LOG_ITEM_FAIL,

  CLEAR_LOG_ITEMS,
}
