import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/olflogo.png";
import Alert from "../../components/UI/Alert/Alert";
import Button, { EButtonColor, EButtonType } from "../../components/UI/Button/Button";
import Input, { EInputType, IInputField, IInputOptions } from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../context/InputContext";
import { updateInputHandler } from "../../shared/utility";
import { ETranslation } from "../../translations/translation-keys";
import { useAuthentication } from "../../hooks/useAuthentication";

const classes = require("./Auth.module.scss");

export enum EAuth {
  username = "username",
  password = "password",
};

const Auth: React.FC = () => {
  const { signIn, authLoading, authError, recaptchaRef } = useAuthentication();

  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    [EAuth.username]: {
      type: EInputType.email,
      labelTranslation: ETranslation.USERNAME,
      placeholderTranslation: ETranslation.USERNAME,
      value: "",
      validation: {
        required: true
      },
    },
    [EAuth.password]: {
      type: EInputType.password,
      labelTranslation: ETranslation.PASSWORD,
      placeholderTranslation: ETranslation.PASSWORD,
      value: "",
      validation: {
        required: true
      },
    },
  });

  useEffect(() => {
    document.body.classList.add(classes.Background);
    return () => {
      document.body.classList.remove(classes.Background);
    };
  }, []);

  const createInput = (inputName: EAuth, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={undefined}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.AUTH}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    />
  };

  const submitHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const username = inputs[EAuth.username].value as string;
    const password = inputs[EAuth.password].value as string;
    signIn(username, password);
  };

  return (
    <div className={classes.Container}>
      <div className={classes.LogoContainer}>
        <img src={Logo} alt="One Leasing Finland" className={classes.Logo} />
      </div>
      <div className={classes.LoginBox}>
        <div className={classes.Title}>{t(ETranslation.TITLE_LEASING_MANAGEMENT)}</div>
        <div ref={recaptchaRef} />
        {(authLoading && <Spinner />) || (
          <form onSubmit={submitHandler}>
            <div className={classes.Inputs}>
              {createInput(EAuth.username, {
                showValidation: false,
              })}
              {createInput(EAuth.password, {
                showValidation: false,
              })}
            </div>
            <div className={classes.ButtonContainer}>
              <Button onClick={submitHandler} className={classes.Button} color={EButtonColor.SECONDARY} type={EButtonType.SUBMIT}>
                {t(ETranslation.LOGIN)}
              </Button>
            </div>
            {authError && (
              <Alert className={classes.ErrorMessage}>{t(authError)}</Alert>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Auth;

