import React, { useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "../../classes/Routes";
import Container from "../../components/UI/Container/Container";
import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState, IDeal } from "../../interfaces";
import Alert from "../../components/UI/Alert/Alert";
import Spinner from "../../components/UI/Spinner/Spinner";
import DealsTablePartner from "../../components/Deals/DealsTable/DealsTablePartner";
import PreoffersSearch from "../../components/Deals/PreoffersSearch/PreoffersSearch";
import { ETranslation } from "../../translations/translation-keys";

interface IProps extends RouteComponentProps { }

const Preoffers: React.FC<IProps> = ({history}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { error, loading, preoffers, idList } = useSelector((state: IAppState) => ({
    error: state.deals.error,
    loading: state.deals.loading,
    preoffers: state.deals.preOffers,
    idList: state.deals.idList,
  }));

  const searchHandler = useCallback((searchOptions) => {
    dispatch(actions.listPreoffers(searchOptions));
  }, [dispatch]);

  const showDeal = (deal?: IDeal) => {
    if (deal) {
      history.push(Routes.DRAFT(deal.id));
    }
  }

  const dealFromPreoffer = (id?: string) => {
    if (id) {
      history.push(Routes.DEAL(id));
    }
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
       
      <Container>
        <h2>{t(ETranslation.PREOFFERS)}</h2>
        <PreoffersSearch onSearch={searchHandler} />
        {loading ? <Spinner/> :
        (preoffers &&
          <DealsTablePartner deals={preoffers} idList={idList ?? undefined} onClick={showDeal} dealFromPreoffer={dealFromPreoffer} showPartnerOrganization={true} preofferView={true} sentDate={true}/>
        )
        }
      </Container>
      
    </>
  );
}

export default Preoffers;