import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EInputUpdateAction } from "../../../../../context/InputContext";
import { InputSuffix } from "../../../../../enums";
import { IDealExtraFee } from "../../../../../interfaces";
import {
  getInputData,
  updateInputHandler,
} from "../../../../../shared/utility";
import { ETranslation } from "../../../../../translations/translation-keys";
import Button, {
  EButtonColor,
  EButtonSize,
} from "../../../../UI/Button/Button";
import { EContentType } from "../../../../UI/Dropzone/Dropzone";
import Input, {
  EInputType,
  IInputField,
  IInputOptions,
} from "../../../../UI/Input/Input";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import classes from "./ExtraFee.module.scss";

export enum EExtraFee {
  NAME = "name",
  VALUE = "value",
  ATTACHMENTS = "attachments",
}

interface IProps {
  fee: IDealExtraFee;
  onCopy: (fee: IDealExtraFee) => void;
  onRemove: (id: string) => void;
  onUpdate: (fee: IDealExtraFee) => void;
  disabled: boolean;
  attachmentsEnabled?: boolean;
}

const ExtraFee: React.FC<IProps> = ({
  fee,
  onCopy,
  onRemove,
  onUpdate,
  disabled,
  attachmentsEnabled,
}) => {
  const { t } = useTranslation();

  const [feeInputs, setFeeInputs] = useState<IInputField>({
    [EExtraFee.NAME]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_EXTRA_FEE_NAME,
      placeholderTranslation: ETranslation.DEAL_EXTRA_FEE_NAME,
      value: fee.name || "",
    },
    [EExtraFee.VALUE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_EXTRA_FEE_VALUE,
      placeholderTranslation: ETranslation.DEAL_EXTRA_FEE_VALUE,
      post: InputSuffix.EUROS,
      value: fee.value || "",
    },
    [EExtraFee.ATTACHMENTS]: {
      type: EInputType.dropzone,
      labelTranslation: ETranslation.DEAL_EXTRA_FEE_ATTACHMENTS,
      value: fee.attachments || [],
      config: {
        accept: EContentType.PDF,
        text: t(ETranslation.DROPZONE_TEXT_PDF),
        dragText: t(ETranslation.DROPZONE_DRAG_TEXT_PDF),
        folder: "extrafee",
      },
      multiple: true,
    },
  });

  useEffect(() => {
    if (fee && fee.id) onUpdate(getData());
    // eslint-disable-next-line
  }, [feeInputs]);

  const createInput = (inputName: EExtraFee, options?: IInputOptions) => {
    const item = feeInputs[inputName];
    return (
      <Input
        disabled={disabled}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.DEAL_EXTRA_FEE}
        onChange={(value) => updateInputHandler(inputName, value, setFeeInputs)}
        inputName={inputName}
      />
    );
  };

  const getData = (): IDealExtraFee => {
    const extraFee: IDealExtraFee = getInputData(feeInputs);
    extraFee.id = fee.id;
    // console.log(extraFee);
    return extraFee;
  };

  return (
    <div className={classes.Container}>
      {attachmentsEnabled && (
        <InputGroup>{createInput(EExtraFee.ATTACHMENTS)}</InputGroup>
      )}
      <InputGroup className={classes.ExtraFee}>
        {createInput(EExtraFee.NAME)}
        {createInput(EExtraFee.VALUE)}
        {!disabled && (
          <>
            <Button
              size={EButtonSize.SMALL}
              color={EButtonColor.SECONDARY}
              onClick={() => onCopy(getData())}
              icon={faCopy}
            />
            <Button
              size={EButtonSize.SMALL}
              color={EButtonColor.SECONDARY}
              onClick={() => {
                if (fee.id) onRemove(fee.id);
              }}
              icon={faTrash}
            />
          </>
        )}
      </InputGroup>
    </div>
  );
};

export default ExtraFee;
