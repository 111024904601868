import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import Table from "../../components/UI/Table/Table";
import { IAppState, IDealership } from "../../interfaces";
import { Routes } from "../../classes/Routes";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";

interface IProps extends RouteComponentProps { }

const Dealerships: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { loading, error, dealerships } = useSelector((state: IAppState) => ({
    loading: state.dealerships.loading,
    error: state.dealerships.error,
    dealerships: state.dealerships.dealerships,
  }));

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.listDealerships());
  }, [dispatch]);

  const showDealership = (id?: string) => {
    if (id) {
      history.push(Routes.DEALERSHIP(id));
    }
  }

  const createDealershipsTable = (dealerships: Array<IDealership>) => {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>{t(ETranslation.DEALERSHIP_NAME)}</th>
          </tr>
        </thead>
        <tbody>
          {dealerships.map(dealership => (
            <tr key={dealership.id} onClick={() => showDealership(dealership.id)}>
              <td>{dealership.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{t(ETranslation.TITLE_DEALERSHIPS)}</h2>
        <Button onClick={() => history.push(Routes.DEALERSHIPS_NEW)} icon={faPlus} style={{ marginBottom: "1rem" }}>{t(ETranslation.DEALERSHIP_ADD_NEW)}</Button>
        {loading
          ? <Spinner />
          : dealerships
            ? createDealershipsTable(dealerships)
            : <p>{t(ETranslation.NO_DEALERSHIPS)}</p>
        }
      </Container>
    </>
  )
};

export default Dealerships;
