import React, { CSSProperties, ReactNode } from "react";
import desktopLogo from "../../../../../assets/images/olflogo.png";
import classes from "./Logo.module.scss";

interface IProps {
  style?: CSSProperties;
  children?: ReactNode;
}

const Logo: React.FC<IProps> = ({ style, children }) => {
  let containerClassNames = [classes.Logo];

  if (children) {
    containerClassNames.push(classes.LogoContainer)
  }

  return (
    <div className={containerClassNames.join(" ")}>
      <div>
        <img src={desktopLogo} alt="One Leasing Finland" style={style} className={classes.Background} />
      </div>
      <div>
        {children}
      </div>
    </div>
  )
};

export default Logo;
