import React from 'react';
//import { useTranslation } from 'react-i18next';

import { useUserOptions } from '../../../hooks/useUserOptions';
//import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';

interface IProps {
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  userId: string;
}

const UserSearch: React.FC<IProps> = ({ setUserId, userId }) => {

  const { userOptions, usersLoading } = useUserOptions();
  //const { t } = useTranslation();

  return (
    <div>
      <label>Käyttäjä</label>
      <Select
        inputName={"userSearch"}
        value={userId}
        onChange={setUserId}
        options={userOptions}
        isSearchable
        isClearable
        isLoading={usersLoading}
      />
    </div>
  )

}

export default UserSearch;
