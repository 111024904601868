import { ICustomerAction, ICustomerState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: ICustomerState = {
  loading: false,
  customers: null,
  customer: null,
  error: null,
};

const getCustomerStart = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    customer: null,
    loading: true,
    error: null,
  });
};

const getCustomerSuccess = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    customer: action.customer,
    loading: false,
    error: null,
  });
};

const getCustomerFail = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listCustomersStart = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const listCustomersSuccess = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    customers: action.customers,
    loading: false,
    error: null,
  });
};

const listCustomersFail = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveCustomerStart = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveCustomerSuccess = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    customer: action.customer,
    loading: false,
    error: null,
  });
};

const saveCustomerFail = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateCustomerStart = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateCustomerSuccess = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    customer: action.customer,
    loading: false,
    error: null,
  });
};

const updateCustomerFail = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: ICustomerState = initialState,
  action: ICustomerAction
): ICustomerState => {
  switch (action.type) {
    case ActionTypes.GET_CUSTOMER_START:
      return getCustomerStart(state, action);
    case ActionTypes.GET_CUSTOMER_SUCCESS:
      return getCustomerSuccess(state, action);
    case ActionTypes.GET_CUSTOMER_FAIL:
      return getCustomerFail(state, action);
    case ActionTypes.LIST_CUSTOMERS_START:
      return listCustomersStart(state, action);
    case ActionTypes.LIST_CUSTOMERS_SUCCESS:
      return listCustomersSuccess(state, action);
    case ActionTypes.LIST_CUSTOMERS_FAIL:
      return listCustomersFail(state, action);
    case ActionTypes.SAVE_CUSTOMER_START:
      return saveCustomerStart(state, action);
    case ActionTypes.SAVE_CUSTOMER_SUCCESS:
      return saveCustomerSuccess(state, action);
    case ActionTypes.SAVE_CUSTOMER_FAIL:
      return saveCustomerFail(state, action);
    case ActionTypes.UPDATE_CUSTOMER_START:
      return updateCustomerStart(state, action);
    case ActionTypes.UPDATE_CUSTOMER_SUCCESS:
      return updateCustomerSuccess(state, action);
    case ActionTypes.UPDATE_CUSTOMER_FAIL:
      return updateCustomerFail(state, action);
    default:
      return state;
  }
};

export default reducer;
