import React from "react";
import Input, { EInputType, TInputValue } from "./Input";
import { ETranslation } from "../../../translations/translation-keys";

interface IProps {
  value?: TInputValue;
  labelTranslation: ETranslation;
  post?: string;
}

const DisabledInput: React.FC<IProps> = ({ value = "", labelTranslation, post }) => {
  return (
    <Input
      value={value}
      type={EInputType.text}
      onChange={() => {}}
      inputName=""
      labelTranslation={labelTranslation}
      disabled
      post={post}
    />
  );
};

export default DisabledInput;
