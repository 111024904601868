import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
  AnyAction, applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Reducer
} from "redux";
import thunk, { ThunkDispatch } from "redux-thunk";
import App from "./App";
import './i18n';
import "./index.scss";
import { IAppState } from "./interfaces";
import attachmentsReducer from "./store/reducers/attachmentsReducer";
import auditLogsReducer from "./store/reducers/auditLogsReducer";
import authReducer from "./store/reducers/authReducer";
import customersReducer from "./store/reducers/customersReducer";
import dealershipsReducer from "./store/reducers/dealershipsReducer";
import dealsReducer from "./store/reducers/dealsReducer";
import financeCompaniesReducer from "./store/reducers/financeCompaniesReducer";
import pipedriveDealReducer from "./store/reducers/pipedriveDealReducer";
import reportsReducer from "./store/reducers/reportsReducer";
import usersReducer from "./store/reducers/usersReducer";
import invoicesReducer from "./store/reducers/invoicesReducer";
import dashboardReducer from "./store/reducers/dashboardReducer";
import talenomReducer from "./store/reducers/talenomReducer";
import notesReducer from "./store/reducers/notesReducer";
import netwheelsReducer from "./store/reducers/netwheelsReducer";
import settingsReducer from "./store/reducers/settingsReducer";
import biltemaReducer from "./store/reducers/biltemaReducer";
import partnersReducer from "./store/reducers/partnersReducer";
import logItemsReducer from "./store/reducers/logItemsReducer";
import primaryReducer from "./store/reducers/primaryReducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const rootReducer: Reducer<IAppState> = combineReducers({
  auth: authReducer,
  deals: dealsReducer,
  customers: customersReducer,
  partners: partnersReducer,
  users: usersReducer,
  financeCompanies: financeCompaniesReducer,
  dealerships: dealershipsReducer,
  attachments: attachmentsReducer,
  reports: reportsReducer,
  pipedriveDeal: pipedriveDealReducer,
  auditLogs: auditLogsReducer,
  invoices: invoicesReducer,
  dashboard: dashboardReducer,
  talenom: talenomReducer,
  notes: notesReducer,
  netwheels: netwheelsReducer,
  settings: settingsReducer,
  biltema: biltemaReducer,
  logItems: logItemsReducer,
  primaryOrganizations: primaryReducer
});

export type TDispatch = ThunkDispatch<IAppState, void, AnyAction>;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const root = (
  <Suspense fallback="loading">
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);

ReactDOM.render(root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
