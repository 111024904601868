import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IInvoice, IInvoiceRow } from "../../../interfaces";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../../UI/Button/Button";
import InputGroup from "../../UI/InputGroup/InputGroup";
import ModalBody from "../../UI/Modal/ModalBody/ModalBody";
import ModalFooter from "../../UI/Modal/ModalFooter/ModalFooter";
import EditInvoiceRow from "./EditInvoiceRow/EditInvoiceRow";

interface IProps {
  invoice: IInvoice;
  onAccept: (id: string, rows: IInvoiceRow[]) => void;
  onCancel: () => void;
}

const EditInvoiceRowsDialog: React.FC<IProps> = ({
  invoice,
  onAccept,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<IInvoiceRow[]>(invoice.invoiceRows ?? []);

  const deleteRow = (id: number) => {
    if (!id) return;
    setRows((rows) => rows.filter((row) => row.id !== id));
  };

  return (
    <React.Fragment>
      <ModalBody
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        {rows.map((row) => (
          <EditInvoiceRow
            key={row.id}
            invoiceRow={row}
            setRows={setRows}
            deleteRow={deleteRow}
          />
        ))}
      </ModalBody>
      <ModalFooter>
        <InputGroup style={{ marginTop: "1rem" }}>
          <Button
            onClick={() => onAccept(invoice.id, rows)}
            style={{ marginRight: ".5rem" }}
          >
            {t(ETranslation.COMMON_SAVE)}
          </Button>
          <Button onClick={onCancel}>{t(ETranslation.COMMON_RETURN)}</Button>
        </InputGroup>
      </ModalFooter>
    </React.Fragment>
  );
};

export default EditInvoiceRowsDialog;
