import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { EServiceProvider } from "../../../../components/Deals/Deal/DealEdit/DealMaintenanceInfoDialog/DealMaintenanceInfoDialog";
import OneFleetLogo from "../../../../components/Logo/OneFleetLogo/OneFleetLogo";
import Alert from "../../../../components/UI/Alert/Alert";
import Condition from "../../../../components/UI/ConditionList/Condition/Condition";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { IAppState, ICondition } from "../../../../interfaces";
import { dateToString } from "../../../../shared/utility";
import * as actions from "../../../../store/actions";
import { ETranslation } from "../../../../translations/translation-keys";
import classes from "./DealMaintenanceInfo.module.scss";

interface IMatch {
  id: string;
}

interface IDealMaintenanceInfoQueryParams {
  token: string;
  serviceProvider: EServiceProvider;
  text: string;
}

const DealMaintenanceInfo: React.FC<RouteComponentProps<IMatch>> = ({
  match,
  location,
}) => {
  const params = useMemo((): IDealMaintenanceInfoQueryParams => {
    const params = new URLSearchParams(location.search);
    return {
      token: params.get("token") as string,
      serviceProvider: params.get("sp") as EServiceProvider,
      text: params.get("text") as string,
    };
  }, [location.search]);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { loading, error, deal } = useSelector((state: IAppState) => ({
    loading: state.deals.loading,
    error: state.deals.error,
    deal: state.deals.deal,
  }));

  const { id } = match.params;

  useEffect(() => {
    if (!id) return;
    dispatch(actions.getMaintenanceInfoDeal(id, params.token));
    // eslint-disable-next-line
  }, [dispatch]);

  if (!id) return null;

  const mapConditions = (conditions: ICondition[]) => {
    if (conditions) {
      return (
        <div>
          {conditions.map((condition) => {
            return (
              <Condition
                key={condition.id}
                condition={condition}
                isActive={condition.accepted}
                onChange={() => {}}
                name={""}
                disabled={true}
                invalid={false}
                inputClassName={""}
                onRemove={() => Promise.reject()}
              />
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <div className={classes.Container}>
        {loading ? (
          <Spinner />
        ) : (
          deal && (
            <>
              <OneFleetLogo>
                <p>
                  {t(ETranslation.DEAL_ORDER_CONFIRMATION_PRINTED)}:{" "}
                  {dateToString(new Date())}
                </p>
              </OneFleetLogo>

              <div className={classes.Content}>
                <h1>{t(ETranslation.DEAL_MAINTENANCE_INFO_TITLE)}</h1>
                <h2>
                  {deal.brand} {deal.model} {deal.registrationNumber}
                </h2>
                <h2>
                  {t(ETranslation.DEAL_MAINTENANCE_INFO_CAR_OWNER)}{" "}
                  {deal.customer?.name}
                </h2>

                <h3>{t(ETranslation.DEAL_MAINTENANCE_INFO_SERVICES)}</h3>
                {deal.maintenanceServices &&
                  mapConditions(deal.maintenanceServices)}

                <p>
                  {t(ETranslation.DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER)}{" "}
                  {params.serviceProvider === EServiceProvider.IMPORTER
                    ? t(
                        ETranslation.DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER_IMPORTER
                      )
                    : t(
                        ETranslation.DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER_OLF
                      )}
                </p>

                <p>{t(ETranslation.DEAL_MAINTENANCE_INFO_CONFIRM)}</p>

                <p>010 212 7701</p>
                <p>info@onefleet.fi</p>

                <div className={classes.MarginTop}>
                  <h3>
                    {t(
                      ETranslation.DEAL_MAINTENANCE_INFO_EINVOICE_INSTRUCTIONS
                    )}
                  </h3>

                  <p>
                    {t(ETranslation.DEAL_MAINTENANCE_INFO_EINVOICE)}{" "}
                    003732985844
                  </p>
                  <p>{t(ETranslation.DEAL_MAINTENANCE_INFO_BROKER)} Maventa</p>
                  <p>
                    {t(ETranslation.DEAL_MAINTENANCE_INFO_BROKER_ID)}{" "}
                    003721291126
                  </p>
                  <p>
                    {t(ETranslation.DEAL_MAINTENANCE_INFO_BROKER_ID_BANK)}{" "}
                    DABAFIHH
                  </p>
                </div>

                {params.text && (
                  <div className={classes.MarginTop}>
                    <h3>{t(ETranslation.DEAL_MAINTENANCE_INFO_EXTRA_INFO)}</h3>
                    <p style={{ whiteSpace: "pre-wrap" }}>{params.text}</p>
                  </div>
                )}
              </div>
            </>
          )
        )}
      </div>
    </>
  );
};

export default DealMaintenanceInfo;
