import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EInputUpdateAction } from "../../../../../context/InputContext";
import { EDealStatus } from "../../../../../enums";
import { updateInputHandler, validateInputs } from "../../../../../shared/utility";
import { ETranslation } from "../../../../../translations/translation-keys";
import Alert from "../../../../UI/Alert/Alert";
import Button from "../../../../UI/Button/Button";
import Input, { EInputType, IInputField, IInputOptions } from "../../../../UI/Input/Input";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import ModalBody from "../../../../UI/Modal/ModalBody/ModalBody";
import ModalContext from "../../../../UI/Modal/ModalContext";
import ModalFooter from "../../../../UI/Modal/ModalFooter/ModalFooter";

// TODO: notes komponentti, embedded entity

interface IProps {
  id?: string;
  updateStatus: EDealStatus;
  handoverDate?: Date;
  updateDealStatus: (status: EDealStatus, date: Date, notes?: string, id?: string) => void;
  disabledText?: string[];
}

export enum EDealHandoverDialog {
  HANDOVER_DATE = "handoverDate",
  HANDOVER_NOTES = "handoverNotes",
}

const DealHandoverDialog: React.FC<IProps> = ({ id, updateStatus, handoverDate, updateDealStatus, disabledText }) => {
  const { t } = useTranslation();
  const [isValid, setValid] = useState(false);
  const { closeModal } = useContext(ModalContext);

  const [inputs, setInputs] = useState<IInputField>({
    [EDealHandoverDialog.HANDOVER_DATE]: {
      type: EInputType.datepicker,
      labelTranslation: ETranslation.DEAL_HANDOVER_DATE,
      placeholderTranslation: ETranslation.DEAL_HANDOVER_DATE,
      value: handoverDate ? handoverDate : null,
      validation: {
        required: true,
      }
    },
    [EDealHandoverDialog.HANDOVER_NOTES]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.DEAL_HANDOVER_NOTES,
      placeholderTranslation: ETranslation.DEAL_HANDOVER_NOTES,
      value: "",
    },
  });

  const createInput = (inputName: EDealHandoverDialog, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.DEAL_CONFIRM_DIALOG}
      onChange={value => {
        updateInputHandler(inputName, value, setInputs);
      }}
      inputName={inputName}
      showValidation={!isValid}
    />
  };

  useEffect(() => {
    setValid(validateInputs(inputs));
  }, [inputs]);

  return (
    <React.Fragment>
      <ModalBody style={{ display: "flex", flexDirection: "column" }}>
        {disabledText ? disabledText.map(text =>
          <Alert key={text} noHorizontalMargin>{text}</Alert>
        ) : (
          <>
            {updateStatus === EDealStatus.CONTRACT
              ? <p>{t(ETranslation.DEAL_PREVIEW_CONFIRM_DEAL_TEXT)}</p>
              : <p>{t(ETranslation.DEAL_PREVIEW_SUBSCRIPTION_AGREEMENT_TEXT)}</p>
            }
            {createInput(EDealHandoverDialog.HANDOVER_DATE)}
            {updateStatus === EDealStatus.PRECONTRACT && createInput(EDealHandoverDialog.HANDOVER_NOTES)}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <InputGroup>
          {disabledText ? (
            <Button onClick={() => closeModal()}>
              {t(ETranslation.COMMON_OK)}
            </Button>
          ) : (
            <>
              <Button
                disabled={!isValid}
                onClick={() =>
                  updateDealStatus(updateStatus, inputs[EDealHandoverDialog.HANDOVER_DATE].value as Date, inputs[EDealHandoverDialog.HANDOVER_NOTES].value as string, id)
                }
              >
                {t(ETranslation.COMMON_YES)}
              </Button>
              <Button onClick={() => closeModal()}>
                {t(ETranslation.COMMON_NO)}
              </Button>
            </>
          )}
        </InputGroup>
      </ModalFooter>
    </React.Fragment >
  );
}

export default DealHandoverDialog;
