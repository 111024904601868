import { ISettingsAction, ISettingsState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: ISettingsState = {
  loading: false,
  netsUrl: null,
  error: null,
};

const getSettingsStart = (
  state: ISettingsState,
  action: ISettingsAction
): ISettingsState => {
  return updateObject(state, {
    netsUrl: null,
    loading: true,
    error: null,
  });
};

const getSettingsSuccess = (
  state: ISettingsState,
  action: ISettingsAction
): ISettingsState => {
  return updateObject(state, {
    netsUrl: action.netsUrl,
    loading: false,
    error: null,
  });
};

const getSettingsFail = (
  state: ISettingsState,
  action: ISettingsAction
): ISettingsState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: ISettingsState = initialState,
  action: ISettingsAction
): ISettingsState => {
  switch (action.type) {
    case ActionTypes.SETTINGS_GET_START:
      return getSettingsStart(state, action);
    case ActionTypes.SETTINGS_GET_SUCCESS:
      return getSettingsSuccess(state, action);
    case ActionTypes.SETTINGS_GET_FAIL:
      return getSettingsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
