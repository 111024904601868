import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { TDispatch } from "../../..";
import Alert from "../../../components/UI/Alert/Alert";
import Button from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import Fieldset from "../../../components/UI/Fieldset/Fieldset";
import Input, { EInputType, IInputField, IInputOptions } from "../../../components/UI/Input/Input";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Limit from "../../../components/UI/Limit/Limit";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../../context/InputContext";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { IAppState, IOrganization, IOrganizationLimit } from "../../../interfaces";
import { Routes } from "../../../classes/Routes";
import { generateUUID, initForm, updateInputHandler, validateInputs } from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import Users from "../../Users/Users";
import { EOrganizationLevel } from "../../../enums";

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

export enum ECustomer {
  NAME = "name",
  BUSINESS_ID = "businessId",
  PIPEDRIVE_ORGANIZATION_ID = "pipedriveOrganizationId",
  STREET_ADDRESS = "streetAddress",
  ZIP = "zip",
  CITY = "city",
  LIMITS = "limits",
  contactPersonName = "contactPersonName",
  contactPersonEmail = "contactPersonEmail",
  contactPersonPhoneNumber = "contactPersonPhoneNumber",
}

const Customer: React.FC<IProps> = ({ history, location, match, }) => {
  const dispatch = useDispatch<TDispatch>();

  const authUser = useAuthUser();

  const { loading, error, customer, financeCompanies, financeCompaniesLoading, financeCompaniesError } = useSelector((state: IAppState) => ({
    loading: state.customers.loading,
    error: state.customers.error,
    customer: state.customers.customer,
    financeCompanies: state.financeCompanies.financeCompanies,
    financeCompaniesLoading: state.financeCompanies.loading,
    financeCompaniesError: state.financeCompanies.error,
  }));

  const { t } = useTranslation();
  const { id } = match.params;

  const isNew = id === "new";

  const initialInputs: IInputField = {
    [ECustomer.NAME]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CUSTOMER_NAME,
      placeholderTranslation: ETranslation.CUSTOMER_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [ECustomer.BUSINESS_ID]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CUSTOMER_BUSINESS_ID,
      placeholderTranslation: ETranslation.CUSTOMER_BUSINESS_ID,
      value: "",
      validation: {
        required: true
      }
    },
    [ECustomer.PIPEDRIVE_ORGANIZATION_ID]: {
      type: EInputType.number,
      labelTranslation: ETranslation.CUSTOMER_PIPEDRIVE_ORGANIZATION_ID,
      placeholderTranslation: ETranslation.CUSTOMER_PIPEDRIVE_ORGANIZATION_ID,
      value: "",
      // validation: {
      //   required: true
      // }
    },
    [ECustomer.STREET_ADDRESS]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_STREET_ADDRESS,
      placeholderTranslation: ETranslation.COMMON_STREET_ADDRESS,
      value: "",
      validation: {
        required: true
      }
    },
    [ECustomer.ZIP]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_ZIP,
      placeholderTranslation: ETranslation.COMMON_ZIP,
      value: "",
      validation: {
        required: true
      }
    },
    [ECustomer.CITY]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_CITY,
      placeholderTranslation: ETranslation.COMMON_CITY,
      value: "",
      validation: {
        required: true
      }
    },
    [ECustomer.contactPersonName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTACT_PERSON_NAME,
      placeholderTranslation: ETranslation.CONTACT_PERSON_NAME,
      value: "",
    },
    [ECustomer.contactPersonEmail]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTACT_PERSON_EMAIL,
      placeholderTranslation: ETranslation.CONTACT_PERSON_EMAIL,
      value: "",
    },
    [ECustomer.contactPersonPhoneNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTACT_PERSON_PHONE_NUMBER,
      placeholderTranslation: ETranslation.CONTACT_PERSON_PHONE_NUMBER,
      value: "",
    },
  };

  const [inputs, setInputs] = useState<IInputField>({ ...initialInputs });

  const [limits, setLimits] = useState<IOrganizationLimit[]>([]);

  const [isValid, setValid] = useState(false);

  useEffect(() => {
    if (!authUser?.isPrimary) return;
    dispatch(actions.listFinanceCompanies());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (id && !isNew) {
      dispatch(actions.getCustomer(id));
    }
  }, [id, dispatch, isNew]);

  useEffect(() => {
    if (id && !isNew && customer) {
      setInputs(stateInputs => initForm(stateInputs, customer));
      setLimits(customer?.limits || []);
    } else {
      setInputs(_ => initialInputs);
      setLimits([]);
    }
    // eslint-disable-next-line
  }, [customer]);

  const createInput = (inputName: ECustomer, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.CUSTOMER}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
      showValidation={!isValid}
    />
  };

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const name = inputs[ECustomer.NAME].value as string;
    const businessId = inputs[ECustomer.BUSINESS_ID].value as string;
    const pipedriveOrganizationId = inputs[ECustomer.PIPEDRIVE_ORGANIZATION_ID].value as string;
    const streetAddress = inputs[ECustomer.STREET_ADDRESS].value as string;
    const zip = inputs[ECustomer.ZIP].value as string;
    const city = inputs[ECustomer.CITY].value as string;
    const contactPersonName = inputs[ECustomer.contactPersonName].value as string;
    const contactPersonEmail = inputs[ECustomer.contactPersonEmail].value as string;
    const contactPersonPhoneNumber = inputs[ECustomer.contactPersonPhoneNumber].value as string;

    const organization: IOrganization = {
      id: "",
      name,
      level: EOrganizationLevel.CUSTOMER,
      businessId,
      pipedriveOrganizationId,
      streetAddress, zip, city, limits,
      contactPersonName, contactPersonEmail, contactPersonPhoneNumber,
    }

    if (isNew) {
      await dispatch(actions.saveCustomer(organization));
    } else {
      organization.id = id;
      await dispatch(actions.updateCustomer(organization));
    }

    // console.log(organization);
    history.push(Routes.CUSTOMERS);
  };

  const createInputs = () => {
    return (
      <>
        <InputGroup>
          {createInput(ECustomer.NAME)}
        </InputGroup>
        <InputGroup>
          {createInput(ECustomer.BUSINESS_ID)}
        </InputGroup>
        {/* <InputGroup>
          {createInput(ECustomer.PIPEDRIVE_ORGANIZATION_ID)}
        </InputGroup> */}
        <InputGroup>
          {createInput(ECustomer.STREET_ADDRESS)}
        </InputGroup>
        <InputGroup>
          {createInput(ECustomer.ZIP)}
          {createInput(ECustomer.CITY)}
        </InputGroup>
        <InputGroup>
          {createInput(ECustomer.contactPersonName)}
          {createInput(ECustomer.contactPersonEmail)}
          {createInput(ECustomer.contactPersonPhoneNumber)}
        </InputGroup>
      </>
    );
  }

  const defaultLimit = (id: string): IOrganizationLimit => {
    return { id, financeCompany: undefined, amount: undefined };
  }

  const addLimit = () => {
    // console.log("add")
    setLimits([...limits, defaultLimit(generateUUID(ECustomer.LIMITS))]);
  };

  const removeLimit = (id: string) => {
    // console.log("remove")
    setLimits(limits.filter(l => l.id !== id));
  };

  // const updateLimit = (id: string, field: string, value: TInputValue) => {
  //   // console.log("update", field, value)
  //   setLimits(limits.map(el => el.id === id ? { ...el, [field]: value } : el));
  // };

  const updateLimit = (limit: IOrganizationLimit) => {
    // console.log("Updating limit", limit);
    setLimits(limits.map(el => el.id === limit.id ? { ...limit } : el));
  }

  const createLimits = () => {
    return (
      <>
        {limits && limits.length > 0 && (
          <Fieldset label={t(ETranslation.LIMITS)}>
            {limits.map(limit => (
              <Limit key={limit.id} financeCompanies={financeCompanies || []} limit={limit} onRemove={removeLimit} onUpdate={updateLimit} />
            ))}
          </Fieldset>
        )}
        <Button style={{ marginBottom: "1rem" }} onClick={addLimit}>{t(ETranslation.COMMON_ADD)} {t(ETranslation.LIMIT)}</Button>
      </>
    )
  }

  useEffect(() => {
    setValid(validateInputs(inputs));
  }, [inputs]);

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      {financeCompaniesError && <Alert>{t(financeCompaniesError)}</Alert>}
      <Container>
        <h2>{isNew ? t(ETranslation.TITLE_CUSTOMER_NEW) : t(ETranslation.TITLE_CUSTOMER)}</h2>
        {loading || financeCompaniesLoading
          ? <Spinner />
          : (
            <>
              {createInputs()}
              {createLimits()}
            </>
          )
        }
        <InputGroup>
          <Button disabled={loading || !isValid} onClick={submitHandler}>
            {t(ETranslation.COMMON_SAVE)}
          </Button>
          <Button onClick={() => history.goBack()}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
        </InputGroup>
        {!isNew && <Users organizationId={id} history={history} location={location} match={match} />}
      </Container>
    </>
  );
};

export default Customer;
