import { INetwheelsAction, INetwheelsState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: INetwheelsState = {
  loading: false,
  residualValue: null,
  error: null,
};

const getNetwheelsResidualValueStart = (
  state: INetwheelsState,
  action: INetwheelsAction
): INetwheelsState => {
  return updateObject(state, {
    residualValue: null,
    loading: true,
    error: null,
    variationId: action.variationId,
  });
};

const getNetwheelsResidualValueSuccess = (
  state: INetwheelsState,
  action: INetwheelsAction
): INetwheelsState => {
  return updateObject(state, {
    residualValue: action.residualValue,
    loading: false,
    error: null,
  });
};

const getNetwheelsResidualValueFail = (
  state: INetwheelsState,
  action: INetwheelsAction
): INetwheelsState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    variationId: null,
  });
};

const clearNetwheelsState = (
  state: INetwheelsState,
  action: INetwheelsAction
): INetwheelsState => {
  return initialState;
};

const reducer = (
  state: INetwheelsState = initialState,
  action: INetwheelsAction
): INetwheelsState => {
  switch (action.type) {
    case ActionTypes.NETWHEELS_GET_RESIDUAL_VALUE_START:
      return getNetwheelsResidualValueStart(state, action);
    case ActionTypes.NETWHEELS_GET_RESIDUAL_VALUE_SUCCESS:
      return getNetwheelsResidualValueSuccess(state, action);
    case ActionTypes.NETWHEELS_GET_RESIDUAL_VALUE_FAIL:
      return getNetwheelsResidualValueFail(state, action);
    case ActionTypes.NETWHEELS_CLEAR:
      return clearNetwheelsState(state, action);
    default:
      return state;
  }
};

export default reducer;
