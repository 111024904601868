import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { TDispatch } from '../../..';
import { Routes } from '../../../classes/Routes';
import Alert from '../../../components/UI/Alert/Alert';
import Button from '../../../components/UI/Button/Button';
import Container from '../../../components/UI/Container/Container';
import { EContentType } from '../../../components/UI/Dropzone/Dropzone';
import Fieldset from '../../../components/UI/Fieldset/Fieldset';
import { EInputType, IInputField } from '../../../components/UI/Input/Input';
import InputGroup from '../../../components/UI/InputGroup/InputGroup';
import ModalContext, { EModalSize, defaultModal } from '../../../components/UI/Modal/ModalContext';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { EDealStatus, InputSuffix } from '../../../enums';
import { useAuthUser } from '../../../hooks/useAuthUser';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { useUserOptions } from '../../../hooks/useUserOptions';
import { IAppState, IAttachment, IDeal, IOrganization, IUser } from '../../../interfaces';
import { initForm, pmt, toTwoDecimals, updateInputHandler, validateInputs } from '../../../shared/utility';
import { VAT_PERCENT_255 } from '../../../shared/vat';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import classes from './Draft.module.scss';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

export enum EDraftInputs {
  seller = "seller",
  name = "name",

  customerName = "customerName",
  customerBusinessId = "customerBusinessId",
  customerStreetAddress = "customerStreetAddress",
  customerZip = "customerZip",
  customerCity = "customerCity",
  contactPersonName = "contactPersonName",
  contactPersonEmail = "contactPersonEmail",
  contactPersonPhoneNumber = "contactPersonPhoneNumber",

  accessories = "accessories",
  totalPriceVAT = "totalPriceVAT",

  partnerContractLength = "partnerContractLength",
  partnerGrossProfit = "partnerGrossProfit",
  partnerExchangeValue = "partnerExchangeValue",
  partnerExchangeDescription = "partnerExchangeDescription",
  partnerResidual = "partnerResidual",
  
  registrationNumber = "registrationNumber",
  serialNumber = "serialNumber",
  attachments = "attachments"
}

const Draft: React.FC<IProps> = ({ match, history }) => {
  const dispatch = useDispatch<TDispatch>();
  const { t } = useTranslation();
  const { id } = match.params;
  const isNew = id === "new";
  const authUser = useAuthUser();
  const { setModal, closeModal } = useContext(ModalContext);
  const [changesMade, setChangesMade] = useState<boolean>(false);

  const { loading, error, deal, users, usersLoading, loadingAttachmentIds } = useSelector((state: IAppState) => ({
    loading: state.deals.loading,
    error: state.deals.error,
    deal: state.deals.deal,
    users: state.users.users,
    usersLoading: state.users.loading,
    loadingAttachmentIds: state.attachments.loadingIds
  }));

  const initialInputs: IInputField = {
    [EDraftInputs.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_NAME,
      placeholderTranslation: ETranslation.DEAL_NAME,
      value: "",
      validation: {
        required: true
      },
    },
    [EDraftInputs.customerName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_CUSTOMER_NAME,
      placeholderTranslation: ETranslation.DEAL_CUSTOMER_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [EDraftInputs.customerBusinessId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_CUSTOMER_BUSINESS_ID,
      placeholderTranslation: ETranslation.DEAL_CUSTOMER_BUSINESS_ID,
      value: "",
      validation: {
        required: true
      }
    },
    [EDraftInputs.customerStreetAddress]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_CUSTOMER_STREET_ADDRESS,
      placeholderTranslation: ETranslation.DEAL_CUSTOMER_STREET_ADDRESS,
      value: "",
      validation: {
        required: true
      }
    },
    [EDraftInputs.customerZip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_CUSTOMER_ZIP,
      placeholderTranslation: ETranslation.DEAL_CUSTOMER_ZIP,
      value: "",
      validation: {
        required: true
      }
    },
    [EDraftInputs.customerCity]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_CUSTOMER_CITY,
      placeholderTranslation: ETranslation.DEAL_CUSTOMER_CITY,
      value: "",
      validation: {
        required: true
      }
    },
    [EDraftInputs.contactPersonName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTACT_PERSON_NAME,
      placeholderTranslation: ETranslation.CONTACT_PERSON_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [EDraftInputs.contactPersonEmail]: {
      type: EInputType.email,
      labelTranslation: ETranslation.CONTACT_PERSON_EMAIL,
      placeholderTranslation: ETranslation.CONTACT_PERSON_EMAIL,
      value: "",
      validation: {
        required: true
      }
    },
    [EDraftInputs.contactPersonPhoneNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTACT_PERSON_PHONE_NUMBER,
      placeholderTranslation: ETranslation.CONTACT_PERSON_PHONE_NUMBER,
      value: "",
      validation: {
        required: true
      }
    },

    [EDraftInputs.accessories]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.DRAFT_ACCESSORIES,
      placeholderTranslation: ETranslation.DRAFT_ACCESSORIES,
      value: "",
      validation: {
        required: true
      }
    },
    [EDraftInputs.totalPriceVAT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DRAFT_TOTAL_PRICE_VAT,
      placeholderTranslation: ETranslation.DRAFT_TOTAL_PRICE_VAT,
      post: InputSuffix.EUROS,
      value: "",
      validation: {
        required: true,
        minAmount: 1
      }
    },
    [EDraftInputs.partnerContractLength]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CONTRACT_LENGTH,
      placeholderTranslation: ETranslation.DEAL_CONTRACT_LENGTH,
      post: InputSuffix.MONTHS(t),
      value: "",
      validation: {
        required: true,
        maxAmount: 72,
        minAmount: 1
      }
    },
    [EDraftInputs.registrationNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_REGISTRATION_NUMBER,
      placeholderTranslation: ETranslation.DEAL_REGISTRATION_NUMBER,
      value: "",
    },
    [EDraftInputs.attachments]: {
      type: EInputType.dropzone,
      labelTranslation: ETranslation.DRAFT_ATTACHMENTS,
      value: [],
      config: { accept: EContentType.PDF, text: t(ETranslation.DROPZONE_TEXT_PDF), dragText: t(ETranslation.DROPZONE_DRAG_TEXT_PDF) },
      multiple: true,
    },
    [EDraftInputs.serialNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DEAL_SERIAL_NUMBER,
      placeholderTranslation: ETranslation.DEAL_SERIAL_NUMBER,
      value: "",
    },
    [EDraftInputs.partnerExchangeDescription]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DRAFT_EXCHANGE_DESCRIPTION,
      placeholderTranslation: ETranslation.DRAFT_EXCHANGE_DESCRIPTION,
      value: "",
    },
    [EDraftInputs.partnerExchangeValue]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DRAFT_EXCHANGE_VALUE,
      placeholderTranslation: ETranslation.DRAFT_EXCHANGE_VALUE,
      value: "",
      post: InputSuffix.EUROS,
    },
    [EDraftInputs.partnerGrossProfit]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DRAFT_GROSS_PROFIT,
      placeholderTranslation: ETranslation.DRAFT_GROSS_PROFIT,
      value: "",
      post: InputSuffix.EUROS,
      disabled: true
    },
    [EDraftInputs.seller]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.DEAL_SELLER,
      placeholderTranslation: ETranslation.DEAL_SELLER,
      value: "",
      options: []
    },
    [EDraftInputs.partnerResidual]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DRAFT_RESIDUAL,
      placeholderTranslation: ETranslation.DRAFT_RESIDUAL,
      value: "",
      post: InputSuffix.EUROS,
    },
  };

  const [inputs, setInputs] = useState<IInputField>({ ...initialInputs });
  const [monthlyPayment, setMonthlyPayment] = useState<number>();
  const [partnerContractLength, setPartnerContractLength] = useState<number>();
  const [totalPriceVAT, setTotalPriceVAT] = useState<number>();
  const [partnerExchangeValue, setPartnerExchangeValue] = useState<number>();
  const [partnerGrossProfit, setPartnerGrossProfit] = useState<number>();
  const [partnerInterestRate, setPartnerInterestRate] = useState<number>();
  const [partnerResidual, setPartnerResidual] = useState<number>();
  const [isValid, setValid] = useState(false);
  const { userOptions } = useUserOptions();
  const [newSave, setNewSave] = useState(false);
  const [disableForm, setDisableForm] = useState(false);

  useEffect(() => {
    if (id && !isNew) {
      dispatch(actions.listUsers());
      dispatch(actions.getDraft(id));
    }
  }, [id, dispatch, isNew]);

  useEffect(() => {
    if (authUser?.isPartner && deal?.sendDate) {
      setDisableForm(true);
    }
  }, [authUser, deal]);

  useEffect(() => {
    if (id && !isNew && deal) {
      setInputs(stateInputs => initForm(stateInputs, deal));
      updateInputHandler(EDraftInputs.customerBusinessId, deal.customer?.businessId ?? '', setInputs);
      updateInputHandler(EDraftInputs.customerCity, deal.customer?.city ?? '', setInputs);
      updateInputHandler(EDraftInputs.customerStreetAddress, deal.customer?.streetAddress ?? '', setInputs);
      updateInputHandler(EDraftInputs.customerZip, deal.customer?.zip ?? '', setInputs);
      updateInputHandler(EDraftInputs.contactPersonEmail, deal.customer?.contactPersonEmail ?? '', setInputs);
      updateInputHandler(EDraftInputs.contactPersonName, deal.customer?.contactPersonName ?? '', setInputs);
      updateInputHandler(EDraftInputs.contactPersonPhoneNumber, deal.customer?.contactPersonPhoneNumber ?? '', setInputs);

      updateInputHandler(EDraftInputs.seller, deal.seller?.id ?? '', setInputs);
      updateInputHandler(EDraftInputs.attachments, deal.attachments ?? [], setInputs);
    } else {
      setInputs(_ => initialInputs);
    }
    // eslint-disable-next-line
  }, [deal]);

  const createInput = useCreateInput(inputs, setInputs, {showValidation:!isValid, onBlur:()=> !changesMade && setChangesMade(true), disabled:disableForm});

  const getDealData = (): IDeal => {
    const name = inputs[EDraftInputs.name].value as string;

    const customerName = inputs[EDraftInputs.customerName].value as string;
    const customerBusinessId = inputs[EDraftInputs.customerBusinessId].value as string;
    const customerStreetAddress = inputs[EDraftInputs.customerStreetAddress].value as string;
    const customerZip = inputs[EDraftInputs.customerZip].value as string;
    const customerCity = inputs[EDraftInputs.customerCity].value as string;
    const contactPersonName = inputs[EDraftInputs.contactPersonName].value as string;
    const contactPersonEmail = inputs[EDraftInputs.contactPersonEmail].value as string;
    const contactPersonPhoneNumber = inputs[EDraftInputs.contactPersonPhoneNumber].value as string;

    const accessories = inputs[EDraftInputs.accessories].value as string;
    const registrationNumber = inputs[EDraftInputs.registrationNumber].value as string;
    const serialNumber = inputs[EDraftInputs.serialNumber].value as string;
    const partnerExchangeDescription = inputs[EDraftInputs.partnerExchangeDescription].value as string;
    const partnerExchangeValue = inputs[EDraftInputs.partnerExchangeValue].value as number;
    //const partnerGrossProfitPercentage = inputs[EDraftInputs.partnerGrossProfitPercentage].value as number;

    const partnerResidual = inputs[EDraftInputs.partnerResidual].value as number;

    const totalPriceVAT = inputs[EDraftInputs.totalPriceVAT].value as number;
    const totalPrice = totalPriceVAT / VAT_PERCENT_255;

    const partnerContractLength = inputs[EDraftInputs.partnerContractLength].value as number;

    const attachments = inputs[EDraftInputs.attachments].value as IAttachment[];

    let customer: IOrganization | undefined = undefined;

    if (customerName) customer = { id: "", name: customerName };
    if (customerBusinessId) customer = { id: "", ...customer, businessId: customerBusinessId };
    if (customerStreetAddress) customer = { id: "", ...customer, streetAddress: customerStreetAddress };
    if (customerZip) customer = { id: "", ...customer, zip: customerZip };
    if (customerCity) customer = { id: "", ...customer, city: customerCity };
    if (contactPersonName) customer = { id: "", ...customer, contactPersonName };
    if (contactPersonEmail) customer = { id: "", ...customer, contactPersonEmail };
    if (contactPersonPhoneNumber) customer = { id: "", ...customer, contactPersonPhoneNumber };

    const sellerId = inputs[EDraftInputs.seller].value as string;
    let seller: IUser | undefined = undefined;
    if (users && sellerId) {
       seller = users.filter(u => u.id === sellerId)[0];
    }

    const newDeal: IDeal = {
      id: "",
      ...deal,
      seller,
      name,
      customer, 
      accessories,
      totalPrice,
      totalPriceVAT,
      partnerContractLength,
      registrationNumber,
      serialNumber,
      partnerExchangeDescription,
      partnerExchangeValue,
      partnerGrossProfit,
      partnerInterestRate,
      partnerResidual,
      attachments,
      vatPercent: VAT_PERCENT_255,
    }

    return newDeal;
  }

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const newDeal = getDealData();
    if (isNew) {
      newDeal.status = EDealStatus.DRAFT;
      await dispatch(actions.saveDraft(newDeal));
    } else {
      newDeal.id = id;
      await dispatch(actions.updateDraft(newDeal, false));
    }
    setChangesMade(false);
    if (authUser?.isPartner) {
      setNewSave(true);
    } else {
      history.push(Routes.PREOFFERS);
    }
    
  };

  const sendHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (deal) {
      await dispatch(actions.updateDraft(deal, true));
    }
    history.push(Routes.DRAFTS);
  };

  useEffect(() => {
    if (partnerContractLength && partnerContractLength >= 1 && partnerContractLength <= 72 && totalPriceVAT) {
      let grossProfitPercentage = 0;
      if (totalPriceVAT < 10000) grossProfitPercentage = 5;
      if (totalPriceVAT > 10000) grossProfitPercentage = 4;
      if (totalPriceVAT > 50000) grossProfitPercentage = 3.5;
      
      const totalPriceNoVAT = totalPriceVAT / VAT_PERCENT_255;
      
      let interestRate = 0;
      if (isNew) {
        if (authUser?.organization.partnerInterestRate) interestRate = authUser.organization.partnerInterestRate;
      } else {
        if (deal?.partnerInterestRate) interestRate = deal.partnerInterestRate;
      }
      
      const grossProfit = grossProfitPercentage / 100 * totalPriceNoVAT;

      let exchangeValue = 0;
      if(partnerExchangeValue) exchangeValue = partnerExchangeValue

      let residual = 0;
      if(partnerResidual) residual = partnerResidual

      const monthly = pmt(
        interestRate / 100 / 12,
        partnerContractLength,
        -(totalPriceNoVAT + grossProfit - exchangeValue),
        +residual,
        1
      );
      
      setPartnerGrossProfit(grossProfit);
      setPartnerInterestRate(interestRate);
      updateInputHandler(EDraftInputs.partnerGrossProfit, toTwoDecimals(grossProfit), setInputs);
      setMonthlyPayment(monthly);
    } else {
      setMonthlyPayment(undefined);
    }
  }, [partnerContractLength, totalPriceVAT, partnerExchangeValue, authUser, isNew, deal, partnerResidual]);

  useEffect(() => {
    setValid(validateInputs(inputs));
    const partnerContractLengthInput = inputs[EDraftInputs.partnerContractLength].value as number;
    const totalPriceVATInput = inputs[EDraftInputs.totalPriceVAT].value as number
    const partnerExchangeValueInput = inputs[EDraftInputs.partnerExchangeValue].value as number
    const partnerResidualInput = inputs[EDraftInputs.partnerResidual].value as number
    if (partnerContractLengthInput !== partnerContractLength) setPartnerContractLength(partnerContractLengthInput);
    if (totalPriceVATInput !== totalPriceVAT) setTotalPriceVAT(totalPriceVATInput);
    if (partnerExchangeValueInput !== partnerExchangeValue) setPartnerExchangeValue(partnerExchangeValueInput);
    if (partnerResidualInput !== partnerResidual) setPartnerResidual(partnerResidualInput);
  }, [inputs, partnerContractLength, partnerExchangeValue, partnerResidual, totalPriceVAT]);



  const setArchiveDialog = () => setModal({
    isOpen: true,
    title: deal?.archived? t(ETranslation.DEAL_ACTION_UNARCHIVE) : t(ETranslation.DEAL_ACTION_ARCHIVE),
    size: EModalSize.SMALL,
    content: (
      <div>
        <p>{deal?.archived? t(ETranslation.DRAFT_UNARCHIVE_CONFIRM) : t(ETranslation.DRAFT_ARCHIVE_CONFIRM)}</p>
        <InputGroup>
          <Button onClick={() => archiveHandler()}>{t(ETranslation.COMMON_YES)}</Button>
          <Button onClick={closeModal}>{t(ETranslation.COMMON_NO)}</Button>
        </InputGroup>
      </div>
    ),
  });

  const archiveHandler = async () => {
    if (deal) {
      setModal(defaultModal);
      dispatch(actions.archiveDraft(deal?.id));
    }
    if (authUser?.isPartner) {
      history.push(Routes.DRAFTS);
    } else {
      history.push(Routes.PREOFFERS);
    }
  }

  useEffect(() => {
    if (newSave && deal) {
      setNewSave(false);
      history.push(Routes.DRAFT(deal.id));
    }
  }, [newSave, history, deal]);

  const mapAttachments = (attachments: IAttachment[]) => {
    return (
      <div style={{ wordBreak: "break-all" }}>
        <h3>{t(ETranslation.DEAL_ATTACHMENTS)}</h3>
        {attachments.map((attachment) => (
          loadingAttachmentIds?.includes(attachment.id) ? <Spinner/> :
          <p key={attachment.id} style={{cursor: "pointer"}} onClick={()=>
            dispatch(actions.openAttachment(attachment.link, attachment.id))}>
              <FontAwesomeIcon icon={faFile} size="lg" color={"black"} />{" "}
              {attachment.clientName}
          </p>
        ))}
      </div>
    );
  };

  return (
    <>
    {error && <Alert>{t(error)}</Alert>}
    {(loading || usersLoading) ? <Spinner/> :
      <Container>
        {!isNew && deal && deal.status === EDealStatus.PREOFFER && authUser?.isPrimary &&
        <Fieldset label={t(ETranslation.DEAL_SELLER)}>
          <InputGroup>{createInput(EDraftInputs.seller, {options: userOptions})}</InputGroup>
        </Fieldset>
        }
        <Fieldset label={t(ETranslation.DEAL_CUSTOMER_INFORMATION)}>
          <InputGroup>{createInput(EDraftInputs.customerName)}</InputGroup>
          <InputGroup>{createInput(EDraftInputs.customerBusinessId)}</InputGroup>
          <InputGroup>
            {createInput(EDraftInputs.customerStreetAddress)}
          </InputGroup>
          <InputGroup>
            {createInput(EDraftInputs.customerZip)}
            {createInput(EDraftInputs.customerCity)}
          </InputGroup>
          <InputGroup>
            {createInput(EDraftInputs.contactPersonName)}
            {createInput(EDraftInputs.contactPersonEmail)}
            {createInput(EDraftInputs.contactPersonPhoneNumber)}
          </InputGroup>
        </Fieldset>
        <Fieldset label={t(ETranslation.DRAFT_ACQUISITION)}>
          <InputGroup>{createInput(EDraftInputs.name)}</InputGroup>
          <InputGroup>{createInput(EDraftInputs.accessories)}</InputGroup>
          <InputGroup>
              {createInput(EDraftInputs.totalPriceVAT)}
              {createInput(EDraftInputs.partnerContractLength)}
          </InputGroup>
          <InputGroup>
              {createInput(EDraftInputs.registrationNumber)}
              {createInput(EDraftInputs.serialNumber)}
          </InputGroup>
          <InputGroup>
              {createInput(EDraftInputs.partnerExchangeValue)}
              {createInput(EDraftInputs.partnerResidual)}
              {authUser?.isPrimary &&
              createInput(EDraftInputs.partnerGrossProfit)
              }
          </InputGroup>
          <InputGroup>{createInput(EDraftInputs.partnerExchangeDescription)}</InputGroup>
        </Fieldset>
        {monthlyPayment &&
        <Fieldset label={t(ETranslation.DRAFT_VARIATION)}>
          {monthlyPayment &&
          <table className={classes.Variation}>
            <tbody>
              <tr>
                <th>{t(ETranslation.DEAL_CONTRACT_LENGTH)}</th>
                <td>{partnerContractLength} {InputSuffix.MONTHS(t)}</td>
              </tr>
              <tr>
                <th>{t(ETranslation.DEAL_MONTHLY)}</th>
                <td>{`${toTwoDecimals(monthlyPayment)} ${InputSuffix.EUROS_PER_MONTH(t)} ${t(ETranslation.DEAL_PERCENTAGE)}`}</td>
                <td>{`${toTwoDecimals(monthlyPayment * VAT_PERCENT_255)} ${InputSuffix.EUROS_PER_MONTH(t)} ${t(ETranslation.DEAL_VAT_255)}`}</td>
              </tr>
            </tbody>
          </table>
          }
        </Fieldset>
        }
        {!isNew && deal && authUser?.isPrimary &&
        <Fieldset label={t(ETranslation.DRAFT_PARTNER)}>
          <table className={classes.Variation}>
            <tbody>
              <tr>
                <th>{t(ETranslation.DRAFT_PARTNER)}</th>
                <td>{deal.partnerUser?.organization?.name} {deal?.partnerUser?.organization?.businessId && `(${deal?.partnerUser?.organization.businessId})`}</td>
              </tr>
              <tr>
                <th>{t(ETranslation.USER_NAME)}</th>
                <td>{deal.partnerUser?.name}</td>
              </tr>
              <tr>
                <th>{t(ETranslation.USER_EMAIL)}</th>
                <td>{deal.partnerUser?.email}</td>
              </tr>
              <tr>
                <th>{t(ETranslation.USER_PHONE_NUMBER)}</th>
                <td>{deal.partnerUser?.phoneNumber}</td>
              </tr>
              <tr>
                <th>{t(ETranslation.DEAL_SEND_DATE)}</th>
                <td>{deal.sendDate}</td>
              </tr>
            </tbody>
          </table>
        </Fieldset>
        }

        {authUser?.isPrimary && deal?.attachments &&
          deal.attachments.length > 0 &&
          mapAttachments(deal.attachments)}
          
        {authUser?.isPartner && createInput(EDraftInputs.attachments)}

        {authUser?.isPartner && !isNew && !disableForm && (changesMade ?
        <p style={{"color":"grey"}}>{t(ETranslation.DRAFT_NOT_SAVED)}</p>
        :
        <p style={{"color":"green"}}>{t(ETranslation.DRAFT_SAVED)}</p>
        )}
        <InputGroup>
          <Button disabled={loading || !isValid || disableForm}  onClick={submitHandler}>
            {t(ETranslation.COMMON_SAVE)}
          </Button>
          <Button onClick={() => history.goBack()}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
          {!isNew && deal && deal.status === EDealStatus.DRAFT &&
          <Button onClick={sendHandler} disabled={changesMade}>
            {t(ETranslation.COMMON_SEND)}
          </Button>
          }
          {!isNew && deal &&
          <Button disabled={loading} onClick={setArchiveDialog}>
            {deal?.archived
              ? t(ETranslation.DEAL_ACTION_UNARCHIVE)
              : t(ETranslation.DEAL_ACTION_ARCHIVE)}
          </Button>
          }
          {!isNew && deal && authUser?.isPrimary &&
          <Button disabled={loading} onClick={() => history.push(Routes.DEAL_PREOFFER(deal.id))}>
            {t(ETranslation.DRAFT_COPY)}
          </Button>
          }
        </InputGroup>
      </Container>
      }
    </>
  );
};

export default Draft;