import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthUser } from '../../classes/AuthUser';
import { Routes } from '../../classes/Routes';
import HeaderContext from '../../context/HeaderContext';
import { EDealStatus } from '../../enums';
import { ETranslation } from '../../translations/translation-keys';
import ThumbnailLogo from '../Logo/ThumbnailLogo/ThumbnailLogo';
import DealSearch from './DealSearch/DealSearch';
import classes from './Header.module.scss';
import Menu, { EMenuType, IMenuItem } from './Menu/Menu';

interface IProps extends RouteComponentProps {
  currentUser: AuthUser | null;
  logout: () => void;
}

const Header: React.FC<IProps> = ({ history, currentUser, logout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prevState) => !prevState);

  const { t } = useTranslation();

  const clickHandler = (link: string) => history.push(link);

  const menuItems: IMenuItem[] = [
    { text: t(ETranslation.DEALS), to: Routes.DEALS },
    // deal.tsx state does not reset correctly when navigating from existing deal
    // reload the page
    // to: Routes.DEALS_NEW
    // action: () => window.open(Routes.DEALS_NEW, "_self")
    { text: t(ETranslation.DEALS_NEW), to: Routes.DEALS_NEW },
    { text: t(ETranslation.PREOFFERS), to: Routes.PREOFFERS },
    {
      text: t(ETranslation.REPORTS), subMenuItems: [
        { text: t(ETranslation.REPORTS_OFFER), to: Routes.REPORTS(EDealStatus.OFFER.toLowerCase()) },
        { text: t(ETranslation.REPORTS_PRECONTRACT), to: Routes.REPORTS(EDealStatus.PRECONTRACT.toLowerCase()) },
        { text: t(ETranslation.REPORTS_CONTRACT), to: Routes.REPORTS(EDealStatus.CONTRACT.toLowerCase()) },
        { text: t(ETranslation.REPORTS_MAINTENANCE_LEASING), to: Routes.MAINTENANCE_LEASING_REPORT },
        { text: t(ETranslation.REPORTS_MAINTENANCE_BUDGET), to: Routes.MAINTENANCE_BUDGET_REPORT },
        { text: t(ETranslation.REPORTS_TERMINATED), to: Routes.REPORTS(EDealStatus.TERMINATED.toLocaleLowerCase()) },
        { text: t(ETranslation.REPORTS_ARCHIVED), to: Routes.REPORTS(EDealStatus.ARCHIVED.toLocaleLowerCase()) },
        // { text: t(ETranslation.REPORTS_FEES), to: Routes.REPORTS(EReportType.FEES.toLowerCase()) },
        { text: t(ETranslation.INVOICES), to: Routes.INVOICES },
        { text: t(ETranslation.LOG), to: Routes.LOG_ITEMS },
      ]
    },
    { text: t(ETranslation.CONTACTS), subMenuItems: [
        { text: t(ETranslation.CUSTOMERS), to: Routes.CUSTOMERS },
        { text: t(ETranslation.PARTNERS), to: Routes.PARTNERS },
      ]
    },
    { text: t(ETranslation.SEARCH), search: true },
  ];

  const dropdownMenuItems: IMenuItem[] = [
    {
      text: currentUser?.name || "", subMenuItems: [
        { text: t(ETranslation.USERS), to: Routes.USERS },
        { text: t(ETranslation.SETTINGS), to: Routes.SETTINGS },
        { text: t(ETranslation.LOGOUT), action: logout },
      ]
    }
  ];

  const customerMenuItems: IMenuItem[] = [
    { text: t(ETranslation.DASHBOARD), to: Routes.DASHBOARD },
  ]

  const customerDropdownMenuItems: IMenuItem[] = [
    {
      text: currentUser?.name || "", subMenuItems: [
        { text: t(ETranslation.USERS), to: Routes.USERS },
        { text: t(ETranslation.LOGOUT), action: logout },
      ]
    }
  ]

  const partnerMenuItems: IMenuItem[] = [
    { text: t(ETranslation.DRAFTS), to: Routes.DRAFTS },
    { text: t(ETranslation.DEALS), to: Routes.PARTNER_DEALS },
  ]

  const partnerDropdownMenuItems: IMenuItem[] = [
    {
      text: currentUser?.name || "", subMenuItems: [
        { text: t(ETranslation.USERS), to: Routes.USERS },
        { text: t(ETranslation.LOGOUT), action: logout },
      ]
    }
  ]

  return (
    <HeaderContext.Provider value={{
      menuItems: currentUser?.isPrimary ? menuItems : 
        currentUser?.isPartner ? partnerMenuItems : customerMenuItems,
      dropdownMenuItems: currentUser?.isPrimary ? dropdownMenuItems : 
        currentUser?.isPartner ? partnerDropdownMenuItems : customerDropdownMenuItems,
    }}>
      <Menu type={EMenuType.MOBILE} isOpen={isOpen} toggleMenu={toggleMenu} />
      <header className={classes.Container}>
        <div className={classes.Wrapper}>
          <div className={classes.ToggleMenu} onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} className={classes.Icon} />
          </div>
          <div className={classes.Logo} onClick={() => clickHandler(currentUser?.isPrimary ? Routes.DEALS : Routes.DASHBOARD)}>
            <ThumbnailLogo />
          </div>
          <Menu type={EMenuType.DESKTOP} isOpen={false} toggleMenu={toggleMenu} />
          {currentUser?.isPrimary ? <DealSearch text={t(ETranslation.SEARCH)} mobile={true} /> : null}
        </div>
      </header>
    </HeaderContext.Provider>
  );
};

export default withRouter(React.memo(Header));
