import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IOption } from "../components/UI/Input/Input";
import {
  IAppState,
  IAuthState,
  IUserState,
} from "../interfaces/state";
import { createOptions } from "../shared/option-utils";
import * as actions from "../store/actions";
import { ETranslation } from "../translations/translation-keys";

const defaultParams = {
  withPlaceholder: false,
  withBusinessId: false,
};

export const useUserOptions = ({
  withBusinessId,
  withPlaceholder,
} = defaultParams) => {
  const { authUser } = useSelector<IAppState, IAuthState>(
    (state) => state.auth
  );
  const { t } = useTranslation();
  const [userOptions, setUserOptions] = useState<IOption[]>(
    []
  );
  const dispatch = useDispatch();

  const [tryFetch, setTryFetch] = useState(false);

  const { users, loading } = useSelector<
    IAppState,
    IUserState
  >((state) => state.users);

  useEffect(() => {
    if (authUser) {
      setTryFetch(authUser.isPrimary);
    }
  }, [authUser]);

  useEffect(() => {
    if (tryFetch && !users && !loading) {
      dispatch(actions.listUsers());
      setTryFetch(false);
    }
  }, [dispatch, users, tryFetch, loading]);

  useEffect(() => {
    let placeholder;
    if (withPlaceholder) {
      placeholder = t(ETranslation.SELECT_SELLER_ID);
    }
    setUserOptions(
      users
        ? createOptions(users.filter(u => !u.email?.endsWith("@positiveproductions.fi") && !u.email?.endsWith("@posp.fi")), (u) => u.name || u.id, placeholder)
        : []
    );
  }, [users, t, withPlaceholder]);

  return { userOptions, usersLoading: loading };
};
