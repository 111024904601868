import React from "react";
import { useTranslation } from "react-i18next";
import { ICondition } from "../../../interfaces";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../Button/Button";
import Condition from "./Condition/Condition";
import classes from "./ConditionList.module.scss";

interface IProps {
  value: ICondition[];
  onChange: (value: ICondition) => void;
  name: string;
  disabled?: boolean;
  invalid?: boolean;
  onAdd?: () => void;
  inputClassName?: string;
  onRemove: (id: string) => Promise<void>;
}

const ConditionList: React.FC<IProps> = ({ value, onChange, name, disabled, invalid, onAdd, inputClassName, onRemove }) => {
  const { t } = useTranslation();

  let addButton = null;

  if (onAdd && !disabled) {
    addButton = (
      <Button className={classes.AddButton} onClick={onAdd}>{t(ETranslation.COMMON_ADD)}</Button>
    )
  }

  return (
    <>
      <div className={classes.OptionContainer}>
        {value &&
          value.map(condition => (
            <Condition
              key={condition.id}
              condition={condition}
              isActive={condition.accepted}
              onChange={onChange}
              name={name}
              disabled={disabled}
              invalid={invalid}
              inputClassName={inputClassName}
              onRemove={onRemove}
            />
          ))}
      </div>
      {addButton}
    </>
  );
};

export default ConditionList;
