import { faCaretDown, faCaretUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, ReactNode, useState } from "react";
import classes from "./Fieldset.module.scss";


interface IProps {
  label: string;
  children: ReactNode;
  style?: CSSProperties;
  legendStyle?: CSSProperties;
  onRemove?: () => void;
  hidden?: boolean;
  transparent?: boolean;
  accordion?: boolean;
}

const Fieldset: React.FC<IProps> = ({
  label,
  children,
  style,
  legendStyle,
  onRemove,
  hidden,
  transparent,
  accordion,
}) => {
  const [isOpen, setIsOpen] = useState(!accordion);

  if(hidden) return null;
  let containerStyles:CSSProperties = {};
  if(style) {
    containerStyles = style;
  }
  if(transparent) {
    containerStyles.background = 'transparent';
  }

  const toggle = () => {
    if (!accordion) return;
    setIsOpen(open => !open);
  }

  return (
    <fieldset className={classes.Container} style={containerStyles}>
      {onRemove && (
        <span className={classes.RemoveButton} onClick={onRemove}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      )}
      <legend className={classes.Legend} style={legendStyle}>
        {label}
        {accordion && <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} onClick={toggle} className={classes.Toggle} size="lg" />}
      </legend>
      {isOpen && children}
    </fieldset>
  );
};

export default Fieldset;
