import * as qs from "query-string";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "../../../../classes/Routes";
import DealSignatureDialog, {
  EIdentificationType,
} from "../../../../components/Deals/Deal/DealView/DealSignatureDialog/DealSignatureDialog";
import DealView from "../../../../components/Deals/Deal/DealView/DealView";
import Alert from "../../../../components/UI/Alert/Alert";
import ModalContext, {
  EModalSize,
} from "../../../../components/UI/Modal/ModalContext";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { EDealStatus } from "../../../../enums";
import { useAuthUser } from "../../../../hooks/useAuthUser";
import { IAppState } from "../../../../interfaces";
import * as actions from "../../../../store/actions";
import { ETranslation } from "../../../../translations/translation-keys";
import classes from "./DealAccept.module.scss";

interface IMatch {
  id: string;
}

interface IDealAcceptQueryParams {
  token: string;
  printable: boolean;
}

interface IProps extends RouteComponentProps<IMatch> {}

const DealAccept: React.FC<IProps> = ({ match, location, history }) => {
  const queryParams = ((): IDealAcceptQueryParams => {
    const params = qs.parse(location.search);
    return {
      token: params.token as string,
      printable: params.printable === "true",
    };
  })();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setModal } = useContext(ModalContext);

  const authUser = useAuthUser();

  const { loading, error, deal, netsUrl } = useSelector((state: IAppState) => ({
    loading: state.deals.loading,
    error: state.deals.error,
    deal: state.deals.deal,
    netsUrl: state.deals.netsUrl,
  }));

  useEffect(() => {
    const { id } = match.params;
    if (id) {
      if (authUser?.isPrimary) {
        history.push(Routes.DEAL(id));
        return;
      }
      // get deal using token
      // console.log("Query params", queryParams);
      dispatch(
        actions.getTokenDeal(id, queryParams.token, queryParams.printable)
      );
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (netsUrl) window.open(netsUrl, "_self");
    // if (netsUrl) window.open(netsUrl, "_blank");
  }, [netsUrl]);

  const signatureHandler = useCallback(
    (variationId: string) => {
      dispatch(
        actions.dealIdentify(
          match.params.id,
          queryParams.token,
          EIdentificationType.CONTRACT,
          variationId
        )
      );
    },
    [dispatch, match.params.id, queryParams.token]
  );

  const showSignatureDialog = useCallback(() => {
    if (!deal) return;
    setModal({
      isOpen: true,
      title: t(ETranslation.DEAL_ACTION_EIDENT_START),
      size: EModalSize.MEDIUM,
      content: <DealSignatureDialog deal={deal} onSubmit={signatureHandler} />,
    });
  }, [deal, setModal, signatureHandler, t]);

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      {loading ? (
        <Spinner />
      ) : deal ? (
        <>
          <DealView
              deal={deal}
              printable={queryParams.printable}
              containerClassName={classes.Container}
              accept={true}
              showSignatureDialog={deal?.status &&
                ![EDealStatus.ARCHIVED, EDealStatus.TERMINATED].includes(
                  deal.status
                )
                ? showSignatureDialog
                : undefined} 
              history={history} location={location} match={match}
            />
        </>
      ) : null}
    </>
  );
};

export default DealAccept;
