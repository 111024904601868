import {
  faCheck,
  faCopy,
  faFile,
  faFileExcel,
  faFileSignature,
  faMailBulk,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AuthUser } from "../../../../classes/AuthUser";
import { EDealEdit } from "../../../../containers/Deals/Deal/Deal";
import {
  EDealStatus,
  EDealType,
  ELeasingModel,
  InputSuffix,
} from "../../../../enums";
import { useAuthUser } from "../../../../hooks/useAuthUser";
import {
  IAppState,
  IAttachment,
  ICondition,
  IDeal,
} from "../../../../interfaces";
import { formatDateString, removeWhitespace } from "../../../../shared/utility";
import { ETranslation } from "../../../../translations/translation-keys";
import DesktopLogo from "../../../Logo/DesktopLogo/DesktopLogo";
import Button, { EButtonColor } from "../../../UI/Button/Button";
import Condition from "../../../UI/ConditionList/Condition/Condition";
import InputGroup from "../../../UI/InputGroup/InputGroup";
import Spinner from "../../../UI/Spinner/Spinner";
import DealAdditionalExpensesTable from "../DealAdditionalExpensesTable/DealAdditionalExpensesTable";
import DealIdentifications from "../DealIdentifications/DealIdentifications";
import MaintenanceBudgetStatus from "../DealPreview/MaintenanceBudgetStatus/MaintenanceBudgetStatus";
import DealVariationPreview from "../DealVariation/DealVariationPreview/DealVariationPreview";
import classes from "./DealView.module.scss";
import * as actions from "../../../../store/actions";
import { RouteComponentProps } from "react-router-dom";
import ModalContext, { EModalSize } from "../../../UI/Modal/ModalContext";

interface IProps {
  deal?: IDeal;
  currentUser?: AuthUser | null;
  printable?: boolean;
  containerClassName?: string;
  setDealConfirmModal?: () => void;
  setDealSendDialog?: () => void;
  accept?: boolean;
  copyDeal?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showDealMaintenanceInfoDialog?: (link: string) => void;
  showSignatureDialog?: () => void;
  showConfirmSignatureModal?: () => Promise<void>;
}

interface IDealViewQueryParams {
  netsError: string;
}

interface IProps extends RouteComponentProps {}

const DealView: React.FC<IProps> = ({
  deal,
  currentUser,
  printable,
  containerClassName,
  setDealConfirmModal,
  setDealSendDialog,
  accept,
  copyDeal,
  showDealMaintenanceInfoDialog,
  showSignatureDialog,
  showConfirmSignatureModal,
  location
}) => {
  const params = useMemo((): IDealViewQueryParams => {
    const params = new URLSearchParams(location.search);
    return {
      netsError: params.get("netsError") ?? "",
    };
  }, [location.search]);
  const { t } = useTranslation();
  const { invoicesLoading, netsLoading, loadingAttachmentIds } = useSelector(
    (state: IAppState) => ({
      invoicesLoading: state.deals.invoicesLoading,
      netsLoading: state.deals.netsLoading,
      loadingAttachmentIds: state.attachments.loadingIds
    })
  );
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const { setModal } = useContext(ModalContext);
  // console.log("deal view", deal);

  useEffect(() => {
    if (params.netsError) {
      setModal({
        isOpen: true,
        title: t(ETranslation.NETS_EIDENT_TITLE),
        size: EModalSize.SMALL,
        content: (
          <div>
            <p>{t(ETranslation.NETS_EIDENT_FAIL)}</p>
          </div>
        ),
      });
    }
  }, [t, params.netsError, setModal]);

  const mapConditions = (inputName: EDealEdit, conditions?: ICondition[]) => {
    if (conditions) {
      if (!conditions.find((c) => c.accepted === true)) {
        return null;
      }
      return (
        <div>
          {inputName === EDealEdit.BASIC_SERVICES && (
            <h3>{t(ETranslation.DEAL_PREVIEW_TITLE_BASIC_SERVICES)}</h3>
          )}
          {inputName === EDealEdit.maintenanceServices && (
            <h3>{t(ETranslation.DEAL_PREVIEW_TITLE_MAINTENANCE_SERVICES)}</h3>
          )}
          {inputName === EDealEdit.ADDITIONAL_INFORMATION && (
            <h3>{t(ETranslation.DEAL_PREVIEW_TITLE_ADDITIONAL_INFORMATION)}</h3>
          )}
          {conditions.map((condition) => {
            if (!condition.accepted) {
              return null;
            }
            return (
              <Condition
                key={condition.id}
                condition={condition}
                isActive={condition.accepted}
                onChange={() => {}}
                name={""}
                disabled={true}
                invalid={false}
                inputClassName={""}
                onRemove={() => Promise.reject()}
              />
            );
          })}
        </div>
      );
    }
  };

  const mapFooter = () => {
    let seller = null;

    if (deal && deal.id && deal.seller) {
      seller = (
        <div className={classes.Footer}>
          <p>
            <i>{t(ETranslation.DEAL_PREVIEW_BEST_REGARDS_TEXT)}</i>
          </p>
          {deal.seller.organization && (
            <p>
              <b>{deal.seller.organization.name}</b>
            </p>
          )}
          {deal.seller.name && (
            <p>
              <b>{deal.seller.name}</b>
            </p>
          )}
          {deal.seller.title && (
            <p>
              <b>{deal.seller.title}</b>
            </p>
          )}
          {deal.seller.phoneNumber && (
            <p>
              <a href={`tel:${removeWhitespace(deal.seller.phoneNumber)}`}>
                {deal.seller.phoneNumber}
              </a>
            </p>
          )}
          {deal.seller.email && (
            <p>
              <a href={`mailto:${deal.seller.email}`}>{deal.seller.email}</a>
            </p>
          )}
        </div>
      );
    } else {
      if (currentUser) {
        seller = (
          <div className={classes.Footer}>
            <p>
              <i>{t(ETranslation.DEAL_PREVIEW_BEST_REGARDS_TEXT)}</i>
            </p>
            {currentUser.organization && (
              <p>
                <b>{currentUser.organization.name}</b>
              </p>
            )}
            {currentUser.name && (
              <p>
                <b>{currentUser.name}</b>
              </p>
            )}
            {currentUser.title && (
              <p>
                <b>{currentUser.title}</b>
              </p>
            )}
            {currentUser.phoneNumber && (
              <p>
                <a href={`tel:${removeWhitespace(currentUser.phoneNumber)}`}>
                  {currentUser.phoneNumber}
                </a>
              </p>
            )}
            {currentUser.email && (
              <p>
                <a href={`mailto:${currentUser.email}`}>{currentUser.email}</a>
              </p>
            )}
          </div>
        );
      }
    }

    return (
      <div>
        <h3>
          {deal?.leasingModel === ELeasingModel.REILU
            ? t(ETranslation.DEAL_PREVIEW_REILU_LEASING_TITLE)
            : null}
        </h3>
        {deal?.leasingModel === ELeasingModel.REILU ? (
          <p>{t(ETranslation.DEAL_PREVIEW_REILU_LEASING_TEXT)}</p>
        ) : null}

        {(!deal?.status || deal?.status === EDealStatus.OFFER) && (
          <p>{t(ETranslation.DEAL_PREVIEW_FOOTER_TEXT_1)}</p>
        )}

        <p>{t(ETranslation.DEAL_PREVIEW_FOOTER_TEXT_2)}</p>
        <p>{t(ETranslation.DEAL_PREVIEW_FOOTER_TEXT_3)}</p>

        <p className={classes.Small}>
          {t(ETranslation.DEAL_PREVIEW_FOOTER_TEXT_4)}
        </p>
        {typeof deal?.invoicingFee !== "undefined" ? (
          deal.invoicingFee > 0 ? (
            <p>
              <b>
                {deal.type === EDealType.CAR
                  ? t(ETranslation.DEAL_PREVIEW_INVOICE_PROCESSING_FEE_VAT, {
                      [EDealEdit.INVOICING_FEE]: deal.invoicingFee,
                    })
                  : t(ETranslation.DEAL_PREVIEW_INVOICE_PROCESSING_FEE, {
                      [EDealEdit.INVOICING_FEE]: deal.invoicingFee,
                    })}
              </b>
            </p>
          ) : null
        ) : deal?.financeCompany?.invoicingFee ? (
          <p>
            <b>
              {deal.type === EDealType.CAR
                ? t(ETranslation.DEAL_PREVIEW_INVOICE_PROCESSING_FEE_VAT, {
                    [EDealEdit.INVOICING_FEE]: deal.financeCompany.invoicingFee,
                  })
                : t(ETranslation.DEAL_PREVIEW_INVOICE_PROCESSING_FEE, {
                    [EDealEdit.INVOICING_FEE]: deal.financeCompany.invoicingFee,
                  })}
            </b>
          </p>
        ) : null}
        {typeof deal?.openingFee !== "undefined" ? (
          deal.openingFee > 0 ? (
            <p>
              <b>
                {deal.type === EDealType.CAR
                  ? t(ETranslation.DEAL_PREVIEW_SETUP_COST_VAT, {
                      [EDealEdit.OPENING_FEE]: deal.openingFee,
                    })
                  : t(ETranslation.DEAL_PREVIEW_SETUP_COST, {
                      [EDealEdit.OPENING_FEE]: deal.openingFee,
                    })}
              </b>
            </p>
          ) : null
        ) : deal?.financeCompany?.openingFee ? (
          <p>
            <b>
              {deal.type === EDealType.CAR
                ? t(ETranslation.DEAL_PREVIEW_SETUP_COST_VAT, {
                    [EDealEdit.OPENING_FEE]: deal.financeCompany.openingFee,
                  })
                : t(ETranslation.DEAL_PREVIEW_SETUP_COST, {
                    [EDealEdit.OPENING_FEE]: deal.financeCompany.openingFee,
                  })}
            </b>
          </p>
        ) : null}

        {deal?.latestContractIdentification ? (
          <div className={classes.Footer}>
            <p>{t(ETranslation.DEAL_PREVIEW_OFFER_ACCEPTED)}</p>
            <p>{t(ETranslation.DEAL_PREVIEW_OFFER_SIGNED_AT)} {deal.latestContractIdentification.date}</p>
            <p>{t(ETranslation.DEAL_PREVIEW_OFFER_SIGNATURE)}: {deal.latestContractIdentification.name}</p>
            <p>{t(ETranslation.DEAL_PREVIEW_OFFER_IDENTIFIED_BANK)}: {deal.latestContractIdentification.bankName}</p>
          </div>
        ) : (
          <>
            {(!deal?.status || deal?.status === EDealStatus.OFFER) && (
              <>
                <p>{t(ETranslation.DEAL_PREVIEW_HELP_TEXT_SIGNATURE)}</p>
                <p className={classes.Signature}>
                  ________________________________________________
                </p>
              </>
            )}
            {deal?.customer?.name && (
              <p>
                <b>{deal.customer.name}</b>
              </p>
            )}
          </>
        )}

        <br />

        {seller}
      </div>
    );
  };

  const mapInfoText = () => {
    return (
      <div>
        <p>
          <b>{t(ETranslation.DEAL_PREVIEW_INFO_TEXT_1)}</b>{" "}
          {t(ETranslation.DEAL_PREVIEW_INFO_TEXT_2)}
        </p>
        <p>{t(ETranslation.DEAL_PREVIEW_INFO_TEXT_3)}</p>
      </div>
    );
  };

  const mapCustomer = () => {
    if (!deal?.customer) return null;

    return (
      <div className={classes.Customer}>
        {deal.customer.name && (
          <p>
            <b>{deal.customer.name}</b>
          </p>
        )}
        {deal.customer.streetAddress && (
          <p>
            <b>{deal.customer.streetAddress}</b>
          </p>
        )}
        {(deal.customer.zip || deal.customer.city) && (
          <p>
            <b>
              {deal.customer.zip} {deal.customer.city}
            </b>
          </p>
        )}
        {deal.customer.businessId && (
          <p>
            <b>{deal.customer.businessId}</b>
          </p>
        )}
      </div>
    );
  };

  const mapAttachments = (attachments: IAttachment[]) => {
    return (
      <div style={{ wordBreak: "break-all" }}>
        <h3>{t(ETranslation.DEAL_ATTACHMENTS)}</h3>
        {attachments.map((attachment) => (
          loadingAttachmentIds?.includes(attachment.id) ? <Spinner/> :
          <p key={attachment.id} style={{cursor: "pointer"}} onClick={()=>
            dispatch(actions.openAttachment(attachment.link, attachment.id))}>
              <FontAwesomeIcon icon={faFile} size="lg" color={"black"} />{" "}
              {attachment.clientName}
          </p>
        ))}
      </div>
    );
  };

  const mapBasicDetails = () => {
    if (
      !deal?.brand &&
      !deal?.model &&
      !deal?.co2Emissions &&
      !deal?.takeHomeVehicle &&
      !deal?.carBenefit &&
      !deal?.accessories
    )
      return null;

    return (
      <table className={classes.BasicDetails}>
        <tbody>
          {deal?.brand || deal?.model ? (
            <tr>
              <th>
                {deal?.type === EDealType.MACHINE
                  ? t(ETranslation.DEAL_MACHINE_BRAND)
                  : t(ETranslation.DEAL_CAR_BRAND)}
              </th>
              <td>
                {deal?.brand} {deal?.model}
              </td>
            </tr>
          ) : null}
          {deal?.co2Emissions ? (
            <tr>
              <th>{t(ETranslation.DEAL_CAR_CO2_EMISSIONS)}</th>
              <td>
                {deal?.co2Emissions &&
                  `${deal.co2Emissions} ${InputSuffix.GRAMS_PER_KILOMETER}`}
              </td>
            </tr>
          ) : null}
          {deal?.takeHomeVehicle ? (
            <tr>
              <th>{t(ETranslation.DEAL_CAR_TAKE_HOME_VEHICLE)}</th>
              <td>
                {deal?.takeHomeVehicle &&
                  `${deal.takeHomeVehicle} ${InputSuffix.EUROS_PER_MONTH(t)}`}
              </td>
            </tr>
          ) : null}
          {deal?.carBenefit ? (
            <tr>
              <th>{t(ETranslation.DEAL_CAR_CAR_BENEFIT)}</th>
              <td>
                {deal?.carBenefit &&
                  `${deal.carBenefit} ${InputSuffix.EUROS_PER_MONTH(t)}`}
              </td>
            </tr>
          ) : null}
          {deal?.accessories ? (
            <tr>
              <th>{t(ETranslation.DEAL_ACCESSORIES)}</th>
              <td>{deal.accessories}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    );
  };

  const print = !printable && deal?.printLink;
  const confirm =
    !printable &&
    deal?.orderConfirmationLink &&
    deal?.status &&
    deal.status === EDealStatus.PRECONTRACT;

  const containerClassNames = [containerClassName];

  if (printable) {
    containerClassNames.push(classes.Print);
  }

  return (
    <div className={containerClassNames.join(" ")}>
      <DesktopLogo>
        {deal?.updated && <p>{formatDateString(deal.updated)}</p>}
      </DesktopLogo>

      <h1 className={classes.DealName}>
        {deal?.leasingModel === ELeasingModel.REILU
          ? t(ETranslation.LEASING_MODEL_REILU)
          : deal?.leasingModel === ELeasingModel.HUOLTO
          ? t(ETranslation.LEASING_MODEL_HUOLTO)
          : deal?.leasingModel === ELeasingModel.RAHOITUS
          ? t(ETranslation.LEASING_MODEL_RAHOITUS)
          : null}{" "}
        {deal?.name}
      </h1>

      {deal?.startDate && (
        <p>
          {t(ETranslation.DEAL_PREVIEW_STARTS)} {deal.startDate}
        </p>
      )}
      {deal?.status === EDealStatus.TERMINATED ? (
        <p>
          {t(ETranslation.DEAL_PREVIEW_TERMINATED)} {deal.endDate} {deal.terminatedUser?.name} {t(ETranslation.DEAL_PREVIEW_TERMINATED_CHOSEN)} {deal.terminatedDate}
        </p>
      ) : (
        deal?.endDate && (
          <p>
            {t(ETranslation.DEAL_PREVIEW_ENDS)} {deal.endDate}
          </p>
        )
      )}

      {mapCustomer()}
      {(!deal?.status || deal?.status === EDealStatus.OFFER) && mapInfoText()}

      {mapBasicDetails()}

      {deal?.dealPricingVariations &&
        deal.dealPricingVariations.map((variation, index) => (
          <DealVariationPreview
            key={variation.id}
            variation={variation}
            index={index + 1}
            status={deal.status}
            accept={accept}
            leasingModel={deal.leasingModel}
            isCar={deal.type === EDealType.CAR}
            hideVatPrices={deal.hideVatPrices}
            vatPercent={deal.vatPercent}
          />
        ))}

      {mapConditions(EDealEdit.BASIC_SERVICES, deal?.basicServices)}
      {mapConditions(EDealEdit.maintenanceServices, deal?.maintenanceServices)}
      {mapConditions(
        EDealEdit.ADDITIONAL_INFORMATION,
        deal?.additionalInformation
      )}
      {mapFooter()}

      {((deal?.id && setDealSendDialog) || setDealConfirmModal) && (
        <InputGroup style={{ marginTop: "1rem" }}>
          {deal?.id && setDealSendDialog && (
            <Button color={EButtonColor.SECONDARY} onClick={setDealSendDialog}>
              <FontAwesomeIcon icon={faMailBulk} />{" "}
              {t(ETranslation.DEAL_ACTION_SEND)}
            </Button>
          )}
          {setDealConfirmModal &&
            deal &&
            deal.status &&
            deal.status === EDealStatus.PRECONTRACT && (
              <Button
                color={EButtonColor.SECONDARY}
                onClick={() => setDealConfirmModal()}
              >
                <FontAwesomeIcon icon={faCheck} />{" "}
                {t(ETranslation.DEAL_PREVIEW_CONFIRM_DEAL_TITLE)}
              </Button>
            )}
        </InputGroup>
      )}
      {(print ||
        confirm ||
        copyDeal ||
        showDealMaintenanceInfoDialog ||
        showSignatureDialog ||
        showConfirmSignatureModal) && (
        <InputGroup style={{ marginTop: "1rem" }}>
          {print && deal?.printLink && (
            <Button
              color={EButtonColor.SECONDARY}
              onClick={() => {
                dispatch(actions.uploadPrint(deal.id));
                window.open(deal.printLink, "_blank");
              }
              }
            >
              <FontAwesomeIcon icon={faPrint} />{" "}
              {t(ETranslation.DEAL_ACCEPT_PRINT)}
            </Button>
          )}
          {currentUser && confirm && deal?.orderConfirmationLink && (
            <Button
              color={EButtonColor.SECONDARY}
              onClick={() => window.open(deal.orderConfirmationLink, "_blank")}
            >
              <FontAwesomeIcon icon={faFileExcel} />{" "}
              {t(ETranslation.DEAL_ORDER_CONFIRMATION)}
            </Button>
          )}
          {showDealMaintenanceInfoDialog && deal?.maintenanceInfoPrintLink && (
            <Button
              color={EButtonColor.SECONDARY}
              onClick={() =>
                showDealMaintenanceInfoDialog(deal.maintenanceInfoPrintLink ?? "")
              }
            >
              <FontAwesomeIcon icon={faPrint} />{" "}
              {t(ETranslation.DEAL_PRINT_MAINTENANCE_INFO_TITLE)}
            </Button>
          )}
          {copyDeal && (
            <Button color={EButtonColor.SECONDARY} onClick={copyDeal}>
              <FontAwesomeIcon icon={faCopy} />{" "}
              {t(ETranslation.DEAL_ACTION_COPY)}
            </Button>
          )}
          {!printable && showSignatureDialog && (
            <Button
              color={EButtonColor.SECONDARY}
              onClick={showSignatureDialog}
              loading={netsLoading}
            >
              <FontAwesomeIcon icon={faFileSignature} />{" "}
              {t(ETranslation.DEAL_ACTION_EIDENT_START)}
            </Button>
          )}
        </InputGroup>
      )}

      {/* <AuditLogs id={+match.params.id} token={queryParams.token} printable={queryParams.printable} /> */}

      {deal?.additionalExpenses && deal.additionalExpenses.length > 0 && (
        <DealAdditionalExpensesTable expenses={deal.additionalExpenses} />
      )}

      {deal?.attachments &&
        deal.attachments.length > 0 &&
        mapAttachments(deal.attachments)}

      {authUser?.isPrimary && deal?.invoiceInfo && (
        <div>
          <h3>{t(ETranslation.DEAL_INVOICE_INFO)}</h3>
          <p style={{ whiteSpace: "pre-line" }}>{deal.invoiceInfo}</p>
        </div>
      )}

      {invoicesLoading ? (
        <Spinner />
      ) : deal?.invoices?.length || deal?.talenomInvoices?.length ? (
        <MaintenanceBudgetStatus
          type={deal?.type}
          variation={deal.selectedVariation}
          invoices={deal.invoices}
          talenomInvoices={deal.talenomInvoices}
          registrationNumber={deal.registrationNumber}
        />
      ) : null}

      {deal?.contractIdentifications && (
        <DealIdentifications identifications={deal.contractIdentifications} />
      )}
    </div>
  );
};

export default DealView;
