import { TFunction } from "i18next";
import { EPaymentType, EResidualValueLiability } from "../components/Deals/Deal/DealVariation/DealVariationEdit/DealVariationEdit";
import { EDealStatus, EDealType, EInvoicePayer, ELeasingModel } from "../enums";
import { IDeal, IDealExtraFee, IDealPricingVariation } from "../interfaces";
import {
  carBasicServices,
  machineAdditionalInformation,
  maintenanceServices,
} from "./../containers/Deals/Deal/Deal";
import { ETranslation } from "./../translations/translation-keys";
import { VAT_PERCENT_255 } from "./vat";

export const defaultDeal = (t: TFunction): IDeal => {
  return {
    id: "",
    created: undefined,
    updated: undefined,
    name: undefined,
    customer: undefined,
    seller: undefined,
    sendDate: undefined,
    openedDate: undefined,
    status: undefined,
    archivedDate: undefined,
    archivedUser: undefined,
    type: EDealType.CAR,
    basicServices: carBasicServices(t),
    additionalInformation: machineAdditionalInformation(t),
    dealership: undefined,
    dealershipSellerName: undefined,
    dealershipSaleNumber: undefined,
    brand: undefined,
    model: undefined,
    co2Emissions: undefined,
    takeHomeVehicle: undefined,
    carBenefit: undefined,
    registrationNumber: undefined,
    registrationDate: undefined,
    signingDate: undefined,
    surety: undefined,
    handoverDate: undefined,
    insurance: undefined,
    accessories: undefined,
    totalPrice: undefined,
    discount: undefined,
    carTax: undefined,
    totalPriceVAT: undefined,
    dealPricingVariations: [],
    financeCompany: undefined,
    leasingModel: ELeasingModel.REILU,
    handoverNotes: undefined,
    handoverUser: undefined,
    confirmDate: undefined,
    confirmUser: undefined,
    attachments: [],
    invoiceReference: undefined,
    costCenter: undefined,
    invoicePayer: EInvoicePayer.OLF,
    invoicingFee: undefined,
    openingFee: undefined,
    insuranceCertificateReceived: false,
    additionalExpenses: [],
    maintenanceServices: maintenanceServices(t),
    vatPercent: VAT_PERCENT_255,
  };
};

export const defaultPricingVariation = (id: string): IDealPricingVariation => {
  return {
    id,
    maintenanceBudget: undefined,
    maintenanceBudgetTotal: undefined,
    maintenanceBudgetMonthly: undefined,
    extraTireSets: undefined,
    extraTireSetPrice: undefined,
    tireStoragePrice: undefined,
    tireStorageMonthly: undefined,
    replacementCarPrice: undefined,
    grossProfitVAT: undefined,
    grossProfit: undefined,
    winterTiresPurchasePrice: undefined,
    carPriceForCalculator: undefined,
    totalPriceVATIncludingPollution: undefined,
    residualValue: undefined,
    residualValuePercentage: undefined,
    residualValueVAT: undefined,
    contractLength: undefined,
    contractKilometers: undefined,
    interestRate: undefined,
    monthlyPaymentVAT: undefined,
    monthlyPayment: undefined,
    firstLargerRent: undefined,
    futureValue: undefined,
    monthlyInstallmentFirstInstallmentVAT: undefined,
    monthlyInstallmentFirstInstallment: undefined,
    maintenanceBudgetVAT: undefined,
    fundableCapital: undefined,
    extraKilometerCharge: undefined,
    hidden: false,
    extraFees: [],
    downPayment: undefined,
    paymentType: EPaymentType.LEASING,
    residualValueLiability: EResidualValueLiability.CUSTOMER,
  };
};

export const defaultExtraFee = (id: string): IDealExtraFee => {
  return {
    id,
    name: "",
    value: 0,
    attachments: [],
  };
};

export const hasDealStatus = (
  status: EDealStatus,
  ...statuses: Array<EDealStatus>
) => {
  if (!status || !statuses) return false;
  return statuses.indexOf(status) !== -1;
};

export const isDealDone = (deal: IDeal) => {
  if (!deal || !deal.status) return false;
  return hasDealStatus(
    deal.status,
    EDealStatus.CONTRACT,
    EDealStatus.TERMINATED
  );
};

// export const sortDealsByDate = (deals: IDeal[]) => {
//   // Sort item arrays by dateOfTest
//   return deals.sort((a, b) => {
//     if (!a.created || !b.created) return undefined;
//     const aDate = dateFns.parseISO(a.created.toString());
//     const bDate = dateFns.parseISO(b.created.toString());
//     if (aDate > bDate) return 1;
//     if (bDate > aDate) return -1;
//     return undefined;
//   });
// };

export const getDealStatus = (status: EDealStatus) => {
  switch (status) {
    case EDealStatus.OFFER:
      return ETranslation.DEAL_STATUS_OFFER;
    case EDealStatus.PRECONTRACT:
      return ETranslation.DEAL_STATUS_PRECONTRACT;
    case EDealStatus.CONTRACT:
      return ETranslation.DEAL_STATUS_CONTRACT;
    case EDealStatus.TERMINATED:
      return ETranslation.DEAL_STATUS_TERMINATED;
    case EDealStatus.ARCHIVED:
      return ETranslation.DEAL_STATUS_ARCHIVED;
    case EDealStatus.DRAFT:
      return ETranslation.DEAL_STATUS_DRAFT;
    case EDealStatus.PREOFFER:
      return ETranslation.DEAL_STATUS_PREOFFER;
  }
};

export const getResidualValueLiability = (
  residualValueLiability: EResidualValueLiability | undefined
) => {
  switch (residualValueLiability) {
    case EResidualValueLiability.CUSTOMER:
      return ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY_CUSTOMER;
    case EResidualValueLiability.OLF:
      return ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY_OLF;
    case EResidualValueLiability.SELLER:
      return ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY_SELLER;
    default:
      return "";
  }
};
