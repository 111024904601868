import React, { CSSProperties, ReactNode } from "react";
import thumbLogo from "../../../assets/images/logo_desktop.png";
import classes from "./ThumbnailLogo.module.scss";

interface IProps {
  style?: CSSProperties;
  children?: ReactNode;
}

const ThumbnailLogo: React.FC<IProps> = ({ style, children }) => {
  let containerClassNames = [classes.Logo];

  if (children) {
    containerClassNames.push(classes.LogoContainer)
  }

  return (
    <div className={containerClassNames.join(" ")}>
      <img src={thumbLogo} alt="One Leasing Finland" style={style} />
      {children}
    </div>
  )
};

export default ThumbnailLogo;
