import { useDispatch } from 'react-redux';
import { IOrganization } from '../interfaces/domain';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IAppState, IAuthState, IPrimaryState } from '../interfaces/state';
import { useSelector } from 'react-redux';
import { IOption } from '../components/UI/Input/Input';
import { useEffect, useState } from 'react';
import { createOptions } from '../shared/option-utils';

import * as actions from '../store/actions';

export const usePrimaryOptions = () => {
  const { authUser } = useSelector<IAppState, IAuthState>(state => state.auth);
  const { t } = useTranslation()
  const [primaryOptions, setPrimaryOptions] = useState<IOption[]>([])
  const dispatch = useDispatch();

  const [tryFetch, setTryFetch] = useState(false);

  const { primaryOrganizations, loading } = useSelector<IAppState, IPrimaryState>((state) => state.primaryOrganizations);

  useEffect(() => {
    if(authUser) {
      setTryFetch(authUser.isPrimary);
    }
  }, [authUser]);

  useEffect(() => {
    if(tryFetch && !primaryOrganizations) {
      dispatch(actions.listPrimaryOrganizations());
      setTryFetch(false);
    }
  }, [dispatch, primaryOrganizations, tryFetch]);

  const createLabel = useCallback((organization: IOrganization) => {
    let labelArray = [organization.name || organization.id];

    return labelArray.join(' ');
  }, []);

  useEffect(() => {
    setPrimaryOptions(primaryOrganizations ? createOptions(primaryOrganizations, createLabel) : []);
  }, [primaryOrganizations, t, createLabel]);

  return { primaryOptions, primaryLoading: loading };

}
