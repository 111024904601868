import {
  JSON_ORGANIZATIONS_GET,
  JSON_ORGANIZATIONS_LIST,
  JSON_ORGANIZATIONS_ADD,
  JSON_ORGANIZATIONS_UPDATE,
} from "./../../endpoints/index";
import { IOrganization } from "../../interfaces/domain";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { ICustomerAction } from "../../interfaces";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { EOrganizationLevel } from "../../enums";

const getCustomerStart = (): ICustomerAction => {
  return {
    type: ActionTypes.GET_CUSTOMER_START,
  };
};

const getCustomerSuccess = (
  customer: IOrganization
): ICustomerAction => {
  return {
    type: ActionTypes.GET_CUSTOMER_SUCCESS,
    customer,
  };
};

const getCustomerFail = (error: string): ICustomerAction => {
  return {
    type: ActionTypes.GET_CUSTOMER_FAIL,
    error,
  };
};

export const getCustomer = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getCustomerStart());
    try {
      const res = await axios.get(JSON_ORGANIZATIONS_GET(id));
      dispatch(getCustomerSuccess(res.data));
    } catch ({ response }) {
      dispatch(getCustomerFail((response as AxiosResponse).data));
    }
  };
};

const listCustomersStart = (): ICustomerAction => {
  return {
    type: ActionTypes.LIST_CUSTOMERS_START,
  };
};

const listCustomersSuccess = (
  customers: Array<IOrganization>
): ICustomerAction => {
  return {
    type: ActionTypes.LIST_CUSTOMERS_SUCCESS,
    customers,
  };
};

const listCustomersFail = (error: string): ICustomerAction => {
  return {
    type: ActionTypes.LIST_CUSTOMERS_FAIL,
    error,
  };
};

export const listCustomers = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listCustomersStart());
    try {
      const res = await axios.get(JSON_ORGANIZATIONS_LIST(EOrganizationLevel.CUSTOMER));
      dispatch(listCustomersSuccess(res.data));
    } catch ({ response }) {
      dispatch(listCustomersFail((response as AxiosResponse).data));
    }
  };
};

const saveCustomerStart = (): ICustomerAction => {
  return {
    type: ActionTypes.SAVE_CUSTOMER_START,
  };
};

const saveCustomerSuccess = (customer: IOrganization): ICustomerAction => {
  return {
    type: ActionTypes.SAVE_CUSTOMER_SUCCESS,
    customer,
  };
};

const saveCustomerFail = (error: string): ICustomerAction => {
  return {
    type: ActionTypes.SAVE_CUSTOMER_FAIL,
    error,
  };
};

export const saveCustomer = (customer: IOrganization) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveCustomerStart());
    try {
      const res = await axios.post(JSON_ORGANIZATIONS_ADD, customer);
      dispatch(saveCustomerSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveCustomerFail((response as AxiosResponse).data));
    }
  };
};

const updateCustomerStart = (): ICustomerAction => {
  return {
    type: ActionTypes.UPDATE_CUSTOMER_START,
  };
};

const updateCustomerSuccess = (customer: IOrganization): ICustomerAction => {
  return {
    type: ActionTypes.UPDATE_CUSTOMER_SUCCESS,
    customer,
  };
};

const updateCustomerFail = (error: string): ICustomerAction => {
  return {
    type: ActionTypes.UPDATE_CUSTOMER_FAIL,
    error,
  };
};

export const updateCustomer = (customer: IOrganization) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateCustomerStart());
    try {
      const res = await axios.put(JSON_ORGANIZATIONS_UPDATE, customer);
      dispatch(updateCustomerSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateCustomerFail((response as AxiosResponse).data));
    }
  };
};
