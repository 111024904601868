import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import Table from "../../components/UI/Table/Table";
import { IAppState, IOrganization } from "../../interfaces";
import { Routes } from "../../classes/Routes";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";

interface IProps extends RouteComponentProps { }

const Partners: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { loading, error, partners } = useSelector((state: IAppState) => ({
    loading: state.partners.loading,
    error: state.partners.error,
    partners: state.partners.partners,
  }));

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.listPartners());
  }, [dispatch]);

  const showPartner = (id?: string) => {
    if (id) {
      history.push(Routes.PARTNER(id));
    }
  }

  const createPartnersTable = (partners: Array<IOrganization>) => {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>{t(ETranslation.PARTNER_NAME)}</th>
            <th>{t(ETranslation.PARTNER_BUSINESS_ID)}</th>
            <th>{t(ETranslation.COMMON_STREET_ADDRESS)}</th>
            <th>{t(ETranslation.COMMON_ZIP)}</th>
            <th>{t(ETranslation.COMMON_CITY)}</th>
          </tr>
        </thead>
        <tbody>
          {partners.map((partner) => (
            <tr key={partner.id} onClick={() => showPartner(partner.id)}>
              <td>{partner.name}</td>
              <td>{partner.businessId}</td>
              <td>{partner.streetAddress}</td>
              <td>{partner.zip}</td>
              <td>{partner.city}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{t(ETranslation.TITLE_PARTNERS)}</h2>
        <Button onClick={() => history.push(Routes.PARTNERS_NEW)} icon={faPlus} style={{ marginBottom: "1rem" }}>{t(ETranslation.PARTNERS_ADD_NEW)}</Button>
        {loading
          ? <Spinner />
          : partners
            ? createPartnersTable(partners)
            : <p>{t(ETranslation.NO_PARTNERS)}</p>
        }
      </Container>
    </>
  );
}

export default Partners;
