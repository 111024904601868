import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EDealEdit } from "../../../../../containers/Deals/Deal/Deal";
import DealContext from "../../../../../context/DealContext";
import DealVariationContext from "../../../../../context/DealVariationContext";
import { EDealType, InputSuffix } from "../../../../../enums";
import {
  IDealExtraFee,
  IDealPricingVariation,
} from "../../../../../interfaces";
import { ETranslation } from "../../../../../translations/translation-keys";
import { EInputType, IInputField, IOption } from "../../../../UI/Input/Input";
import DealVariationEditCar from "./DealVariationEditCar/DealVariationEditCar";
import DealVariationEditMachine from "./DealVariationEditMachine/DealVariationEditMachine";
import { INVALID_DEFAULT, VALID_DEFAULT, toTwoDecimals } from "../../../../../shared/utility";

export enum EDealVariation {
  MAINTENANCE_BUDGET = "maintenanceBudget",
  MAINTENANCE_BUDGET_TOTAL = "maintenanceBudgetTotal",
  MAINTENANCE_BUDGET_MONTHLY = "maintenanceBudgetMonthly",

  EXTRA_TIRE_SETS = "extraTireSets",
  EXTRA_TIRE_SET_PRICE = "extraTireSetPrice",

  // TIRE_STORAGE_PRICE = "tireStoragePrice",
  TIRE_STORAGE_MONTHLY = "tireStorageMonthly",
  REPLACEMENT_CAR_PRICE = "replacementCarPrice",

  GROSS_PROFIT_VAT = "grossProfitVAT",
  GROSS_PROFIT = "grossProfit",
  WINTER_TIRES_PURCHASE_PRICE = "winterTiresPurchasePrice",

  MAINTENANCE_BUDGET_VAT = "maintenanceBudgetVAT",

  RESIDUAL_VALUE = "residualValue",
  RESIDUAL_VALUE_PERCENTAGE = "residualValuePercentage",
  RESIDUAL_VALUE_VAT = "residualValueVAT",

  CONTRACT_LENGTH = "contractLength",
  CONTRACT_KILOMETERS = "contractKilometers",
  INTEREST_RATE = "interestRate",

  MONTHLY_PAYMENT_VAT = "monthlyPaymentVAT",
  MONTHLY_PAYMENT = "monthlyPayment",

  FIRST_LARGER_RENT = "firstLargerRent",
  FUTURE_VALUE = "futureValue",
  MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT = "monthlyInstallmentFirstInstallmentVAT",
  MONTHLY_INSTALLMENT_FIRST_INSTALLMENT = "monthlyInstallmentFirstInstallment",

  CAR_PRICE_FOR_CALCULATOR = "carPriceForCalculator",
  FUNDABLE_CAPITAL = "fundableCapital",
  FUNDABLE_CAPITAL_VAT = "fundableCapitalVAT",

  TOTAL_PRICE_VAT_INCLUDING_POLLUTION = "totalPriceVATIncludingPollution",
  EXTRA_KILOMETER_CHARGE = "extraKilometerCharge",

  EXTRA_FEES = "extraFees",
  DOWN_PAYMENT = "downPayment",
  PAYMENT_TYPE = "paymentType",
  RESIDUAL_VALUE_LIABILITY = "residualValueLiability",
  isUsedCar = "isUsedCar",

  kickbackVAT = "kickbackVAT",
  kickback = "kickback",
}

export enum EPaymentType {
  LEASING = "LEASING",
  CASH_OLF = "CASH_OLF",
  CASH_SELLER = "CASH_SELLER",
  INSTALLMENT = "INSTALLMENT",
}

export enum EResidualValueLiability {
  CUSTOMER = "CUSTOMER",
  OLF = "OLF",
  SELLER = "SELLER",
}

interface IProps {
  variation: IDealPricingVariation;
  index?: number;
}

const paymentTypeOptions: IOption[] = [
  { value: EPaymentType.LEASING, labelTranslation: ETranslation.DEAL_PAYMENT_TYPE_LEASING },
  { value: EPaymentType.CASH_SELLER, labelTranslation: ETranslation.DEAL_PAYMENT_TYPE_CASH_SELLER },
  { value: EPaymentType.CASH_OLF, labelTranslation: ETranslation.DEAL_PAYMENT_TYPE_CASH_OLF },
  { value: EPaymentType.INSTALLMENT, labelTranslation: ETranslation.DEAL_PAYMENT_TYPE_INSTALLMENT },
];

const residualValueLiabilityOptions: IOption[] = [
  { value: EResidualValueLiability.CUSTOMER, labelTranslation: ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY_CUSTOMER },
  { value: EResidualValueLiability.OLF, labelTranslation: ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY_OLF },
  { value: EResidualValueLiability.SELLER, labelTranslation: ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY_SELLER },
];

const DealVariationEdit: React.FC<IProps> = ({ variation, index }) => {
  const { t } = useTranslation();
  const { inputs, financeCompany } = useContext(DealContext);

  const dealType = inputs[EDealEdit.TYPE].value as EDealType;
  const isCar = dealType === EDealType.CAR;
  const isMachine = dealType === EDealType.MACHINE;

  const vatPercent = inputs[EDealEdit.vatPercent].value as number;

  const [variationInputs, setVariationInputs] = useState<IInputField>({
    [EDealVariation.RESIDUAL_VALUE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_RESIDUAL_VALUE,
      placeholderTranslation: ETranslation.DEAL_RESIDUAL_VALUE,
      post: InputSuffix.EUROS,
      value: variation.residualValue || "",
    },
    [EDealVariation.RESIDUAL_VALUE_PERCENTAGE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_RESIDUAL_VALUE_PERCENTAGE,
      placeholderTranslation: ETranslation.DEAL_RESIDUAL_VALUE_PERCENTAGE,
      post: InputSuffix.PERCENT,
      value: variation.residualValuePercentage || "",
      disabled: true,
    },
    [EDealVariation.RESIDUAL_VALUE_VAT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_RESIDUAL_VALUE_VAT,
      placeholderTranslation: ETranslation.DEAL_RESIDUAL_VALUE_VAT,
      post: InputSuffix.EUROS,
      value: variation.residualValueVAT || "",
      // disabled: true,
    },

    [EDealVariation.CONTRACT_LENGTH]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CONTRACT_LENGTH,
      placeholderTranslation: ETranslation.DEAL_CONTRACT_LENGTH,
      post: InputSuffix.MONTHS(t),
      value: variation.contractLength || "",
      validation: {
        required: true,
      },
      validationResult: !!variation.contractLength ? VALID_DEFAULT : INVALID_DEFAULT,
    },
    [EDealVariation.CONTRACT_KILOMETERS]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CONTRACT_KILOMETERS,
      placeholderTranslation: ETranslation.DEAL_CONTRACT_KILOMETERS,
      post: InputSuffix.KILOMETERS,
      value: variation.contractKilometers || "",
    },
    [EDealVariation.INTEREST_RATE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_INTEREST_RATE,
      placeholderTranslation: ETranslation.DEAL_INTEREST_RATE,
      post: InputSuffix.PERCENT,
      value: variation.interestRate || "",
    },

    [EDealVariation.MONTHLY_PAYMENT_VAT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_MONTHLY_PAYMENT_VAT,
      placeholderTranslation: ETranslation.DEAL_MONTHLY_PAYMENT_VAT,
      post: InputSuffix.EUROS_PER_MONTH(t),
      value: variation.monthlyPaymentVAT || "",
      disabled: true,
    },
    [EDealVariation.MONTHLY_PAYMENT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_MONTHLY_PAYMENT,
      placeholderTranslation: ETranslation.DEAL_MONTHLY_PAYMENT,
      post: InputSuffix.EUROS_PER_MONTH(t),
      value: variation.monthlyPayment || "",
      disabled: true,
    },

    [EDealVariation.FIRST_LARGER_RENT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_FIRST_LARGER_RENT,
      placeholderTranslation: ETranslation.DEAL_FIRST_LARGER_RENT,
      post: InputSuffix.EUROS,
      value: variation.firstLargerRent || "",
    },
    [EDealVariation.FUTURE_VALUE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_FUTURE_VALUE_VAT,
      placeholderTranslation: ETranslation.DEAL_FUTURE_VALUE_VAT,
      post: InputSuffix.EUROS,
      value: variation.futureValue || "",
      disabled: true,
    },
    [EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT]: {
      type: EInputType.number,
      labelTranslation:
        ETranslation.DEAL_MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT,
      placeholderTranslation:
        ETranslation.DEAL_MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT,
      post: InputSuffix.EUROS,
      value: variation.monthlyInstallmentFirstInstallmentVAT || "",
      disabled: true,
    },
    [EDealVariation.MONTHLY_INSTALLMENT_FIRST_INSTALLMENT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_MONTHLY_INSTALLMENT_FIRST_INSTALLMENT,
      placeholderTranslation:
        ETranslation.DEAL_MONTHLY_INSTALLMENT_FIRST_INSTALLMENT,
      post: InputSuffix.EUROS,
      value: variation.monthlyInstallmentFirstInstallment || "",
      disabled: true,
    },

    [EDealVariation.MAINTENANCE_BUDGET]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_MACHINE_MAINTENANCE_CONTRACT,
      placeholderTranslation: ETranslation.DEAL_MACHINE_MAINTENANCE_CONTRACT,
      post: InputSuffix.EUROS,
      value: variation.maintenanceBudget || "",
    },
    [EDealVariation.MAINTENANCE_BUDGET_TOTAL]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CAR_MAINTENANCE_BUDGET_TOTAL,
      placeholderTranslation: ETranslation.DEAL_CAR_MAINTENANCE_BUDGET_TOTAL,
      post: InputSuffix.EUROS,
      value: variation.maintenanceBudgetTotal || "",
      disabled: true,
    },
    [EDealVariation.MAINTENANCE_BUDGET_MONTHLY]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CAR_MAINTENANCE_BUDGET_MONTHLY,
      placeholderTranslation: ETranslation.DEAL_CAR_MAINTENANCE_BUDGET_MONTHLY,
      post: InputSuffix.EUROS_PER_MONTH(t),
      value: variation.maintenanceBudgetMonthly || "",
      disabled: true,
    },
    [EDealVariation.EXTRA_TIRE_SETS]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CAR_EXTRA_TIRE_SETS,
      placeholderTranslation: ETranslation.DEAL_CAR_EXTRA_TIRE_SETS,
      value: variation.extraTireSets || "",
    },
    [EDealVariation.EXTRA_TIRE_SET_PRICE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CAR_EXTRA_TIRE_SET_PRICE,
      placeholderTranslation: ETranslation.DEAL_CAR_EXTRA_TIRE_SET_PRICE,
      post: InputSuffix.EUROS_PER_SET(t),
      value: variation.extraTireSetPrice || "",
    },
    // [EDealVariation.TIRE_STORAGE_PRICE]: {
    //   type: EInputType.number,
    //   labelTranslation: ETranslation.DEAL_CAR_TIRE_STORAGE_PRICE,
    //   placeholderTranslation: ETranslation.DEAL_CAR_TIRE_STORAGE_PRICE,
    //   post: InputSuffix.EUROS,
    //   value: variation.tireStoragePrice || '',
    // },
    [EDealVariation.TIRE_STORAGE_MONTHLY]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CAR_TIRE_STORAGE_MONTHLY,
      placeholderTranslation: ETranslation.DEAL_CAR_TIRE_STORAGE_MONTHLY,
      post: InputSuffix.EUROS_PER_MONTH(t),
      value: variation.tireStorageMonthly || "",
      // disabled: true,
    },
    [EDealVariation.REPLACEMENT_CAR_PRICE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CAR_REPLACEMENT_CAR_PRICE,
      placeholderTranslation: ETranslation.DEAL_CAR_REPLACEMENT_CAR_PRICE,
      post: InputSuffix.EUROS,
      value: variation.replacementCarPrice || "",
    },
    [EDealVariation.GROSS_PROFIT_VAT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_GROSS_PROFIT_VAT,
      placeholderTranslation: ETranslation.DEAL_GROSS_PROFIT_VAT,
      post: InputSuffix.EUROS,
      value: variation.grossProfitVAT || "",
      validation: {
        required: true,
      },
      validationResult: !!variation.grossProfitVAT ? VALID_DEFAULT : INVALID_DEFAULT,
    },
    [EDealVariation.WINTER_TIRES_PURCHASE_PRICE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CAR_WINTER_TIRES_PURCHASE_PRICE,
      placeholderTranslation: ETranslation.DEAL_CAR_WINTER_TIRES_PURCHASE_PRICE,
      post: InputSuffix.EUROS,
      value: variation.winterTiresPurchasePrice || "",
    },

    [EDealVariation.CAR_PRICE_FOR_CALCULATOR]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CAR_CAR_PRICE_FOR_CALCULATOR,
      placeholderTranslation: ETranslation.DEAL_CAR_CAR_PRICE_FOR_CALCULATOR,
      post: InputSuffix.EUROS,
      value: variation.carPriceForCalculator || "",
      disabled: true,
    },

    [EDealVariation.MAINTENANCE_BUDGET_VAT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_CAR_MAINTENANCE_BUDGET,
      placeholderTranslation: ETranslation.DEAL_CAR_MAINTENANCE_BUDGET,
      post: InputSuffix.EUROS,
      value: variation.maintenanceBudgetVAT || "",
      disabled: false,
    },

    [EDealVariation.GROSS_PROFIT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_GROSS_PROFIT,
      placeholderTranslation: ETranslation.DEAL_GROSS_PROFIT,
      post: InputSuffix.EUROS,
      value: variation.grossProfit || "",
      disabled: true,
      validation: {
        required: true,
      },
      validationResult: !!variation.grossProfit ? VALID_DEFAULT : INVALID_DEFAULT,
    },

    [EDealVariation.FUNDABLE_CAPITAL]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_MACHINE_FUNDABLE_CAPITAL,
      placeholderTranslation: ETranslation.DEAL_MACHINE_FUNDABLE_CAPITAL,
      post: InputSuffix.EUROS,
      value: variation.fundableCapital || "",
      disabled: true,
    },

    [EDealVariation.FUNDABLE_CAPITAL_VAT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_MACHINE_FUNDABLE_CAPITAL_VAT,
      placeholderTranslation: ETranslation.DEAL_MACHINE_FUNDABLE_CAPITAL_VAT,
      post: InputSuffix.EUROS,
      value: variation.fundableCapital ? (toTwoDecimals(variation.fundableCapital * vatPercent)) : "",
      disabled: true,
    },

    [EDealVariation.TOTAL_PRICE_VAT_INCLUDING_POLLUTION]: {
      type: EInputType.number,
      labelTranslation:
        ETranslation.DEAL_CAR_TOTAL_PRICE_VAT_INCLUDING_POLLUTION,
      placeholderTranslation:
        ETranslation.DEAL_CAR_TOTAL_PRICE_VAT_INCLUDING_POLLUTION,
      post: InputSuffix.EUROS,
      value: variation.totalPriceVATIncludingPollution || "",
      disabled: true,
    },
    [EDealVariation.EXTRA_KILOMETER_CHARGE]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_EXTRA_KILOMETER_CHARGE,
      placeholderTranslation: ETranslation.DEAL_EXTRA_KILOMETER_CHARGE,
      post: InputSuffix.EUROS,
      value: variation.extraKilometerCharge || "",
      disabled: false,
    },
    [EDealVariation.DOWN_PAYMENT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_DOWN_PAYMENT,
      placeholderTranslation: ETranslation.DEAL_DOWN_PAYMENT,
      post: InputSuffix.EUROS,
      value: variation.downPayment || "",
      disabled: false,
    },
    [EDealVariation.PAYMENT_TYPE]: {
      type: EInputType.select,
      labelTranslation: ETranslation.DEAL_PAYMENT_TYPE,
      options: paymentTypeOptions,
      value: variation.paymentType || EPaymentType.LEASING,
      disabled: false,
    },
    [EDealVariation.RESIDUAL_VALUE_LIABILITY]: {
      type: EInputType.select,
      labelTranslation: ETranslation.DEAL_RESIDUAL_VALUE_LIABILITY,
      options: residualValueLiabilityOptions,
      value: variation.residualValueLiability || "",
      disabled: false,
    },
    [EDealVariation.isUsedCar]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.DEAL_IS_USED_CAR,
      value: "",
      disabled: false,
    },

    [EDealVariation.kickbackVAT]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_KICKBACK_VAT,
      placeholderTranslation: ETranslation.DEAL_KICKBACK_VAT,
      post: InputSuffix.EUROS,
      value: variation.kickbackVAT || "",
    },
    [EDealVariation.kickback]: {
      type: EInputType.number,
      labelTranslation: ETranslation.DEAL_KICKBACK,
      placeholderTranslation: ETranslation.DEAL_KICKBACK,
      post: InputSuffix.EUROS,
      value: variation.kickback || "",
      disabled: true,
    },
  });

  const [extraFees, setExtraFees] = useState<IDealExtraFee[]>(
    variation.extraFees || []
  );

  useEffect(() => {
    if (financeCompany?.interestRate) {
      setVariationInputs((inputs) => {
        const newInputs = { ...inputs };
        const intrestRate = newInputs[EDealVariation.INTEREST_RATE].value;
        if (intrestRate) return inputs;
        newInputs[EDealVariation.INTEREST_RATE].value =
          financeCompany.interestRate;
        return newInputs;
      });
    }
  }, [financeCompany]);

  if (variation.hidden) return null;

  return (
    <DealVariationContext.Provider
      value={{
        variation,
        variationInputs,
        setVariationInputs,
        extraFees,
        setExtraFees,
        index,
      }}
    >
      {isCar && <DealVariationEditCar />}
      {isMachine && <DealVariationEditMachine />}
    </DealVariationContext.Provider>
  );
};

export default DealVariationEdit;
