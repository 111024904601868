import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFinanceCompanyOptions } from '../../../hooks/useFinanceCompanyOptions';
import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';

interface IProps {
  setFinanceCompanyId: React.Dispatch<React.SetStateAction<string>>;
  financeCompanyId: string;
}

const FinanceCompanySearch: React.FC<IProps> = ({ setFinanceCompanyId, financeCompanyId }) => {

  const { financeCompanyOptions, financeCompaniesLoading } = useFinanceCompanyOptions();
  const { t } = useTranslation();

  return (
    <div>
      <label>{t(ETranslation.DEAL_FINANCE_COMPANY)}</label>
      <Select
        inputName={"financeCompaniesSearch"}
        value={financeCompanyId}
        onChange={setFinanceCompanyId}
        options={financeCompanyOptions}
        isSearchable
        isClearable
        isLoading={financeCompaniesLoading}
      />
    </div>
  )

}

export default FinanceCompanySearch;
