export enum ERoute {
  LOGIN = "/login",
  AUTH_ERROR = "/auth-error",
  DEALS = "/deals",
  DEALS_NEW = "/deals/new",
  DEAL = "/deals/:id",
  DEAL_PREOFFER = "/deals/preoffer=:id",
  DEAL_TOKEN = "/deal/:id",
  DRAFTS = "/drafts",
  DRAFTS_NEW = "/drafts/new",
  DRAFT = "/drafts/:id",
  PREOFFERS = "/preoffers",
  PARTNER_DEALS = "/partner-deals",
  REPORTS = "/reports/:status",
  CUSTOMERS = "/customers",
  CUSTOMERS_NEW = "/customers/new",
  CUSTOMER = "/customers/:id",
  PARTNERS = "/partners",
  PARTNERS_NEW = "/partners/new",
  PARTNER = "/partners/:id",
  SETTINGS = "/settings",
  USERS = "/users",
  USERS_NEW = "/users/new",
  USER = "/users/:id",
  FINANCE_COMPANIES = "/finance-companies",
  FINANCE_COMPANIES_NEW = "/finance-companies/new",
  FINANCE_COMPANY = "/finance-companies/:id",
  DEALERSHIPS = "/dealerships",
  DEALERSHIPS_NEW = "/dealerships/new",
  DEALERSHIP = "/dealerships/:id",
  PIPEDRIVE_DEAL = `/deals/pipedrive`,
  DASHBOARD = "/dashboard",
  ORDER_CONFIRMATION = "/order-confirmation/:id",
  ORGANIZATION_USER = "/customers/:organizationId/users/:id",
  ORGANIZATION_USERS_NEW = "/customers/:organizationId/users/new",
  INVOICES = "/invoices",
  LEASING_LIABILITIES_REPORT = "/leasing-liabilities",
  MAINTENANCE_BUDGET_REPORT = "/reports/maintenance-budget",
  MAINTENANCE_LEASING_REPORT = "/reports/maintenance-leasing",
  TERMINATION_CALCULATION = "/termination-calculation/:id",
  MAINTENANCE_INFO = "/maintenance-info/:id",
  LOG_ITEMS = "/log-items",
  LOG_ITEM = "/log-items/:id",
}

export enum ERouteStaticParams {
  new = 'new'
}

type TParam = string | ERouteStaticParams;

interface IParams {
  [key: string]: TParam;
}

export class Routes {
  public static LOGIN = ERoute.LOGIN;
  public static AUTH_ERROR = ERoute.AUTH_ERROR;
  public static DEALS = ERoute.DEALS;
  public static DEALS_NEW = ERoute.DEALS_NEW;
  public static DEAL = (id: TParam) => Routes.withParams(ERoute.DEAL, { id });
  public static DEAL_PREOFFER = (id: TParam) => Routes.withParams(ERoute.DEAL_PREOFFER, { id });
  public static DEAL_TOKEN = (id: TParam) => Routes.withParams(ERoute.DEAL_TOKEN, { id });
  public static DRAFTS = ERoute.DRAFTS;
  public static DRAFTS_NEW = ERoute.DRAFTS_NEW;
  public static DRAFT = (id: TParam) => Routes.withParams(ERoute.DRAFT, { id });
  public static PREOFFERS = ERoute.PREOFFERS;
  public static PARTNER_DEALS = ERoute.PARTNER_DEALS;
  public static REPORTS = (status: TParam) => Routes.withParams(ERoute.REPORTS, { status });
  public static CUSTOMERS = ERoute.CUSTOMERS;
  public static CUSTOMERS_NEW = ERoute.CUSTOMERS_NEW;
  public static CUSTOMER = (id: TParam) => Routes.withParams(ERoute.CUSTOMER, { id });
  public static PARTNERS = ERoute.PARTNERS;
  public static PARTNERS_NEW = ERoute.PARTNERS_NEW;
  public static PARTNER = (id: TParam) => Routes.withParams(ERoute.PARTNER, { id });
  public static SETTINGS = ERoute.SETTINGS;
  public static USERS = ERoute.USERS;
  public static USERS_NEW = ERoute.USERS_NEW;
  public static USER = (id: TParam) => Routes.withParams(ERoute.USER, { id });
  public static FINANCE_COMPANIES = ERoute.FINANCE_COMPANIES;
  public static FINANCE_COMPANIES_NEW = ERoute.FINANCE_COMPANIES_NEW;
  public static FINANCE_COMPANY = (id: TParam) => Routes.withParams(ERoute.FINANCE_COMPANY, { id });
  public static DEALERSHIPS = ERoute.DEALERSHIPS;
  public static DEALERSHIPS_NEW = ERoute.DEALERSHIPS_NEW;
  public static DEALERSHIP = (id: TParam) => Routes.withParams(ERoute.DEALERSHIP, { id });
  public static PIPEDRIVE_DEAL = ERoute.PIPEDRIVE_DEAL;
  public static DASHBOARD = ERoute.DASHBOARD;
  public static ORDER_CONFIRMATION = (id: TParam) => Routes.withParams(ERoute.ORDER_CONFIRMATION, { id });
  public static ORGANIZATION_USER = (organizationId: TParam, id: TParam) => Routes.withParams(ERoute.ORGANIZATION_USER, { organizationId, id });
  public static ORGANIZATION_USERS_NEW = (organizationId: TParam) => Routes.withParams(ERoute.ORGANIZATION_USERS_NEW, { organizationId });
  public static INVOICES = ERoute.INVOICES;
  public static LEASING_LIABILITIES_REPORT = ERoute.LEASING_LIABILITIES_REPORT;
  public static MAINTENANCE_BUDGET_REPORT = ERoute.MAINTENANCE_BUDGET_REPORT;
  public static MAINTENANCE_LEASING_REPORT = ERoute.MAINTENANCE_LEASING_REPORT;
  public static TERMINATION_CALCULATION = (id: TParam) => Routes.withParams(ERoute.TERMINATION_CALCULATION, { id });
  public static MAINTENANCE_INFO = (id: TParam) => Routes.withParams(ERoute.MAINTENANCE_INFO, { id });
  public static LOG_ITEMS = ERoute.LOG_ITEMS;
  public static LOG_ITEM = (id: TParam) => Routes.withParams(ERoute.LOG_ITEM, { id });

  public static withParams(route: ERoute, params: IParams): string {
    let routeWithParams: string = route;
    for(let key in params) {
      routeWithParams = routeWithParams.replace(`:${key}`, params[key] as string);
    }
    return routeWithParams;
  }

  public static isNew(id: TParam): boolean {
    return id === ERouteStaticParams.new;
  }

  public static isNotNew(id: TParam): boolean {
    return id !== ERouteStaticParams.new;
  }

}
