import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../../../../hooks/useCreateInput";
import { getInputData, validateInputs } from "../../../../../shared/utility";
import { ETranslation } from "../../../../../translations/translation-keys";
import Button from "../../../../UI/Button/Button";
import { EInputType, IInputField, IOption } from "../../../../UI/Input/Input";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import ModalBody from "../../../../UI/Modal/ModalBody/ModalBody";
import ModalContext from "../../../../UI/Modal/ModalContext";
import ModalFooter from "../../../../UI/Modal/ModalFooter/ModalFooter";

interface IProps {
  link: string;
}

export enum EDealMaintenanceInfoDialog {
  serviceProvider = "serviceProvider",
  text = "text",
}

export enum EServiceProvider {
  OLF = "OLF",
  IMPORTER = "IMPORTER",
}

const serviceProviderOptions: IOption[] = [
  { value: EServiceProvider.OLF, label: "OLF" },
  { value: EServiceProvider.IMPORTER, label: "Maahantuoja" },
];

type MaintenanceInfo = {
  serviceProvider: string;
  text: string;
};

const DealMaintenanceInfoDialog: React.FC<IProps> = ({ link }) => {
  const { t } = useTranslation();
  const [isValid, setValid] = useState(false);
  const { closeModal } = useContext(ModalContext);

  const [inputs, setInputs] = useState<IInputField>({
    [EDealMaintenanceInfoDialog.serviceProvider]: {
      type: EInputType.select,
      label: "Huolto-ohjelman mukaiset huollot",
      placeholder: "Huolto-ohjelman mukaiset huollot",
      value: "",
      options: serviceProviderOptions,
    },
    [EDealMaintenanceInfoDialog.text]: {
      type: EInputType.textarea,
      label: "Huolto-ohjelman teksti",
      placeholder: "Huolto-ohjelman teksti",
      value: "",
    },
  });

  const createInput = useCreateInput(inputs, setInputs);

  useEffect(() => {
    setValid(validateInputs(inputs));
  }, [inputs]);

  const submitHandler = () => {
    const data = getInputData<MaintenanceInfo>(inputs);
    window.open(
      `${link}${encodeURIComponent(
        `&sp=${data.serviceProvider}&text=${data.text ?? ""}`
      )}`,
      "_blank"
    );
    closeModal();
  };

  return (
    <React.Fragment>
      <ModalBody style={{ display: "flex", flexDirection: "column" }}>
        <p>{t(ETranslation.DEAL_MAINTENANCE_DIALOG_TEXT)}</p>
        {createInput(EDealMaintenanceInfoDialog.serviceProvider)}
        {createInput(EDealMaintenanceInfoDialog.text)}
      </ModalBody>
      <ModalFooter>
        <InputGroup>
          <Button disabled={!isValid} onClick={submitHandler}>
            {t(ETranslation.COMMON_YES)}
          </Button>
          <Button onClick={() => closeModal()}>
            {t(ETranslation.COMMON_NO)}
          </Button>
        </InputGroup>
      </ModalFooter>
    </React.Fragment>
  );
};

export default DealMaintenanceInfoDialog;
