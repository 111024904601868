import React from "react";
import { IInputField } from "./../components/UI/Input/Input";
import { IDealExtraFee, IDealPricingVariation } from "./../interfaces/domain";

const DealVariationContext = React.createContext<{
  variation: IDealPricingVariation | null;
  variationInputs: IInputField;
  setVariationInputs: React.Dispatch<React.SetStateAction<IInputField>>;
  extraFees: IDealExtraFee[];
  setExtraFees: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>;
  index?: number;
}>({
  variation: null,
  variationInputs: {},
  setVariationInputs: () => {},
  extraFees: [],
  setExtraFees: () => {},
  index: undefined,
});

export default DealVariationContext;
