import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreatableReactSelect from "react-select/creatable";
import ReactSelect from "react-select";
import { ETranslation } from "../../../translations/translation-keys";
import { IOption } from "../Input/Input";

interface IProps {
  inputName?: string;
  value: string;
  onChange: (value: string) => void;
  onInputChange?: (value: string) => void;
  options?: IOption[];
  disabled?: boolean;
  invalid?: boolean;
  className?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isCreatable?: boolean;
  isMulti?: boolean;
}

const Select: React.FC<IProps> = ({
  inputName, value, onChange, onInputChange, options, disabled, invalid, className, isSearchable, isClearable, isLoading, isMulti, isCreatable = false
}) => {
  const { t } = useTranslation();



  const [selectValue, setSelectValue] = useState<IOption>();
  const [selectOptions, setSelectOptions] = useState<IOption[]>([]);

  useEffect(() => {
    if(value && options) {
      setSelectValue(options.find(o => o.value === value));
    } else {
      setSelectValue(undefined);
    }

  }, [value, options]);

  useEffect(() => {
    setSelectOptions(options ? options.map(o => ({ label: (o.labelTranslation ? t(o.labelTranslation) : o.label) || o.value, value: o.value })) : []);
  }, [options, t]);

  const changeHandler = (option: IOption | null) => {
    onChange(option?.value || '');
  }

  return (
    isCreatable ? (
      <CreatableReactSelect
        name={inputName}
        value={selectValue}
        onChange={(option) => changeHandler(option as IOption | null)}
        options={selectOptions}
        isDisabled={disabled}
        invalid={invalid}
        className={className}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isLoading={isLoading}
        formatCreateLabel={value => `${t(ETranslation.REACT_SELECT_ADD_NEW)} "${value}"`}
        placeholder={t(ETranslation.REACT_SELECT_PLACEHOLDER)}
        isMulti={isMulti}
      />
    ) : (
        <ReactSelect
          name={inputName}
          value={selectValue}
          onChange={(option) => changeHandler(option as IOption | null)}
          options={selectOptions}
          isDisabled={disabled}
          invalid={invalid}
          className={className}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isLoading={isLoading}
          noOptionsMessage={() => t(ETranslation.NOT_FOUND_ERROR)}
          placeholder={t(ETranslation.REACT_SELECT_PLACEHOLDER)}
          isMulti={isMulti}
        />
      )
  )
}

export default Select;
